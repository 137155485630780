import { useEffect, useMemo, useState } from "react";
import {
  Alert,
  Button,
  cn,
  Dropdown,
  Flex,
  Input,
  JsonViewer,
  Loading,
  Select,
  Tooltip,
  Typography,
} from "djuno-design";

import {
  selectDSSCapabilitiesConnector,
  selectDSSCapabilitiesConnectorLoading,
  selectDSSConnector,
  selectDSSConnectorLoading,
  selectDSSConnectorsConfiguration,
  selectDSSConnectorsConfigurationLoading,
  selectDSSConnectorTransforms,
} from "../../../store/database/serviceSlice";
import { useAppSelector } from "../../../hooks";
import { GroupType } from "../../../pages/data-streaming/connectors/ConnectorPage";
import RadioGrouper from "../../general/RadioGrouper";
import { ReactComponent as InfoIcon } from "../../../assets/icons/question-mark-circle.svg";
import { ReactComponent as DeleteIcon } from "../../../assets/icons/archive-box.svg";
import { ReactComponent as Arrowdown } from "../../../assets/icons/arrow-down.svg";
import { useLocation } from "react-router-dom";

export const ragioGroupItems = [
  { label: "Default", value: "default" },
  { label: "True", value: "true" },
  { label: "False", value: "false" },
];

interface ConnectorTabProps {
  group?: GroupType;
  configData?: Record<string, any>;
  onChange?: (group: string, paramName: string, value: any) => void;
  setConfigData?: (data: any) => void;
}

const maskPasswords = (data: { [key: string]: any }) => {
  const maskedData = { ...data }; // Create a shallow copy of the configData object

  Object.keys(maskedData).forEach((key) => {
    if (
      key.toLowerCase().includes("password") &&
      typeof maskedData[key] === "string"
    ) {
      // Replace the password with a string of asterisks equal to the length of the actual password
      maskedData[key] = "*".repeat(maskedData[key].length);
    }
  });

  return maskedData;
};

const ConnectorTab: React.FC<ConnectorTabProps> = ({
  group,
  configData = {},
  onChange = () => {},
  setConfigData = () => {},
}) => {
  const capabilitiesConnectorLoading = useAppSelector(
    selectDSSCapabilitiesConnectorLoading
  );
  const configurationLoading = useAppSelector(
    selectDSSConnectorsConfigurationLoading
  );
  const connector = useAppSelector(selectDSSConnector);
  const connectorLoading = useAppSelector(selectDSSConnectorLoading);

  const location = useLocation();
  const isEditMode = location.pathname.includes("edit");

  if (!group) return null;

  const handleBooleanChange = (name: string, value: string) => {
    setConfigData((prev: Record<string, string>) => {
      const updatedConfig = { ...prev };

      if (value === ragioGroupItems[0].value) {
        delete updatedConfig[name];
      } else {
        updatedConfig[name] = value === "true" ? "true" : "false";
      }

      return updatedConfig;
    });
  };

  const handleSelectChange = (name: string, value: string) => {
    if (value === "default") {
      const updatedConfig = { ...configData };
      delete updatedConfig[name];
      setConfigData(updatedConfig);
    } else {
      onChange(group.title, name, value);
    }
  };

  const handleInputChange = (group: string, name: string, value: string) => {
    setConfigData((prev: Record<string, string | boolean>) => {
      const updatedConfig = { ...prev };

      if (value.trim() === "") {
        delete updatedConfig[name];
      } else {
        updatedConfig[name] = value;
      }

      return updatedConfig;
    });
  };

  const uniqueItemNames = new Set<string>();

  return (
    <>
      {connectorLoading ||
      capabilitiesConnectorLoading ||
      configurationLoading ? (
        <Flex justify="center" items="center" className="!min-h-[300px]">
          <Loading borderSize={2} />
        </Flex>
      ) : (
        <>
          <div className="grid grid-cols-8 gap-5 justify-between p-5">
            <div className="mt-5 w-full flex flex-col gap-5 col-span-8 md:col-span-5 xl:col-span-4">
              {group.parameters.map((item) => {
                const isFieldEmpty = !configData[item.name];

                if (group.title === "Extra" || group.title === "Transforms")
                  return null;
                if (uniqueItemNames.has(item.name)) return null; // Changed variable name
                uniqueItemNames.add(item.name);

                if (
                  (item.type === "string" ||
                    item.type === "password" ||
                    item.type === "list" ||
                    item.type === "class") &&
                  !item.values
                ) {
                  return (
                    <Input
                      key={item.name}
                      label={item.displayName || item.name}
                      type={item.type}
                      tooltip={{ content: item.description }}
                      required={item.required}
                      placeholder={item.type}
                      value={
                        isEditMode && group.title === "Common" && item.required
                          ? connector?.name ?? configData[item.name] ?? ""
                          : configData[item.name] ?? ""
                      }
                      error={
                        isEditMode && group.title === "Common" && item.required
                          ? ""
                          : item.required && isFieldEmpty
                          ? `${item.displayName} is required`
                          : ""
                      }
                      onChange={(e) =>
                        handleInputChange(
                          group.title,
                          item.name,
                          e.target.value
                        )
                      }
                      labelClassName="mt-5 !whitespace-normal"
                      disabled={
                        isEditMode && group.title === "Common" && item.required
                      }
                    />
                  );
                }

                if (
                  item.type === "int32" ||
                  item.type === "int64" ||
                  item.type === "int16"
                ) {
                  return (
                    <Input
                      key={item.name}
                      label={item.displayName || item.name}
                      type="number"
                      tooltip={{ content: item.description }}
                      required={item.required}
                      value={
                        configData.hasOwnProperty(item.name)
                          ? configData[item.name]
                          : item.defaultValue ?? ""
                      }
                      error={
                        item.required && isFieldEmpty
                          ? `${item.displayName} is required`
                          : ""
                      }
                      onChange={(e) =>
                        handleInputChange(
                          group.title,
                          item.name,
                          e.target.value
                        )
                      }
                      labelClassName="mt-5 !whitespace-normal"
                      readOnly
                      onFocus={(e) => e.target.removeAttribute("readonly")}
                      placeholder={item.type}
                    />
                  );
                }

                if (item.values) {
                  return (
                    <Select
                      key={item.name}
                      label={item.displayName}
                      value={configData[item.name] ?? "default"}
                      error={
                        item.required && isFieldEmpty
                          ? `${item.displayName} is required`
                          : ""
                      }
                      onChange={(value) =>
                        handleSelectChange(item.name, value || "default")
                      }
                      options={[
                        { label: "Default", value: "default" },
                        ...(item.values
                          ? item.values.map((val) => ({
                              label: val,
                              value: val,
                            }))
                          : []),
                      ]}
                      labelClassName="mt-5 !whitespace-normal"
                      required={item.required}
                      tooltip={{ content: item.description }}
                    />
                  );
                }

                if (item.type === "boolean") {
                  return (
                    <>
                      <Typography.Text className="flex items-center gap-1 !text-sm whitespace-nowrap mt-5 mb-2 ">
                        {item.displayName || item.name}
                        <Tooltip content={item.description}>
                          {" "}
                          <InfoIcon className="dd-w-4 dd-text-slate-500 dark:dd-text-slate-300 dark:hover:dd-text-slate-100" />
                        </Tooltip>
                      </Typography.Text>
                      <RadioGrouper
                        items={ragioGroupItems}
                        selected={
                          ragioGroupItems.find(
                            (i) =>
                              i.value ===
                              (configData[item.name] !== undefined
                                ? configData[item.name]
                                : ragioGroupItems[0].value)
                          ) || ragioGroupItems[0]
                        }
                        setSelected={(s) =>
                          handleBooleanChange(item.name, s.value)
                        }
                      />
                    </>
                  );
                }

                return null;
              })}

              {group.title === "Extra" && configData && setConfigData && (
                <>
                  <Alert
                    description="
                       Here you can define connector configurations parameters, that are not defined in the configuration schema."
                    uiType="info"
                    showIcon
                  />
                  <ExtraConfigFields
                    configData={configData}
                    setConfigData={setConfigData}
                  />
                </>
              )}
              {group.title === "Transforms" && (
                <div className="">
                  <Alert
                    description={
                      <Typography.Text>
                        Transformations enable simple message-at-a-time
                        modifications. See{" "}
                        <Typography.Link
                          href="https://kafka.apache.org/documentation.html#connect_transforms"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          documentation
                        </Typography.Link>{" "}
                        for more details.
                      </Typography.Text>
                    }
                    uiType="info"
                    showIcon
                  />

                  <div className="flex mt-5">
                    <TransformsSection
                      configData={configData}
                      setConfigData={setConfigData}
                    />
                  </div>
                </div>
              )}
            </div>

            <div className="mt-5 w-full flex flex-col gap-5 col-span-8 md:col-span-3 xl:col-span-4">
              <div className="sticky top-24">
                <div className="w-full overflow-x-auto">
                  <Typography.Text>Connector configuration</Typography.Text>
                  <JsonViewer value={maskPasswords(configData)} />
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

interface Field {
  id: number;
  key: string;
  value: string;
  isNew?: boolean;
}

interface ExtraConfigFieldsProps {
  configData: Record<string, any>;
  setConfigData: (data: Record<string, any>) => void;
}

const ExtraConfigFields: React.FC<ExtraConfigFieldsProps> = ({
  configData,
  setConfigData,
}) => {
  const [fields, setFields] = useState<Field[]>([]);
  const configuration = useAppSelector(selectDSSConnectorsConfiguration);
  const capabilitiesConnector = useAppSelector(selectDSSCapabilitiesConnector);

  const ignoredKeys = useMemo(() => {
    return new Set(
      configuration
        .filter((item) => item.group !== "Extra")
        .map((item) => item.name)
    );
  }, [configuration]);

  const ignoredPrefixes = useMemo(() => {
    return configuration
      .filter((item) => item.group !== "Extra")
      .map((item) => item.group);
  }, [configuration]);

  useEffect(() => {
    setFields((prevFields) => {
      const existingKeys = new Set(prevFields.map((f) => f.key));

      const extraConfigFields = Object.entries(configData)

        .filter(([key]) => !ignoredKeys.has(key))
        .filter(([key]) => !key.startsWith("transforms."))
        .filter(([key]) => !key.startsWith(capabilitiesConnector?.author || ""))
        .filter(
          ([key]) =>
            !ignoredPrefixes.some((prefix) => key.startsWith(`${prefix}.`))
        )
        .filter(([key]) => !existingKeys.has(key))
        .map(([key, value]) => ({
          id: Date.now() + Math.random(),
          key,
          value,
          isNew: false,
        }));

      return [...prevFields, ...extraConfigFields];
    });
  }, [configData, ignoredKeys, ignoredPrefixes]);

  const handleChange = (id: number, type: "key" | "value", val: string) => {
    setFields((prevFields) =>
      prevFields.map((field) =>
        field.id === id ? { ...field, [type]: val } : field
      )
    );
  };

  const handleBlur = (id: number) => {
    setFields((prevFields) => {
      return prevFields.map((field) => {
        if (field.id === id && field.key && field.value) {
          setConfigData((prevConfig: Record<string, any>) => ({
            ...prevConfig,
            [field.key]: field.value,
          }));
          return { ...field, isNew: false };
        }
        return field;
      });
    });
  };

  // Add a new empty row for user input
  const handleAddRow = () => {
    setFields((prevFields) => [
      ...prevFields,
      { id: Date.now(), key: "", value: "", isNew: true },
    ]);
  };

  // Remove a field and update configData accordingly
  const handleRemoveField = (id: number) => {
    setFields((prevFields) => {
      const updatedFields = prevFields.filter((f) => f.id !== id);
      return updatedFields.length > 0
        ? updatedFields
        : [{ id: Date.now(), key: "", value: "", isNew: true }];
    });

    setConfigData((prevConfig: Record<string, any>) => {
      const newConfig = { ...prevConfig };
      const field = fields.find((f) => f.id === id);
      if (field && field.key in newConfig) {
        delete newConfig[field.key];
      }
      return newConfig;
    });
  };

  return (
    <div className="mt-5 space-y-4">
      <Typography.Text className="text-lg font-semibold">
        Additional Configuration
      </Typography.Text>
      {fields.map((field) => (
        <div key={field.id} className="flex items-center gap-3">
          <Input
            label="Configuration key"
            placeholder="Key"
            value={field.key}
            onChange={(e) => handleChange(field.id, "key", e.target.value)}
            onBlur={() => handleBlur(field.id)}
            disabled={!field.isNew}
          />
          <Input
            label="Value"
            placeholder="Value"
            value={field.value}
            onChange={(e) => handleChange(field.id, "value", e.target.value)}
            onBlur={() => handleBlur(field.id)}
            disabled={!field.isNew}
          />
          <Button onClick={() => handleRemoveField(field.id)} className="mt-5">
            -
          </Button>
        </div>
      ))}
      <Button onClick={handleAddRow}>+ Add configuration</Button>
    </div>
  );
};

//Transforms tab
interface ConfigData {
  [key: string]: string;
}
interface TransformsSectionProps {
  configData: ConfigData;
  setConfigData: React.Dispatch<React.SetStateAction<ConfigData>>;
}

const TransformsSection: React.FC<TransformsSectionProps> = ({
  configData,
  setConfigData,
}) => {
  const connectorTransforms = useAppSelector(selectDSSConnectorTransforms);

  const transformTypes = useMemo(() => {
    return Array.from(new Set(connectorTransforms.map((t) => t.transformType)));
  }, [connectorTransforms]);

  const transformNames = useMemo(() => {
    return Array.from(
      new Set(
        Object.keys(configData)
          .filter(
            (key) =>
              key.startsWith("transforms.") && key.split(".").length === 2
          )
          .map((key) => key.split(".")[1])
      )
    );
  }, [configData]);

  const handleAddTransform = () => {
    if (transformTypes.length === 0) return;

    const existingNumbers = transformNames
      .map((name) => parseInt(name.split("-")[1]))
      .filter((num) => !isNaN(num));

    const nextNumber =
      existingNumbers.length > 0 ? Math.max(...existingNumbers) + 1 : 1;
    const newTransformName = `transform-${nextNumber}`;

    const newTransformNames = [...transformNames, newTransformName];
    const newTransformsList = newTransformNames.join(", ");

    setConfigData((prevConfigData) => ({
      ...prevConfigData,
      [`transforms.${newTransformName}`]: newTransformName,
      [`transforms.${newTransformName}.type`]: transformTypes[0],
      transforms: newTransformsList,
    }));
  };

  const handleUpdateTransformType = (
    transformName: string,
    newType: string
  ) => {
    setConfigData((prevConfigData) => {
      const updatedConfigData = Object.keys(prevConfigData).reduce(
        (newConfig, key) => {
          if (!key.startsWith(`transforms.${transformName}.`)) {
            newConfig[key] = prevConfigData[key];
          }
          return newConfig;
        },
        {} as Record<string, string>
      );

      const newTransformNames = Object.keys(updatedConfigData)
        .filter(
          (key) => key.startsWith("transforms.") && key.split(".").length === 2
        )
        .map((key) => key.split(".")[1]);
      const newTransformsList = newTransformNames.join(", ");

      return {
        ...updatedConfigData,
        [`transforms.${transformName}.type`]: newType,
        transforms: newTransformsList, // Add updated transforms key
      };
    });
  };

  const processConfigKey = (configKey: string): string => {
    const parts = configKey.split(".");

    if (parts.length > 2 && parts[0] === "transforms") {
      return parts.slice(2).join("."); // Remove "transforms.<transformName>" prefix
    }

    return configKey;
  };

  const handleAddConfigOption = (transformName: string, configKey: string) => {
    const key = processConfigKey(configKey);

    setConfigData((prevConfigData) => ({
      ...prevConfigData,
      [`transforms.${transformName}.${key}`]:
        prevConfigData[`transforms.${transformName}.${key}`] || "",
    }));
  };

  const handleRemoveConfigOption = (
    transformName: string,
    configKey: string
  ) => {
    setConfigData((prevConfigData) => {
      const updatedConfig = { ...prevConfigData };
      delete updatedConfig[configKey];
      return updatedConfig;
    });
  };

  const handleUpdateConfigValue = (
    transformName: string,
    configKey: string,
    value: string
  ) => {
    const key = processConfigKey(configKey);

    setConfigData((prevConfigData) => ({
      ...prevConfigData,
      [`transforms.${transformName}.${key}`]: value,
    }));
  };

  const handleBooleanChange = (name: string, value: string) => {
    setConfigData((prev: Record<string, string>) => {
      const updatedConfig = { ...prev };

      if (value === ragioGroupItems[0].value) {
        delete updatedConfig[name];
      } else {
        updatedConfig[name] = value === "true" ? "true" : "false";
      }

      return updatedConfig;
    });
  };

  const handleRemoveTransform = (transformName: string) => {
    const updatedConfig = { ...configData };
    Object.keys(updatedConfig).forEach((key) => {
      if (key.startsWith(`transforms.${transformName}`)) {
        delete updatedConfig[key];
      }
    });
    setConfigData(updatedConfig);
  };

  const [tempTransformNames, setTempTransformNames] = useState<
    Record<string, string>
  >({});

  useEffect(() => {
    const timeouts: Record<string, NodeJS.Timeout> = {};

    Object.keys(tempTransformNames).forEach((oldName) => {
      const newName = tempTransformNames[oldName]?.trim();

      if (newName && newName !== oldName) {
        timeouts[oldName] = setTimeout(() => {
          handleRenameTransform(oldName, newName);
        }, 1000);
      }
    });

    return () => Object.values(timeouts).forEach(clearTimeout);
  }, [tempTransformNames]);

  const handleRenameTransform = (oldName: string, newName: string) => {
    if (!newName || newName === oldName || transformNames.includes(newName))
      return;

    setConfigData((prevConfigData) => {
      const updatedConfig: ConfigData = {};
      Object.keys(prevConfigData).forEach((key) => {
        if (key.startsWith(`transforms.${oldName}`)) {
          return;
        } else {
          updatedConfig[key] = prevConfigData[key];
        }
      });

      updatedConfig[`transforms.${newName}`] = newName;
      updatedConfig[`transforms.${newName}.type`] =
        prevConfigData[`transforms.${oldName}.type`] || transformTypes[0];

      // Update the transforms list
      const newTransformNames = Object.keys(updatedConfig)
        .filter(
          (key) => key.startsWith("transforms.") && key.split(".").length === 2
        )
        .map((key) => key.split(".")[1]);

      const newTransformsList = newTransformNames.join(", ");

      return {
        ...updatedConfig,
        transforms: newTransformsList,
      };
    });

    setTempTransformNames((prev) => {
      const { [oldName]: _, ...rest } = prev;
      return { ...rest, [newName]: newName };
    });
  };

  useEffect(() => {
    setTimeout(() => {
      window.dispatchEvent(new Event("resize"));
    }, 100);
  }, [configData]);

  return (
    <div className="space-y-4">
      <Typography.Text className="text-lg font-semibold">
        Transforms
      </Typography.Text>

      {transformNames.map((transformName) => {
        const transformType =
          configData[`transforms.${transformName}.type`] || "";
        const availableConfigOptions = connectorTransforms.filter(
          (t) => t.transformType === transformType
        );

        return (
          <div
            key={transformName}
            className="border p-4 rounded-lg shadow-sm space-y-3"
          >
            <div className="flex justify-between">
              <Typography.Text className="font-semibold">
                {transformName}
              </Typography.Text>

              <div className="h-6 flex justify-center items-center">
                <DeleteIcon
                  onClick={() => handleRemoveTransform(transformName)}
                  className="w-5 h-5 text-slate-800 dark:text-slate-100 transition-all duration-200 hover:text-red-600 dark:hover:text-red-300 cursor-pointer"
                />
              </div>
            </div>

            <Input
              label="name"
              value={tempTransformNames[transformName] ?? transformName}
              onChange={(e) =>
                setTempTransformNames({
                  ...tempTransformNames,
                  [transformName]: e.target.value,
                })
              }
              onBlur={() =>
                handleRenameTransform(
                  transformName,
                  tempTransformNames[transformName] ?? transformName
                )
              }
              placeholder="Transform Name"
            />

            {/* transform type */}
            <Select
              label="Type"
              value={transformType}
              onChange={(value) =>
                handleUpdateTransformType(transformName, value || "")
              }
              options={transformTypes.map((type) => ({
                label: type,
                value: type,
              }))}
              optionsClassName="h-40"
            />
            {/* select Config */}
            {availableConfigOptions.length > 0 && (
              <div className="flex">
                <Button
                  onClick={() => {}}
                  uiType="primary"
                  className="!pr-1 !pl-2 !rounded-r-none group !w-[169px]"
                >
                  Add Configuration
                </Button>
                <Flex>
                  <Dropdown
                    anchor="bottom end"
                    itemsClassName="!w-[200px] h-40"
                    menu={availableConfigOptions
                      .filter(
                        (opt) =>
                          !Object.keys(configData).includes(
                            `transforms.${transformName}.${opt.name}`
                          )
                      )
                      .map((opt) => ({
                        key: opt.name,
                        label: <div className="gap-1">{opt.displayName}</div>,
                        onClick: () =>
                          handleAddConfigOption(transformName, opt.name),
                      }))}
                  >
                    <Button
                      uiType="primary"
                      className="!px-1 !rounded-l-none !bg-primary-500 !justify-start "
                    >
                      <Arrowdown className="w-4" />
                    </Button>
                  </Dropdown>
                </Flex>
              </div>
            )}
            {/* create config input */}
            {Object.keys(configData)
              .filter(
                (configKey) =>
                  configKey.startsWith(`transforms.${transformName}.`) &&
                  configKey !== `transforms.${transformName}.type`
              )
              .map((configKey) => {
                const label = configKey.split(".").slice(2).join(".");

                const configOption = availableConfigOptions.find(
                  (opt) =>
                    `transforms.${transformName}.${opt.name}` === configKey
                );
                // console.log("configData[configKey]:", configData[configKey]);

                return (
                  <div
                    key={`${transformName}-${configKey}`}
                    className="flex items-center space-x-2"
                  >
                    {configOption?.type === "boolean" ? (
                      <div className="flex flex-col">
                        <Typography.Text className="flex items-center gap-1 !text-sm whitespace-nowrap mt-5 mb-2">
                          {label}
                          <Tooltip content={configOption?.description}>
                            <InfoIcon className="dd-w-4 dd-text-slate-500 dark:dd-text-slate-300 dark:hover:dd-text-slate-100" />
                          </Tooltip>
                        </Typography.Text>
                        <RadioGrouper
                          items={ragioGroupItems}
                          selected={
                            ragioGroupItems.find(
                              (i) =>
                                i.value ===
                                (configData[configKey] !== undefined
                                  ? configData[configKey]
                                  : ragioGroupItems[0].value)
                            ) || ragioGroupItems[0]
                          }
                          setSelected={(s) =>
                            handleBooleanChange(configKey, s.value)
                          }
                        />
                      </div>
                    ) : configOption?.values ? (
                      <Select
                        label={configOption.name}
                        tooltip={{ content: configOption.description }}
                        required={configOption.required}
                        value={configData[configKey] || ""}
                        onChange={(value) =>
                          handleUpdateConfigValue(
                            transformName,
                            configKey,
                            value || ""
                          )
                        }
                        options={configOption.values.map((value) => ({
                          label: value,
                          value: value,
                        }))}
                      />
                    ) : (
                      <Input
                        label={configOption?.name || ""}
                        tooltip={{ content: configOption?.description || "" }}
                        required={configOption?.required || false}
                        placeholder={configOption?.type || ""}
                        value={configData[configKey] || ""}
                        onChange={(e) =>
                          handleUpdateConfigValue(
                            transformName,
                            configKey,
                            e.target.value
                          )
                        }
                      />
                    )}

                    <div className="h-10 flex justify-center items-center mt-5">
                      <DeleteIcon
                        onClick={() =>
                          handleRemoveConfigOption(transformName, configKey)
                        }
                        className={cn(
                          "w-5 h-5 text-slate-800 dark:text-slate-100 transition-all duration-200 hover:text-red-600 dark:hover:text-red-300 cursor-pointer"
                        )}
                      />
                    </div>
                  </div>
                );
              })}
          </div>
        );
      })}

      <Button onClick={handleAddTransform}>+ Add transform</Button>
    </div>
  );
};

export default ConnectorTab;
