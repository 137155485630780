import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { isAxiosError } from "axios";
import { IThunkRejectValue, RootState } from "../../types";
import type { PayloadAction } from "@reduxjs/toolkit";
import toast from "react-hot-toast";
import {
  CreateIpfsApiKeyData,
  IpfsApiKey,
  IpfsApiKeyStateType,
} from "../../types/ipfs-api-key";
import {
  createIpfsApiKeyApi,
  deleteIpfsApiKeyApi,
  getIpfsApiKeysApi,
  updateIpfsApiKeyApi,
} from "../../apis/ipfsApiKeysAPI";
import { changeIpfsNodeStatus } from "./ipfsPublicSlice";
import { getExtractErrors } from "../../apis";
import { CustomErrorToast } from "../../components/general/Toast";
import { IpfsUpdatingMessage } from "./filesSlice";

const initialState: IpfsApiKeyStateType = {
  loading: false,
  apiKeys: [],
  selectedApiKey: null,
  showEditorModal: false,
  actionLoading: false,
};

export const getIpfsApiKeysAysnc = createAsyncThunk<
  { apiKeys: IpfsApiKey[] },
  undefined,
  IThunkRejectValue
>(
  "ipfs/api-keys",
  async (_, { fulfillWithValue, rejectWithValue, dispatch }) => {
    try {
      const response = await getIpfsApiKeysApi();

      const { NodeStatus, Result } = response.data;
      const apiKeys = Result || [];

      dispatch(changeIpfsNodeStatus({ status: NodeStatus }));

      return fulfillWithValue({ apiKeys });
    } catch (e) {
      if (isAxiosError(e)) {
        dispatch(
          changeIpfsNodeStatus({
            status: e.response?.data.NodeStatus || 4,
            message: IpfsUpdatingMessage,
          })
        );
        return fulfillWithValue({ apiKeys: [] });
      } else {
        return rejectWithValue({ message: getExtractErrors(e) });
      }
    }
  }
);

export const deleteIpfsApiKeyAsync = createAsyncThunk<
  any,
  { id: number },
  IThunkRejectValue
>(
  "ipfs/api-keys/delete",
  async ({ id }, { fulfillWithValue, rejectWithValue }) => {
    try {
      const response = await deleteIpfsApiKeyApi(id);
      return response.data;
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);

export const createIpfsApiKeyAsync = createAsyncThunk<
  any,
  CreateIpfsApiKeyData,
  IThunkRejectValue
>("ipfs/api-keys/create", async (formData, { rejectWithValue }) => {
  try {
    const response = await createIpfsApiKeyApi(formData);
    return response.data;
  } catch (e) {
    return rejectWithValue({ message: getExtractErrors(e) });
  }
});

export const updateIpfsApiKeyAsync = createAsyncThunk<
  any,
  { id: number; formData: CreateIpfsApiKeyData },
  IThunkRejectValue
>("ipfs/api-keys/update", async ({ id, formData }, { rejectWithValue }) => {
  try {
    const response = await updateIpfsApiKeyApi(id, formData);
    return response.data;
  } catch (e) {
    return rejectWithValue({ message: getExtractErrors(e) });
  }
});

const ipfsApiKeysSlice = createSlice({
  name: "ipfsApiKey",
  initialState: initialState,
  reducers: {
    clearApiKeys(state) {
      state.apiKeys = initialState.apiKeys;
      state.loading = initialState.loading;
    },
    handleShowEditorModal: (
      state,
      action: PayloadAction<{ apiKey?: IpfsApiKey }>
    ) => {
      state.showEditorModal = true;
      state.selectedApiKey = action.payload.apiKey || null;
    },
    handleHideEditorModal: (state) => {
      state.showEditorModal = false;
      state.selectedApiKey = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getIpfsApiKeysAysnc.fulfilled, (state, action) => {
        state.apiKeys = action.payload.apiKeys;
        state.loading = false;
      })
      .addCase(getIpfsApiKeysAysnc.pending, (state) => {
        state.loading = true;
      })
      .addCase(getIpfsApiKeysAysnc.rejected, (state, { payload }) => {
        state.loading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(deleteIpfsApiKeyAsync.fulfilled, (state, action) => {
        state.actionLoading = false;
      })
      .addCase(deleteIpfsApiKeyAsync.pending, (state, action) => {
        state.actionLoading = true;
      })
      .addCase(deleteIpfsApiKeyAsync.rejected, (state, { payload }) => {
        state.actionLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(createIpfsApiKeyAsync.pending, (state) => {
        state.actionLoading = true;
      })
      .addCase(createIpfsApiKeyAsync.fulfilled, (state) => {
        state.actionLoading = false;
      })
      .addCase(createIpfsApiKeyAsync.rejected, (state, { payload }) => {
        state.actionLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(updateIpfsApiKeyAsync.pending, (state) => {
        state.actionLoading = true;
      })
      .addCase(updateIpfsApiKeyAsync.fulfilled, (state) => {
        state.actionLoading = false;
      })
      .addCase(updateIpfsApiKeyAsync.rejected, (state, { payload }) => {
        state.actionLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });
  },
});

export const selectIPFSLoading = (state: RootState) =>
  state.ipfsApiKeys.loading;
export const selectIPFSApiKeysActionLoading = (state: RootState) =>
  state.ipfsApiKeys.actionLoading;

export const selectIpfsApiKeys = (state: RootState) =>
  state.ipfsApiKeys.apiKeys;

export const selectSelectedIpfsApiKey = (state: RootState) =>
  state.ipfsApiKeys.selectedApiKey;

export const selectShowEditorModal = (state: RootState) =>
  state.ipfsApiKeys.showEditorModal;

export const { clearApiKeys, handleHideEditorModal, handleShowEditorModal } =
  ipfsApiKeysSlice.actions;

export default ipfsApiKeysSlice.reducer;
