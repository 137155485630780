import { useCallback, useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { InstancesVolumeSnapshotListUrl } from "./../../../../../utils/urls";
import { useAppDispatch, useAppSelector } from "./../../../../../hooks";
import { yupResolver } from "@hookform/resolvers/yup";
import { InstanceVolumeBackupSchema } from "./../../../../../utils/validations";
import { DBSCatalog } from "./../../../../../types/database";
import {
  getDBSCatalogsAsync,
  selectDBSCatalogs,
  selectDBSCatalogsLoading,
} from "./../../../../../store/database/servicesSlice";
import {
  createInstancesVolumeSnapshotAsync,
  getInstancesVolumeAsync,
  getInstancesVolumesAsync,
  selectInstancesSelectedVolume,
  selectInstancesSelectedVolumeLoading,
  selectInstancesVolumesActionLoading,
} from "./../../../../../store/instances/instancesVolumesSlice";
import { RadioGroup } from "@headlessui/react";
import { capitalizeFirstLetter } from "./../../../../../utils";
import { getDBSPriceAmount } from "../../../../databases/ServiceCreateComponents";
import {
  getInstanceVolumeSnapshotListAsync,
  handleHideVolumeSnapshorModal,
  selectshowVolumeSnapshotModal,
} from "./../../../../../store/instances/instancesVolumeSlice";
import {
  Button,
  cn,
  Flex,
  Input,
  Loading,
  Modal,
  Typography,
} from "djuno-design";

const backupTypes = [
  {
    id: "snapshot",
    code: "volume.snapshot.monthly.postpaid",
    title: "Volume Snapshot",
    description: `With snapshot backups, you can quickly revert your system back to a previous state.
  The snapshot is stored on the same storage cluster as your volume.`,
  },
];

const InstanceVolumeSnapshostsModal = () => {
  const { id } = useParams();

  const navigate = useNavigate();
  const isOpen = useAppSelector(selectshowVolumeSnapshotModal);
  const selectedVolume = useAppSelector(selectInstancesSelectedVolume);
  const selectedVolumeLoading = useAppSelector(
    selectInstancesSelectedVolumeLoading
  );
  const catalogs = useAppSelector(selectDBSCatalogs);
  const catalogsLoading = useAppSelector(selectDBSCatalogsLoading);
  const actionLoading = useAppSelector(selectInstancesVolumesActionLoading);

  // states
  const [filteredCatalogs, setFilteredCatalogs] = useState<Array<DBSCatalog>>(
    []
  );

  const dispatch = useAppDispatch();

  const methods = useForm({
    mode: "all",
    resolver: yupResolver(InstanceVolumeBackupSchema),
    defaultValues: {
      selectedType: "volume.snapshot.monthly.postpaid",
      name: "",
    },
  });

  const {
    handleSubmit,
    reset,
    control,
    formState: { errors, isValid },
    register,
    watch,
  } = methods;

  // watch form values
  const selectedType = watch("selectedType");

  //get selected volume
  useEffect(() => {
    if (selectedVolume === null && id !== undefined) {
      dispatch(getInstancesVolumeAsync({ id }));
    }
  }, [dispatch, id, selectedVolume]);

  //get first data
  useEffect(() => {
    dispatch(getDBSCatalogsAsync());
  }, [dispatch]);

  useEffect(() => {
    if (catalogs) {
      setFilteredCatalogs(
        catalogs.filter(
          (c) =>
            c.planCode.startsWith("volume.") ||
            c.planCode.startsWith("snapshot.")
        )
      );
    }
  }, [catalogs]);

  const handleSubmitForm = (data: any) => {
    if (selectedVolume && !actionLoading) {
      if (selectedType === "volume.snapshot.monthly.postpaid") {
        dispatch(
          createInstancesVolumeSnapshotAsync({
            volumeId: selectedVolume.id,
            data: {
              description: "",
              name: data.name,
            },
          })
        ).then((action) => {
          if (action.type === "instances/volumes/snapshot/create/fulfilled") {
            handleOnClose();
            dispatch(
              getInstancesVolumeAsync({
                id: selectedVolume.id,
                withoutLoading: true,
              })
            ).then((action) => {
              //?
              if (
                action.type === "instances/volume/fulfilled" &&
                selectedVolume.status === "available"
              ) {
                dispatch(getInstancesVolumesAsync({ withoutLoading: true }));
                dispatch(
                  getInstanceVolumeSnapshotListAsync({
                    volumeId: selectedVolume?.id,
                    withoutLoading: true,
                  })
                );
              }
            }); //?
            navigate(InstancesVolumeSnapshotListUrl(selectedVolume.id)); //?
          }
        });
      }
    }
  };

  const disabledForMakingBackup = useMemo(() => {
    if (!selectedVolume) return true;
    // console.log(selectedType);
    if (
      selectedVolume.attachedTo.length > 0 &&
      selectedType === "snapshot.monthly.postpaid"
    ) {
      return true;
    } else {
      return false;
    }
  }, [selectedType, selectedVolume]);

  const handleOnClose = useCallback(() => {
    reset();
    dispatch(handleHideVolumeSnapshorModal());
  }, [dispatch, reset]);

  useEffect(() => {
    return () => {
      handleOnClose();
    };
  }, [handleOnClose]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleOnClose}
      contentClassName="max-w-lg"
      //containerClassName="!items-start"
      title={`Create a snapshot from ${selectedVolume?.name}`}
    >
      {(catalogsLoading || selectedVolumeLoading) && (
        <Flex items="center" justify="center" className="min-h-[250px] w-full">
          <Loading borderSize={2} />
        </Flex>
      )}
      {!catalogsLoading && !selectedVolumeLoading && (
        <form onSubmit={handleSubmit(handleSubmitForm)}>
          <div className="mt-3 w-full flex flex-col lg:flex-row relative">
            <div className="w-full flex flex-col">
              <Controller
                name="selectedType"
                control={control}
                render={({ field: { value, onChange } }) => {
                  return (
                    <RadioGroup value={value || null} onChange={onChange}>
                      <div className="flex flex-col w-full mt-3">
                        <div className="w-full mb-5">
                          {backupTypes.map((type, i) => {
                            const addon = filteredCatalogs.find(
                              (a) => a.planCode === type.code
                            );

                            const exVAT = addon
                              ? getDBSPriceAmount(addon.pricings[0].price, 3)
                              : null;
                            return (
                              <RadioGroup.Option
                                key={type.title + i}
                                value={type.code}
                              >
                                {({ checked, disabled }) => {
                                  return (
                                    <div
                                      className={cn(
                                        "col-span-1 border-2 text-md rounded-xl dark:bg-dark-3 dark:border-gray-400/10 bg-white p-4 shadow hover:shadow-lg transition-all duration-300 cursor-pointer h-full",
                                        {
                                          "border-primary-400 dark:border-primary-400":
                                            checked,
                                          "!cursor-not-allowed": disabled,
                                        }
                                      )}
                                    >
                                      <div className="flex items-center justify-between mb-2">
                                        <Typography.Text className="!text-sm">
                                          {capitalizeFirstLetter(type.title)}
                                        </Typography.Text>
                                      </div>
                                      <div className="flex items-center justify-between ">
                                        <Typography.Text className="!text-xs">
                                          {type.description}
                                        </Typography.Text>
                                      </div>
                                      <div className="flex flex-col border-t  gap-0.5">
                                        {exVAT && (
                                          <div
                                            key={i}
                                            className="flex items-center "
                                          >
                                            <Typography.Text className="!text-xs font-medium">
                                              Price: ${exVAT} ex. VAT/GB/month
                                            </Typography.Text>
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  );
                                }}
                              </RadioGroup.Option>
                            );
                          })}
                        </div>
                      </div>
                    </RadioGroup>
                  );
                }}
              />

              {/* select name */}
              <div className="flex flex-col gap-1 mb-9">
                <Input
                  label="Backup name"
                  {...register("name")}
                  error={errors.name?.message}
                />
              </div>

              <div className="mt-4 flex justify-end gap-2">
                <Button uiType="light" onClick={handleOnClose}>
                  Cancel
                </Button>
                <Button
                  uiType="primary"
                  type="submit"
                  disabled={!isValid || disabledForMakingBackup}
                  loading={actionLoading}
                >
                  Confirm
                </Button>
              </div>
            </div>
          </div>
        </form>
      )}
    </Modal>
  );
};

export default InstanceVolumeSnapshostsModal;
