import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import toast from "react-hot-toast";
import { IThunkRejectValue, RootState } from "../../types";
import { getExtractErrors } from "../../apis";
import { CustomErrorToast } from "../../components/general/Toast";
import { K8Service, K8ServicesState } from "../../types/kubernetes";
import { deleteK8ServiceApi, getK8ServicesApi } from "../../apis/kubernetesApi";

export const K8RefreshStatus = ["INSTALLING", "UPDATING", "CREATING"];

const initialState: K8ServicesState = {
  services: [],
  loading: false,
  actionLoading: false, //for delete , update
  selectedService: null, //for ?
};

export const getK8ServicesAsync = createAsyncThunk<
  { services: Array<K8Service> },
  { withoutLoading?: boolean },
  IThunkRejectValue
>(
  "k8-services",
  async (
    { withoutLoading = false },
    { rejectWithValue, fulfillWithValue, dispatch, requestId }
  ) => {
    try {
      dispatch(
        getK8ServicesAsync.pending(requestId, {
          withoutLoading,
        })
      );

      const response = await getK8ServicesApi();
      const services = response.data.Result;
      return fulfillWithValue({ services });
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);

export const deleteK8ServiceAsync = createAsyncThunk<
  true,
  { id: string },
  IThunkRejectValue
>("k8-services/delete", async ({ id }, { rejectWithValue }) => {
  try {
    await deleteK8ServiceApi(id);
    return true;
  } catch (e) {
    return rejectWithValue({ message: getExtractErrors(e) });
  }
});

export const k8ServicesSlice = createSlice({
  name: "kubernetesServices",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getK8ServicesAsync.pending, (state, action) => {
        if (!action.meta.arg.withoutLoading) state.loading = true;
      })
      .addCase(getK8ServicesAsync.fulfilled, (state, action) => {
        const { services } = action.payload;
        state.services = services;
        state.loading = false;
      })
      .addCase(getK8ServicesAsync.rejected, (state, { payload }) => {
        state.loading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(deleteK8ServiceAsync.pending, (state) => {
        state.actionLoading = true;
      })
      .addCase(deleteK8ServiceAsync.fulfilled, (state) => {
        state.actionLoading = false;
      })
      .addCase(deleteK8ServiceAsync.rejected, (state, { payload }) => {
        state.actionLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });
  },
});

export const selectK8Services = (state: RootState) => state.k8Services.services;
export const selectK8ServicesLoading = (state: RootState) =>
  state.k8Services.loading;

export const selectK8ServicesActionLoading = (state: RootState) =>
  state.k8Services.actionLoading;

export const selectSelectedK8Service = (state: RootState) =>
  state.k8Services.selectedService;

// export const {} = servicesSlice.actions;
export default k8ServicesSlice.reducer;
