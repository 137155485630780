import { Controller, useForm } from "react-hook-form";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Accordion,
  Button,
  Flex,
  Input,
  Modal,
  Select,
  SelectOption,
  Switcher,
  Tag,
  Typography,
} from "djuno-design";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  createDSSRelicationAsync,
  getDSSReplicationsAsync,
  handleToggleDSSReplicationModal,
  selectDatabaseService,
  selectDSSShowReplicationEditor,
  selectDSSReplicationsActionLoading,
  selectDBSIntegrations,
  selectDBSIntegrationsLoading,
  getDBSServiceIntegrationsAsync,
  selectDSSSelectedReplication,
  updateDSSRelicationAsync,
} from "../../../store/database/serviceSlice";
import { DataStreamingReplicationCreateSchema } from "../../../utils/validations";
import {
  getDatabaseServicesAsync,
  selectDatabaseServices,
  selectDatabaseServicesLoading,
} from "../../../store/database/servicesSlice";

export const ReplicationFlowCreateModal: React.FC = () => {
  const isOpen = useAppSelector(selectDSSShowReplicationEditor);
  const service = useAppSelector(selectDatabaseService);
  const actionLoading = useAppSelector(selectDSSReplicationsActionLoading);

  const replication = useAppSelector(selectDSSSelectedReplication);

  const integrations = useAppSelector(selectDBSIntegrations);
  const integrationsLoading = useAppSelector(selectDBSIntegrationsLoading);

  const services = useAppSelector(selectDatabaseServices);
  const servicesLoading = useAppSelector(selectDatabaseServicesLoading);

  const [topics, setTopics] = useState<Array<string>>([]);
  const [topic, setTopic] = useState<string>("");

  const [blacklists, setBlacklists] = useState<Array<string>>([]);
  const [blacklist, setBlacklist] = useState<string>("");

  const dispatch = useAppDispatch();

  const methods = useForm({
    resolver: yupResolver(DataStreamingReplicationCreateSchema),
    mode: "all",
  });

  const {
    control,
    handleSubmit,
    register,
    formState: { errors },
    reset,
    setValue,
  } = methods;

  const handleSubmitForm = (data: any) => {
    if (!actionLoading) {
      if (service) {
        if (replication) {
          const formData = {
            emitHeartbeats: data.emitHeartbeats,
            enabled: data.enabled,
            replicationPolicyClass: data.replicationPolicyClass,
            syncGroupOffsets: data.syncGroupOffsets,
            syncInterval: data.syncInterval,
            topicExcludeList: blacklists,
            topics: topics,
          };
          dispatch(
            updateDSSRelicationAsync({
              engine: service.engine,
              id: service.id,
              replicationId: replication.id,
              data: formData,
            })
          ).then((action) => {
            if (action.type === "service/replications/update/fulfilled") {
              dispatch(
                getDSSReplicationsAsync({
                  engine: service.engine,
                  clusterId: service.id,
                })
              );
              handleOnClose();
            }
          });
        } else {
          const formData = {
            sourceIntegration: data.sourceIntegration,
            targetIntegration: data.targetIntegration,
            emitHeartbeats: data.emitHeartbeats,
            enabled: data.enabled,
            replicationPolicyClass: data.replicationPolicyClass,
            syncGroupOffsets: data.syncGroupOffsets,
            syncInterval: data.syncInterval,
            topicExcludeList: blacklists,
            topics: topics,
          };
          dispatch(
            createDSSRelicationAsync({
              engine: service?.engine,
              id: service?.id,
              data: formData,
            })
          ).then((action) => {
            if (action.type === "service/replications/create/fulfilled") {
              dispatch(
                getDSSReplicationsAsync({
                  engine: service?.engine,
                  clusterId: service?.id,
                })
              );
              handleOnClose();
            }
          });
        }
      }
    }
  };

  const handleOnClose = useCallback(() => {
    reset();
    dispatch(handleToggleDSSReplicationModal({ status: false }));
    setTopics([]);
    setTopic("");
    setBlacklists([]);
    setBlacklist("");
  }, [dispatch, reset]);

  useEffect(() => {
    return () => {
      handleOnClose();
    };
  }, [handleOnClose]);

  useEffect(() => {
    if (isOpen) {
      if (replication) {
        setValue("targetIntegration", replication.targetIntegration);
        setValue("emitHeartbeats", replication.emitHeartbeats);
        setValue("enabled", replication.enabled);
        setValue("replicationPolicyClass", replication.replicationPolicyClass);
        setValue("sourceIntegration", replication.sourceIntegration);
        setValue("syncGroupOffsets", replication.syncGroupOffsets);
        setValue("syncInterval", replication.syncInterval);

        setTopics(replication.topics);
        setBlacklists(replication.topicExcludeList);
      } else {
        setValue("targetIntegration", "");
        setValue("emitHeartbeats", true);
        setValue("enabled", true);
        setValue("replicationPolicyClass", "");
        setValue("sourceIntegration", "");
        setValue("syncGroupOffsets", false);
        setValue("syncInterval", 60);
      }
    }
  }, [isOpen, replication, setValue]);

  useEffect(() => {
    if (isOpen && service && integrations.length === 0)
      dispatch(
        getDBSServiceIntegrationsAsync({
          clusterId: service.id,
          engine: service.engine,
        })
      );
  }, [dispatch, integrations.length, isOpen, service]);

  useEffect(() => {
    if (isOpen && services && services.length === 0)
      dispatch(getDatabaseServicesAsync({}));
  }, [dispatch, isOpen, services]);

  const selectableIntegrations = useMemo(() => {
    return integrations
      .filter((i) => i.status === "READY")
      .filter((i) => i.type === "kafkaMirrorMaker");
  }, [integrations]);

  const integrationsOptions: SelectOption[] = useMemo(() => {
    return selectableIntegrations.map((i) => {
      const service = services.find((s) => s.id === i.sourceServiceId);
      const option: SelectOption = {
        label: service?.description,
        value: i.id,
      };
      return option;
    });
  }, [selectableIntegrations, services]);

  const handleAddNewTopic = useCallback(() => {
    if (topic) {
      setTopics((prev) => [...prev, topic]);
      setTopic("");
    }
  }, [topic]);

  const handleDeleteTopic = useCallback((selected_topic: string) => {
    setTopics((prev) => [...prev.filter((t) => t !== selected_topic)]);
  }, []);

  const handleAddNewBlacklist = useCallback(() => {
    if (blacklist) {
      setBlacklists((prev) => [...prev, blacklist]);
      setBlacklist("");
    }
  }, [blacklist]);

  const handleDeleteBlacklist = useCallback((selected_blacklist: string) => {
    setBlacklists((prev) => [...prev.filter((b) => b !== selected_blacklist)]);
  }, []);

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleOnClose}
      contentClassName="!max-w-xl overflow-hidden"
      title={"Create a replicatio flow"}
    >
      <form className="mt-5" onSubmit={handleSubmit(handleSubmitForm)}>
        <div className="flex flex-col gap-3">
          <div className="grid grid-cols-2 items-start w-full gap-5 mt-5">
            <Controller
              control={control}
              name="sourceIntegration"
              render={({ field: { value, onChange } }) => (
                <Select
                  disabled={
                    integrationsOptions.length <= 1 || replication !== null
                  }
                  value={value?.toString() || undefined}
                  onChange={onChange}
                  options={integrationsOptions}
                  error={errors.sourceIntegration?.message}
                  label="Source service"
                  required
                  emptyString=""
                  className="col-span-1"
                  loading={servicesLoading || integrationsLoading}
                />
              )}
            />
            <Controller
              control={control}
              name="targetIntegration"
              render={({ field: { value, onChange } }) => (
                <Select
                  disabled={
                    integrationsOptions.length <= 1 || replication !== null
                  }
                  value={value?.toString() || undefined}
                  onChange={onChange}
                  options={integrationsOptions}
                  error={errors.targetIntegration?.message}
                  label="Target service"
                  required
                  emptyString=""
                  className="col-span-1"
                  loading={servicesLoading || integrationsLoading}
                />
              )}
            />
          </div>
          <div className="grid grid-cols-2 items-start w-full gap-5 mt-5">
            <Flex direction="col" className="col-span-1 gap-1">
              <Flex items="end" className="gap-1 w-full flex-1">
                <Input
                  label={"Topics"}
                  value={topic}
                  onChange={(e) => setTopic(e.target.value)}
                  placeholder="logs_*"
                  className="w-full flex-1"
                  containerClassName="w-full flex-1"
                />
                <Button onClick={handleAddNewTopic} disabled={topic === ""}>
                  Add
                </Button>
              </Flex>
              <Flex className="gap-1 w-full flex-wrap">
                {topics.map((t, i) => (
                  <Tag
                    color="processing"
                    key={i + t}
                    closable
                    onClose={() => handleDeleteTopic(t)}
                  >
                    {t}
                  </Tag>
                ))}
              </Flex>
            </Flex>

            <Flex direction="col" className="col-span-1 gap-1">
              <Flex items="end" className="gap-1 w-full flex-1">
                <Input
                  label={"Topics blacklists"}
                  value={blacklist}
                  onChange={(e) => setBlacklist(e.target.value)}
                  placeholder="logs_*"
                  className="w-full flex-1"
                  containerClassName="w-full flex-1"
                />
                <Button
                  onClick={handleAddNewBlacklist}
                  disabled={blacklist === ""}
                >
                  Add
                </Button>
              </Flex>
              <Flex direction="col" className="gap-1 w-full flex-col">
                {blacklists.map((b, i) => (
                  <Tag
                    color="processing"
                    key={i + b}
                    closable
                    onClose={() => handleDeleteBlacklist(b)}
                  >
                    {b}
                  </Tag>
                ))}
              </Flex>
            </Flex>
          </div>
          <div className="grid grid-cols-2 items-start w-full gap-5 mt-5">
            <Input
              label={"Sync interval(s)"}
              {...register("syncInterval")}
              type={"number"}
              error={errors.syncInterval?.message}
              className="col-span-1"
            />
            <Controller
              control={control}
              name="replicationPolicyClass"
              render={({ field: { value, onChange } }) => (
                <Select
                  value={value?.toString() || undefined}
                  onChange={onChange}
                  options={[
                    {
                      label: "DefaultReplicationPolicy",
                      value:
                        "org.apache.kafka.connect.mirror.DefaultReplicationPolicy",
                    },
                    {
                      label: "IdentityReplicationPolicy",
                      value:
                        "org.apache.kafka.connect.mirror.IdentityReplicationPolicy",
                    },
                  ]}
                  error={errors.replicationPolicyClass?.message}
                  label="Class replication policy"
                  required
                  emptyString=""
                  className="col-span-1"
                  tooltip={{
                    className: "!text-xs",
                    place: "top",
                    content: (
                      <Typography.Text
                        size="xs"
                        uiType="transparent"
                        className="flex-wrap"
                      >
                        The class replication policy controls the prefix when
                        copying topics.
                        <br />
                        <br />
                        org.apache.kafka.connect.mirror.DefaultReplicationPolicy
                        is the default policy. It adds the cluster alias as a
                        prefix for the replicated topics.
                        <br />
                        <br />
                        For example, “topic1” will become
                        “cluster_alias.topic1”.
                        <br />
                        <br />
                        org.apache.kafka.connect.mirror.IdentityReplicationPolicy
                        does not include a prefix and the topic name will remain
                        the same in the replicated topic.
                      </Typography.Text>
                    ),
                  }}
                />
              )}
            />
          </div>
          <div className="grid grid-cols-3 items-start w-full gap-5 my-5">
            <Controller
              control={control}
              name="syncGroupOffsets"
              render={({ field: { value, onChange } }) => (
                <Flex direction="col">
                  <Typography.Text size="sm" className="col-span-1">
                    Sync group offsets
                  </Typography.Text>
                  <Switcher value={value} onChange={onChange} />
                </Flex>
              )}
            />
            <Controller
              control={control}
              name="emitHeartbeats"
              render={({ field: { value, onChange } }) => (
                <Flex direction="col">
                  <Typography.Text size="sm" className="col-span-1">
                    Heartbeats Enabled
                  </Typography.Text>
                  <Switcher value={value} onChange={onChange} />
                </Flex>
              )}
            />
            <Controller
              control={control}
              name="enabled"
              render={({ field: { value, onChange } }) => (
                <Flex direction="col" className="col-span-1">
                  <Typography.Text size="sm">Status</Typography.Text>
                  <Switcher value={value} onChange={onChange} />
                </Flex>
              )}
            />
          </div>
          <div className="flex justify-end gap-2">
            <Button uiType="simple" onClick={handleOnClose}>
              Cancel
            </Button>
            <Button uiType="primary" type="submit" loading={actionLoading}>
              {replication ? "Update" : "Create"}
            </Button>
          </div>
        </div>
      </form>
    </Modal>
  );
};
