import { Tag } from "djuno-design";
import React, { memo, useMemo } from "react";
import ReactMarkdown from "react-markdown";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { materialDark } from "react-syntax-highlighter/dist/esm/styles/prism";

const MarkdownText: React.FC<{ text: string }> = memo(({ text }) => {
  const renderMarkdown = useMemo(() => {
    return (
      <ReactMarkdown
        components={{
          code(props) {
            const { children, className, node, ref, ...rest } = props;
            const match = /language-(\w+)/.exec(className || "");
            return match ? (
              <SyntaxHighlighter
                {...rest}
                PreTag="div"
                children={String(children).replace(/\n$/, "")}
                language={match[1]}
                style={materialDark}
              />
            ) : (
              <Tag style={{ marginRight: "0.2rem" }} bordered>
                {children}
              </Tag>
            );
          },
          p({ children }) {
            return (
              <p style={{ marginTop: "1rem", lineHeight: "1.6" }}>
                {React.Children.toArray(children).map((child, index) =>
                  typeof child === "string"
                    ? child.split("\n").map((line, i, arr) => (
                        <React.Fragment key={i}>
                          {line}
                          {i < arr.length - 1 && <br />}
                        </React.Fragment>
                      ))
                    : child
                )}
              </p>
            );
          },
          h3({ children }) {
            return (
              <h3
                style={{
                  marginTop: "1rem",
                  lineHeight: "1.6",
                  fontWeight: "600",
                }}
              >
                {children}
              </h3>
            );
          },
          ol({ children }) {
            return (
              <ol
                style={{
                  paddingLeft: "1.5rem",
                  marginTop: "1rem",
                  lineHeight: "1.6",
                  listStyleType: "decimal",
                }}
              >
                {children}
              </ol>
            );
          },
          ul({ children }) {
            return (
              <ul
                style={{
                  paddingLeft: "1.5rem",
                  marginTop: "1rem",
                  lineHeight: "1.6",
                  listStyleType: "disc",
                }}
              >
                {children}
              </ul>
            );
          },
          li({ children }) {
            return <li style={{ marginBottom: "0.5rem" }}>{children}</li>;
          },
        }}
      >
        {text}
      </ReactMarkdown>
    );
  }, [text]);

  return renderMarkdown;
});

export default MarkdownText;
