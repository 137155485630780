import { Helmet } from "react-helmet";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { useEffect, useState } from "react";
import { ReactComponent as PlusIcon } from "./../../../assets/icons/plus.svg";
import { ReactComponent as MoreIcon } from "./../../../assets/icons/more.svg";
// import { ReactComponent as ArrowRightIcon } from "./../../../assets/icons/arrow-right.svg";
import { ReactComponent as InformationIcon } from "./../../../assets/icons/bars-3-bottom-left.svg";
import { ReactComponent as EditIcon } from "./../../../assets/icons/pencil.svg";
import { ReactComponent as ArchiveIcon } from "./../../../assets/icons/archive-box.svg";
import { ReactComponent as RefreshIcon } from "./../../../assets/icons/arrow-path.svg";
import { DeleteModal } from "../../modals/QuestionModal";
import { useSearch } from "../../../providers/SearchProvider";
import {
  deleteDBSPoolAsync,
  getDBSDatabasesAsync,
  getDBSPoolsAsync,
  getDBSUsersAsync,
  handleDBSPoolEditor,
  handleDBSPoolInfo,
  selectDBSDatabases,
  selectDBSDatabasesLoading,
  selectDBSPools,
  selectDBSPoolsActionLoading,
  selectDBSPoolsLoading,
  selectDatabaseService,
  selectDatabaseServiceLoading,
  selectDatabaseServiceUsers,
  selectDatabaseServiceUsersLoading,
} from "../../../store/database/serviceSlice";
import { DBSPool } from "../../../types/database";
import { generateServicePermissions } from "../../../pages/databases/ServicePage";
import { useNavigate } from "react-router-dom";
import { DataServiceGeneralInformationUrl } from "../../../utils/urls";
import DBSPoolCreateModal from "./DBSPoolCreateModal";
import DBSPoolInfoModal from "./DBSPoolInfoModal";
import {
  Button,
  Dropdown,
  EmptyState,
  Flex,
  SimpleTable,
  Skeleton,
  Typography,
} from "djuno-design";
import HighlighterText from "../../general/HighlighterText";

const DBSPoolsTab = () => {
  const service = useAppSelector(selectDatabaseService);
  const serviceLoading = useAppSelector(selectDatabaseServiceLoading);

  const pools = useAppSelector(selectDBSPools);
  const poolsLoading = useAppSelector(selectDBSPoolsLoading);
  const [filteredPools, setFilteredPools] = useState<DBSPool[]>([]);

  const actionLoading = useAppSelector(selectDBSPoolsActionLoading);

  const users = useAppSelector(selectDatabaseServiceUsers);
  const usersLoading = useAppSelector(selectDatabaseServiceUsersLoading);

  const databases = useAppSelector(selectDBSDatabases);
  const databasesLoading = useAppSelector(selectDBSDatabasesLoading);

  const dispatch = useAppDispatch();

  const [deletePool, setDeletePool] = useState<DBSPool | null>(null);
  const { value: searchValue } = useSearch();
  const navigate = useNavigate();

  useEffect(() => {
    if (service) {
      const permissions = generateServicePermissions().pools;
      if (!permissions.includes(service.engine)) {
        navigate(DataServiceGeneralInformationUrl(service.engine, service.id));
      } else {
        dispatch(
          getDBSPoolsAsync({
            engine: service.engine,
            clusterId: service.id,
          })
        );
      }
    }
  }, [dispatch, navigate, service]);

  useEffect(() => {
    const lookedUp = pools.filter((pool) =>
      pool.name?.toLowerCase().includes(searchValue.toLowerCase())
    );
    setFilteredPools(lookedUp);
  }, [dispatch, searchValue, pools]);

  useEffect(() => {
    if (service && databases.length === 0) {
      dispatch(
        getDBSDatabasesAsync({ engine: service.engine, clusterId: service.id })
      );
    }
  }, [databases.length, dispatch, service]);

  useEffect(() => {
    if (service && users.length === 0) {
      dispatch(getDBSUsersAsync({ engine: service.engine, id: service.id }));
    }
  }, [dispatch, service, users.length]);

  return (
    <>
      <Helmet>
        <title>{process.env.REACT_APP_NAME} | Database</title>
        <meta name="description" content="" />
      </Helmet>
      <div className="">
        <Flex direction="col" className="gap-3 w-full">
          <Flex items="center" justify="between">
            <div className="flex flex-col gap-1">
              <Typography.Title level={5} className="!mb-0">
                Pools
              </Typography.Title>
            </div>
            <div className="flex items-center gap-2">
              <Button
                uiType="light"
                onClick={() => {
                  if (service)
                    dispatch(
                      getDBSPoolsAsync({
                        engine: service.engine,
                        clusterId: service.id,
                      })
                    );
                }}
                disabled={serviceLoading}
                className="group"
                tooltip={{ content: "Refresh" }}
              >
                <RefreshIcon className="w-4 h-4 group-hover:rotate-90 group-hover:scale-110 transition-all duration-500" />
              </Button>
              <Button
                uiType="primary"
                onClick={() =>
                  dispatch(handleDBSPoolEditor({ status: true, pool: null }))
                }
                disabled={serviceLoading}
                className="group"
              >
                Add a pool
                <PlusIcon className="w-3 h-3 group-hover:rotate-90 group-hover:scale-110 transition-all duration-500" />
              </Button>
            </div>
          </Flex>
          <Typography.Text className="!font-light !text-sm"></Typography.Text>
        </Flex>
        <div className="mt-5 w-full">
          <SimpleTable
            loading={poolsLoading}
            containerClassName="min-h-[240px]"
          >
            <SimpleTable.Head>
              <SimpleTable.Row>
                <SimpleTable.TH lable="Name" />
                <SimpleTable.TH lable="Database" />
                <SimpleTable.TH lable="Mode" />
                <SimpleTable.TH lable="Size" />
                <SimpleTable.TH lable="Username" />
                <SimpleTable.TH lable="" />
              </SimpleTable.Row>
            </SimpleTable.Head>
            <SimpleTable.Body>
              {filteredPools.map((pool, i) => (
                <SimpleTable.Row key={i}>
                  <SimpleTable.TD>
                    <div className="flex items-center flex-row justify-between space-x-3">
                      <HighlighterText
                        searchValue={searchValue}
                        textToHighlight={pool.name}
                        className="max-w-[110px] md:max-w-[400px] lg:max-w-[350px] xl:max-w-[400px] truncate"
                      />
                    </div>
                  </SimpleTable.TD>
                  <SimpleTable.TD>
                    {databasesLoading && (
                      <Skeleton style={{ width: 120, height: 30 }} />
                    )}
                    {!databasesLoading && (
                      <Typography.Text className="!text-xs md:!text-sm">
                        {databases.find((d) => d.id === pool.databaseId)?.name}
                      </Typography.Text>
                    )}
                  </SimpleTable.TD>
                  <SimpleTable.TD>
                    <Typography.Text className="!text-xs md:!text-sm">
                      {pool.mode}
                    </Typography.Text>
                  </SimpleTable.TD>
                  <SimpleTable.TD>
                    <Typography.Text className="!text-xs md:!text-sm">
                      {pool.size}
                    </Typography.Text>
                  </SimpleTable.TD>
                  <SimpleTable.TD>
                    {usersLoading && (
                      <Skeleton style={{ width: 120, height: 30 }} />
                    )}
                    {!usersLoading && (
                      <Typography.Text className="!text-xs md:!text-sm">
                        {users.find((u) => u.id === pool.userId)?.username}
                      </Typography.Text>
                    )}
                  </SimpleTable.TD>
                  <SimpleTable.TD className="w-20 gap-1">
                    <div className="h-full w-full inline-flex items-center justify-end gap-1">
                      <div className="w-8 flex justify-center items-center">
                        <Dropdown
                          anchor="bottom end"
                          itemsClassName="!w-48"
                          menu={[
                            {
                              key: "information",
                              label: (
                                <div className="flex items-center gap-1">
                                  <InformationIcon className="w-4" />
                                  Information
                                </div>
                              ),
                              onClick: (_, close) => {
                                close();
                                dispatch(
                                  handleDBSPoolInfo({ status: true, pool })
                                );
                              },
                            },
                            {
                              key: "edit",
                              label: (
                                <div className="flex items-center gap-1">
                                  <EditIcon className="w-4" />
                                  Edit
                                </div>
                              ),
                              onClick: (_, close) => {
                                close();
                                dispatch(
                                  handleDBSPoolEditor({ status: true, pool })
                                );
                              },
                            },
                            {
                              type: "divider",
                            },
                            {
                              key: "end",
                              label: (
                                <div className="flex items-center gap-1">
                                  <ArchiveIcon className="w-4" />
                                  Delete
                                </div>
                              ),
                              danger: true,
                              onClick: (_, close) => {
                                close();
                                setDeletePool(pool);
                              },
                            },
                          ]}
                        >
                          <Button
                            uiType="icon"
                            uiSize="small"
                            className="!px-2"
                          >
                            <MoreIcon className="w-4 h-4" />
                          </Button>
                        </Dropdown>
                      </div>
                    </div>
                  </SimpleTable.TD>
                </SimpleTable.Row>
              ))}

              {pools.length === 0 && (
                <SimpleTable.Row withoutHoverStyle={true} className="h-[200px]">
                  <SimpleTable.TD colSpan={6} className="!border-0 !h-full">
                    <EmptyState
                      className="!h-full"
                      text="You have not created any pool yet"
                    />
                  </SimpleTable.TD>
                </SimpleTable.Row>
              )}
            </SimpleTable.Body>
          </SimpleTable>
        </div>
        <DBSPoolCreateModal />
        <DBSPoolInfoModal />
        <DeleteModal
          title="Delete a Pool"
          isOpen={deletePool !== null}
          onClose={() => setDeletePool(null)}
          description=""
          confirmButtonText="Confirm"
          confirmButtonType="danger"
          confirmButtonClassName="w-full"
          onConfirm={() => {
            deletePool &&
              service &&
              dispatch(
                deleteDBSPoolAsync({
                  clusterId: service.id,
                  engine: service.engine,
                  poolId: deletePool.id,
                })
              ).then((action) => {
                if (action.type === "service/pools/delete/fulfilled") {
                  dispatch(
                    getDBSPoolsAsync({
                      engine: service.engine,
                      clusterId: service.id,
                    })
                  );
                  setDeletePool(null);
                }
              });
          }}
          loading={actionLoading}
          confirmString={deletePool?.name}
        />
      </div>
    </>
  );
};

export default DBSPoolsTab;
