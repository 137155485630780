import React, { PropsWithChildren, useCallback, useEffect } from "react";
import { useState } from "react";
import { useAppDispatch, useAppSelector } from "../hooks";
import SurveyModal from "../components/user-onboarding/SurveyModal";
import {
  // getMeAsync,
  handleSelectEnv,
  logout,
  selectLogin,
  selectLoginLoading,
  selectOnStageEnv,
  selectUser,
  selectUserLoading,
} from "../store/auth/authSlice";
import EmailNotVerify from "../components/user-onboarding/EmailNotVerify";
import { useLocation, useNavigate } from "react-router-dom";
import { SignInUrl } from "../utils/urls";
import EnvironmentSelectModal from "../components/environments/EnvironmentSelectModal";
import {
  closeEnvSelectionModal,
  getEnvironmentAsync,
  openEnvSelectionModal,
} from "../store/environments/environmentsSlice";
import InstallationModal from "../components/settings/registeries/InstallationModal";
import { Environment } from "../types/environment";
import SuspendAccount from "../components/user-onboarding/SuspendAccount";
import { Flex, Loading, sleep } from "djuno-design";
import CardInformation from "../components/user-onboarding/CardInformation";
import PanelContentProvider from "./PanelContentProvider";

type Status =
  | "not-verify"
  | "card-information"
  | "survey"
  | "loading"
  | "ok"
  | "suspend";

const testMode: boolean = false;
const initStatus: Status = !testMode ? "loading" : "card-information";

const AccountProvider = ({ children }: PropsWithChildren) => {
  const [status, setStatus] = useState<Status>(initStatus);
  const loginUser = useAppSelector(selectLogin);
  const loginUserLoading = useAppSelector(selectLoginLoading);
  const user = useAppSelector(selectUser);
  const userLoading = useAppSelector(selectUserLoading);
  const selectedEnv = useAppSelector(selectOnStageEnv);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const key = selectedEnv ? selectedEnv.Id : "no-env";
  const [validateEnvLoading, setValidateEnvLoading] = useState(false);

  useEffect(() => {
    if (!testMode) {
      if (loginUser) {
        if (user) {
          // check user email status
          if (!user.IsVerified) {
            setStatus("not-verify");
          } else {
            if (selectedEnv) {
              setStatus("loading");
              dispatch(closeEnvSelectionModal());
              // Initiate
              // Intent
              // CardDetailWaiting
              if (
                selectedEnv.EnvironmentStatus === 0 ||
                selectedEnv.EnvironmentStatus === 1 ||
                selectedEnv.EnvironmentStatus === 2
              ) {
                setStatus("card-information");
              } else if (selectedEnv.EnvironmentStatus === 3) {
                //CardStored
                //survey
                setStatus("survey");
              } else if (selectedEnv.EnvironmentStatus === 4) {
                //Finalized
                setStatus("ok");
              } else if (selectedEnv.EnvironmentStatus === 5) {
                // Suspended
                setStatus("suspend");
              }
            } else {
              setStatus("loading");
              dispatch(openEnvSelectionModal({ closable: false }));
            }
          }
        } else {
          if (userLoading) {
            setStatus("loading");
          } else {
            dispatch(logout());
            navigate(SignInUrl);
          }
        }
      } else {
        if (loginUserLoading) {
          setStatus("loading");
        } else {
          dispatch(logout());
          navigate(SignInUrl);
        }
      }
    }
  }, [
    dispatch,
    navigate,
    loginUser,
    user,
    loginUserLoading,
    userLoading,
    selectedEnv,
  ]);

  const handleValidateSelectedEnv = useCallback(async () => {
    if (selectedEnv) {
      setValidateEnvLoading(true);
      await sleep(10000);
      dispatch(getEnvironmentAsync({ id: selectedEnv.Id })).then((action) => {
        if (action.type === "environments/getOne/fulfilled") {
          const env = (action.payload as { environment: Environment })
            .environment;
          if (env.EnvironmentStatus === 2) {
            handleValidateSelectedEnv();
          } else {
            setValidateEnvLoading(false);
            dispatch(handleSelectEnv({ env }));
          }
        }
      });
    }
  }, [dispatch, selectedEnv]);

  useEffect(() => {
    if (selectedEnv && selectedEnv.EnvironmentStatus === 2) {
      handleValidateSelectedEnv();
    }
  }, [handleValidateSelectedEnv, selectedEnv, selectedEnv?.EnvironmentStatus]);

  return (
    <React.Fragment key={key}>
      {status === "not-verify" && <EmailNotVerify />}
      {status === "card-information" && (
        <CardInformation
          handleValidateSelectedEnv={handleValidateSelectedEnv}
          validateEnvLoading={validateEnvLoading}
        />
      )}
      {status === "survey" && <SurveyModal />}
      {status === "loading" && (
        <Flex items="center" justify="center" className="!h-[calc(100vh-4rem)]">
          <Loading borderSize={2} />
        </Flex>
      )}
      {status === "suspend" && (
        <>
          {location.pathname === "/settings/billing" ? (
            children
          ) : (
            <SuspendAccount />
          )}
        </>
      )}

      {status === "ok" &&
        selectedEnv &&
        selectedEnv.EnvironmentStatus === 4 && (
          <PanelContentProvider>{children} </PanelContentProvider>
        )}

      <EnvironmentSelectModal />
      <InstallationModal />
    </React.Fragment>
  );
};
export default AccountProvider;
