import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import toast from "react-hot-toast";
import { IThunkRejectValue, RootState } from "../../types";
import { getExtractErrors } from "../../apis";
import { CustomErrorToast } from "../../components/general/Toast";
import {
  K8Logs,
  K8LogsUrlData,
  K8NodePool,
  K8Service,
  K8ServiceState,
  MetricEtcdUsage,
  OpenIdConnect,
} from "../../types/kubernetes";
import {
  createK8IpRestrictionsApi,
  deleteK8ServiceApi,
  getK8IpRestrictionsApi,
  getK8LogsApi,
  getK8LogsUrlApi,
  getK8NodePoolsApi,
  getK8ServiceApi,
  K8ConfigureOIDCApi,
  K8DeleteConfigureOIDCApi,
  K8GetConfigureOIDCApi,
  K8GetMetricEtcdUsageListApi,
  K8ResetClusterApi,
  K8ResetKubeconfigApi,
  K8UpdateConfigureOIDCApi,
  K8UpdateCustomizationApi,
  K8UpdatePolicyApi,
  updateK8ServiceApi,
} from "../../apis/kubernetesApi";

const initialState: K8ServiceState = {
  service: null,
  loading: false,
  actionLoading: false, //for create, delete, rename
  showChangeNameModal: false,

  //pools
  pools: [],
  poolsLoading: false,

  //ip restrictions
  ipRestrictions: [],
  ipRestrictionsLoading: false,
  ipRestrictionsActionLoading: false,
  selectedIpRestriction: null,
  showIpRestrictionEditor: false,

  //logs
  logsUrl: null,
  logsUrlLoading: false,
  logs: null,
  logsLoading: false,

  k8ShowNameModal: false,
  k8ShowResetClusterModal: false,
  k8ShowResetkubeConfigModal: false,
  k8ShowChangeSecurityModal: false,
  k8ShowEnableDisableModal: false,
  K8ConfigureOIDCModal: false,

  openIdConnect: null,
  openIdConnectLoading: false,

  metricEtcdUsage: null,
  metricEtcdUsageLoading: false,
};

export const getK8ServiceAsync = createAsyncThunk<
  { service: K8Service },
  { id: string; withoutLoading?: boolean },
  IThunkRejectValue
>(
  "k8-service",
  async (
    { id, withoutLoading = false },
    { rejectWithValue, fulfillWithValue, dispatch, requestId }
  ) => {
    try {
      dispatch(
        getK8ServiceAsync.pending(requestId, {
          id,
          withoutLoading,
        })
      );
      const response = await getK8ServiceApi(id);
      const service = response.data.Result;
      return fulfillWithValue({ service });
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);

export const getK8LogsUrlAsync = createAsyncThunk<
  K8LogsUrlData,
  { id: string },
  IThunkRejectValue
>(
  "k8-service/logs-url",
  async ({ id }, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await getK8LogsUrlApi(id);
      const res = response.data.Result as K8LogsUrlData;

      return fulfillWithValue(res);
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);

export const getK8LogsAsync = createAsyncThunk<
  K8Logs,
  { url: string; withoutLoading?: boolean },
  IThunkRejectValue
>(
  "k8-service/logs",
  async (
    { url, withoutLoading = false },
    { rejectWithValue, fulfillWithValue, dispatch, requestId }
  ) => {
    try {
      dispatch(
        getK8LogsAsync.pending(requestId, {
          url,
          withoutLoading,
        })
      );

      const response = await getK8LogsApi(url);
      const logs = response.data;

      return fulfillWithValue(logs);
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);

export const getK8NodePoolsAsync = createAsyncThunk<
  { pools: Array<K8NodePool> },
  {
    id: string;
  },
  IThunkRejectValue
>("k8-service/pools", async ({ id }, { rejectWithValue, fulfillWithValue }) => {
  try {
    const response = await getK8NodePoolsApi(id);
    const pools = response.data.Result;
    return fulfillWithValue({ pools });
  } catch (e) {
    return rejectWithValue({ message: getExtractErrors(e) });
  }
});

export const K8ResetClusterAsync = createAsyncThunk<
  boolean,
  { kubeId: string; data: any },
  IThunkRejectValue
>("k8-service/reset-cluster", async ({ kubeId, data }, { rejectWithValue }) => {
  try {
    await K8ResetClusterApi(kubeId, data);
    return true;
  } catch (e) {
    return rejectWithValue({ message: getExtractErrors(e) });
  }
});

export const getK8IpRestrictionsAsync = createAsyncThunk<
  Array<string>,
  {
    id: string;
  },
  IThunkRejectValue
>(
  "k8-service/ip-restrictions",
  async ({ id }, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await getK8IpRestrictionsApi(id);
      return fulfillWithValue(response.data.Result || []);
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);

export const createK8IpRestrictionsAsync = createAsyncThunk<
  boolean,
  {
    id: string;
    ips: Array<string>;
  },
  IThunkRejectValue
>(
  "k8-service/ip-restrictions/create",
  async ({ id, ips }, { rejectWithValue }) => {
    try {
      await createK8IpRestrictionsApi(id, ips);
      return false;
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);

export const deleteK8serviceAsync = createAsyncThunk<
  true,
  { id: string },
  IThunkRejectValue
>("k8-service/delete", async ({ id }, { rejectWithValue }) => {
  try {
    await deleteK8ServiceApi(id);
    return true;
  } catch (e) {
    return rejectWithValue({ message: getExtractErrors(e) });
  }
});

export const updateK8serviceAsync = createAsyncThunk<
  any,
  { id: string; data: any },
  IThunkRejectValue
>("k8-service/update", async ({ id, data }, { rejectWithValue }) => {
  try {
    const response = await updateK8ServiceApi(id, data);
    return response.data;
  } catch (e) {
    return rejectWithValue({ message: getExtractErrors(e) });
  }
});
export const K8ResetkubeconfigAsync = createAsyncThunk<
  boolean,
  { kubeId: string },
  IThunkRejectValue
>("k8-service/reset-kubeconfig", async ({ kubeId }, { rejectWithValue }) => {
  try {
    await K8ResetKubeconfigApi(kubeId);
    return true;
  } catch (e) {
    return rejectWithValue({ message: getExtractErrors(e) });
  }
});
export const K8UpdatePolicyAsync = createAsyncThunk<
  boolean,
  { kubeId: string; data: any },
  IThunkRejectValue
>("k8-service/update-policy", async ({ kubeId, data }, { rejectWithValue }) => {
  try {
    await K8UpdatePolicyApi(kubeId, data);
    return true;
  } catch (e) {
    return rejectWithValue({ message: getExtractErrors(e) });
  }
});

export const getK8ConfigureOIDCAsync = createAsyncThunk<
  { openIdConnect: OpenIdConnect },
  { id: string; withoutLoading?: boolean },
  IThunkRejectValue
>(
  "k8-service/get/openIdConnect",
  async (
    { id, withoutLoading = false },
    { rejectWithValue, fulfillWithValue, dispatch, requestId }
  ) => {
    try {
      dispatch(
        getK8ServiceAsync.pending(requestId, {
          id,
          withoutLoading,
        })
      );
      const response = await K8GetConfigureOIDCApi(id);
      const openIdConnect = response.data.Result;
      return fulfillWithValue({ openIdConnect });
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);

export const K8ConfigureOIDCAsync = createAsyncThunk<
  boolean,
  { kubeId: string; data: any },
  IThunkRejectValue
>("k8-service/openIdConnect", async ({ kubeId, data }, { rejectWithValue }) => {
  try {
    await K8ConfigureOIDCApi(kubeId, data);
    return true;
  } catch (e) {
    return rejectWithValue({ message: getExtractErrors(e) });
  }
});

export const deleteK8ConfigureOIDCAsync = createAsyncThunk<
  true,
  { id: string },
  IThunkRejectValue
>("k8-service/delete/configure-oidc", async ({ id }, { rejectWithValue }) => {
  try {
    await K8DeleteConfigureOIDCApi(id);
    return true;
  } catch (e) {
    return rejectWithValue({ message: getExtractErrors(e) });
  }
});
export const updateK8ConfigureOIDCAsync = createAsyncThunk<
  any,
  { id: string; data: any },
  IThunkRejectValue
>(
  "k8-service/update/configure-oidc",
  async ({ id, data }, { rejectWithValue }) => {
    try {
      const response = await K8UpdateConfigureOIDCApi(id, data);
      return response.data;
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);
export const updateK8CustomizationAsync = createAsyncThunk<
  any,
  { kubeId: string; data: any },
  IThunkRejectValue
>(
  "k8-service/update/customization",
  async ({ kubeId, data }, { rejectWithValue }) => {
    try {
      const response = await K8UpdateCustomizationApi(kubeId, data);
      return response.data;
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);

export const getK8MetricEtcdUsageListAsync = createAsyncThunk<
  { metricEtcdUsage: MetricEtcdUsage },
  { id: string; withoutLoading?: boolean },
  IThunkRejectValue
>(
  "k8-service/metrics/etcdUsage",
  async (
    { id, withoutLoading = false },
    { rejectWithValue, fulfillWithValue, dispatch, requestId }
  ) => {
    try {
      dispatch(
        getK8ServiceAsync.pending(requestId, {
          id,
          withoutLoading,
        })
      );
      const response = await K8GetMetricEtcdUsageListApi(id);
      const metricEtcdUsage = response.data.Result;
      return fulfillWithValue({ metricEtcdUsage });
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);

export const k8ServiceSlice = createSlice({
  name: "k8ServiceSlice",
  initialState,
  reducers: {
    handleClearK8ServiceSlice: (state) => {
      state.service = null;
      state.showChangeNameModal = initialState.showChangeNameModal;
      state.pools = initialState.pools;
      state.logs = initialState.logs;
    },
    handleToggleK8IpRestrictionEditor: (
      state,
      action: PayloadAction<{ status: boolean; ipRestriction?: string | null }>
    ) => {
      state.showIpRestrictionEditor = action.payload.status;
      state.selectedIpRestriction = action.payload.ipRestriction || null;
    },
    handleToggleK8NameModal: (
      state,
      action: PayloadAction<boolean | undefined>
    ) => {
      state.k8ShowNameModal = action.payload || false;
    },
    handleToggleK8ResetClusterModal: (
      state,
      action: PayloadAction<boolean | undefined>
    ) => {
      state.k8ShowResetClusterModal = action.payload || false;
    },
    handleToggleK8ResetkubeConfigModal: (
      state,
      action: PayloadAction<boolean | undefined>
    ) => {
      state.k8ShowResetkubeConfigModal = action.payload || false;
    },
    handleToggleK8ChangeSecurityModal: (
      state,
      action: PayloadAction<boolean | undefined>
    ) => {
      state.k8ShowChangeSecurityModal = action.payload || false;
    },
    handleToggleK8EnableDisableModal: (
      state,
      action: PayloadAction<boolean | undefined>
    ) => {
      state.k8ShowEnableDisableModal = action.payload || false;
    },
    handleToggleK8ConfigureOIDCModal: (
      state,
      action: PayloadAction<boolean | undefined>
    ) => {
      state.K8ConfigureOIDCModal = action.payload || false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getK8ServiceAsync.pending, (state, action) => {
        if (!action.meta.arg.withoutLoading) state.loading = true;
      })
      .addCase(getK8ServiceAsync.fulfilled, (state, action) => {
        const { service } = action.payload;
        state.service = service;
        state.loading = false;
      })
      .addCase(getK8ServiceAsync.rejected, (state, { payload }) => {
        state.loading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    //pools
    builder
      .addCase(getK8NodePoolsAsync.pending, (state) => {
        state.poolsLoading = true;
      })
      .addCase(
        getK8NodePoolsAsync.fulfilled,
        (state, { payload: { pools } }) => {
          state.pools = pools;
          state.poolsLoading = false;
        }
      )
      .addCase(getK8NodePoolsAsync.rejected, (state, { payload }) => {
        state.poolsLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    //ip restrictions
    builder
      .addCase(getK8IpRestrictionsAsync.pending, (state, action) => {
        state.ipRestrictionsLoading = true;
      })
      .addCase(getK8IpRestrictionsAsync.fulfilled, (state, { payload }) => {
        state.ipRestrictions = payload;
        state.ipRestrictionsLoading = false;
      })
      .addCase(getK8IpRestrictionsAsync.rejected, (state, { payload }) => {
        state.ipRestrictionsLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(createK8IpRestrictionsAsync.pending, (state) => {
        state.ipRestrictionsActionLoading = true;
      })
      .addCase(createK8IpRestrictionsAsync.fulfilled, (state) => {
        state.ipRestrictionsActionLoading = false;
      })
      .addCase(createK8IpRestrictionsAsync.rejected, (state, { payload }) => {
        state.ipRestrictionsActionLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    //logs
    builder
      .addCase(getK8LogsUrlAsync.pending, (state) => {
        state.logsUrlLoading = true;
      })
      .addCase(getK8LogsUrlAsync.fulfilled, (state, { payload }) => {
        state.logsUrl = payload;
        state.logsUrlLoading = false;
      })
      .addCase(getK8LogsUrlAsync.rejected, (state, { payload }) => {
        state.logsUrlLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });
    builder
      .addCase(getK8LogsAsync.pending, (state, action) => {
        if (!action.meta.arg.withoutLoading) state.logsLoading = true;
      })
      .addCase(getK8LogsAsync.fulfilled, (state, { payload }) => {
        state.logs = payload;
        state.logsLoading = false;
      })
      .addCase(getK8LogsAsync.rejected, (state, { payload }) => {
        state.logsLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    //delete
    builder
      .addCase(deleteK8serviceAsync.pending, (state) => {
        state.actionLoading = true;
      })
      .addCase(deleteK8serviceAsync.fulfilled, (state) => {
        state.actionLoading = false;
      })
      .addCase(deleteK8serviceAsync.rejected, (state, { payload }) => {
        state.actionLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });
    //update
    builder
      .addCase(updateK8serviceAsync.pending, (state) => {
        state.actionLoading = true;
      })
      .addCase(updateK8serviceAsync.fulfilled, (state) => {
        state.actionLoading = false;
      })
      .addCase(updateK8serviceAsync.rejected, (state, { payload }) => {
        state.actionLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });
    //reset cluster
    builder
      .addCase(K8ResetClusterAsync.pending, (state) => {
        state.actionLoading = true;
      })
      .addCase(K8ResetClusterAsync.fulfilled, (state) => {
        state.actionLoading = false;
      })
      .addCase(K8ResetClusterAsync.rejected, (state, { payload }) => {
        state.actionLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    //reset kubeconfig
    builder
      .addCase(K8ResetkubeconfigAsync.pending, (state) => {
        state.actionLoading = true;
      })
      .addCase(K8ResetkubeconfigAsync.fulfilled, (state) => {
        state.actionLoading = false;
      })
      .addCase(K8ResetkubeconfigAsync.rejected, (state, { payload }) => {
        state.actionLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });
    //update policy
    builder
      .addCase(K8UpdatePolicyAsync.pending, (state) => {
        state.actionLoading = true;
      })
      .addCase(K8UpdatePolicyAsync.fulfilled, (state) => {
        state.actionLoading = false;
      })
      .addCase(K8UpdatePolicyAsync.rejected, (state, { payload }) => {
        state.actionLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });
    //openIdConnect
    builder
      .addCase(getK8ConfigureOIDCAsync.pending, (state, action) => {
        if (!action.meta.arg.withoutLoading) state.openIdConnectLoading = true;
      })
      .addCase(getK8ConfigureOIDCAsync.fulfilled, (state, { payload }) => {
        state.openIdConnect = payload.openIdConnect;
        state.openIdConnectLoading = false;
      })
      .addCase(getK8ConfigureOIDCAsync.rejected, (state, { payload }) => {
        state.openIdConnectLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(K8ConfigureOIDCAsync.pending, (state) => {
        state.actionLoading = true;
      })
      .addCase(K8ConfigureOIDCAsync.fulfilled, (state) => {
        state.actionLoading = false;
      })
      .addCase(K8ConfigureOIDCAsync.rejected, (state, { payload }) => {
        state.actionLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });
    //delete
    builder
      .addCase(deleteK8ConfigureOIDCAsync.pending, (state) => {
        state.actionLoading = true;
      })
      .addCase(deleteK8ConfigureOIDCAsync.fulfilled, (state) => {
        state.actionLoading = false;
      })
      .addCase(deleteK8ConfigureOIDCAsync.rejected, (state, { payload }) => {
        state.actionLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });
    //
    builder
      .addCase(updateK8ConfigureOIDCAsync.pending, (state) => {
        state.actionLoading = true;
      })
      .addCase(updateK8ConfigureOIDCAsync.fulfilled, (state) => {
        state.actionLoading = false;
      })
      .addCase(updateK8ConfigureOIDCAsync.rejected, (state, { payload }) => {
        state.actionLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });
    builder
      .addCase(updateK8CustomizationAsync.pending, (state) => {
        state.actionLoading = true;
      })
      .addCase(updateK8CustomizationAsync.fulfilled, (state) => {
        state.actionLoading = false;
      })
      .addCase(updateK8CustomizationAsync.rejected, (state, { payload }) => {
        state.actionLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(getK8MetricEtcdUsageListAsync.pending, (state, action) => {
        if (!action.meta.arg.withoutLoading)
          state.metricEtcdUsageLoading = true;
      })
      .addCase(
        getK8MetricEtcdUsageListAsync.fulfilled,
        (state, { payload }) => {
          state.metricEtcdUsage = payload.metricEtcdUsage;
          state.metricEtcdUsageLoading = false;
        }
      )
      .addCase(getK8MetricEtcdUsageListAsync.rejected, (state, { payload }) => {
        state.metricEtcdUsageLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });
  },
});

export const selectK8Service = (state: RootState) => state.k8Service.service;
export const selectK8ServiceLoading = (state: RootState) =>
  state.k8Service.loading;
export const selectK8ServiceActionLoading = (state: RootState) =>
  state.k8Service.actionLoading;

//pools
export const selectK8Pools = (state: RootState) => state.k8Service.pools;
export const selectK8PoolsLoading = (state: RootState) =>
  state.k8Service.poolsLoading;

//ip restrictions
export const selectK8IpRestrictions = (state: RootState) =>
  state.k8Service.ipRestrictions;
export const selectK8IpRestrictionsLoading = (state: RootState) =>
  state.k8Service.ipRestrictionsLoading;
export const selectK8IpRestrictionsActionLoading = (state: RootState) =>
  state.k8Service.ipRestrictionsActionLoading;
export const selectK8IpRestrictionEditor = (state: RootState) =>
  state.k8Service.showIpRestrictionEditor;
export const selectK8SelectedIpRestriction = (state: RootState) =>
  state.k8Service.selectedIpRestriction;

//logs
export const selectK8LogsUrl = (state: RootState) => state.k8Service.logsUrl;
export const selectK8LogsUrlLoading = (state: RootState) =>
  state.k8Service.logsUrlLoading;
export const selectK8Logs = (state: RootState) => state.k8Service.logs;
export const selectK8LogsLoading = (state: RootState) =>
  state.k8Service.logsLoading;

export const selectK8NameModal = (state: RootState) =>
  state.k8Service.k8ShowNameModal;
export const selectK8ResetClusterModal = (state: RootState) =>
  state.k8Service.k8ShowResetClusterModal;
export const selectK8ChangeSecurityModal = (state: RootState) =>
  state.k8Service.k8ShowChangeSecurityModal;
export const selectK8ResetkubeConfigModal = (state: RootState) =>
  state.k8Service.k8ShowResetkubeConfigModal;
export const selectK8EnableDisableModal = (state: RootState) =>
  state.k8Service.k8ShowEnableDisableModal;
export const selectK8ConfigureOIDCModal = (state: RootState) =>
  state.k8Service.K8ConfigureOIDCModal;

export const selectK8OpenIdConnect = (state: RootState) =>
  state.k8Service.openIdConnect;
export const selectK8OpenIdConnectLoading = (state: RootState) =>
  state.k8Service.openIdConnectLoading;

export const selectK8MetricEtcdUsage = (state: RootState) =>
  state.k8Service.metricEtcdUsage;
export const selectK8MetricEtcdUsageLoading = (state: RootState) =>
  state.k8Service.metricEtcdUsageLoading;

export const {
  handleClearK8ServiceSlice,
  handleToggleK8IpRestrictionEditor,
  handleToggleK8NameModal,
  handleToggleK8ResetClusterModal,
  handleToggleK8ChangeSecurityModal,
  handleToggleK8ResetkubeConfigModal,
  handleToggleK8EnableDisableModal,
  handleToggleK8ConfigureOIDCModal,
} = k8ServiceSlice.actions;
export default k8ServiceSlice.reducer;
