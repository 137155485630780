import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import { EmptyState, Flex, SimpleTable, Tag, Typography } from "djuno-design";
import {
  getK8NodePoolsAsync,
  selectK8Pools,
  selectK8PoolsLoading,
  selectK8Service,
} from "../../../../store/kubernetes/k8ServiceSlice";
import HighlighterText from "../../../general/HighlighterText";
import { useSearch } from "../../../../providers/SearchProvider";
import { K8NodePool } from "../../../../types/kubernetes";
import { formatTimestamp } from "../../../../utils/date";
import { ReactComponent as CloseIcon } from "./../../../../assets/icons/close.svg";
import { ReactComponent as CheckIcon } from "./../../../../assets/icons/check.svg";

const K8NodePoolsTab = () => {
  const k8Service = useAppSelector(selectK8Service);

  const pools = useAppSelector(selectK8Pools);
  const poolsLoading = useAppSelector(selectK8PoolsLoading);
  const [filteredPools, setFilteredPools] = useState<K8NodePool[]>([]);

  const { value: searchValue } = useSearch();

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (k8Service) {
      dispatch(getK8NodePoolsAsync({ id: k8Service.id }));
    }
  }, [dispatch, k8Service]);

  useEffect(() => {
    const lookedUpFiles = pools?.filter((pool) =>
      pool?.name.toLowerCase().includes(searchValue.toLowerCase())
    );
    setFilteredPools(lookedUpFiles);
  }, [dispatch, pools, searchValue]);

  return (
    <>
      {/* <Flex items="center" justify="between" className="webapps-header">
        <Typography.Title level={5}>
          Manage access to the APIServer
        </Typography.Title>
        <div className="flex ">
          <div className="flex create-webapp-button">
            <Button
              onClick={() => {
                dispatch(handleToggleK8IpRestrictionEditor({ status: true }));
              }}
              uiType="primary"
              className="group"
            >
              Add IP
              <PlusIcon className="w-3 h-3 group-hover:rotate-90 group-hover:scale-110 transition-all duration-500" />
            </Button>
          </div>
        </div>
      </Flex> */}

      <div className="mt-5 w-full database-table">
        {/* <Typography.Text size="sm">
          Choose the IPv4 ranges that can access your cluster’s APIServer. If
          there is no IP, no restriction is applied
        </Typography.Text> */}
        <SimpleTable
          loading={poolsLoading}
          containerClassName="min-h-[240px] mt-5"
        >
          <SimpleTable.Head>
            <SimpleTable.Row>
              <SimpleTable.TH lable="Pool Name" />
              <SimpleTable.TH lable="Flavor" />
              <SimpleTable.TH lable="Anti-affinity" />
              <SimpleTable.TH lable="Number of nodes" />
              <SimpleTable.TH lable="Autoscaling" />
              <SimpleTable.TH lable="Monthly rate" />
              <SimpleTable.TH lable="Creation date" />
              <SimpleTable.TH lable="Status" />
            </SimpleTable.Row>
          </SimpleTable.Head>
          <SimpleTable.Body>
            {filteredPools.map((pool, i) => (
              <SimpleTable.Row key={i}>
                <SimpleTable.TD className="cursor-pointer">
                  <Flex>
                    <HighlighterText
                      searchValue={searchValue}
                      textToHighlight={pool.name}
                      className="max-w-[110px] md:max-w-[400px] lg:max-w-[350px] xl:max-w-[400px] truncate"
                    />
                  </Flex>
                </SimpleTable.TD>
                <SimpleTable.TD>
                  <Typography.Text className="!text-xs md:!text-sm">
                    {pool.flavor}
                  </Typography.Text>
                </SimpleTable.TD>
                <SimpleTable.TD>
                  <Typography.Text className="!text-xs md:!text-sm">
                    {pool.antiAffinity ? (
                      <CheckIcon className="w-4 aspect-square flex-shrink-0 dark:text-slate-300" />
                    ) : (
                      <CloseIcon className="w-4 aspect-square flex-shrink-0 dark:text-slate-300" />
                    )}
                  </Typography.Text>
                </SimpleTable.TD>
                <SimpleTable.TD>
                  <Typography.Text className="!text-xs md:!text-sm">
                    {pool.currentNodes}/{pool.availableNodes}
                  </Typography.Text>
                </SimpleTable.TD>
                <SimpleTable.TD>
                  <Typography.Text className="!text-xs md:!text-sm">
                    {pool.autoscale ? (
                      <Tag color="success">Enabled</Tag>
                    ) : (
                      <Tag color="error">Disabled</Tag>
                    )}
                  </Typography.Text>
                </SimpleTable.TD>
                <SimpleTable.TD>
                  <Typography.Text className="!text-xs md:!text-sm">
                    {pool.monthlyBilled ? (
                      <CheckIcon className="w-4 aspect-square flex-shrink-0 dark:text-slate-300" />
                    ) : (
                      <CloseIcon className="w-4 aspect-square flex-shrink-0 dark:text-slate-300" />
                    )}
                  </Typography.Text>
                </SimpleTable.TD>
                <SimpleTable.TD>
                  <Typography.Text className="!text-xs md:!text-sm">
                    {formatTimestamp(pool.createdAt, "DD/MM/YYYY").date}
                  </Typography.Text>
                </SimpleTable.TD>
                <SimpleTable.TD>
                  <Typography.Text className="!text-xs md:!text-sm">
                    <Tag
                      className="text-xs"
                      color={
                        pool.status === "CREATING"
                          ? "warning"
                          : pool.status === "READY"
                          ? "success"
                          : "processing"
                      }
                    >
                      {pool.status}
                    </Tag>
                  </Typography.Text>
                </SimpleTable.TD>
              </SimpleTable.Row>
            ))}

            {pools.length === 0 && (
              <SimpleTable.Row withoutHoverStyle={true} className="h-[200px]">
                <SimpleTable.TD colSpan={8} className="!border-0">
                  <EmptyState text="You have not created any ip yet" />
                </SimpleTable.TD>
              </SimpleTable.Row>
            )}
          </SimpleTable.Body>
        </SimpleTable>
      </div>
    </>
  );
};

export default K8NodePoolsTab;
