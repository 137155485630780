import { useNavigate } from "react-router-dom";
import { ReactComponent as CloseIcon } from "./../../assets/icons/close.svg";
import { ReactComponent as ArrowRightIcon } from "./../../assets/icons/arrow-right.svg";
import { K8ServicesUrl } from "../../utils/urls";
import { useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { K8CreateSchema } from "../../utils/validations";
import { Button, cn, Steps, Typography } from "djuno-design";
import useConfigIntercomeSetting from "../../hooks/useConfigIntercomeSetting";
// import useLimitations from "../../hooks/useLimitations";
import { K8CreateLevels, K8CreationStep } from "../../types/kubernetes";

import DeployingSourceStep from "../../components/kubernetes/create-page-steps/DeployingSourceStep";
import {
  createK8ServiceAsync,
  getK8PlansAsync,
  handleClearK8CreationSlice,
  selectK8CreateLoading,
} from "../../store/kubernetes/k8CreateSlice";
import { getRegionsAsync } from "../../store/instances/instancesSlice";
import ConfigsStep from "../../components/kubernetes/create-page-steps/ConfigsStep";
import { getK8ServicesAsync } from "../../store/kubernetes/k8ServicesSlice";

const K8CreatePage = () => {
  useConfigIntercomeSetting();

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [activeStepValue, setActiveStepValue] = useState<K8CreateLevels>(
    "DeployingSourceStep"
  );

  // const { numberOfWebAppsLimitChecker } = useLimitations({
  //   cases: ["NumberOfK8"],
  // });

  const createLoading = useAppSelector(selectK8CreateLoading);

  const steps: K8CreationStep[] = useMemo(() => {
    const deployingSourceOption: K8CreationStep = {
      title: "Deploying Source",
      value: "DeployingSourceStep",
    };

    const formOption: K8CreationStep = {
      title: "Service Data",
      value: "ConfigsStep",
    };

    return [deployingSourceOption, formOption];
  }, []);

  const activeStepData = useMemo(() => {
    const stepsValues = steps.map((s) => s.value);
    const activeStep = steps.find((s) => s.value === activeStepValue);
    return {
      activeStep,
      activeStepIndex: stepsValues.indexOf(activeStepValue),
    };
  }, [activeStepValue, steps]);

  const form = useForm({
    resolver: yupResolver(K8CreateSchema()),
    mode: "all",
    defaultValues: {
      Version: "1.32",
      SecurityPolicy: "ALWAYS_UPDATE",
    },
  });

  const selectedRegion = form.watch("Region");
  const selectedVersion = form.watch("Version");
  const selectedPolicy = form.watch("SecurityPolicy");

  const continueButtonValidation = useMemo(() => {
    let disabled = true;

    if (activeStepValue === "DeployingSourceStep") {
      disabled =
        selectedRegion === "" ||
        selectedVersion === "" ||
        selectedPolicy === "";
    }

    if (activeStepValue === "ConfigsStep") {
      disabled = !form.formState.isValid;
    }

    return { disabled };
  }, [
    activeStepValue,
    form.formState.isValid,
    selectedPolicy,
    selectedRegion,
    selectedVersion,
  ]);

  const handleCreateK8 = (data: any) => {
    if (!createLoading) {
      let formData = {
        name: data.AppName,
        region: data.Region,
        version: data.Version,
        updatePolicy: data.SecurityPolicy,
        planId: data.PlanId,
      };

      dispatch(createK8ServiceAsync({ data: formData })).then((action) => {
        if (action.type === "k8-services/create/fulfilled") {
          dispatch(getK8ServicesAsync({}));
          navigate(K8ServicesUrl);
        }
      });
    }
  };

  useEffect(() => {
    dispatch(getK8PlansAsync({}));
  }, [dispatch]);

  useEffect(() => {
    form.setValue("AppName", "");
  }, [form]);

  useEffect(() => {
    dispatch(getRegionsAsync());
  }, [dispatch]);

  useEffect(() => {
    return () => {
      dispatch(handleClearK8CreationSlice());
    };
  }, [dispatch]);

  return (
    <>
      <div className="flex items-center justify-between h-16 px-6 sticky top-0 z-20 bg-white dark:bg-dark-1 border-b dark:border-dark-2">
        <div className="items-center justify-between flex flex-1 transition duration-150">
          <Typography.Text className="font-medium">
            Create kubernetes service
          </Typography.Text>
        </div>
        <div className="">
          <Button
            uiType="light"
            uiSize="small"
            onClick={() => navigate(K8ServicesUrl)}
            className="group"
          >
            <CloseIcon className="w-3 h-3 group-hover:rotate-90 group-hover:scale-110 transition-all duration-500" />
          </Button>
        </div>
      </div>

      <Steps
        step={activeStepValue}
        steps={[
          ...steps.map((step) => ({
            label: (
              <Typography.Text
                uiType="transparent"
                className="!text-xs md:!text-sm"
              >
                {step.title}
              </Typography.Text>
            ),
            value: step.value,
            callback: step.callback,
          })),
        ]}
        className="px-6"
      />
      <form>
        <div className="mt-5 w-full px-6">
          <div className="mx-auto flex-1 flex justify-start pb-14 w-full">
            {activeStepValue === "DeployingSourceStep" && (
              <DeployingSourceStep form={form} />
            )}
            {activeStepValue === "ConfigsStep" && <ConfigsStep form={form} />}
          </div>
        </div>

        <div className="fixed bottom-0 right-0 left-0 flex items-center w-full h-16 border-t bg-white dark:bg-dark-1 dark:border-dark-2 px-6 lg:pl-80">
          <div
            className={cn("flex items-center justify-between w-full", {
              "!justify-end": activeStepData.activeStepIndex === 0,
            })}
          >
            {activeStepData.activeStepIndex > 0 && (
              <Button
                uiType="light"
                onClick={() => {
                  setActiveStepValue(
                    steps[activeStepData.activeStepIndex - 1].value
                  );
                }}
              >
                back
              </Button>
            )}

            <Button
              uiType="primary"
              disabled={continueButtonValidation.disabled}
              onClick={() => {
                if (activeStepData.activeStepIndex + 1 < steps.length) {
                  setActiveStepValue(
                    steps[activeStepData.activeStepIndex + 1].value
                  );
                }
                if (activeStepData.activeStepIndex + 1 === steps.length) {
                  form.handleSubmit(handleCreateK8)();
                }
              }}
              type="button"
              className="group w-[110px]"
              loading={createLoading}
            >
              {activeStepData.activeStepIndex + 1 !== steps.length
                ? "Continue"
                : "Create Service"}
              <ArrowRightIcon className="w-4 h-4 group-hover:scale-110 group-hover:translate-x-1 transition-all duration-300" />
            </Button>
          </div>
        </div>
      </form>
    </>
  );
};

export default K8CreatePage;
