import { useNavigate, useParams } from "react-router-dom";
import { InstancesVolumeBackupListUrl } from "../../../../../utils/urls";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../hooks";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { InstanceVolumeBackupSchema } from "../../../../../utils/validations";
import { DBSCatalog } from "../../../../../types/database";
import {
  getDBSCatalogsAsync,
  selectDBSCatalogs,
  selectDBSCatalogsLoading,
} from "../../../../../store/database/servicesSlice";
import {
  createInstancesVolumeBackupAsync,
  getInstancesVolumeAsync,
  getInstancesVolumesAsync,
  handleInstanceDetachShowModal,
  selectInstancesSelectedVolume,
  selectInstancesSelectedVolumeLoading,
  selectInstancesVolumesActionLoading,
} from "../../../../../store/instances/instancesVolumesSlice";
import { RadioGroup } from "@headlessui/react";
import { capitalizeFirstLetter } from "../../../../../utils";
import { getDBSPriceAmount } from "../../../../databases/ServiceCreateComponents";
import {
  getInstanceVolumeBackupListAsync,
  handleHideVolumeBackupModal,
  selectshowVolumeBackupModal,
} from "../../../../../store/instances/instancesVolumeSlice";
import {
  Alert,
  Button,
  cn,
  Flex,
  Input,
  Loading,
  Modal,
  Typography,
} from "djuno-design";
import InstanceDetachModal from "../../InstanceDetachModal";

const backupTypes = [
  {
    id: "backup",
    code: "snapshot.monthly.postpaid",
    title: "Volume Backup",
    description: `Limit the impact of unexpected data loss on your application data.
The backup is stored on a different cluster (Object Storage), but in the same location as your volume.`,
  },
];

const InstanceVolumeBackupModal = () => {
  const { id } = useParams();

  const navigate = useNavigate();
  const isOpen = useAppSelector(selectshowVolumeBackupModal);
  const selectedVolume = useAppSelector(selectInstancesSelectedVolume);
  const selectedVolumeLoading = useAppSelector(
    selectInstancesSelectedVolumeLoading
  );
  const catalogs = useAppSelector(selectDBSCatalogs);
  const catalogsLoading = useAppSelector(selectDBSCatalogsLoading);
  const actionLoading = useAppSelector(selectInstancesVolumesActionLoading);

  // states
  const [filteredCatalogs, setFilteredCatalogs] = useState<Array<DBSCatalog>>(
    []
  );

  const dispatch = useAppDispatch();

  const methods = useForm({
    mode: "all",
    resolver: yupResolver(InstanceVolumeBackupSchema),
    defaultValues: {
      selectedType: "snapshot.monthly.postpaid",
      name: "",
    },
  });

  const {
    handleSubmit,
    reset,
    control,
    formState: { errors, isValid },
    register,
    watch,
  } = methods;

  // watch form values
  const selectedType = watch("selectedType");

  //get selected volume
  useEffect(() => {
    if (selectedVolume === null && id !== undefined) {
      dispatch(getInstancesVolumeAsync({ id }));
    }
  }, [dispatch, id, selectedVolume]);

  //get first data
  useEffect(() => {
    dispatch(getDBSCatalogsAsync());
  }, [dispatch]);

  useEffect(() => {
    if (catalogs) {
      setFilteredCatalogs(
        catalogs.filter(
          (a) =>
            a.planCode.startsWith("volume.") ||
            a.planCode.startsWith("snapshot.")
        )
      );
    }
  }, [catalogs]);

  const handleSubmitForm = (data: any) => {
    if (selectedVolume && !actionLoading) {
      if (selectedType === "snapshot.monthly.postpaid") {
        dispatch(
          createInstancesVolumeBackupAsync({
            region: selectedVolume.region,
            data: {
              name: data.name,
              volumeId: selectedVolume.id,
            },
          })
        ).then((action) => {
          if (action.type === "instances/volumes/backup/create/fulfilled") {
            handleOnClose();
            dispatch(
              getInstanceVolumeBackupListAsync({
                volumeId: selectedVolume?.id,
                regionName: selectedVolume?.region,
                withoutLoading: true,
              })
            );
            dispatch(
              getInstancesVolumeAsync({
                id: selectedVolume.id,
                withoutLoading: true,
              })
            ); //?
            dispatch(getInstancesVolumesAsync({ withoutLoading: true })); //?
            navigate(InstancesVolumeBackupListUrl(selectedVolume.id)); //?
          }
        });
      }
    }
  };

  const disabledForMakingBackup = useMemo(() => {
    if (!selectedVolume) return true;
    // console.log(selectedType);
    if (
      selectedVolume.attachedTo.length > 0 &&
      selectedType === "snapshot.monthly.postpaid"
    ) {
      return true;
    } else {
      return false;
    }
  }, [selectedType, selectedVolume]);

  const handleOnClose = useCallback(() => {
    reset();
    dispatch(handleHideVolumeBackupModal());
  }, [dispatch, reset]);

  useEffect(() => {
    return () => {
      handleOnClose();
    };
  }, [handleOnClose]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleOnClose}
      contentClassName="max-w-lg"
      title={`Create a backup from ${selectedVolume?.name}`}
    >
      {(catalogsLoading || selectedVolumeLoading) && (
        <Flex items="center" justify="center" className="w-full min-h-[250px]">
          <Loading borderSize={2} />
        </Flex>
      )}
      {!catalogsLoading && !selectedVolumeLoading && (
        <form onSubmit={handleSubmit(handleSubmitForm)}>
          <div className="mt-3 w-full flex flex-col lg:flex-row relative">
            <div className="w-full flex flex-col">
              <Controller
                name="selectedType"
                control={control}
                render={({ field: { value, onChange } }) => {
                  return (
                    <RadioGroup value={value || null} onChange={onChange}>
                      <div className="flex flex-col w-full mt-3">
                        <div className="w-full mb-5">
                          {backupTypes.map((type, i) => {
                            const addon = filteredCatalogs.find(
                              (a) => a.planCode === type.code
                            );

                            const exVAT = addon
                              ? getDBSPriceAmount(addon.pricings[0].price, 3)
                              : null;
                            return (
                              <RadioGroup.Option
                                key={type.title + i}
                                value={type.code}
                              >
                                {({ checked, disabled }) => {
                                  return (
                                    <div
                                      className={cn(
                                        "col-span-1 border-2 text-md rounded-xl dark:bg-dark-3 dark:border-gray-400/10 bg-white p-4 shadow hover:shadow-lg transition-all duration-300 cursor-pointer h-full",
                                        {
                                          "border-primary-400 dark:border-primary-400":
                                            checked,
                                          "!cursor-not-allowed": disabled,
                                        }
                                      )}
                                    >
                                      <div className="flex items-center justify-between mb-2">
                                        <Typography.Text className="!text-sm">
                                          {capitalizeFirstLetter(type.title)}
                                        </Typography.Text>
                                      </div>
                                      <div className="flex items-center justify-between mb-2">
                                        <Typography.Text className="!text-xs">
                                          {type.description}
                                        </Typography.Text>
                                      </div>
                                      <div className="flex flex-col border-t mt-2 pt-2 gap-0.5">
                                        {exVAT && (
                                          <div
                                            key={i}
                                            className="flex items-center "
                                          >
                                            <Typography.Text className="!text-xs font-medium">
                                              Price: ${exVAT} ex. VAT/GB/month
                                            </Typography.Text>
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  );
                                }}
                              </RadioGroup.Option>
                            );
                          })}
                        </div>
                      </div>
                    </RadioGroup>
                  );
                }}
              />

              {/* select name */}
              <div className="flex flex-col gap-1 mb-9">
                <Input
                  label="Backup name"
                  {...register("name")}
                  error={errors.name?.message}
                />
              </div>
              {selectedVolume && disabledForMakingBackup && (
                <Alert
                  uiType="warning"
                  showIcon
                  message={
                    <Typography.Text uiType="transparent" size="sm">
                      If you want to create a Volume Backup, you must detach
                      your volume from its instance.
                      <Button
                        className="!mt-2"
                        onClick={() => {
                          dispatch(
                            handleInstanceDetachShowModal({
                              selectedVolume,
                            })
                          );
                        }}
                      >
                        Detach
                      </Button>
                    </Typography.Text>
                  }
                />
              )}

              <div className="mt-4 flex justify-end gap-2">
                <Button uiType="light" onClick={handleOnClose}>
                  Cancel
                </Button>
                <Button
                  uiType="primary"
                  type="submit"
                  disabled={!isValid || disabledForMakingBackup}
                  loading={actionLoading}
                >
                  Confirm
                </Button>
              </div>
            </div>
          </div>
        </form>
      )}
      <InstanceDetachModal />
    </Modal>
  );
};

export default InstanceVolumeBackupModal;
