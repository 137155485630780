import { Outlet } from "react-router-dom";
import IpfsFilesTab from "../../components/ipfs/files/IpfsFilesTab";
import IpfsOverviewTab from "../../components/ipfs/overview/IpfsOverviewTab";
import IpfsSettingsTab from "../../components/ipfs/settings/IpfsSettingsTab";
import IpfsPlanesTab from "../../components/ipfs/plans/IpfsPlansTab";
import {
  IpfsFilesUrl,
  IpfsOverviewUrl,
  IpfsPlansUrl,
  IpfsSettingsUrl,
} from "../../utils/urls";
import { Helmet } from "react-helmet";
import { ReactComponent as ComputerIcon } from "./../../assets/icons/computer-desktop.svg";
import { ReactComponent as DocumentIcon } from "./../../assets/icons/document-text.svg";
import { ReactComponent as SettingIcon } from "./../../assets/icons/setting.svg";
import { ReactComponent as ListBulletIcon } from "./../../assets/icons/list-bullet.svg";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { selectIpfsNodeStatus } from "../../store/ipfs/ipfsPublicSlice";
import { useEffect } from "react";
import { fetchFilesAysnc } from "../../store/ipfs/filesSlice";
import { Typography, Tabs, TabOptions } from "djuno-design";
import useServiceType from "../../hooks/useServiceType";
import { ServiceRefreshStatus } from "../../store/publicSlice";

export const IpfsPageMenus: TabOptions = [
  {
    label: (
      <div className="flex items-center gap-1 overview">
        <ComputerIcon className="w-3" />
        Overview
      </div>
    ),
    url: IpfsOverviewUrl,
    element: <IpfsOverviewTab />,
    testId: "1th-tab",
  },
  {
    label: (
      <div className="flex items-center gap-1">
        <DocumentIcon className="w-3" />
        Files
      </div>
    ),
    url: IpfsFilesUrl,
    element: <IpfsFilesTab />,
    testId: "2th-tab",
  },

  {
    label: (
      <div className="flex items-center gap-1 settings">
        <SettingIcon className="w-3" />
        Settings
      </div>
    ),
    url: IpfsSettingsUrl,
    element: <IpfsSettingsTab />,
    testId: "3th-tab",
  },
  {
    label: (
      <div className="flex items-center gap-1 plans">
        <ListBulletIcon className="w-3" />
        Plans
      </div>
    ),
    url: IpfsPlansUrl,
    element: <IpfsPlanesTab />,
    testId: "4th-tab",
  },
];

const IpfsPage = () => {
  const ipfsStatus = useAppSelector(selectIpfsNodeStatus);
  const dispatch = useAppDispatch();

  const { serviceType } = useServiceType(
    process.env.REACT_APP_IPFS_SERVICE_TYPE
  );

  useEffect(() => {
    let interval: NodeJS.Timeout | undefined;
    let intervalTime =
      Number(process.env.REACT_APP_NODES_REFRESH_INTERVAL) || 20000;

    if (ipfsStatus !== null && ServiceRefreshStatus.includes(ipfsStatus)) {
      interval = setInterval(() => {
        dispatch(fetchFilesAysnc());
      }, intervalTime);
    } else {
      if (interval) clearInterval(interval);
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [dispatch, ipfsStatus]);

  return (
    <>
      <Helmet>
        <title>
          {process.env.REACT_APP_NAME} | {serviceType?.ServiceName || ""}
        </title>
        <meta
          name="description"
          content="IPFS, or InterPlanetary File System, is a decentralized protocol designed to revolutionize the way we store and access content on the internet. Unlike traditional web hosting systems that rely on centralized servers, IPFS utilizes a peer-to-peer network where files are stored across multiple nodes. Each file is assigned a unique cryptographic hash, enabling efficient retrieval and verification"
        />
      </Helmet>
      <div className="flex items-center justify-between h-20">
        <div className="px-6 items-center justify-between flex flex-1 transition duration-150">
          <Typography.Title level={5} className="!mb-0">
            {serviceType?.ServiceName}
          </Typography.Title>
        </div>
        <div className="mr-6 w-96"></div>
      </div>
      <div className="mx-6 min-h-[calc(100vh-9.5rem)] flex flex-col">
        <Tabs
          options={IpfsPageMenus.map((o) => ({
            label: o.label,
            url: o.url,
            testId: o.testId,
          }))}
        />
        <div className="py-6 min-h-full flex flex-col flex-1">
          <Outlet />
        </div>
      </div>
    </>
  );
};
export default IpfsPage;
