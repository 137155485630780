import { Button, cn, Steps, Typography } from "djuno-design";
import { Helmet } from "react-helmet";
import { ReactComponent as CloseIcon } from "./../../assets/icons/close.svg";
import { ReactComponent as ArrowRightIcon } from "./../../assets/icons/arrow-right.svg";
import { WebAppsUrl } from "../../utils/urls";
import { useNavigate } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import { useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks";
import useConfigIntercomeSetting from "../../hooks/useConfigIntercomeSetting";
import { WebAppTemplate } from "../../types/web-app";
import {
  craeteWebAppsFromTemplateAsync,
  getWebAppsAsync,
  getWebAppsPlansAsync,
  getWebAppsTemplatesAsync,
  selectWebApps,
  selectWebAppsActionLoading,
  selectWebAppsTemplatesLoading,
} from "../../store/web-app/webAppsSlice";
import SelectTemplateStep from "../../components/web-apps/create-from-template-steps/SelectTemplateStep";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { WebAppCreateFromTemplateSchema } from "../../utils/validations";
import ConfigsStep from "../../components/web-apps/create-page-steps/ConfigsStep";
import useLimitations from "../../hooks/useLimitations";
import {
  selectWebAppNameValidation,
  selectWebAppNameValidationLoading,
} from "../../store/web-app/webAppCreateSlice";

const steps = [
  {
    title: "Select Template",
    value: "SelectTemplate",
  },
  {
    title: "App Data",
    value: "ConfigsStep",
  },
];

const WebAppCreateFromTemplatePage = () => {
  useConfigIntercomeSetting();
  const webApps = useAppSelector(selectWebApps);
  const templatesLoading = useAppSelector(selectWebAppsTemplatesLoading);
  const loading = useAppSelector(selectWebAppsActionLoading);

  const [activeStepValue, setActiveStepValue] = useState<
    "SelectTemplate" | "ConfigsStep"
  >("SelectTemplate");

  const [selectedCategory, setSelectedCategory] = useState<string>("0");
  const [selectedTemplate, setSelectedTemplate] = useState<
    WebAppTemplate | undefined
  >();

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (webApps.length === 0) {
      dispatch(getWebAppsAsync({}));
    }
  }, [dispatch, webApps.length]);

  const { numberOfWebAppsLimitChecker } = useLimitations({
    cases: ["NumberOfWebApps"],
  });

  const isValidToCreateNewWebApp = useMemo(
    () => !numberOfWebAppsLimitChecker(webApps.length),
    [numberOfWebAppsLimitChecker, webApps.length]
  );

  if (!isValidToCreateNewWebApp) {
    navigate(WebAppsUrl);
  }

  const nameValidationLoading = useAppSelector(
    selectWebAppNameValidationLoading
  );
  const nameValidation = useAppSelector(selectWebAppNameValidation);

  const form = useForm({
    resolver: yupResolver(WebAppCreateFromTemplateSchema()),
    mode: "all",
  });

  useEffect(() => {
    dispatch(getWebAppsTemplatesAsync());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getWebAppsPlansAsync({}));
  }, [dispatch]);

  const handleSelectTemplate = (template: WebAppTemplate) => {
    setSelectedTemplate(template);
  };

  const handleSelectCategory = (category: string) => {
    setSelectedCategory(category);
  };

  const handleCreateWebApp = (data: any) => {
    if (!loading && selectedTemplate) {
      const formData = {
        AppName: data.AppName,
        PlanId: data.PlanId,
        ServiceName: selectedTemplate.Name,
      };

      dispatch(craeteWebAppsFromTemplateAsync({ data: formData })).then(
        (action) => {
          if (action.type === "web-app/templates/craete/fulfilled") {
            // dispatch(getWebAppsAsync({}));
            navigate(WebAppsUrl);
          }
        }
      );
    }
  };

  return (
    <>
      <Helmet>
        <title>{process.env.REACT_APP_NAME} | Create From Templates</title>
      </Helmet>
      <div className="flex items-center justify-between h-16 px-6 sticky top-0 z-20 bg-white dark:bg-dark-1 border-b dark:border-dark-2">
        <div className="items-center justify-between flex flex-1 transition duration-150">
          <Typography.Text className="font-medium">
            Create Web App From Templates
          </Typography.Text>
        </div>
        <div className="">
          <Button
            uiType="light"
            uiSize="small"
            onClick={() => navigate(WebAppsUrl)}
            className="group"
          >
            <CloseIcon className="w-3 h-3 group-hover:rotate-90 group-hover:scale-110 transition-all duration-500" />
          </Button>
        </div>
      </div>

      <Steps
        step={activeStepValue}
        steps={[
          ...steps.map((step) => ({
            label: (
              <Typography.Text
                uiType="transparent"
                className="!text-xs md:!text-sm"
              >
                {step.title}
              </Typography.Text>
            ),
            value: step.value,
          })),
        ]}
        className="px-6"
      />
      <form>
        <div className="mt-5 w-full px-6">
          <div className="mx-auto flex-1 flex justify-start pb-20 w-full">
            <AnimatePresence>
              {activeStepValue === "SelectTemplate" && (
                <SelectTemplateStep
                  selectedTemplate={selectedTemplate}
                  handleSelectTemplate={handleSelectTemplate}
                  selectedCategory={selectedCategory}
                  handleSelectCategory={handleSelectCategory}
                />
              )}
              {activeStepValue === "ConfigsStep" && (
                <ConfigsStep form={form} showEnvVars={false} showPort={false} />
              )}
            </AnimatePresence>
          </div>
          <div className="fixed bottom-0 right-0 left-0 flex items-center w-full h-16 border-t bg-white dark:bg-dark-1 dark:border-dark-2 px-6 lg:pl-80">
            <div
              className={cn("flex items-center justify-between w-full", {
                "!justify-end": activeStepValue === "SelectTemplate",
              })}
            >
              {activeStepValue !== "SelectTemplate" && (
                <Button
                  uiType="light"
                  onClick={() => {
                    setActiveStepValue("SelectTemplate");
                  }}
                >
                  back
                </Button>
              )}

              <Button
                uiType="primary"
                disabled={
                  selectedTemplate === undefined ||
                  (activeStepValue === "ConfigsStep" &&
                    nameValidation !== null) ||
                  (activeStepValue === "ConfigsStep" &&
                    !form.formState.isValid) ||
                  nameValidationLoading
                }
                onClick={() => {
                  if (activeStepValue === "SelectTemplate") {
                    setActiveStepValue("ConfigsStep");
                  }
                  if (activeStepValue === "ConfigsStep") {
                    form.handleSubmit(handleCreateWebApp)();
                  }
                }}
                type="button"
                className="group w-[110px]"
                loading={templatesLoading || nameValidationLoading || loading}
              >
                {activeStepValue === "SelectTemplate"
                  ? "Continue"
                  : "Create Web App"}
                <ArrowRightIcon className="w-4 h-4 group-hover:scale-110 group-hover:translate-x-1 transition-all duration-300" />
              </Button>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default WebAppCreateFromTemplatePage;
