import {
  Alert,
  Button,
  Card,
  cn,
  Dropdown,
  Flex,
  Loading,
  SecureCopy,
  Tag,
  Tooltip,
  Typography,
} from "djuno-design";
import { NotExist } from "./../../../layouts/NotData";
import { useNavigate } from "react-router-dom";
import { ReactComponent as MoreIcon } from "./../../../../assets/icons/more.svg";
import { ReactComponent as RefreshIcon } from "./../../../../assets/icons/arrow-path.svg";
import { ReactComponent as ArchiveIcon } from "./../../../../assets/icons/archive-box.svg";
import { ReactComponent as EditIcon } from "./../../../../assets/icons/pencil-square.svg";
import { ReactComponent as InfoIcon } from "./../../../../assets/icons/question-mark-circle.svg";
import { useAppDispatch, useAppSelector } from "./../../../../hooks";
import { DeleteModal } from "./../../../modals/QuestionModal";
import {
  deleteK8serviceAsync,
  getK8MetricEtcdUsageListAsync,
  getK8ServiceAsync,
  handleToggleK8ChangeSecurityModal,
  handleToggleK8ConfigureOIDCModal,
  handleToggleK8EnableDisableModal,
  handleToggleK8NameModal,
  handleToggleK8ResetClusterModal,
  handleToggleK8ResetkubeConfigModal,
  K8ResetkubeconfigAsync,
  selectK8MetricEtcdUsage,
  selectK8OpenIdConnect,
  selectK8Service,
  selectK8ServiceActionLoading,
  selectK8ServiceLoading,
} from "./../../../../store/kubernetes/k8ServiceSlice";
import { K8ApiServerAccessUrl, K8ServicesUrl } from "./../../../../utils/urls";
import CustomLink from "./../../../general/CustomLink";
import K8RenameModal from "./K8RenameModal";
import K8ResetClusterModal from "./K8ResetClusterModal";
import K8EnableDisablePluginsModal from "./K8EnableDisablePluginsModal";
import { K8Service } from "./../../../../types/kubernetes";
import K8ResetConfigModal from "./K8ResetConfigModal";
import K8ConfigureOIDCModal from "./K8ConfigureOIDCModal";
import { useEffect, useState } from "react";
import K8UpdatePolicyModal from "./K8UpdatePolicyModal";
import { getK8ServicesAsync } from "../../../../store/kubernetes/k8ServicesSlice";
import { humanizeSize } from "../../../../utils/file";
import Slider from "rc-slider";

const K8OverviewTab = () => {
  const [deleteK8Service, setDeleteK8Service] = useState<K8Service | null>(
    null
  );
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);

  const k8Service = useAppSelector(selectK8Service);
  const loading = useAppSelector(selectK8ServiceLoading);
  const actionLoading = useAppSelector(selectK8ServiceActionLoading);
  const openIdConnect = useAppSelector(selectK8OpenIdConnect);
  const metricEtcdUsage = useAppSelector(selectK8MetricEtcdUsage);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  // useEffect(() => {
  //   if (id) {
  //     dispatch(getK8ServiceAsync({ id: id }));
  //   }
  // }, [dispatch, id]);

  useEffect(() => {
    if (k8Service) {
      // dispatch(getK8ConfigureOIDCAsync({ id: k8Service.id }));
      dispatch(getK8MetricEtcdUsageListAsync({ id: k8Service.id }));
    }
  }, []);

  const OverviewCardSetting = () => {
    if (!k8Service) return null;
    return (
      <div className="flex gap-2">
        <Button
          uiSize="medium"
          uiType="light"
          onClick={() =>
            dispatch(
              getK8ServiceAsync({
                id: k8Service.id,
              })
            )
          }
          className="group"
          tooltip={{ content: "Refresh" }}
        >
          <RefreshIcon className="w-4 h-4 group-hover:rotate-90 group-hover:scale-110 transition-all duration-500" />
        </Button>

        <Dropdown
          itemsClassName="!p-0 !w-60"
          anchor="bottom end"
          menu={[
            {
              key: "1",
              label: (
                <div className="flex items-center gap-1">
                  <EditIcon className="w-4" />
                  Rename
                </div>
              ),
              onClick: (_, close) => {
                close();
                dispatch(handleToggleK8NameModal(true));
              },
              disabled:
                k8Service.status === "INSTALLING" ||
                k8Service.status === "REDEPLOYING" ||
                k8Service.status === "RESETTING" ||
                k8Service.status === "REDEPLOYING",
            },
            {
              key: "2",
              label: (
                <div className="flex items-center gap-1">
                  <RefreshIcon className="w-4" />
                  Reset your cluster
                </div>
              ),
              onClick: (_, close) => {
                close();
                dispatch(handleToggleK8ResetClusterModal(true));
              },
              disabled:
                k8Service.status === "INSTALLING" ||
                k8Service.status === "REDEPLOYING" ||
                k8Service.status === "RESETTING" ||
                k8Service.status === "REDEPLOYING",
            },
            // {
            //   key: "3",
            //   label: (
            //     <div className="flex items-center gap-1">
            //       <PlusIcon className="w-4" />
            //       Create a node pool
            //     </div>
            //   ),
            //   onClick: () => navigate()),
            // },
            {
              key: "4",
              label: (
                <div className="flex items-center gap-1">
                  <RefreshIcon className="w-4" />
                  Reset kubeconfig
                </div>
              ),
              onClick: (_, close) => {
                close();
                dispatch(handleToggleK8ResetkubeConfigModal(true));
              },
              disabled:
                k8Service.status === "INSTALLING" ||
                k8Service.status === "REDEPLOYING" ||
                k8Service.status === "RESETTING" ||
                k8Service.status === "REDEPLOYING",
            },
            {
              key: "5",
              label: (
                <div className="flex items-center gap-1">
                  <EditIcon className="w-4" />
                  Change security policy
                </div>
              ),
              onClick: (_, close) => {
                close();
                dispatch(handleToggleK8ChangeSecurityModal(true));
              },
              disabled:
                k8Service.status === "INSTALLING" ||
                k8Service.status === "REDEPLOYING" ||
                k8Service.status === "RESETTING" ||
                k8Service.status === "REDEPLOYING",
            },
            {
              type: "divider",
            },
            { type: "divider" as const },
            {
              key: "end",
              label: (
                <div className="flex items-center gap-1">
                  <ArchiveIcon className="w-4" />
                  Delete
                </div>
              ),
              danger: true,
              onClick: (_, close) => {
                close();
                setDeleteK8Service(k8Service);
                setShowDeleteModal(true);
              },
              disabled:
                k8Service.status === "INSTALLING" ||
                k8Service.status === "REDEPLOYING" ||
                k8Service.status === "RESETTING" ||
                k8Service.status === "REDEPLOYING",
            },
          ]}
        >
          <Button>Actions</Button>
        </Dropdown>
      </div>
    );
  };

  return (
    <>
      {k8Service && !loading && (
        <>
          <div className="mt-5">
            <Card title="Cluster information" setting={<OverviewCardSetting />}>
              <div className="mt-2 grid gap-7 gap-x-10 md:grid-cols-2">
                <Flex items="center" className="gap-1">
                  <div className="mb-4 md:mb-0 w-full">
                    <Typography.Text strong className="!text-sm">
                      ID
                    </Typography.Text>

                    <SecureCopy type="copy" text={k8Service.id} />
                  </div>
                </Flex>

                <Flex items="center" className="gap-1">
                  <Typography.Text strong className="!text-sm">
                    Name:
                  </Typography.Text>
                  <Typography.Text className="!text-sm">
                    {k8Service?.name}
                  </Typography.Text>
                </Flex>

                <Flex items="center" className="gap-1">
                  <Typography.Text strong className="!text-sm">
                    Status:
                  </Typography.Text>
                  <Tag
                    className="text-xs inline-block"
                    color={k8Service.status === "READY" ? "success" : "warning"}
                  >
                    {k8Service.status}
                  </Tag>
                </Flex>

                <Flex items="center" className="gap-1">
                  <Typography.Text strong className="!text-sm">
                    Minor version of Kubernetes:
                  </Typography.Text>
                  <Typography.Text className="!text-sm">
                    {k8Service.version}
                  </Typography.Text>
                </Flex>

                <Flex direction="col" className="gap-2">
                  <Flex items="center" className="gap-1" direction="row">
                    <Typography.Text
                      strong
                      className="flex items-center gap-1 !text-sm whitespace-nowrap mt-5 mb-2"
                    >
                      Use of ETCD quota
                    </Typography.Text>
                    <Tooltip
                      content="The ETCD quota represents the space allocated to the ETCD database in your managed cluster."
                      className="!text-sm"
                    >
                      <InfoIcon className="dd-w-4 dd-text-slate-500 dark:dd-text-slate-300 dark:hover:dd-text-slate-100 !mt-3" />
                    </Tooltip>
                  </Flex>

                  <Typography.Text>
                    {humanizeSize(metricEtcdUsage?.usage ?? 0).join("B")} KiB/
                    {humanizeSize(metricEtcdUsage?.quota ?? 0).join("B")} MiB
                  </Typography.Text>
                </Flex>

                <div className="flex flex-col gap-1">
                  <Typography.Text strong className="!text-sm">
                    Admission Plugins:
                  </Typography.Text>

                  <div className="flex justify-between items-center ">
                    <Typography.Text className="flex items-center gap-1 !text-sm whitespace-nowrap">
                      Plugin Always Pull Images
                      <Tooltip content="Each new pod has to download the required images every time.">
                        <InfoIcon className="dd-w-4 dd-text-slate-500 dark:dd-text-slate-300 dark:hover:dd-text-slate-100" />
                      </Tooltip>
                    </Typography.Text>
                    {k8Service.customization.apiServer.admissionPlugins.enabled.includes(
                      "AlwaysPullImages"
                    ) ? (
                      <Tag color="success" className="!h-6 mt-3">
                        Enabled
                      </Tag>
                    ) : (
                      <Tag color="warning" className="!h-6 mt-3">
                        Disabled
                      </Tag>
                    )}
                  </div>

                  <div className="flex justify-between items-center">
                    <Typography.Text className="flex items-center gap-1 !text-sm whitespace-nowrap mb-2">
                      Plugin Node Restriction
                      <Tooltip content="The NodeRestriction admission controller plugin restricts what Node and Pod objects a Kubelet can modify. When this admission controller is active, Kubelets are limited to modifying their own API Node object and only API Pod objects that are linked to their node.">
                        <InfoIcon className="dd-w-4 dd-text-slate-500 dark:dd-text-slate-300 dark:hover:dd-text-slate-100" />
                      </Tooltip>
                    </Typography.Text>
                    {k8Service.customization.apiServer.admissionPlugins.enabled.includes(
                      "NodeRestriction"
                    ) ? (
                      <Tag color="success" className="!h-6 mt-3">
                        Enabled
                      </Tag>
                    ) : (
                      <Tag color="warning" className="!h-6 mt-3">
                        Disabled
                      </Tag>
                    )}
                  </div>

                  <Button
                    onClick={() =>
                      dispatch(handleToggleK8EnableDisableModal(true))
                    }
                    disabled={
                      k8Service.status === "INSTALLING" ||
                      k8Service.status === "REDEPLOYING" ||
                      k8Service.status === "RESETTING" ||
                      k8Service.status === "REDEPLOYING"
                    }
                  >
                    Enable/disable plugins
                  </Button>
                </div>

                <Flex items="center" className="gap-1">
                  <Typography.Text strong className="!text-sm">
                    Region:
                  </Typography.Text>
                  <Typography.Text className="!text-sm !inline-block">
                    {k8Service.region}
                  </Typography.Text>
                </Flex>

                <Flex items="center" className="gap-1">
                  <div className="mb-4 md:mb-0 w-full">
                    <Typography.Text strong className="!text-sm">
                      Kubernetes node URLs for your cluster
                    </Typography.Text>

                    <SecureCopy type="copy" text={k8Service.nodesUrl} />
                  </div>
                </Flex>
              </div>
            </Card>
          </div>
          <div className="mt-5">
            <Card title="Network">
              <div className="mt-2 grid gap-7 gap-x-10 md:grid-cols-2">
                <Flex items="center" className="gap-1">
                  <Typography.Text strong className="!text-sm">
                    Attached network:
                  </Typography.Text>
                  <Typography.Text className="!text-sm">Public</Typography.Text>
                </Flex>
              </div>
            </Card>
          </div>
          <div className="mt-5">
            <Card title="Access and security">
              <div className="mt-2 grid gap-7 gap-x-10 md:grid-cols-2">
                <Flex items="center" className="gap-1">
                  <div className="mb-4 md:mb-0 w-full">
                    <Typography.Text strong className="!text-sm">
                      Kubernetes API URL for your cluster
                    </Typography.Text>

                    <SecureCopy type="copy" text={k8Service.url} />
                  </div>
                </Flex>

                <div className="flex flex-col">
                  <Typography.Text strong className="!text-sm">
                    Access limit to APIServer:
                  </Typography.Text>
                  <Typography.Text className="!text-sm">
                    No limit
                  </Typography.Text>

                  <Typography.Text className="text-sm flex items-center gap-1 mt-1">
                    <CustomLink
                      className={cn(
                        "text-sm cursor-pointer text-sky-500 hover:text-sky-600"
                      )}
                      to={K8ApiServerAccessUrl(k8Service.id)}
                    >
                      Modify limitations
                    </CustomLink>
                  </Typography.Text>
                </div>

                <div className="flex flex-col gap-1">
                  <Flex items="center" className="gap-1">
                    <Typography.Text
                      strong
                      className="flex items-center gap-1 !text-sm whitespace-nowrap mt-5 mb-2 "
                    >
                      Kubeconfig file
                    </Typography.Text>
                    <Tooltip
                      content="   The kubeconfig file contains the data required by the
                          kubectl command-line tool to communicate with a
                          cluster's API."
                      className="!text-sm"
                    >
                      <InfoIcon className="dd-w-4 dd-text-slate-500 dark:dd-text-slate-300 dark:hover:dd-text-slate-100 !mt-3" />
                    </Tooltip>
                  </Flex>

                  {/* <Typography.Text className="text-sm flex items-center gap-1 mt-1">
                    <CustomLink
                      className={cn(
                        "text-sm cursor-pointer text-sky-500 hover:text-sky-600"
                      )}
                      to=""
                    >
                      kobeconfig
                    </CustomLink>
                  </Typography.Text> */}
                  <Button
                    onClick={() =>
                      dispatch(
                        K8ResetkubeconfigAsync({
                          kubeId: k8Service.id,
                        })
                      ).then((action) => {
                        if (
                          action.type ===
                          "k8-service/reset-kubeconfig/fulfilled"
                        ) {
                          dispatch(
                            getK8ServicesAsync({ withoutLoading: false })
                          );
                          dispatch(getK8ServiceAsync({ id: k8Service.id }));
                        }
                      })
                    }
                    loading={actionLoading}
                  >
                    kubeconfig
                  </Button>
                </div>

                <Flex items="center" justify="between" className="gap-1">
                  <Flex items="center" className="gap-1">
                    <Typography.Text strong className="!text-sm">
                      OIDC provider:
                    </Typography.Text>

                    <Typography.Text className="!text-sm">
                      No OIDC provider configured
                    </Typography.Text>
                  </Flex>
                  <div className="flex border border-gray-300 rounded-md">
                    <Dropdown
                      itemsClassName="!p-0 !w-60"
                      anchor="bottom end"
                      menu={[
                        {
                          key: "1",
                          label: "Configure an OIDC provider",
                          onClick: (_, close) => {
                            close();
                            dispatch(handleToggleK8ConfigureOIDCModal(true));
                          },
                        },
                        {
                          key: "2", //TODO
                          label: "Edit OIDC provider",
                          onClick: (_, close) => {
                            close();
                            dispatch(handleToggleK8ConfigureOIDCModal(true));
                          },
                          disabled: !openIdConnect,
                        },

                        {
                          key: "3", //TODO
                          label: "Delete the OIDC provider",
                          // onClick: (_, close) => {
                          //   close();
                          //   setDeleteK8Service(k8Service);
                          // },
                          disabled: !openIdConnect,
                        },
                      ]}
                    >
                      <div className="p-1 rounded-md dark:border-dark-2 hover:dark:bg-dark-2 hover:bg-slate-200">
                        <MoreIcon className="w-4 h-4 dark:text-slate-100 text-slate-800" />
                      </div>
                    </Dropdown>
                  </div>
                </Flex>
                <Flex items="center" className="gap-1">
                  <Typography.Text strong className="!text-sm">
                    Security updates:
                  </Typography.Text>
                  <Typography.Text className="!text-sm !inline-block">
                    Maximum security
                  </Typography.Text>
                </Flex>
              </div>
            </Card>
          </div>
        </>
      )}

      {/* empty service */}
      {k8Service === null && !loading && (
        <Flex items="center" justify="center" className="h-full px-4">
          <NotExist
            url={() => navigate(K8ServicesUrl)}
            text="The cluster does not exist"
          />
        </Flex>
      )}

      {loading && (
        <Flex items="center" justify="center" className="!min-h-[300px]">
          <Loading borderSize={2} />
        </Flex>
      )}

      <K8RenameModal />
      <K8ResetClusterModal />
      <K8ResetConfigModal />
      <K8UpdatePolicyModal />
      <K8EnableDisablePluginsModal />
      <K8ConfigureOIDCModal />

      <DeleteModal
        title="Delete cluster"
        description={
          <Flex direction="col" className="!mt-6">
            <Typography.Text size="sm">
              {`Are you sure that you want to delete the ${k8Service?.name} cluster?`}
            </Typography.Text>
            <Alert
              uiType="warning"
              showIcon
              description="Deleting it will permanently erase all corresponding data. The process of deletion does not entitle you to a refund."
              className="mt-5"
            />
          </Flex>
        }
        contentClassName="max-w-lg overflow-hidden"
        isOpen={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        confirmString={k8Service?.name}
        confirmButtonType="danger"
        confirmButtonText="Delete"
        onConfirm={() => {
          deleteK8Service &&
            dispatch(
              deleteK8serviceAsync({
                id: deleteK8Service.id,
              })
            ).then((action) => {
              if (action.type === "k8-service/delete/fulfilled" && k8Service) {
                dispatch(
                  getK8ServiceAsync({ id: k8Service.id, withoutLoading: false })
                );
                setDeleteK8Service(null);
                navigate(K8ServicesUrl);
              }
            });
        }}
        loading={actionLoading}
      />
    </>
  );
};

export default K8OverviewTab;
