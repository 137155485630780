import { Outlet } from "react-router-dom";
import {
  WorkflowApiKeysUrl,
  WorkflowContractsUrl,
  WorkflowPlanesUrl,
  WorkflowWalletsUrl,
  WorkflowsUrl,
} from "../../utils/urls";
import { Helmet } from "react-helmet";
import WorkflowsTab from "../../components/workflows/workflows/WorkflowsTab";
import WorkflowsContractsTab from "../../components/workflows/contracts/WorkflowsContractsTab";
import WorkflowsWalletsTab from "../../components/workflows/wallets/WorkflowsWalletsTab";
import WorkflowsApiKeysTab from "../../components/workflows/api_keys/WorkflowsApiKeysTab";
import { ReactComponent as KeyIcon } from "./../../assets/icons/key.svg";
import { ReactComponent as WalletIcon } from "./../../assets/icons/wallet.svg";
import { ReactComponent as DocumentIcon } from "./../../assets/icons/document-text.svg";
import { ReactComponent as WorkflowIcon } from "./../../assets/icons/workflow.svg";
import { ReactComponent as ListBulletIcon } from "./../../assets/icons/list-bullet.svg";
import WorkflowPlanesTab from "../../components/workflows/plans/WorkflowPlansTab";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks";
import {
  getWorkflowsAsync,
  selectWorkflowNodeStatus,
} from "../../store/workflows/workflowsSlice";
import { Typography, Tabs, TabOptions } from "djuno-design";
import useServiceType from "../../hooks/useServiceType";
import { ServiceRefreshStatus } from "../../store/publicSlice";

export const WorkflowStudioPageMenus: TabOptions = [
  {
    label: (
      <div className="flex items-center gap-1">
        <WorkflowIcon className="w-3" />
        Workflows
      </div>
    ),
    url: WorkflowsUrl,
    element: <WorkflowsTab />,
  },
  {
    label: (
      <div className="flex items-center gap-1 contracts">
        <DocumentIcon className="w-3" />
        Contracts
      </div>
    ),
    url: WorkflowContractsUrl,
    element: <WorkflowsContractsTab />,
  },
  {
    label: (
      <div className="flex items-center gap-1 wallets">
        <WalletIcon className="w-3" />
        Wallets
      </div>
    ),
    url: WorkflowWalletsUrl,
    element: <WorkflowsWalletsTab />,
  },
  {
    label: (
      <div className="flex items-center gap-1 accesskeys">
        <KeyIcon className="w-3" />
        Access Keys
      </div>
    ),
    url: WorkflowApiKeysUrl,
    element: <WorkflowsApiKeysTab />,
  },
  {
    label: (
      <div className="flex items-center gap-1 plans">
        <ListBulletIcon className="w-3" />
        Plans
      </div>
    ),
    url: WorkflowPlanesUrl,
    element: <WorkflowPlanesTab />,
  },
];

const WorkflowStudioPage = () => {
  const workflowStatus = useAppSelector(selectWorkflowNodeStatus);
  const { serviceType } = useServiceType(
    process.env.REACT_APP_WORKFLOW_SERVICE_TYPE
  );
  const dispatch = useAppDispatch();

  useEffect(() => {
    let interval: NodeJS.Timeout | undefined;

    if (
      workflowStatus !== null &&
      ServiceRefreshStatus.includes(workflowStatus)
    ) {
      interval = setInterval(() => {
        dispatch(getWorkflowsAsync());
      }, 20000);
    } else {
      if (interval) clearInterval(interval);
    }
    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [dispatch, workflowStatus]);

  return (
    <>
      <Helmet>
        <title>
          {process.env.REACT_APP_NAME} | {serviceType?.ServiceName || ""}
        </title>
        <meta name="description" content="" />
      </Helmet>
      <div className="flex items-center justify-between h-20">
        <div className="px-6 items-center justify-between flex flex-1 transition duration-150">
          <Typography.Title level={5} className="!mb-0 ">
            {serviceType?.ServiceName}
          </Typography.Title>
        </div>
        <div className="mr-6 w-96"></div>
      </div>
      <div className="mx-6">
        <Tabs
          options={WorkflowStudioPageMenus.map((o) => ({
            label: o.label,
            url: o.url,
          }))}
        />
        <div className="py-6">
          <Outlet />
        </div>
      </div>
    </>
  );
};
export default WorkflowStudioPage;
