import { useCallback, useEffect, useState } from "react";
import { Button, Flex, Modal, Typography } from "djuno-design";
import { RadioGroupeItem } from "./../../../general/RadioGrouper";
import { useAppDispatch, useAppSelector } from "./../../../../hooks";
import {
  getK8ServiceAsync,
  handleToggleK8ChangeSecurityModal,
  K8UpdatePolicyAsync,
  selectK8ChangeSecurityModal,
  selectK8Service,
  selectK8ServiceActionLoading,
  selectK8ServiceLoading,
} from "./../../../../store/kubernetes/k8ServiceSlice";
import { getK8ServicesAsync } from "../../../../store/kubernetes/k8ServicesSlice";

interface UpdateOption extends RadioGroupeItem<string> {
  description: string;
}

export const updateOptions: UpdateOption[] = [
  {
    label: "Maximum security",
    value: "ALWAYS_UPDATE",
    description:
      "Apply ('patch version') security updates to my Kubernetes service, to guarantee service security and stability. The update may result in your nodes being unavailable for a few minutes, while we perform the rolling update.",
  },
  {
    label: "Minimum unavailability",
    value: "MINIMAL_DOWNTIME",
    description:
      "Apply ('patch version') security updates to my Kubernetes service, to guarantee service security and stability. If we cannot avoid downtime while performing a rolling update on your nodes, we will report this to you. We advise sizing your cluster to ensure that it can be updated at any time.",
  },
  {
    label: "Do not update",
    value: "NEVER_UPDATE",
    description:
      "We do not recommend this choice. OVH reserves the right to update Kubernetes components or your nodes on an exceptional basis, in critical cases that limit the security of our infrastructure.",
  },
];

const K8UpdatePolicyModal = () => {
  const isOpen = useAppSelector(selectK8ChangeSecurityModal);
  const k8Service = useAppSelector(selectK8Service);
  const [selectedOption, setSelectedOption] = useState<UpdateOption>(
    updateOptions.find((option) => option.value === k8Service?.updatePolicy) ??
      updateOptions[0]
  );
  const k8ServiceLoading = useAppSelector(selectK8ServiceLoading);
  const k8ServiceActionLoading = useAppSelector(selectK8ServiceActionLoading);
  const dispatch = useAppDispatch();

  const handleOnClose = useCallback(() => {
    dispatch(handleToggleK8ChangeSecurityModal(false));
  }, [dispatch]);

  useEffect(() => {
    return () => {
      handleOnClose();
    };
  }, [handleOnClose]);

  useEffect(() => {
    k8Service && dispatch(getK8ServiceAsync({ id: k8Service.id }));
  }, [dispatch]);

  const onSubmit = () => {
    if (k8Service && !k8ServiceLoading) {
      dispatch(
        K8UpdatePolicyAsync({
          kubeId: k8Service.id,
          data: { updatePolicy: selectedOption.value },
        })
      ).then((action) => {
        if (action.type === "k8-service/update-policy/fulfilled") {
          handleOnClose();
          dispatch(getK8ServicesAsync({ withoutLoading: false }));
          dispatch(getK8ServiceAsync({ id: k8Service.id }));
        }
      });
    }
  };

  const handleSelection = (item: RadioGroupeItem<string>) => {
    const option = updateOptions.find((opt) => opt.value === item.value);
    if (option) {
      setSelectedOption(option);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleOnClose}
      title="Update Policy"
      contentClassName="max-w-lg"
    >
      <Flex direction="col" className="gap-4 mt-5">
        {updateOptions.map((option) => (
          <div key={option.value} className="flex flex-col gap-2">
            <div className="flex items-center gap-2">
              <input
                type="radio"
                id={option.value}
                name="updateOption"
                value={option.value}
                checked={selectedOption.value === option.value}
                onChange={() => handleSelection(option)}
                className="h-4 w-4"
              />
              <label htmlFor={option.value} className="font-semibold !text-sm">
                {option.label}
              </label>
            </div>
            <Typography.Text className="!text-sm">
              {option.description}
            </Typography.Text>
          </div>
        ))}

        <Flex justify="end" className="mt-4">
          <Button
            uiType="primary"
            onClick={onSubmit}
            loading={k8ServiceActionLoading}
          >
            Confirm
          </Button>
        </Flex>
      </Flex>
    </Modal>
  );
};

export default K8UpdatePolicyModal;
