import { useEffect } from "react";
import { Outlet } from "react-router-dom";
import {
  S3AccessKeysUrl,
  S3BucketsUrl,
  S3EventsUrl,
  S3LogsUrl,
  S3OverviewUrl,
  S3PlansUrl,
  S3TiersUrl,
} from "../../utils/urls";
import S3bucketsTab from "../../components/s3/buckets/S3bucketsTab";
import S3AccessKeysTab from "../../components/s3/access-keys/S3AccessKeysTab";
import S3EventsTab from "../../components/s3/events/S3EventsTab";
import S3LogsTab from "../../components/s3/logs/S3LogsTab";
// import S3SettingsTab from "../../components/s3/settings/S3SettingsTab";
import S3TiersTab from "../../components/s3/tiers/S3TiersTab";

import { Helmet } from "react-helmet";
import S3PlanesTab from "../../components/s3/plans/S3PlansTab";
import { selectS3NodeStatus } from "../../store/s3/s3PublicSlice";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { getBucketsAsync } from "../../store/s3/buckets/bucketsSlice";
import { TabOptions, Tabs, Typography } from "djuno-design";

import { ReactComponent as ComputerIcon } from "./../../assets/icons/computer-desktop.svg";
import { ReactComponent as BucketIcon } from "./../../assets/icons/bucket.svg";
import { ReactComponent as KeyIcon } from "./../../assets/icons/key.svg";
import { ReactComponent as BellIcon } from "./../../assets/icons/bell.svg";
import { ReactComponent as BeatIcon } from "./../../assets/icons/beat.svg";
import { ReactComponent as TiersIcon } from "./../../assets/icons/square-3stack.svg";
import { ReactComponent as ListBulletIcon } from "./../../assets/icons/list-bullet.svg";
import useServiceType from "../../hooks/useServiceType";
import S3NewOverviewTab from "../../components/s3/overview/S3NewOverviewTab";
import { ServiceRefreshStatus } from "../../store/publicSlice";

export const S3PageMenus: TabOptions = [
  {
    label: (
      <div className="flex items-center gap-1 overview">
        <ComputerIcon className="w-3" />
        Overview
      </div>
    ),
    url: S3OverviewUrl,
    element: <S3NewOverviewTab />,

    testId: "1th-tab",
  },
  {
    label: (
      <div className="flex items-center gap-1">
        <BucketIcon className="w-3" />
        Buckets
      </div>
    ),
    url: S3BucketsUrl,
    element: <S3bucketsTab />,
    testId: "2th-tab",
  },
  {
    label: (
      <div className="flex items-center gap-1 access-keys">
        <KeyIcon className="w-3" />
        Access Keys
      </div>
    ),
    url: S3AccessKeysUrl,
    element: <S3AccessKeysTab />,
    testId: "3th-tab",
  },
  {
    label: (
      <div className="flex items-center gap-1 events">
        <BellIcon className="w-3" />
        Events
      </div>
    ),
    url: S3EventsUrl,
    element: <S3EventsTab />,
    testId: "4th-tab",
  },
  {
    label: (
      <div className="flex items-center gap-1 logs">
        <BeatIcon className="w-3" />
        Logs
      </div>
    ),
    url: S3LogsUrl,
    element: <S3LogsTab />,
    testId: "5th-tab",
  },
  {
    label: (
      <div className="flex items-center gap-1 tier">
        <TiersIcon className="w-3" />
        Tier
      </div>
    ),
    url: S3TiersUrl,

    element: <S3TiersTab />,
    testId: "6th-tab",
  },
  {
    label: (
      <div className="flex items-center gap-1 plans">
        <ListBulletIcon className="w-3" />
        Plans
      </div>
    ),
    url: S3PlansUrl,
    element: <S3PlanesTab />,
    testId: "7th-tab",
  },
];

const S3Page = () => {
  const s3Status = useAppSelector(selectS3NodeStatus);
  const dispatch = useAppDispatch();

  const { serviceType } = useServiceType(
    process.env.REACT_APP_SIMPLE_STORAGE_SERVICE_TYPE
  );

  //refresh the list
  useEffect(() => {
    let interval: NodeJS.Timeout | undefined;
    let intervalTime =
      Number(process.env.REACT_APP_NODES_REFRESH_INTERVAL) || 20000;

    if (s3Status !== null && ServiceRefreshStatus.includes(s3Status)) {
      interval = setInterval(() => {
        dispatch(getBucketsAsync());
      }, intervalTime);
    } else {
      if (interval) clearInterval(interval);
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [dispatch, s3Status]);

  return (
    <>
      <Helmet>
        <title>
          {process.env.REACT_APP_NAME} | {serviceType?.ServiceName || ""}
        </title>
        <meta
          name="description"
          content="Simple-storage technology refers to a straightforward method of storing data or information in a manner that is easy to implement and access"
        />
      </Helmet>
      <div className="flex items-center justify-between h-20">
        <div className="px-6 items-center justify-between flex flex-1 transition duration-150">
          <Typography.Title level={5} className="!mb-0">
            {serviceType?.ServiceName}
          </Typography.Title>
        </div>
        <div className="mr-6 w-96"></div>
      </div>
      <div className="mx-6">
        <Tabs
          options={S3PageMenus.map((o) => ({
            label: o.label,
            url: o.url,
            testId: o.testId,
          }))}
        />
        <div className="py-6">
          <Outlet />
        </div>
      </div>
    </>
  );
};
export default S3Page;
