import { Control, Controller, UseFormReturn } from "react-hook-form";
import {
  Flex,
  Input,
  Typography,
  AnimatedFormError,
  Loading,
} from "djuno-design";
import { PropsWithChildren } from "react";
import { RadioGroup } from "@headlessui/react";
import {
  selectK8Plans,
  selectK8PlansLoading,
} from "../../../store/kubernetes/k8CreateSlice";
import { useAppSelector } from "../../../hooks";
import { WebAppInstanceCard } from "../../web-apps/single-page/WebAppPlanTab";
import { K8CreateFormData } from "../../../types/kubernetes";

const ConfigsStep: React.FC<{
  form: UseFormReturn<K8CreateFormData>;
}> = ({ form }) => {
  const {
    formState: { errors },
    control,
  } = form;

  return (
    <div className="flex my-5 mb-20 w-full">
      <div className="flex flex-col space-y-12 md:space-y-16 w-full">
        {/* App Name */}
        <div className="grid grid-cols-3 gap-x-10">
          <Flex direction="col" className="col-span-3 md:col-span-1 text-sm">
            <Typography.Text className="!font-medium !flex !items-center !gap-1.5">
              Name
            </Typography.Text>
          </Flex>
          <div className="col-span-3 md:col-span-2">
            <Controller
              control={control}
              name="AppName"
              render={({ field: { value, onChange } }) => (
                <Input
                  onChange={onChange}
                  value={value}
                  placeholder="example-service-name"
                  error={errors.AppName?.message}
                />
              )}
            />
          </div>
        </div>

        {/* Instance Type */}
        <div className="grid grid-cols-3 gap-x-10">
          <Flex direction="col" className="col-span-3 md:col-span-1 text-sm">
            <Typography.Text className="!font-medium !flex !items-center !gap-1.5">
              Instance Type
            </Typography.Text>
          </Flex>
          <div className="col-span-3 md:col-span-2">
            <K8PlansGroup control={control}>
              <AnimatedFormError error={errors.PlanId?.message} />
            </K8PlansGroup>
          </div>
        </div>
      </div>
    </div>
  );
};

export const K8PlansGroup: React.FC<
  PropsWithChildren<{
    control: Control<any>;
  }>
> = ({ control, children }) => {
  const k8Plans = useAppSelector(selectK8Plans);
  const k8PlansLoading = useAppSelector(selectK8PlansLoading);

  // const {
  //   loading: limitCalcLoading,
  //   planPriceLimit,
  //   planPriceLimitChecker,
  //   amountOfCpuLimit,
  //   amountOfCpuLimitChecker,
  //   amountOfMemoryLimit,
  //   amountOfMemoryLimitChecker,
  // } = useLimitations({
  //   cases: ["PlanPrice", "Cpu", "Memory"],
  //   existingServices: existingWebApps,
  // });

  if (k8PlansLoading)
    return (
      <Flex items="center" justify="center" className="min-h-[200px]">
        <Loading borderSize={2} />
      </Flex>
    );
  return (
    <Controller
      name="PlanId"
      control={control}
      render={({ field: { value, onChange } }) => (
        <RadioGroup value={value || null} onChange={onChange}>
          <div className="grid gap-4 grid-cols-1 md:grid-cols-2 mb-5">
            {k8Plans.map((instance, i) => {
              const price = instance.Price || 0;
              const { CPU, RAM, DISK } = JSON.parse(
                instance.PlanDescription || "{}"
              );
              // const isExceedPrice = planPriceLimitChecker(price);
              // const isExceedCpu = amountOfCpuLimitChecker(CPU);
              // const isExceedMemory = amountOfMemoryLimitChecker(RAM);
              return (
                <RadioGroup.Option
                  key={i}
                  value={instance.Id}
                  // disabled={
                  //   isExceedPrice || isExceedCpu || isExceedMemory
                  // }
                >
                  {({ checked, disabled }) => (
                    <WebAppInstanceCard
                      title={instance.Name}
                      description={{ CPU, RAM }}
                      price={price}
                      selected={checked}
                      disabled={disabled}
                      // disabledReason={
                      //   isExceedPrice
                      //     ? planPriceLimit?.LimitationMessage
                      //     : isExceedCpu
                      //     ? amountOfCpuLimit?.LimitationMessage
                      //     : isExceedMemory
                      //     ? amountOfMemoryLimit?.LimitationMessage
                      //     : ""
                      // }
                    />
                  )}
                </RadioGroup.Option>
              );
            })}
          </div>
          {children}
        </RadioGroup>
      )}
    />
  );
};

export default ConfigsStep;
