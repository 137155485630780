import { Control, Controller, UseFormReturn } from "react-hook-form";
import { OvhRegion } from "../../../types/instance";
import React, { useEffect, useMemo } from "react";
import { RadioGroup } from "@headlessui/react";
import { regionsData } from "../../instances/create/utils";
import {
  AnimatedFormError,
  cn,
  Flex,
  Loading,
  Select,
  SelectOption,
  Tag,
  Typography,
} from "djuno-design";
import { useAppSelector } from "../../../hooks";
import {
  selectInstancesRegions,
  selectInstancesRegionsLoading,
} from "../../../store/instances/instancesSlice";
import {
  K8CreateFormData,
  K8SecurityPolicyOption,
  K8VersionOption,
} from "../../../types/kubernetes";

const k8Versions: Array<K8VersionOption> = [
  { title: "Version 1.32", value: "1.32", tag: "Recommended" },
  { title: "Version 1.31", value: "1.31" },
  { title: "Version 1.30", value: "1.30" },
  { title: "Version 1.29", value: "1.29" },
  { title: "Version 1.28", value: "1.28" },
];

const k8SecurityPolicies: Array<K8SecurityPolicyOption> = [
  {
    title: "Maximum security",
    value: "ALWAYS_UPDATE",
    description:
      "Apply ('patch version') security updates to my Kubernetes service, to guarantee service security and stability. The update may result in your nodes being unavailable for a few minutes, while we perform the rolling update.",
    tag: "Recommended",
  },
  {
    title: "Minimum unavailability",
    description:
      "Apply ('patch version') security updates to my Kubernetes service, to guarantee service security and stability. If we cannot avoid downtime while performing a rolling update on your nodes, we will report this to you. We advise sizing your cluster to ensure that it can be updated at any time.",
    value: "MINIMAL_DOWNTIME",
  },
  {
    title: "Do not update",
    description:
      "We do not recommend this choice. OVH reserves the right to update Kubernetes components or your nodes on an exceptional basis, in critical cases that limit the security of our infrastructure.",
    value: "NEVER_UPDATE",
  },
];

const DeployingSourceStep: React.FC<{
  form: UseFormReturn<K8CreateFormData>;
}> = ({ form }) => {
  const {
    control,
    formState: { errors },
  } = form;
  const regions = useAppSelector(selectInstancesRegions);
  const regionsLoading = useAppSelector(selectInstancesRegionsLoading);

  const k8VersionsOptions: SelectOption[] = useMemo(() => {
    return k8Versions.map((v) => ({
      label: (
        <Flex items="center" justify="between" className="w-full">
          <Typography.Text size="sm">{v.title}</Typography.Text>
          {v.tag && <Tag color="success">{v.tag}</Tag>}
        </Flex>
      ),
      value: v.value,
    }));
  }, []);

  return (
    <div className="w-full min-h-[calc(100%-6rem)]">
      <div className="grid grid-cols-3 gap-x-10">
        <Flex direction="col" className="col-span-3 md:col-span-1 text-sm">
          <Typography.Text className="!font-medium !flex !items-center !gap-1.5">
            Region
          </Typography.Text>
        </Flex>
        <div className="col-span-3 md:col-span-2">
          <K8RegionInput
            regions={regions}
            loading={regionsLoading}
            control={control}
            errorMessage={errors.Region?.message}
          />
        </div>
      </div>
      <div className="grid grid-cols-3 gap-x-10 mt-8">
        <Flex direction="col" className="col-span-3 md:col-span-1 text-sm">
          <Typography.Text className="!font-medium !flex !items-center !gap-1.5">
            Version
          </Typography.Text>
        </Flex>
        <div className="col-span-3 md:col-span-2">
          <K8VersionInput
            versions={k8VersionsOptions}
            control={control}
            errorMessage={errors.Version?.message}
          />
        </div>
      </div>
      <div className="grid grid-cols-3 gap-x-10 mt-8">
        <Flex direction="col" className="col-span-3 md:col-span-1 text-sm">
          <Typography.Text className="!font-medium !flex !items-center !gap-1.5">
            Security policy
          </Typography.Text>
        </Flex>
        <div className="col-span-3 md:col-span-2">
          <K8SecurityPolicyInput
            options={k8SecurityPolicies}
            control={control}
            errorMessage={errors.Version?.message}
          />
        </div>
      </div>
    </div>
  );
};

export const K8RegionInput: React.FC<{
  regions: OvhRegion[];
  control: Control<any>;
  errorMessage: string | undefined;
  loading?: boolean;
  disabled?: boolean;
}> = ({ regions, control, errorMessage, loading, disabled }) => {
  const activeRegions = useMemo(() => {
    return regions
      .filter((r) => r.type === "region")
      .filter((r) => r.services.find((s) => s.name === "instance"))
      .filter((r) => r.status === "UP");
  }, [regions]);

  return (
    <Controller
      name="Region"
      control={control}
      render={({ field: { value, onChange } }) => {
        return (
          <>
            {!loading && (
              <RadioGroup
                value={value || null}
                disabled={disabled}
                onChange={onChange}
              >
                <div className="flex flex-col w-full mt-3">
                  <div className="grid gap-4 grid-cols-1 md:grid-cols-2 mb-5">
                    {activeRegions.map((region, i) => (
                      <RadioGroup.Option key={i} value={region.name}>
                        {({ checked, disabled }) => {
                          const regionData = regionsData.find(
                            (rd) =>
                              rd.datacenterLocation ===
                              region.datacenterLocation
                          );
                          return (
                            <div
                              className={cn(
                                "col-span-1 border-2 text-md rounded-xl dark:bg-dark-3 dark:border-gray-400/10 bg-white p-4 shadow hover:shadow-lg transition-all duration-300 cursor-pointer h-full",
                                {
                                  "border-primary-400 dark:border-primary-400":
                                    checked,
                                  "!cursor-not-allowed": disabled,
                                }
                              )}
                            >
                              <div className="flex items-center justify-between mb-2">
                                <Typography.Text className="!text-sm">
                                  {regionData ? regionData.title : region.name}
                                </Typography.Text>
                                {regionData && regionData.dataImage && (
                                  <span
                                    style={{
                                      backgroundImage: `url(${regionData.dataImage})`,
                                      backgroundSize: "contain",
                                      backgroundPosition: "50%",
                                    }}
                                    className="w-4 h-4 inline-block align-middle bg-no-repeat"
                                  />
                                )}
                              </div>
                              <div className="flex flex-col border-t border-slate-200 dark:border-gray-400/40 mt-2 pt-2 gap-0.5">
                                <Typography.Text
                                  uiType="secondary"
                                  className="!text-xs"
                                >
                                  {region.name.toUpperCase()}
                                </Typography.Text>
                              </div>
                            </div>
                          );
                        }}
                      </RadioGroup.Option>
                    ))}
                  </div>

                  <AnimatedFormError error={errorMessage} />
                </div>
              </RadioGroup>
            )}

            {loading && (
              <Flex items="center" justify="center" className="!min-h-[120px]">
                <Loading borderSize={2} />
              </Flex>
            )}
          </>
        );
      }}
    />
  );
};

export const K8VersionInput: React.FC<{
  versions: SelectOption[];
  control: Control<any>;
  errorMessage: string | undefined;
  loading?: boolean;
  disabled?: boolean;
}> = ({ versions, control, errorMessage, loading, disabled }) => {
  return (
    <Controller
      control={control}
      name="Version"
      render={({ field: { value, onChange } }) => (
        <Select
          options={versions}
          value={value}
          onChange={onChange}
          error={errorMessage}
          loading={loading}
          disabled={disabled}
        />
      )}
    />
  );
};

export const K8SecurityPolicyInput: React.FC<{
  options: K8SecurityPolicyOption[];
  control: Control<any>;
  errorMessage: string | undefined;
  loading?: boolean;
  disabled?: boolean;
}> = ({ options, control, errorMessage, loading, disabled }) => {
  return (
    <Controller
      name="SecurityPolicy"
      control={control}
      render={({ field: { value, onChange } }) => {
        return (
          <>
            {!loading && (
              <RadioGroup
                value={value || null}
                disabled={disabled}
                onChange={onChange}
              >
                <div className="flex flex-col w-full mt-3">
                  <div className="grid gap-4 grid-cols-1 md:grid-cols-2 mb-5">
                    {options.map((option, i) => (
                      <RadioGroup.Option key={i} value={option.value}>
                        {({ checked, disabled }) => {
                          return (
                            <div
                              className={cn(
                                "col-span-1 border-2 text-md rounded-xl dark:bg-dark-3 dark:border-gray-400/10 bg-white p-4 shadow hover:shadow-lg transition-all duration-300 cursor-pointer h-full",
                                {
                                  "border-primary-400 dark:border-primary-400":
                                    checked,
                                  "!cursor-not-allowed": disabled,
                                }
                              )}
                            >
                              <div className="flex items-center justify-between mb-2">
                                <Typography.Text className="!text-sm">
                                  {option.title}
                                </Typography.Text>
                                {option.tag && (
                                  <Tag color="success">{option.tag}</Tag>
                                )}
                              </div>
                              <div className="flex flex-col border-t border-slate-200 dark:border-gray-400/40 mt-2 pt-2 gap-0.5">
                                <Typography.Text
                                  uiType="secondary"
                                  className="!text-xs"
                                >
                                  {option.description}
                                </Typography.Text>
                              </div>
                            </div>
                          );
                        }}
                      </RadioGroup.Option>
                    ))}
                  </div>

                  <AnimatedFormError error={errorMessage} />
                </div>
              </RadioGroup>
            )}

            {loading && (
              <Flex items="center" justify="center" className="!min-h-[120px]">
                <Loading borderSize={2} />
              </Flex>
            )}
          </>
        );
      }}
    />
  );
};

export default DeployingSourceStep;
