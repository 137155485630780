import { useNavigate } from "react-router-dom";
import { EventDestinationOption } from "../../../types/s3-events";
import { S3EventAddFormUrl } from "../../../utils/urls";
import { S3NotActiveTab } from "../../layouts/NotData";
import { useAppSelector } from "../../../hooks";
import { selectS3NodeStatus } from "../../../store/s3/s3PublicSlice";
import { cn, Typography } from "djuno-design";

export const s3EventDestinations: {
  type: string;
  options: Array<EventDestinationOption>;
}[] = [
  {
    type: "Queue",
    options: [
      {
        key: "kafka",
        name: "Kafka",
        iconUrl: "https://cdn.djuno.cloud/events/kafka-logo.svg",
      },
      {
        key: "amqp",
        name: "AMQP",
        iconUrl: "https://cdn.djuno.cloud/events/amqp-logo.svg",
      },
      {
        key: "mqtt",
        name: "MQTT",
        iconUrl: "https://cdn.djuno.cloud/events/mqtt-logo.svg",
      },
      {
        key: "redis",
        name: "Redis",
        iconUrl: "https://cdn.djuno.cloud/events/redis-logo.svg",
      },
      {
        key: "nats",
        name: "NATS",
        iconUrl: "https://cdn.djuno.cloud/events/nats-logo.svg",
      },
      {
        key: "nsq",
        name: "NSQ",
        iconUrl: "https://cdn.djuno.cloud/events/nsq-logo.svg",
      },
    ],
  },
  {
    type: "Database",
    options: [
      {
        key: "postgres",
        name: "PostgresSQL",
        iconUrl: "https://cdn.djuno.cloud/events/postgres-logo.svg",
      },
      {
        key: "mysql",
        name: "MySql",
        iconUrl: "https://cdn.djuno.cloud/events/mysql-logo.svg",
      },
      {
        key: "elasticsearch",
        name: "Elastic Search",
        iconUrl: "https://cdn.djuno.cloud/events/elasticsearch-logo.svg",
      },
    ],
  },
  {
    type: "Functions",
    options: [
      {
        key: "webhook",
        name: "Webhook",
        iconUrl: "https://cdn.djuno.cloud/events/webhooks-logo.svg",
      },
    ],
  },
];

const S3SelectDestination = () => {
  const s3Status = useAppSelector(selectS3NodeStatus);
  const navigate = useNavigate();

  if (!s3Status) {
    return <S3NotActiveTab />;
  }
  return (
    <div className="pb-20">
      <div className="flex flex-col gap-7 p-6 rounded-lg bg-white dark:bg-dark-3 dark:border-dark-2">
        {s3EventDestinations.map((destination, i) => (
          <div key={i} className="flex flex-col">
            <Typography.Title level={6}>{destination.type}</Typography.Title>
            <div className="grid grid-cols-2 md:grid-cols-3 gap-3">
              {destination.options.map((option, j) => (
                <DestinationCard
                  key={j}
                  name={option.name}
                  iconUrl={option.iconUrl}
                  onClick={() => navigate(S3EventAddFormUrl(option.key))}
                />
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export const DestinationCard = ({
  name,
  iconUrl,
  selected,
  onClick,
}: {
  name: string;
  iconUrl: string;
  selected?: boolean;
  onClick?: (chainName: string) => void;
}) => {
  return (
    <div
      className={cn(
        "border-2 text-md rounded-xl dark:bg-dark-3 dark:border-gray-400/10 bg-white py-2 px-4 shadow hover:shadow-lg transition-all duration-300",
        {
          "border-primary-400 dark:border-primary-400": selected,
          "cursor-pointer": onClick,
        }
      )}
      onClick={() => (onClick ? onClick(name) : {})}
    >
      <div className="flex items-center justify-between">
        <div className="w-[64px] h-[64px] flex justify-start items-center p-2">
          <img className="h-full" alt={name} src={iconUrl} />
        </div>
        <Typography.Text className="!text-sm !font-semibold">
          {name}
        </Typography.Text>
      </div>
    </div>
  );
};

export default S3SelectDestination;
