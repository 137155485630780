import { useCallback, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "./../../../../hooks";
import { Alert, Button, Flex, Modal, Select, Typography } from "djuno-design";
import {
  getK8ServiceAsync,
  handleToggleK8ResetClusterModal,
  K8ResetClusterAsync,
  selectK8ResetClusterModal,
  selectK8Service,
  selectK8ServiceActionLoading,
  selectK8ServiceLoading,
} from "./../../../../store/kubernetes/k8ServiceSlice";
import RadioGrouper, { RadioGroupeItem } from "./../../../general/RadioGrouper";
import { getK8ServicesAsync } from "./../../../../store/kubernetes/k8ServicesSlice";

export const radioGroupItems: RadioGroupeItem<string>[] = [
  { label: "Delete nodes", value: "1" },
  { label: "Keep and reinstall nodes", value: "2" },
];

const K8ResetClusterModal = () => {
  const [selectedOption, setSelectedOption] = useState<RadioGroupeItem<string>>(
    radioGroupItems[0]
  );
  const [selectedNetwork, setSelectedNetwork] = useState<string>("none");
  const [selectedVersion, setSelectedVersion] = useState<string>("");

  const isOpen = useAppSelector(selectK8ResetClusterModal);
  const k8Service = useAppSelector(selectK8Service);
  const k8ServiceLoading = useAppSelector(selectK8ServiceLoading);
  const k8ServiceActionLoading = useAppSelector(selectK8ServiceActionLoading);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (k8Service?.version) {
      const extractedVersion = k8Service.version
        .split(".")
        .slice(0, 2)
        .join(".");
      setSelectedVersion(extractedVersion);
      setSelectedOption(radioGroupItems[0]);
    }
  }, [k8Service]);

  const handleChangeVersion = (newValue: string | undefined) => {
    setSelectedVersion(newValue || "");
    // console.log("newValue", newValue);
  };

  const handleNetworkChange = (newValue: string | undefined) => {
    setSelectedNetwork(newValue || "");
    // console.log("newValue", newValue);
  };

  const handleOnClose = useCallback(() => {
    setSelectedVersion("");
    dispatch(handleToggleK8ResetClusterModal(false));
  }, [dispatch]);

  const onSubmit = () => {
    if (k8Service && !k8ServiceLoading) {
      dispatch(
        K8ResetClusterAsync({
          kubeId: k8Service.id,
          data: {
            privateNetworkConfiguration: {
              privateNetworkRoutingAsDefault: false,
              defaultVrackGateway: "",
            },
            version: selectedVersion,
            workerNodesPolicy:
              selectedOption.value === "1" ? "delete" : "reinstall",
          },
        })
      ).then((action) => {
        if (action.type === "k8-service/reset-cluster/fulfilled") {
          handleOnClose();
          dispatch(getK8ServicesAsync({ withoutLoading: false }));
          dispatch(getK8ServiceAsync({ id: k8Service.id }));
        }
      });
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleOnClose}
      contentClassName="max-w-lg"
      title="Reset cluster configuration"
    >
      <div className="w-full mt-5">
        <Alert
          showIcon
          uiType="warning"
          description="Your Kubernetes cluster will be reinstalled in its original state. This will delete all of your services, their configuration, etc. This action cannot be undone."
        />

        <Typography.Text className="flex items-center gap-1 !text-sm whitespace-nowrap mt-5 mb-2">
          Computing nodes
        </Typography.Text>
        <RadioGrouper
          items={radioGroupItems}
          selected={selectedOption}
          setSelected={setSelectedOption}
        />

        <Select
          label="Minor version of Kubernetes"
          value={selectedVersion}
          onChange={handleChangeVersion}
          options={[
            { label: "1.32", value: "1.32" },
            { label: "1.28", value: "1.28" },
            { label: "1.29", value: "1.29" },
            { label: "1.30", value: "1.30" },
            { label: "1.31", value: "1.31" },
          ]}
          labelClassName="mt-5 !whitespace-normal"
        />

        <Select
          label="Private network attached"
          value={selectedNetwork}
          onChange={handleNetworkChange}
          options={[{ label: "None (public IPs)", value: "none" }]}
          labelClassName="mt-5 !whitespace-normal"
        />

        <Flex items="center" justify="end" className="gap-2 mt-5">
          <Button onClick={handleOnClose}>Cancel</Button>
          <Button
            uiType="primary"
            onClick={onSubmit}
            loading={k8ServiceActionLoading}
          >
            Confirm
          </Button>
        </Flex>
      </div>
    </Modal>
  );
};

export default K8ResetClusterModal;
