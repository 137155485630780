import { useNavigate, useParams } from "react-router-dom";
import {
  EventDestinationNames,
  eventDestinationNames,
} from "../../../types/s3-events";
import MySqlForm from "./forms/MySqlForm";
import WebhookForm from "./forms/WebhoohForm";
import { ReactComponent as BellAlertIcon } from "./../../../assets/icons/bell.svg";
import { S3EventAddUrl } from "../../../utils/urls";
import KafkaForm from "./forms/KafkaForm";
import AmqpForm from "./forms/AmqpForm";
import MqttForm from "./forms/MqttForm";
import RedisForm from "./forms/RedisForm";
import NatsForm from "./forms/NatsForm";
import NsqForm from "./forms/NsqForm";
import PostgresForm from "./forms/PostgresForm";
import ElasticSearchForm from "./forms/ElasticSearchForm";
import { S3NotActiveTab } from "../../layouts/NotData";
import { selectS3NodeStatus } from "../../../store/s3/s3PublicSlice";
import { useAppSelector } from "../../../hooks";
import { Button, Typography } from "djuno-design";

const S3EventEditorPage = () => {
  const s3Status = useAppSelector(selectS3NodeStatus);
  const navigate = useNavigate();
  const { destination } = useParams<{ destination: EventDestinationNames }>();

  if (s3Status !== 2) {
    return <S3NotActiveTab />;
  }
  return (
    <div className="pb-20 w-full">
      <div className="flex flex-col mx-auto gap-7 p-6 mt-5 rounded-lg border bg-white dark:bg-dark-3 dark:border-dark-2 max-w-lg">
        {destination === "kafka" && <KafkaForm />}
        {destination === "amqp" && <AmqpForm />}
        {destination === "mqtt" && <MqttForm />}
        {destination === "redis" && <RedisForm />}
        {destination === "nats" && <NatsForm />}
        {destination === "nsq" && <NsqForm />}
        {destination === "postgres" && <PostgresForm />}
        {destination === "mysql" && <MySqlForm />}
        {destination === "elasticsearch" && <ElasticSearchForm />}
        {destination === "webhook" && <WebhookForm />}
        {(typeof destination === "undefined" ||
          !eventDestinationNames.includes(destination)) && (
          <div className="flex justify-start z-10 relative p-8 gap-8">
            <div className="flex items-center justify-center dark:bg-dark-2 w-[80px] h-[80px] border dark:border-dark-2 rounded-xl">
              <BellAlertIcon className="w-1/2 text-slate-600 dark:text-slate-200" />
            </div>
            <div className="flex flex-col gap-2 flex-1">
              <Typography.Title level={4}>
                Invalid destination type
              </Typography.Title>
              <Typography.Text
                uiType="secondary"
                className="!font-normal !text-sm"
              >
                Invalid destination chosen. Please select a valid event
                destination from the available options.
              </Typography.Text>
              <Button
                onClick={() => navigate(S3EventAddUrl)}
                uiType="primary"
                className="mt-5"
              >
                See valid options
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default S3EventEditorPage;
