import { Controller, useFieldArray, useForm } from "react-hook-form";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Alert,
  AnimatedFormError,
  Button,
  Input,
  Modal,
  Select,
  Typography,
} from "djuno-design";
import { useCallback, useEffect } from "react";
import { ReactComponent as PlusIcon } from "./../../../assets/icons/plus.svg";
import { ReactComponent as ArchiveIcon } from "./../../../assets/icons/archive-box.svg";
import {
  getDBSUsersAsync,
  getDSSACLPermissionAsync,
  getPermissionsLabel,
  handleToggleUserAclModal,
  selectDASUserAclEditorModal,
  selectDatabaseService,
  selectDatabaseServiceLoading,
  selectDatabaseServiceUserLoading,
  selectDatabaseServiceUsers,
  selectDatabaseServiceUsersLoading,
  selectDSSAclPermissions,
  updateDBSUserAsync,
} from "../../../store/database/serviceSlice";
import { DataAnalysisUserAclCreateSchema } from "../../../utils/validations";

export const UserAclCreateModal: React.FC = () => {
  const isOpen = useAppSelector(selectDASUserAclEditorModal);

  const service = useAppSelector(selectDatabaseService);
  const serviceLoading = useAppSelector(selectDatabaseServiceLoading);

  const users = useAppSelector(selectDatabaseServiceUsers);
  const usersLoading = useAppSelector(selectDatabaseServiceUsersLoading);

  const userActionLoading = useAppSelector(selectDatabaseServiceUserLoading);

  const permissions = useAppSelector(selectDSSAclPermissions);

  const dispatch = useAppDispatch();

  useEffect(() => {
    service &&
      dispatch(
        getDSSACLPermissionAsync({
          engine: service?.engine,
          clusterId: service?.id,
        })
      );
  }, [dispatch, service]);

  const methods = useForm({
    resolver: yupResolver(DataAnalysisUserAclCreateSchema),
    defaultValues: {
      acls: [{ pattern: "", permission: "" }],
      userId: "",
    },
  });

  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
    control,
    trigger,
  } = methods;

  const { fields, append, remove } = useFieldArray({
    control,
    name: "acls",
  });

  const handleSubmitForm = (data: any) => {
    if (!serviceLoading && !userActionLoading) {
      const user = users.find((u) => u.id === data.userId);
      if (user && service) {
        let newAcls = [...data.acls];
        if (user.acls) {
          newAcls = [...newAcls, ...user.acls];
        }
        dispatch(
          updateDBSUserAsync({
            id: service.id,
            engine: service.engine,
            userId: data.userId,
            data: { acls: newAcls },
          })
        ).then((action) => {
          if (action.type === "service/user/update/fulfilled") {
            dispatch(
              getDBSUsersAsync({ id: service.id, engine: service.engine })
            );
            handleOnClose();
          }
        });
      }
    }
  };

  const handleOnClose = useCallback(() => {
    reset({ acls: [{ pattern: "", permission: "" }], userId: "" });
    dispatch(handleToggleUserAclModal(false));
  }, [dispatch, reset]);

  useEffect(() => {
    return () => {
      handleOnClose();
    };
  }, [handleOnClose]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleOnClose}
      contentClassName="!max-w-xl overflow-hidden"
      title={"Add an ACL"}
    >
      <form className="mt-5" onSubmit={handleSubmit(handleSubmitForm)}>
        <div className="flex flex-col gap-3">
          <Alert uiType="info" showIcon>
            <Typography.Text size="sm">
              The index models use the standard matching system, also called the
              standard glob pattern ('*' works for any number of characters and
              '?' for any character). For example the 'logs_200?' template will
              work for the 'logs_200904' index match, but not 'logs_201904'.
            </Typography.Text>
          </Alert>

          <Controller
            control={control}
            name="userId"
            render={({ field: { value, onChange } }) => (
              <Select
                label="User"
                value={value}
                onChange={onChange}
                options={
                  users.map((u) => ({
                    label: u.username,
                    value: u.id,
                  })) || []
                }
                error={errors.userId?.message}
              />
            )}
          />

          <div className="flex flex-col w-full gap-3">
            {fields.map((_, index) => (
              <div
                key={index}
                className="grid gap-3 grid-cols-12 border rounded-lg"
              >
                <div className="col-span-4 py-3 pl-3">
                  <Controller
                    control={control}
                    name={`acls.${index}.permission` as const}
                    render={({ field: { value, onChange, name } }) => {
                      // console.log(`acls.${index}.permission`, value);
                      return (
                        <Select
                          id={`acls.${index}.permission`}
                          label="Permission"
                          value={value}
                          onChange={onChange}
                          options={
                            permissions?.names?.map((name) => ({
                              label: getPermissionsLabel(name),
                              value: name,
                            })) || []
                          }
                          error={
                            errors.acls &&
                            errors.acls[index] &&
                            errors.acls[index]?.permission &&
                            errors.acls[index]?.permission?.message
                          }
                        />
                      );
                    }}
                  />
                </div>
                <div className="col-span-6 py-3">
                  <div className="flex gap-2">
                    <div className="flex-1">
                      <Input
                        label="Pattern"
                        {...register(`acls.${index}.pattern` as const)}
                        error={
                          errors.acls &&
                          errors.acls[index] &&
                          errors.acls[index]?.pattern &&
                          errors.acls[index]?.pattern?.message
                        }
                        placeholder="e.g. logs_200*"
                        className="flex-1"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-span-2 flex items-center justify-center border-l">
                  <Button
                    uiType="icon"
                    className="!px-2 group"
                    disabled={false}
                    onClick={(e) => {
                      e.preventDefault();
                      trigger("acls");
                      remove(index);
                    }}
                  >
                    <ArchiveIcon className="w-5 text-slate-700 dark:text-slate-300 group-hover:text-red-500 group-hover:dark:text-red-400" />
                  </Button>
                </div>
              </div>
            ))}
            <AnimatedFormError error={errors.acls?.root?.message} />
            <AnimatedFormError error={errors.acls?.message} />
            <div className="flex justify-start gap-2">
              <Button
                uiType="light"
                onClick={(e) => {
                  e.preventDefault();
                  append({
                    pattern: "",
                    permission: "",
                  });
                }}
              >
                <PlusIcon className="w-4" />
              </Button>
            </div>
          </div>

          <div className="flex justify-end gap-2">
            <Button uiType="simple" onClick={handleOnClose}>
              Cancel
            </Button>
            <Button
              uiType="primary"
              type="submit"
              loading={usersLoading || userActionLoading}
            >
              Add an ACL
            </Button>
          </div>
        </div>
      </form>
    </Modal>
  );
};
