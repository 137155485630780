import { useEffect } from "react";
import { AiChatProvider } from "./AiChatProvider";
import { getLimitationsAsync } from "../store/auth/authSlice";
import { useAppDispatch } from "../hooks";

const PanelContentProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getLimitationsAsync());
  }, [dispatch]);

  return <AiChatProvider>{children}</AiChatProvider>;
};

export default PanelContentProvider;
