import { useForm } from "react-hook-form";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Input, Modal, Select } from "djuno-design";
import { useCallback, useEffect, useState } from "react";
import {
  createDSSACLAsync,
  getDSSACLsAsync,
  getDSSACLPermissionAsync,
  handleToggleAclModal,
  selectDatabaseService,
  selectDSSAclPermissions,
  selectDSSAclsLoading,
  selectDSSShowAclEditorModal,
  getPermissionsLabel,
} from "../../../store/database/serviceSlice";
import { DataStreamingACLCreateSchema } from "../../../utils/validations";

export const ACLCreateModal: React.FC = () => {
  const isOpen = useAppSelector(selectDSSShowAclEditorModal);
  const service = useAppSelector(selectDatabaseService);
  const aclActionLoading = useAppSelector(selectDSSAclsLoading);
  const permissions = useAppSelector(selectDSSAclPermissions);
  const dispatch = useAppDispatch();

  useEffect(() => {
    service &&
      dispatch(
        getDSSACLPermissionAsync({
          engine: service?.engine,
          clusterId: service?.id,
        })
      );
  }, [dispatch, service]);

  const methods = useForm({
    resolver: yupResolver(DataStreamingACLCreateSchema),
    mode: "onChange",
  });
  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
    setValue,
    watch,
  } = methods;

  const username = watch("username");
  const topic = watch("topic");
  const permission = watch("permission");

  const isFormComplete = !!username && !!topic && !!permission;

  const handleSubmitForm = (data: any) => {
    if (!aclActionLoading) {
      const formData = {
        topic: data.topic,
        username: data.username,
        permission: data.permission,
      };
      service &&
        dispatch(
          createDSSACLAsync({
            engine: service?.engine,
            id: service?.id,
            data: formData,
          })
        ).then((action) => {
          if (action.type === "service/acls/create/fulfilled") {
            dispatch(
              getDSSACLsAsync({
                engine: service?.engine,
                clusterId: service?.id,
              })
            );
            handleOnClose();
          }
        });
    }
  };

  const handleOnClose = useCallback(() => {
    reset();
    dispatch(handleToggleAclModal(false));
  }, [dispatch, reset]);

  useEffect(() => {
    return () => {
      handleOnClose();
    };
  }, [handleOnClose]);

  useEffect(() => {
    if (isOpen) {
      reset({ username: "", topic: "", permission: "" });
    }
  }, [isOpen, reset]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleOnClose}
      contentClassName="!max-w-xl overflow-hidden"
      title={"Add an access"}
    >
      <form className="mt-5" onSubmit={handleSubmit(handleSubmitForm)}>
        <div className="flex flex-col gap-3">
          <Input
            label="Username"
            {...register("username")}
            error={errors.username?.message}
            required
          />
          <Input
            label="Topic"
            {...register("topic")}
            error={errors.topic?.message}
            required
          />
          <Select
            label="Permission"
            value={permission}
            {...register("permission")}
            error={errors.permission?.message}
            required
            onChange={(v) =>
              setValue("permission", v ?? "", { shouldValidate: true })
            }
            options={
              permissions?.names?.map((name) => ({
                label: getPermissionsLabel(name),
                value: name,
              })) || []
            }
          />

          <div className="flex justify-end gap-2">
            <Button uiType="simple" onClick={handleOnClose}>
              Cancel
            </Button>
            <Button
              uiType="primary"
              type="submit"
              loading={aclActionLoading}
              disabled={!isFormComplete}
            >
              Add an access
            </Button>
          </div>
        </div>
      </form>
    </Modal>
  );
};
