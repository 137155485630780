import { jwtEnvAxios } from ".";
import { DBSCreateApiData, DBSUpdateApiData } from "../types/database";

const GET_DATABASE_SERVICE_API_URL = (id: string, engine: string) =>
  `/${engine}/${id}`;
const UPDATE_DATABASE_SERVICE_API_URL = (id: string, engine: string) =>
  `/${engine}/${id}`;
const CREATE_DATABASE_SERVICE_API_URL = (engine: string) => `/${engine}`;
const DELETE_DATABASE_SERVICE_API_URL = (engine: string, id: string) =>
  `/${engine}/${id}`;
const GET_DBS_CAPABILITIES_API_URL = `/capabilities`;
const GET_DBS_AVAILABILITY_API_URL = `/availability`;
// const GET_DBS_CATALOG_API_URL = `/catalog?ovhSubsidiary=FR`;
const GET_DBS_CATALOG_API_URL = `catalog/newCatalog`;
const GET_DBS_MAINTENANCE_API_URL = (id: string, engine: string) =>
  `/${engine}/${id}/maintenance`;
const GET_DBS_ROLES_API_URL = (id: string, engine: string) =>
  `/${engine}/${id}/roles?advanced=1`;
const CREATE_DBS_USER_API_URL = (engine: string, id: string) =>
  `/${engine}/${id}/user`;
const UPDATE_DBS_USER_API_URL = (engine: string, id: string, userId: string) =>
  `/${engine}/${id}/user/${userId}`;
const DELETE_DBS_USER_API_URL = (engine: string, id: string, userId: string) =>
  `/${engine}/${id}/user/${userId}`;
const GET_DBS_CERTIFICATE_API_URL = (engine: string, id: string) =>
  `/${engine}/${id}/certificates`;

//namespaces
const GET_DBS_NAMESPACES_API_URL = (engine: string, id: string) =>
  `/${engine}/${id}/namespace/detail`;
const CREATE_DBS_NAMESPACES_API_URL = (engine: string, id: string) =>
  `/${engine}/${id}/namespace`;
const UPDATE_DBS_NAMESPACES_API_URL = (
  engine: string,
  id: string,
  namesapceId: string
) => `/${engine}/${id}/namespace/${namesapceId}`;
const DELETE_DBS_NAMESPACES_API_URL = (
  engine: string,
  id: string,
  namesapceId: string
) => `/${engine}/${id}/namespace/${namesapceId}`;

//DBSUser
const GET_DBS_USER_API_URL = (id: string, engine: string) =>
  `/${engine}/${id}/user/detail`;
const RESET_DBS_USER_API_URL = (id: string, engine: string, userId: string) =>
  `/${engine}/${id}/user/${userId}/credentials/reset`;

//query statistics
const GET_DBS_QUERY_STATISTICTS_API_URL = (engine: string, id: string) =>
  `/${engine}/${id}/queryStatistics`;
const RESET_DBS_QUERY_STATISTICTS_API_URL = (engine: string, id: string) =>
  `/${engine}/${id}/queryStatistics/reset`;

//backups
const GET_DBS_BACKUPS_API_URL = (engine: string, id: string) =>
  `/${engine}/${id}/backup/detail`;

//restore
const DBS_RESTORE_API_URL = (
  engine: string,
  clusterId: string,
  backupId: string
) => `/${engine}/${clusterId}/backup/${backupId}/restore`;

export function getDatabaseServicesApi(
  enginCategory: "operational" | "streaming" | undefined = undefined
) {
  return jwtEnvAxios("dbs").get(
    `/service/details${enginCategory ? `?enginCategory=${enginCategory}` : ""}`
  );
}

export function getDatabaseServiceApi(id: string, engine: string) {
  return jwtEnvAxios("dbs").get(GET_DATABASE_SERVICE_API_URL(id, engine));
}

export function UpdateDBSApi(
  id: string,
  engine: string,
  data: DBSUpdateApiData
) {
  return jwtEnvAxios("dbs").put(
    UPDATE_DATABASE_SERVICE_API_URL(id, engine),
    data
  );
}

export function createDBSeApi(engine: string, data: DBSCreateApiData) {
  return jwtEnvAxios("dbs").post(CREATE_DATABASE_SERVICE_API_URL(engine), data);
}

export function deleteDBSApi(engine: string, clusterId: string) {
  return jwtEnvAxios("dbs").delete(
    DELETE_DATABASE_SERVICE_API_URL(engine, clusterId)
  );
}

export function getDBSCapabilitiesApi() {
  return jwtEnvAxios("dbs", true).get(GET_DBS_CAPABILITIES_API_URL);
}

export function getDBSAvailabilityApi() {
  return jwtEnvAxios("dbs", true).get(GET_DBS_AVAILABILITY_API_URL);
}

export function getDBSCatalogApi() {
  return jwtEnvAxios("dbs", true).get(GET_DBS_CATALOG_API_URL);
}

export function getDBSMaintenanceApi(id: string, engine: string) {
  return jwtEnvAxios("dbs").get(GET_DBS_MAINTENANCE_API_URL(id, engine));
}
export function getDBSRolesApi(id: string, engine: string) {
  return jwtEnvAxios("dbs").get(GET_DBS_ROLES_API_URL(id, engine));
}
export function getDBSUserApi(id: string, engine: string) {
  return jwtEnvAxios("dbs").get(GET_DBS_USER_API_URL(id, engine));
}
export function resetDBSUserApi(id: string, engine: string, userId: string) {
  return jwtEnvAxios("dbs").post(RESET_DBS_USER_API_URL(id, engine, userId));
}

export function createDBSUserApi(engine: string, id: string, data: any) {
  return jwtEnvAxios("dbs").post(CREATE_DBS_USER_API_URL(engine, id), data);
}
export function updateDBSUserApi(
  engine: string,
  id: string,
  userId: string,
  data: any
) {
  return jwtEnvAxios("dbs").put(
    UPDATE_DBS_USER_API_URL(engine, id, userId),
    data
  );
}
export function deleteDBSUserApi(engine: string, id: string, userId: string) {
  return jwtEnvAxios("dbs").delete(DELETE_DBS_USER_API_URL(engine, id, userId));
}

export function getDBSLogsApi(engine: string, clusterId: string) {
  return jwtEnvAxios("dbs").get(`/${engine}/${clusterId}/logs`);
}
export function DBSRestoreApi(
  engine: string,
  clusterId: string,
  backupId: string
) {
  return jwtEnvAxios("dbs").post(
    DBS_RESTORE_API_URL(engine, clusterId, backupId)
  );
}

//metrics
export function getDBSAvalibleMetricsApi(
  engine: string,
  clusterId: string,
  extended?: boolean
) {
  return jwtEnvAxios("dbs").get(
    `/${engine}/${clusterId}/metric?extended=${extended || false}`
  );
}

export function getDBSMetricValuesApi(
  engine: string,
  clusterId: string,
  metricName: string,
  period: string
) {
  return jwtEnvAxios("dbs").get(
    `/${engine}/${clusterId}/metric/${metricName}?period=${period}`
  );
}

//databases
export function getDBSDatabasesApi(engine: string, clusterId: string) {
  return jwtEnvAxios("dbs").get(`/${engine}/${clusterId}/database/detail`);
}

export function createDBSDatabasesApi(
  engine: string,
  clusterId: string,
  data: any
) {
  return jwtEnvAxios("dbs").post(`/${engine}/${clusterId}/database`, data);
}

export function deleteDBSDatabasesApi(
  engine: string,
  clusterId: string,
  databaseId: string
) {
  return jwtEnvAxios("dbs").delete(
    `/${engine}/${clusterId}/database/${databaseId}`
  );
}

//query statistics
export function getDBSQuerrStatisticsApi(engine: string, id: string) {
  return jwtEnvAxios("dbs").get(GET_DBS_QUERY_STATISTICTS_API_URL(engine, id));
}

export function resetDBSQuerrStatisticsApi(
  engine: string,
  id: string
  // data: any
) {
  return jwtEnvAxios("dbs").post(
    RESET_DBS_QUERY_STATISTICTS_API_URL(engine, id)
  );
}

export function getDBSCurrentQueriesApi(engine: string, clusterId: string) {
  return jwtEnvAxios("dbs").get(`/${engine}/${clusterId}/currentQueries`);
}

//integration
export function getDBSServiceIntegrationCapabilitiesApi(
  engine: string,
  clusterId: string
) {
  return jwtEnvAxios("dbs").get(
    `/${engine}/${clusterId}/capabilities/integration`
  );
}

export function getDBSServiceIntegrationsApi(
  engine: string,
  clusterId: string
) {
  return jwtEnvAxios("dbs").get(`/${engine}/${clusterId}/integration/detail`);
}

export function deleteDBSServiceIntegrationApi(
  engine: string,
  clusterId: string,
  integrationId: string
) {
  return jwtEnvAxios("dbs").delete(
    `/${engine}/${clusterId}/integration/${integrationId}`
  );
}

export function addDBSServiceIntegrationApi(
  engine: string,
  clusterId: string,
  data: any
) {
  return jwtEnvAxios("dbs").post(`/${engine}/${clusterId}/integration`, data);
}

export function etDBSAvancedConfigurationCapabilitiesApi(
  engine: string,
  clusterId: string
) {
  return jwtEnvAxios("dbs").get(
    `/${engine}/${clusterId}/capabilities/advancedConfiguration`
  );
}

//query statistics
export function getDBSBackupsApi(engine: string, id: string) {
  return jwtEnvAxios("dbs").get(GET_DBS_BACKUPS_API_URL(engine, id));
}

export function getDBSAvancedConfigurationsApi(
  engine: string,
  clusterId: string
) {
  return jwtEnvAxios("dbs").get(
    `/${engine}/${clusterId}/advancedConfiguration`
  );
}

export function updateDBSAvancedConfigurationsApi(
  engine: string,
  clusterId: string,
  data: any
) {
  return jwtEnvAxios("dbs").put(
    `/${engine}/${clusterId}/advancedConfiguration`,
    data
  );
}

//pools
export function getDBSPoolsApi(engine: string, clusterId: string) {
  return jwtEnvAxios("dbs").get(
    `/${engine}/${clusterId}/connectionPool/detail`
  );
}

export function createDBSPoolApi(engine: string, clusterId: string, data: any) {
  return jwtEnvAxios("dbs").post(
    `/${engine}/${clusterId}/connectionPool`,
    data
  );
}

export function updateDBSPoolApi(
  engine: string,
  clusterId: string,
  poolId: string,
  data: any
) {
  return jwtEnvAxios("dbs").put(
    `/${engine}/${clusterId}/connectionPool/${poolId}`,
    data
  );
}

export function deleteDBSPoolApi(
  engine: string,
  clusterId: string,
  poolId: string
) {
  return jwtEnvAxios("dbs").delete(
    `/${engine}/${clusterId}/connectionPool/${poolId}`
  );
}

//certificate
export function getDBSCertificateApi(engine: string, id: string) {
  return jwtEnvAxios("dbs").get(GET_DBS_CERTIFICATE_API_URL(engine, id));
}

//namespaces
export function getDBSNamespacesApi(engine: string, id: string) {
  return jwtEnvAxios("dbs").get(GET_DBS_NAMESPACES_API_URL(engine, id));
}
export function createDBSNamespaceApi(engine: string, id: string, data: any) {
  return jwtEnvAxios("dbs").post(
    CREATE_DBS_NAMESPACES_API_URL(engine, id),
    data
  );
}

export function updateBSNamespaceApi(
  engine: string,
  id: string,
  namespaceId: string,
  data: any
) {
  return jwtEnvAxios("dbs").put(
    UPDATE_DBS_NAMESPACES_API_URL(engine, id, namespaceId),
    data
  );
}
export function deleteBSNamespaceApi(
  engine: string,
  id: string,
  namesapceId: string
) {
  return jwtEnvAxios("dbs").delete(
    DELETE_DBS_NAMESPACES_API_URL(engine, id, namesapceId)
  );
}

export function addDBSNodeApi(engine: string, clusterId: string, data: any) {
  return jwtEnvAxios("dbs").post(`/${engine}/${clusterId}/node`, data);
}

export function deleteDBSNodeApi(
  engine: string,
  clusterId: string,
  nodeId: string
) {
  return jwtEnvAxios("dbs").delete(`/${engine}/${clusterId}/node/${nodeId}`);
}

export function getDSSReplicationsApi(engine: string, clusterId: string) {
  return jwtEnvAxios("dbs").get(`/${engine}/${clusterId}/replication/detail`);
}

export function createDSSReplicationApi(
  engine: string,
  clusterId: string,
  data: any
) {
  return jwtEnvAxios("dbs").post(`/${engine}/${clusterId}/replication`, data);
}

export function updateDSSReplicationApi(
  engine: string,
  clusterId: string,
  replicationId: string,
  data: any
) {
  return jwtEnvAxios("dbs").put(
    `/${engine}/${clusterId}/replication/${replicationId}`,
    data
  );
}

export function deleteDSSReplicationApi(
  engine: string,
  clusterId: string,
  replicationId: string
) {
  return jwtEnvAxios("dbs").delete(
    `/${engine}/${clusterId}/replication/${replicationId}`
  );
}

export function getDSSConnectorsApi(engine: string, clusterId: string) {
  return jwtEnvAxios("dbs").get(`/${engine}/${clusterId}/connector/detail`);
}

export function deleteDSSConnectorApi(
  engine: string,
  clusterId: string,
  connectorId: string
) {
  return jwtEnvAxios("dbs").delete(
    `/${engine}/${clusterId}/connector/${connectorId}`
  );
}

export function getDSSCapabilitiesConnectorsApi(
  engine: string,
  clusterId: string
) {
  return jwtEnvAxios("dbs").get(
    `/${engine}/${clusterId}/capabilities/connector/detail`
  );
}

export function getDSSCapabilitiesConnectorApi(
  engine: string,
  clusterId: string,
  connectorId: string
) {
  return jwtEnvAxios("dbs").get(
    `/${engine}/${clusterId}/capabilities/connector/${connectorId}`
  );
}
export function getDSSConnectorTransformsApi(
  engine: string,
  clusterId: string,
  connectorId: string
) {
  return jwtEnvAxios("dbs").get(
    `/${engine}/${clusterId}/capabilities/connector/${connectorId}/transforms`
  );
}

export function getDSSConnectorsConfigurationApi(
  engine: string,
  clusterId: string,
  connectorId: string
) {
  return jwtEnvAxios("dbs").get(
    `/${engine}/${clusterId}/capabilities/connector/${connectorId}/configuration`
  );
}

export function createDSSConnectorApi(
  engine: string,
  clusterId: string,
  data: any
) {
  return jwtEnvAxios("dbs").post(`/${engine}/${clusterId}/connector`, data);
}

export function updateDSSConnectorApi(
  engine: string,
  clusterId: string,
  connectorId: string,
  data: any
) {
  return jwtEnvAxios("dbs").put(
    `/${engine}/${clusterId}/connector/${connectorId}`,
    data
  );
}
export function getDSSConnectorApi(
  engine: string,
  clusterId: string,
  connectorId: string
) {
  return jwtEnvAxios("dbs").get(
    `/${engine}/${clusterId}/connector/${connectorId}`
  );
}

export function restartDSSConnectorApi(
  engine: string,
  clusterId: string,
  connectorId: string
) {
  return jwtEnvAxios("dbs").post(
    `/${engine}/${clusterId}/connector/${connectorId}/restart`
  );
}
export function pauseDSSConnectorApi(
  engine: string,
  clusterId: string,
  connectorId: string
) {
  return jwtEnvAxios("dbs").post(
    `/${engine}/${clusterId}/connector/${connectorId}/pause`
  );
}
export function resumeDSSConnectorApi(
  engine: string,
  clusterId: string,
  connectorId: string
) {
  return jwtEnvAxios("dbs").post(
    `/${engine}/${clusterId}/connector/${connectorId}/resume`
  );
}

export function getConnectorTasksApi(
  engine: string,
  clusterId: string,
  connectorId: string
) {
  return jwtEnvAxios("dbs").get(
    `/${engine}/${clusterId}/connector/${connectorId}/task`
  );
}

export function getConnectorTaskApi(
  engine: string,
  clusterId: string,
  connectorId: string,
  taskId: string
) {
  return jwtEnvAxios("dbs").get(
    `/${engine}/${clusterId}/connector/${connectorId}/task/${taskId}`
  );
}

export function restartConnectorTaskApi(
  engine: string,
  clusterId: string,
  connectorId: string,
  taskId: string,
  data: any
) {
  return jwtEnvAxios("dbs").post(
    `/${engine}/${clusterId}/connector/${connectorId}/task/${taskId}/restart`,
    data
  );
}

//acl
export function getDSSACLsApi(engine: string, clusterId: string) {
  return jwtEnvAxios("dbs").get(`/${engine}/${clusterId}/topicAcl/detail`);
}
export function getDSSACLApi(engine: string, clusterId: string, aclId: string) {
  return jwtEnvAxios("dbs").get(`/${engine}/${clusterId}/topicAcl/${aclId}`);
}

export function createDSSACLApi(engine: string, clusterId: string, data: any) {
  return jwtEnvAxios("dbs").post(`/${engine}/${clusterId}/topicAcl`, data);
}

export function deleteDSSACLApi(
  engine: string,
  clusterId: string,
  aclId: string
) {
  return jwtEnvAxios("dbs").delete(`/${engine}/${clusterId}/topicAcl/${aclId}`);
}

//topicss
export function getDSSTopicsApi(engine: string, clusterId: string) {
  return jwtEnvAxios("dbs").get(`/${engine}/${clusterId}/topic/detail`);
}

export function createDSSTopicApi(
  engine: string,
  clusterId: string,
  data: any
) {
  return jwtEnvAxios("dbs").post(`/${engine}/${clusterId}/topic`, data);
}

export function deleteDSSTopicsApi(
  engine: string,
  clusterId: string,
  topicId: string
) {
  return jwtEnvAxios("dbs").delete(`/${engine}/${clusterId}/topic/${topicId}`);
}

export function getDASPatternsApi(engine: string, clusterId: string) {
  return jwtEnvAxios("dbs").get(`/${engine}/${clusterId}/pattern/detail`);
}

export function deleteDASPatternsApi(
  engine: string,
  clusterId: string,
  patternId: string
) {
  return jwtEnvAxios("dbs").delete(
    `/${engine}/${clusterId}/pattern/${patternId}`
  );
}

export function createDASPatternsApi(
  engine: string,
  clusterId: string,
  data: any
) {
  return jwtEnvAxios("dbs").post(`/${engine}/${clusterId}/pattern`, data);
}

export function getDASIndexesApi(engine: string, clusterId: string) {
  return jwtEnvAxios("dbs").get(`/${engine}/${clusterId}/index/detail`);
}

export function deleteDASIndexessApi(
  engine: string,
  clusterId: string,
  indexId: string
) {
  return jwtEnvAxios("dbs").delete(`/${engine}/${clusterId}/index/${indexId}`);
}

export function getAclPermissionsApi(engine: string, clusterId: string) {
  return jwtEnvAxios("dbs").get(`/${engine}/${clusterId}/permissions`);
}

//kafka user
export function getUserAccessApi(
  engine: string,
  clusterId: string,
  userId: string
) {
  return jwtEnvAxios("dbs").get(
    `/${engine}/${clusterId}/user/${userId}/access`
  );
}
