import { useDropzone } from "react-dropzone";
import FilesBreadCrumb from "./FilesBreadCrumb";
import { useCallback, useEffect, useRef } from "react";
import toast from "react-hot-toast";
import { fileParentFinder } from "../../../utils/file";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import {
  fetchFilesAysnc,
  handleChangeNewFolderNameOrFile,
  handleShowConflictModal,
  handleShowCreateFolderModal,
  selectBreadCrumbItems,
  clearBreadCrumb as clearIpfsBreadCrumb,
} from "../../../store/ipfs/filesSlice";
import { uploadFileApi } from "../../../apis/filesAPI";
import NewFolderModal, { ConflictModal } from "./NewFolderModal";
import FileList from "./FileList";
import { ToastClasses } from "../../modals/alerts";
import { isAxiosError } from "axios";
import { selectIpfsNodeStatus } from "../../../store/ipfs/ipfsPublicSlice";
import { IpfsNotActiveTab } from "../../layouts/NotData";
import { ReactComponent as UploadIcon } from "./../../../assets/icons/arrow-up-tray.svg";
import { ReactComponent as PlusIcon } from "./../../../assets/icons/plus.svg";
import { Button, Dropdown, Flex, cn } from "djuno-design";
import { CustomErrorToast } from "../../general/Toast";
import { useTour } from "../../../providers/TourProvider";

const IpfsFilesTab = () => {
  const ipfsStatus = useAppSelector(selectIpfsNodeStatus);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const breadCrumb = useAppSelector(selectBreadCrumbItems);
  const url = fileParentFinder(breadCrumb);
  const dispatch = useAppDispatch();

  const { run } = useTour("ipfs");

  useEffect(() => {
    dispatch(fetchFilesAysnc());

    return () => {
      dispatch(clearIpfsBreadCrumb());
    };
  }, [dispatch]);

  const upload = useCallback(
    async (file: File, url: string) => {
      const _t = toast.loading(`uploading file`);
      // const _url = fileUrlConvertor(url);
      dispatch(handleChangeNewFolderNameOrFile({ name: file }));
      try {
        await uploadFileApi(file, url);
        toast.success(`upload completed`, {
          id: _t,
          className: ToastClasses,
        });
        dispatch(fetchFilesAysnc());
        dispatch(handleChangeNewFolderNameOrFile({ name: null }));
      } catch (e) {
        if (isAxiosError(e)) {
          if (e.response?.status === 422) {
            dispatch(handleShowConflictModal({ type: "file" }));
            toast.dismiss(_t);
          } else {
            const message = CustomErrorToast(e.response?.data.message);
            toast.error(message, {
              id: _t,
              className: ToastClasses,
            });
          }
        }
      }
      try {
        if (fileInputRef.current) {
          fileInputRef.current.value = "";
        }
      } catch (e) {}
    },
    [dispatch]
  );

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      upload(acceptedFiles[0], url);
    },
    [upload, url]
  );

  const { isDragActive, getRootProps, getInputProps } = useDropzone({
    onDrop,
    noClick: true,
  });

  const handleUploadClick = useCallback(() => {
    if (fileInputRef.current) fileInputRef.current.click();
  }, []);

  const handleUploadFiles = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const files = e.target.files;
      if (files) {
        upload(files[0], url);
      }
    },
    [upload, url]
  );

  if (ipfsStatus !== 2) {
    return <IpfsNotActiveTab />;
  }
  return (
    <div className="flex flex-col min-h-full flex-1">
      <input
        type="file"
        hidden={true}
        ref={fileInputRef}
        multiple={true}
        onChange={handleUploadFiles}
      />

      <Flex
        items="center"
        justify="between"
        className="transition-all duration-500 ipfs-header"
      >
        <FilesBreadCrumb />
        <div className="hidden ml-auto lg:flex items-center justify-end gap-1 md:gap-2">
          <Button
            onClick={() => {
              run();
            }}
          >
            Start Tour
          </Button>

          <Button
            uiType="light"
            onClick={() => dispatch(handleShowCreateFolderModal())}
            className="new-folder-button"
          >
            New Folder
          </Button>

          <div className="upload-file-button">
            <Button
              uiType="primary"
              onClick={handleUploadClick}
              className="upload-file-button"
            >
              Upload File
            </Button>
          </div>
        </div>
        <div className="lg:hidden">
          <Dropdown
            anchor="bottom end"
            menu={[
              {
                key: "CreateNewPath",
                label: (
                  <div className="flex items-center gap-2">
                    <PlusIcon className="w-4 h-4" /> New Folder
                  </div>
                ),
                onClick: (_, close) => {
                  close();
                  dispatch(handleShowCreateFolderModal());
                },
              },
              {
                key: "UploadFile",
                label: (
                  <div className="flex items-center gap-2">
                    <UploadIcon className="w-4 h-4" /> Upload File
                  </div>
                ),
                onClick: (_, close) => {
                  close();
                  handleUploadClick();
                },
              },
            ]}
          >
            <Button uiSize="medium" uiType="light">
              <PlusIcon className="w-4 h-4" />
            </Button>
          </Dropdown>
        </div>
      </Flex>
      <div
        {...getRootProps({
          className: cn(
            "w-full min-h-[100%] flex-1 mt-5 overflow-hidden rounded-lg border-2 bg-white dark:bg-[rgb(29,33,37)]",
            {
              "border-primary-400": isDragActive,
              "border-transparent": !isDragActive,
            }
          ),
        })}
      >
        <input {...getInputProps()} />
        <FileList />
      </div>
      <NewFolderModal />
      <ConflictModal />
    </div>
  );
};

export default IpfsFilesTab;
