import { ReactComponent as MoreIcon } from "./../../../assets/icons/more.svg";
import { ReactComponent as ArrowDownCircleIcon } from "./../../../assets/icons/arrow-down-circle.svg";
import { ReactComponent as CreditCardIcon } from "./../../../assets/icons/credit-card.svg";
import { ReactComponent as VisaCardIcon } from "./../../../assets/icons/cards/visa.svg";
import { ReactComponent as MasterCardIcon } from "./../../../assets/icons/cards/mastercard.svg";
import { ReactComponent as PaypalCardIcon } from "./../../../assets/icons/cards/paypal.svg";
import { ReactComponent as PlusIcon } from "./../../../assets/icons/plus.svg";
import { ReactComponent as EditIcon } from "./../../../assets/icons/pencil-square.svg";
import { Helmet } from "react-helmet";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import {
  deletePaymentMethodAsync,
  downlodInvoiceAsync,
  editablePlanGroupIds,
  getBillingInformationAsync,
  getCloudCreditAsync,
  getSubscriptionsAsync,
  handleChangeInvoicePagination,
  handleConfigPayNowModal,
  handleShowBillingInformationModal,
  handleShowTopupModal,
  handleShowTopupWithPromoModal,
  invoiceCompletedStatus,
  invoicesAsync,
  makeBillingIntentAsync,
  paymentMethodsAsync,
  selectBillingInformation,
  selectBillingInformationLoading,
  selectCloudCredit,
  selectCloudCreditLoading,
  selectInvoices,
  selectInvoicesLoading,
  selectInvoicesPagination,
  selectPaymentMethodActionLoading,
  selectPaymentMethods,
  selectPaymentMethodsLoading,
  selectSubscriptions,
  selectSubscriptionsLoading,
  selectMakeIntentLoading,
  setAsDefaultPaymentMethodAsync,
  selectUnfinalizedPaymentMethods,
  unfinalizedCountPaymentMethodsAsync,
} from "../../../store/billing/billingSlice";
import { useCallback, useEffect, useMemo, useState } from "react";
import CardDetailsBox from "./CardDetailsBox";
import {
  BillingIntentResponse,
  IPaymentMethod,
  ISubscription,
} from "../../../types/billing";
import toast from "react-hot-toast";
import { CustomToast } from "../../general/Toast";
import BillingInformationModal from "./BillingInformationModal";
import TopupModal from "./TopupModal";
import { formatTimestamp } from "../../../utils/date";
import { UniversalPlansBox } from "../../plans/UniversalPlansBox";
import { getCountryFlag } from "../../../utils";
import { selectUser } from "../../../store/auth/authSlice";
import { DeleteModal } from "../../modals/QuestionModal";
import { currency } from "../../../utils/currency";
import InvoicePayNowModal from "./InvoicePayNowModal";
import {
  Button,
  Modal,
  Typography,
  Dropdown,
  SimpleTable,
  EmptyState,
  Skeleton,
  Card,
  Tag,
  Loading,
  Pagination,
  cn,
  Flex,
} from "djuno-design";
import Ripple from "../../general/Ripple";
import TopupPromoModal from "./TopupPromoModal";
import Meteors from "../../general/Meteors";

const SettingBillingTab = () => {
  const dispatch = useAppDispatch();

  const [deletePaymentMethod, setDeletePaymentMethod] =
    useState<IPaymentMethod | null>(null);

  //download
  const [downloadLoadingIds, setDownloadLoadingIds] = useState<number[]>([]);

  const handleDownloadInvoice = useCallback(
    (invoiceId: number) => {
      setDownloadLoadingIds((prev) => [...prev, invoiceId]);
      dispatch(downlodInvoiceAsync({ invoiceNumber: invoiceId })).then(() => {
        setDownloadLoadingIds((prev) => prev.filter((id) => id !== invoiceId));
      });
    },
    [dispatch]
  );

  const isInvoiceLoading = useMemo(() => {
    return (invoiceId: number) => downloadLoadingIds.includes(invoiceId);
  }, [downloadLoadingIds]);

  const user = useAppSelector(selectUser);

  const cloudCredit = useAppSelector(selectCloudCredit);
  const cloudCreditLoading = useAppSelector(selectCloudCreditLoading);

  const paymentMethods = useAppSelector(selectPaymentMethods);
  const makeIntentLoading = useAppSelector(selectMakeIntentLoading);
  const paymentMethodsLoading = useAppSelector(selectPaymentMethodsLoading);
  const paymentMethodActionLoading = useAppSelector(
    selectPaymentMethodActionLoading
  );
  const [openCardDetailModal, setOpenCardDetailModal] = useState(false);
  const unfinalizedPaymentMethods = useAppSelector(
    selectUnfinalizedPaymentMethods
  );

  const billingInformation = useAppSelector(selectBillingInformation);
  const billingInformationLoading = useAppSelector(
    selectBillingInformationLoading
  );

  const invoices = useAppSelector(selectInvoices);
  const invoicesPagination = useAppSelector(selectInvoicesPagination);
  const invoicesLoading = useAppSelector(selectInvoicesLoading);

  const subscriptions = useAppSelector(selectSubscriptions);
  const subscriptionsLoading = useAppSelector(selectSubscriptionsLoading);

  // const planGroups = useAppSelector(selectPlanGroups);
  // const planGroupsLoading = useAppSelector(selectPlanGroupsLoading);

  const [rescalingPlanGruopId, setRescalingPlanGruopId] = useState<
    undefined | number
  >(undefined);

  useEffect(() => {
    dispatch(getCloudCreditAsync({}));
  }, [dispatch]);

  useEffect(() => {
    dispatch(paymentMethodsAsync());
  }, [dispatch]);

  //get invoices data
  useEffect(() => {
    dispatch(invoicesAsync());
  }, [dispatch, invoicesPagination.PageNumber]);

  //get subscriptions
  useEffect(() => {
    dispatch(getSubscriptionsAsync({ includePlans: true }));
  }, [dispatch]);

  useEffect(() => {
    dispatch(getBillingInformationAsync());
  }, [dispatch]);

  useEffect(() => {
    dispatch(unfinalizedCountPaymentMethodsAsync());
  }, [dispatch]);

  //get invoices data if there is pending data
  useEffect(() => {
    let invoices_interval: NodeJS.Timeout | undefined;
    const statuses = Array.from(new Set(invoices.map((i) => i.InvoiceStatus)));
    if (statuses.includes("pending")) {
      invoices_interval = setInterval(() => {
        dispatch(
          invoicesAsync({
            withoutLoading: true,
          })
        );
      }, 8000);
    } else {
      if (invoices_interval) clearInterval(invoices_interval);
    }
    return () => {
      if (invoices_interval) {
        clearInterval(invoices_interval);
      }
    };
  }, [dispatch, invoices]);

  const handleBeforInitPaymentMethod = async () => {
    return dispatch(makeBillingIntentAsync()).then((action) => {
      if (action.type === "billing/intent/fulfilled") {
        const orderToken = (action.payload as BillingIntentResponse).Token;
        return orderToken;
      } else {
        return undefined;
      }
    });
  };

  const handleAfterInitPaymentMethod = async () => {
    setOpenCardDetailModal(false);
    toast.success(() => CustomToast("New payment details added successfully"));
    dispatch(unfinalizedCountPaymentMethodsAsync());
  };

  const handleSetAsDefault = useCallback(
    (paymentMethodId: number) => {
      dispatch(setAsDefaultPaymentMethodAsync(paymentMethodId)).then(
        (action) => {
          if (
            action.type === "billing/payment-methods/set-as-default/fulfilled"
          ) {
            dispatch(paymentMethodsAsync());
          }
        }
      );
    },
    [dispatch]
  );

  //refresh cloud-credit
  useEffect(() => {
    let interval: NodeJS.Timeout | undefined;
    let intervalTime = 5000;

    if (cloudCredit && cloudCredit.PendingTopupCount > 0) {
      interval = setInterval(() => {
        dispatch(getCloudCreditAsync({ withoutLoading: true }));
      }, intervalTime);
    } else {
      if (interval) clearInterval(interval);
      dispatch(invoicesAsync({ withoutLoading: true }));
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [dispatch, cloudCredit]);

  //refresh payment methods
  useEffect(() => {
    let interval: NodeJS.Timeout | undefined;
    let intervalTime = 5000;

    if (unfinalizedPaymentMethods && unfinalizedPaymentMethods > 0) {
      interval = setInterval(() => {
        dispatch(unfinalizedCountPaymentMethodsAsync());
      }, intervalTime);
    } else {
      if (interval) clearInterval(interval);
      dispatch(paymentMethodsAsync());
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [dispatch, unfinalizedPaymentMethods]);

  const editableSubscriptions = useMemo(() => {
    const editables = [];
    const nonEditables = [];

    for (const subscription of subscriptions) {
      if (subscription.Plan) {
        if (editablePlanGroupIds.includes(subscription.Plan.PlanGroupId)) {
          editables.push(subscription);
        } else {
          nonEditables.push(subscription);
        }
      }
    }

    const sortByPlanGroupName = (a: ISubscription, b: ISubscription) => {
      const nameA = a.Plan?.PlanGroupName || "";
      const nameB = b.Plan?.PlanGroupName || "";
      return nameA.localeCompare(nameB);
    };

    editables.sort(sortByPlanGroupName);
    nonEditables.sort(sortByPlanGroupName);

    return { editables, nonEditables };
  }, [subscriptions]);

  return (
    <>
      <Helmet>
        <title>{process.env.REACT_APP_NAME} | Billing</title>
        <meta name="description" content="" />
      </Helmet>

      <div className="w-full flex flex-col gap-10">
        <div className="w-full grid gap-8">
          <div className="col-span-1">
            <div className="flex flex-col gap-1 !pt-4 p-6 rounded-xl bg-white dark:bg-[rgb(22,26,29)] dark:border-[rgb(34,39,43)] border h-full">
              <Flex items="center" justify="between" className="w-full gap-3">
                <Typography.Text className="!font-medium">
                  Credit balance
                </Typography.Text>
                <div className="flex items-center gap-5">
                  <Typography.Link className="!text-sm" href="#Invoice History">
                    Past invoices
                  </Typography.Link>
                  <Typography.Link
                    className="!text-sm"
                    href="#Subscription and plans"
                  >
                    Subscriptions
                  </Typography.Link>
                </div>
              </Flex>
              <Typography.Text className="!font-normal !text-sm">
                Top up to get 10% discount on every service you use
              </Typography.Text>
              <div className="flex flex-col items-center gap-4 p-6 mt-3 rounded-lg bg-primary-50/50 dark:bg-[rgb(29,33,37)] overflow-hidden relative">
                <Ripple mainCircleSize={70} mainCircleOpacity={0.2} />
                <Typography.Text className="!font-medium !text-base">
                  Your current balance
                </Typography.Text>
                <Flex items="center" justify="center" className="h-10">
                  {cloudCreditLoading ? (
                    <Skeleton
                      shape="rectangle"
                      uiSize="small"
                      style={{ width: 100, height: 30 }}
                    />
                  ) : (
                    <>
                      <Typography.Text className="!font-semibold !text-2xl !min-w-[100px] !text-center">
                        {currency(cloudCredit?.Balance)}
                      </Typography.Text>
                    </>
                  )}
                </Flex>
                {cloudCredit && cloudCredit.PendingTopupCount > 0 && (
                  <Tag className="" color="processing">
                    <Flex items="center" className="gap-1">
                      <Loading
                        uiType="elastic"
                        borderSize={1.5}
                        uiSize={10}
                        theme="transparent"
                      />
                      You have a pending top-up
                      {/* You have{" "}
                      {cloudCredit.PendingTopupCount === 1
                        ? "a"
                        : cloudCredit.PendingTopupCount}{" "}
                      pending top-up */}
                    </Flex>
                  </Tag>
                )}
                <div className="flex items-center justify-center">
                  <Button
                    uiType="light"
                    onClick={() => dispatch(handleShowTopupModal(true))}
                  >
                    Top up
                  </Button>
                </div>
              </div>
              <div className="flex flex-col items-center gap-4 p-6 mt-3 rounded-lg bg-primary-50/50 dark:bg-[rgb(29,33,37)] overflow-hidden relative">
                <Meteors number={15} />
                <Typography.Text className="!font-medium !text-base !opacity-70">
                  Do you have a promotion code?
                </Typography.Text>
                <Button
                  uiType="light"
                  onClick={() => dispatch(handleShowTopupWithPromoModal(true))}
                >
                  Top up with promotion code
                </Button>
              </div>
            </div>
            <TopupModal />
            <TopupPromoModal />
          </div>
        </div>

        {/* payment methods */}
        <Card
          title="Payment Method"
          setting={
            <Button
              onClick={() => setOpenCardDetailModal(true)}
              className="whitespace-nowrap"
            >
              <Typography.Text
                size="sm"
                uiType="transparent"
                className="hidden md:block"
              >
                Add new card
              </Typography.Text>
              <PlusIcon className="w-4 h-4 md:hidden" />
            </Button>
          }
        >
          <div className="mt-0">
            <div className=" flex flex-col gap-5">
              {(paymentMethodsLoading || unfinalizedPaymentMethods > 0) && (
                <Flex
                  items="center"
                  justify="center"
                  className="w-full h-[100px]"
                >
                  {paymentMethodsLoading && unfinalizedPaymentMethods === 0 && (
                    <Loading borderSize={2} />
                  )}
                  {unfinalizedPaymentMethods > 0 && (
                    <Tag className="" color="processing">
                      <Flex items="center" className="gap-1">
                        <Loading
                          uiType="elastic"
                          borderSize={1.5}
                          uiSize={10}
                          theme="transparent"
                        />
                        You have{" "}
                        {unfinalizedPaymentMethods === 1
                          ? "a"
                          : unfinalizedPaymentMethods}{" "}
                        pending payment method
                      </Flex>
                    </Tag>
                  )}
                </Flex>
              )}

              {!paymentMethodsLoading &&
                paymentMethods.length > 0 &&
                paymentMethods.map((paymentMethod, index) => {
                  return (
                    <div
                      className={cn(
                        "flex items-center justify-between gap-2 pt-4 border-slate-200 dark:border-dark-2",
                        {
                          "border-t ": index > 0,
                        }
                      )}
                      key={index}
                    >
                      <div className="flex items-center gap-0.5">
                        {getPaymentMethodCard(paymentMethod.Brand)}
                        <Typography.Text className="!text-sm !font-medium ml-1">
                          {paymentMethod.Brand || "Card"}
                        </Typography.Text>
                        <Typography.Text className="!text-sm">
                          ending in
                        </Typography.Text>
                        <Typography.Text className="!text-sm !font-medium">
                          {paymentMethod.LastFour}
                        </Typography.Text>
                        {paymentMethod.IsDefault && (
                          <Tag
                            className="!ml-2 !rounded-full !px-2 "
                            color="processing"
                            bordered={false}
                          >
                            default
                          </Tag>
                        )}
                      </div>
                      <div className="w-8 flex justify-center items-center">
                        <Dropdown
                          anchor="bottom end"
                          itemsClassName="!w-40"
                          menu={[
                            {
                              key: "setAsDefault",
                              label: "Set as default",
                              onClick(_, close) {
                                close();
                                handleSetAsDefault(paymentMethod.Id);
                              },
                              loading: paymentMethodActionLoading,
                              disabled: paymentMethod.IsDefault,
                            },
                            {
                              type: "divider",
                            },
                            {
                              key: "delete",
                              label: "Delete",
                              danger: true,
                              onClick(item, close) {
                                close();
                                setDeletePaymentMethod(paymentMethod);
                              },
                              disabled: paymentMethod.IsDefault,
                            },
                          ]}
                        >
                          <Button
                            uiSize="medium"
                            uiType="icon"
                            className="!px-2"
                          >
                            <MoreIcon className="w-4 h-4" />
                          </Button>
                        </Dropdown>
                      </div>
                    </div>
                  );
                })}
            </div>
            {!paymentMethodsLoading && paymentMethods.length === 0 && (
              <EmptyState
                text={
                  <div className="flex flex-col items-center gap-2">
                    No information found
                    <Button
                      disabled={paymentMethodsLoading}
                      onClick={() => setOpenCardDetailModal(true)}
                    >
                      Add Card
                    </Button>
                  </div>
                }
              />
            )}
          </div>

          <Modal
            isOpen={openCardDetailModal}
            onClose={() => setOpenCardDetailModal(false)}
            title="Add new payment method"
            contentClassName="max-w-lg"
          >
            <CardDetailsBox
              beforInitFn={handleBeforInitPaymentMethod}
              afterInitFn={handleAfterInitPaymentMethod}
              savingLoading={false}
              initLoading={
                paymentMethodsLoading ||
                paymentMethodActionLoading ||
                makeIntentLoading
              }
              username={user?.FullName}
            />
          </Modal>
        </Card>

        {/* Billing Information */}
        <Card
          title="Billing Information"
          setting={
            <Button
              onClick={() => dispatch(handleShowBillingInformationModal(true))}
            >
              <Typography.Text
                size="sm"
                uiType="transparent"
                className="hidden md:block"
              >
                Update
              </Typography.Text>
              <EditIcon className="w-4 h-4 md:hidden" />
            </Button>
          }
        >
          <div className="flex flex-col gap-2 mt-3">
            <div className="grid grid-cols-12 gap-5">
              <div className="flex flex-col col-span-12 md:col-span-6 xl:col-span-4 gap-2">
                <Typography.Text className="!text-base !font-normal">
                  Additional Information
                </Typography.Text>
                <Typography.Text className="!text-sm" uiType="secondary">
                  Provide additional billing information such as company name ,
                  address , or VAT number.
                </Typography.Text>
              </div>
              {billingInformation && !billingInformationLoading && (
                <div className="flex flex-col col-span-12 md:col-span-3 gap-2">
                  {/* <Text className="text-sm">mohammad sayadi</Text> */}
                  <Typography.Text className="!text-sm">
                    {billingInformation?.AddressLine1}
                  </Typography.Text>
                  <Typography.Text className="!text-sm">
                    {billingInformation?.AddressLine2}
                  </Typography.Text>
                  <Typography.Text className="!text-sm">
                    {`${
                      billingInformation && billingInformation.State
                        ? billingInformation?.State + ","
                        : ""
                    }  ${billingInformation?.ZipCode || ""} ${
                      billingInformation?.City || ""
                    }`}
                  </Typography.Text>
                  <div className="flex items-center gap-1">
                    <img
                      alt={billingInformation?.Country.Name}
                      src={getCountryFlag(billingInformation.Country.Iso3)}
                      className="w-4 h-4"
                    />
                    <Typography.Text className="!text-sm">
                      {billingInformation.Country.Name}
                    </Typography.Text>
                  </div>
                  <div className="col-span-1">
                    <Typography.Text className="!text-sm">
                      {billingInformation.VATNumber}
                    </Typography.Text>
                  </div>
                </div>
              )}
              {billingInformation === null && billingInformationLoading && (
                <Flex
                  items="center"
                  justify="center"
                  className="w-full h-[100px]"
                >
                  <Loading borderSize={2} />
                </Flex>
              )}
            </div>
          </div>
          <BillingInformationModal />
        </Card>

        {/* Invoice History */}
        <Card
          title="Invoice History"
          description="View or download your past invoices."
        >
          <div className="flex flex-col gap-2 mt-3">
            <SimpleTable
              loading={invoicesLoading}
              containerClassName="min-h-[240px]"
            >
              <SimpleTable.Head>
                <SimpleTable.Row>
                  <SimpleTable.TH lable="Date" />
                  <SimpleTable.TH lable="Amout" />
                  <SimpleTable.TH lable="Status" />
                  <SimpleTable.TH lable="Description" />
                  <SimpleTable.TH lable="" />
                </SimpleTable.Row>
              </SimpleTable.Head>
              <SimpleTable.Body>
                {invoices.map((invoice, i) => {
                  return (
                    <SimpleTable.Row key={i}>
                      <SimpleTable.TD>
                        <Typography.Text className="!text-sm">
                          {formatTimestamp(invoice.Date).datetime}
                        </Typography.Text>
                      </SimpleTable.TD>
                      <SimpleTable.TD>
                        <div className="flex items-center gap-1">
                          <Typography.Text className="!text-sm">
                            {currency(invoice.Amount)}
                          </Typography.Text>
                          {invoice.IsPending && (
                            <Typography.Text
                              className="!text-xs"
                              uiType="warning"
                            >
                              Pending
                            </Typography.Text>
                          )}
                        </div>
                      </SimpleTable.TD>
                      <SimpleTable.TD>
                        <div className="flex">
                          <Tag
                            className="!text-xs"
                            color={
                              invoiceCompletedStatus.includes(
                                invoice.InvoiceStatus
                              )
                                ? "success"
                                : "warning"
                            }
                          >
                            {invoice.InvoiceStatus}
                          </Tag>
                        </div>
                      </SimpleTable.TD>
                      <SimpleTable.TD>
                        <Typography.Text className="!text-sm">
                          {invoice.InvoiceDescription}
                        </Typography.Text>
                      </SimpleTable.TD>
                      <SimpleTable.TD className="flex justify-end">
                        <div className="flex justify-end items-center gap-1">
                          {!invoiceCompletedStatus.includes(
                            invoice.InvoiceStatus
                          ) &&
                            invoice.InvoiceActions.length > 0 &&
                            !isInvoiceLoading(invoice.Id) &&
                            invoice.Amount > 0 && (
                              <Button
                                uiType="icon"
                                uiSize="small"
                                onClick={(e: any) => {
                                  e.stopPropagation();
                                  dispatch(handleConfigPayNowModal(invoice));
                                }}
                                tooltip={{ content: "Pay now" }}
                                loading={false}
                                className="!px-2"
                              >
                                <CreditCardIcon className="w-4 h-4 md:w-5 md:h-5" />
                              </Button>
                            )}

                          <Button
                            uiType="icon"
                            uiSize="small"
                            onClick={(e) => {
                              e.stopPropagation();
                              handleDownloadInvoice(invoice.Id);
                            }}
                            tooltip={{ content: "Download" }}
                            loading={isInvoiceLoading(invoice.Id)}
                            className="!px-2"
                          >
                            {!isInvoiceLoading(invoice.Id) && (
                              <ArrowDownCircleIcon className="w-4 h-4 md:w-5 md:h-5" />
                            )}
                          </Button>
                        </div>
                      </SimpleTable.TD>
                    </SimpleTable.Row>
                  );
                })}
                {invoices.length === 0 && (
                  <SimpleTable.Row withoutHoverStyle={true}>
                    <SimpleTable.TD colSpan={5} className="!border-0">
                      <EmptyState text="You don't have any invoice yet" />
                    </SimpleTable.TD>
                  </SimpleTable.Row>
                )}
              </SimpleTable.Body>
            </SimpleTable>

            <div className="flex justify-end mt-3">
              <Pagination
                siblingCount={1}
                loading={invoicesLoading}
                limit={invoicesPagination.PageSize}
                offset={
                  (invoicesPagination.PageNumber - 1) *
                  invoicesPagination.PageSize
                }
                total={
                  invoicesPagination.Total !== null
                    ? invoicesPagination.Total
                    : 5
                }
                onPageChange={(offset: number, limit: number) => {
                  const newPageNumber = Math.floor(offset / limit) + 1;
                  dispatch(
                    handleChangeInvoicePagination({
                      pageNumber: newPageNumber,
                    })
                  );
                }}
              />
            </div>
          </div>
        </Card>

        {/* Subscription */}
        <Card title="Subscription and plans">
          <div className="flex flex-col gap-2 mt-3">
            <SimpleTable loading={subscriptionsLoading}>
              <SimpleTable.Head>
                <SimpleTable.Row>
                  <SimpleTable.TH lable="Group" />
                  <SimpleTable.TH lable="Plan" />
                  <SimpleTable.TH lable="Date" />
                  <SimpleTable.TH lable="" />
                </SimpleTable.Row>
              </SimpleTable.Head>

              <SimpleTable.Body>
                {editableSubscriptions.editables.map((subscription, i) => (
                  <SimpleTable.Row key={i}>
                    <SimpleTable.TD>
                      <Typography.Text className="!text-sm">
                        {subscription.Plan?.PlanGroupName}
                      </Typography.Text>
                    </SimpleTable.TD>
                    <SimpleTable.TD>
                      <Typography.Text className="!text-sm">
                        {subscription.Plan?.Name}
                      </Typography.Text>
                    </SimpleTable.TD>
                    <SimpleTable.TD>
                      <Typography.Text className="!text-sm !whitespace-nowrap">
                        {formatTimestamp(subscription.UpdatedAt).datetime}
                      </Typography.Text>
                    </SimpleTable.TD>
                    <SimpleTable.TD className="w-10 ">
                      <div className="w-8 flex justify-center items-center">
                        <Dropdown
                          anchor="bottom end"
                          itemsClassName="!w-40"
                          menu={[
                            {
                              key: "1",
                              label: (
                                <div className="flex items-center gap-1">
                                  <EditIcon className="w-4" />
                                  Update
                                </div>
                              ),
                              onClick: (_, close) => {
                                close();
                                setRescalingPlanGruopId(
                                  subscription.Plan?.PlanGroupId
                                );
                              },
                            },
                          ]}
                        >
                          <Button
                            uiSize="small"
                            uiType="icon"
                            className="!px-2"
                          >
                            <MoreIcon className="w-4 h-4" />
                          </Button>
                        </Dropdown>
                      </div>
                    </SimpleTable.TD>
                  </SimpleTable.Row>
                ))}
                {editableSubscriptions.nonEditables.map((subscription, i) => (
                  <SimpleTable.Row key={i}>
                    <SimpleTable.TD>
                      <Typography.Text className="!text-sm">
                        {subscription.Plan?.PlanGroupName}
                      </Typography.Text>
                    </SimpleTable.TD>
                    <SimpleTable.TD>
                      <Typography.Text className="!text-sm">
                        {subscription.Plan?.Name}
                      </Typography.Text>
                    </SimpleTable.TD>
                    <SimpleTable.TD>
                      <Typography.Text className="!text-sm !whitespace-nowrap">
                        {formatTimestamp(subscription.UpdatedAt).datetime}
                      </Typography.Text>
                    </SimpleTable.TD>
                    <SimpleTable.TD className="w-10 "></SimpleTable.TD>
                  </SimpleTable.Row>
                ))}
              </SimpleTable.Body>
            </SimpleTable>
            <Modal
              isOpen={!!rescalingPlanGruopId}
              onClose={() => setRescalingPlanGruopId(undefined)}
              title={`Update subscription plan`}
              contentClassName="!max-w-2xl min-h-[400px]"
            >
              <div className="mt-5">
                {rescalingPlanGruopId && (
                  <UniversalPlansBox
                    planGroupId={rescalingPlanGruopId}
                    className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-5"
                    onBuySuccessfull={() => setRescalingPlanGruopId(undefined)}
                  />
                )}

                {/* {rescalingPlanGruopId === 2 && <Web3StoragePlansBox />}
                {rescalingPlanGruopId === 3 && <Web3AuthPlansBox />}
                {rescalingPlanGruopId === 4 && <WorkflowPlansBox />} */}
                {/* {rescalingPlanGruopId === 5 && <BlockEventsPlansBox />} */}
              </div>
            </Modal>
          </div>
        </Card>
        <InvoicePayNowModal />
        <DeleteModal
          title="Delete a Payment"
          isOpen={deletePaymentMethod !== null}
          onClose={() => setDeletePaymentMethod(null)}
          onConfirm={() => {
            deletePaymentMethod &&
              dispatch(
                deletePaymentMethodAsync({
                  paymentId: deletePaymentMethod.Id,
                })
              ).then((action) => {
                if (
                  action.type === "billing/payment-methods/delete/fulfilled"
                ) {
                  dispatch(paymentMethodsAsync());
                  setDeletePaymentMethod(null);
                }
              });
          }}
          loading={paymentMethodsLoading}
          confirmString={
            paymentMethods?.find((p) => p.Id === deletePaymentMethod?.Id)
              ?.LastFour
          }
          confirmButtonType="danger"
        />
      </div>
    </>
  );
};

const getPaymentMethodCard = (brand: string | null | undefined) => {
  switch (brand) {
    case "visa":
      return <VisaCardIcon className="w-7 h-6 dark:text-slate-300" />;
    case "mastercard":
      return <MasterCardIcon className="w-7 h-6 dark:text-slate-300" />;
    case "paypal":
      return <PaypalCardIcon className="w-7 h-6 dark:text-slate-300" />;
    default:
      return <CreditCardIcon className="w-7 h-6 dark:text-slate-300" />;
  }
};

export default SettingBillingTab;
