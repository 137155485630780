import { Link, useNavigate } from "react-router-dom";
import UserAvatarBaseIcon from "./../../assets/images/blank-avatar.png";
// import { ReactComponent as AvatarIcon } from "./../../assets/icons/avatar.svg";
import { ReactComponent as UserIcon } from "./../../assets/icons/user.svg";
import { ReactComponent as TeamsIcon } from "./../../assets/icons/teams.svg";
import { ReactComponent as SignOutIcon } from "./../../assets/icons/sign-out.svg";
import {
  SettingsBillingUrl,
  SettingsProfileUrl,
  SettingsTeamsUrl,
} from "../../utils/urls";
import {
  logout,
  selectOnStageEnv,
  selectUser,
} from "../../store/auth/authSlice";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { BASE_URL } from "../../apis";
import {
  Alert,
  Dropdown,
  DropdownElement,
  Flex,
  Typography,
} from "djuno-design";
import { ShineBorder } from "../general/ShineBorder";

const UserDropdown: React.FunctionComponent = () => {
  const navigate = useNavigate();
  const user = useAppSelector(selectUser);
  const onStageEnv = useAppSelector(selectOnStageEnv);
  const dispatch = useAppDispatch();

  const userDropdoemItems: Array<DropdownElement> = [
    {
      key: "1",
      label: (
        <div className="flex items-center gap-2">
          <UserIcon className="w-4 h-4" /> Profile
        </div>
      ),
      onClick: (_, close) => {
        close();
        navigate(SettingsProfileUrl);
      },
    },
    {
      key: "2",
      label: (
        <div className="flex items-center gap-2">
          <TeamsIcon className="w-4 h-4" /> Teams
        </div>
      ),
      onClick: (_, close) => {
        close();
        navigate(SettingsTeamsUrl);
      },
      disabled: !onStageEnv?.IsAccountOwner,
    },
    { type: "divider" },
    {
      key: "3",
      label: (
        <div className="flex items-center gap-2">
          <SignOutIcon className="w-4 h-4" /> Sign out
        </div>
      ),
      danger: true,
      onClick: () => dispatch(logout()),
    },
  ];
  return (
    <Dropdown
      title={
        <Flex direction="col">
          <Typography.Text size="xs" className="truncate overflow-hidden">
            {user?.FullName}
          </Typography.Text>
          <Typography.Text
            size="xs"
            uiType="transparent"
            className="truncate overflow-hidden"
          >
            {user?.Email}
          </Typography.Text>
          {onStageEnv &&
            onStageEnv.EnvironmentStatus === 4 &&
            onStageEnv.IsAccountOwner &&
            !onStageEnv.HaveCardAttempt && (
              <Alert
                uiType="warning"
                className="!mt-2"
                message={
                  <Typography.Text className="!text-xs !flex-nowrap">
                    To access all features, please{" "}
                    <Link
                      to={SettingsBillingUrl + "#Payment Method"}
                      className="font-medium"
                    >
                      add a payment method
                    </Link>
                    .
                  </Typography.Text>
                }
              />
            )}
        </Flex>
      }
      menu={userDropdoemItems}
      anchor="bottom end"
    >
      <div className="flex items-center gap-2">
        <div className="w-7 h-7 aspect-square rounded-full relative overflow-hidden shadow">
          <ShineBorder shineColor={["#0074E5", "#A07CFE", "#FFBE7B"]} />
          <UserAvatar />
        </div>

        <div className="hidden md:flex whitespace-nowrap max-w-[100px]">
          <Typography.Text size="sm" className="!truncate !overflow-hidden">
            {user?.FullName}
          </Typography.Text>
        </div>
      </div>
    </Dropdown>
  );
};

export const UserAvatar = () => {
  const user = useAppSelector(selectUser);
  return (
    <img
      src={
        user?.AvatarLink !== null
          ? BASE_URL + user?.AvatarLink
          : UserAvatarBaseIcon
      }
      alt={user?.FullName}
      className="w-full h-full"
    />
  );
};
export default UserDropdown;
