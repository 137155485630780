import {
  createContext,
  useCallback,
  useContext,
  useRef,
  useState,
} from "react";
import { Button, cn, Flex, Loading, Typography } from "djuno-design";
import { ReactComponent as ArrowsPointingOutIcon } from "./../../assets/icons/arrows-pointing-out.svg";
import { ReactComponent as ArrowsPointingInIcon } from "./../../assets/icons/arrows-pointing-in.svg";

interface LogTerminalContextType {
  monitorRef: React.MutableRefObject<HTMLDivElement | null>;
  fullScreen: boolean;
  maximize: () => void;
  minimize: () => void;
  scrollToEnd: () => void;
  clearLogTerminal: () => void;
}

type LogsTerminalLog = { time: string; content: React.ReactNode };
type LogsTerminalProps = {
  loading: boolean;
  logs: Array<LogsTerminalLog>;
  serviceName?: string | React.ReactNode;
};

const LogTerminal: React.FC<React.PropsWithChildren<LogsTerminalProps>> = ({
  children,
  loading,
  logs,
  serviceName,
}) => {
  const { monitorRef, fullScreen, maximize, minimize } = useLogTerminal();
  return (
    <div
      className={cn("flex flex-col w-full bg-gray-100 dark:bg-dark-3", {
        "relative rounded-tl-xl rounded-tr-xl rounded-xl pb-1 h-[460px]":
          !fullScreen,
        "z-[2147483004] fixed top-0 left-0 h-screen overflow-y-auto":
          fullScreen,
      })}
    >
      {fullScreen && (
        <header className="py-6 px-10">
          <Flex items="center" justify="between">
            <ol className="antialiased font-sans font-normal text-sm text-gray-900 leading-6 list-none flex space-x-4 m-0 p-0">
              <li className="text-gray-400 after:content-['/'] after:ml-4">
                {serviceName}
              </li>
              <li>
                <Typography.Text size="sm">Logs</Typography.Text>
              </li>
            </ol>
            <Button
              tooltip={{ content: "Minimize", place: "bottom-end" }}
              onClick={minimize}
            >
              <ArrowsPointingInIcon className="w-4 aspect-square" />
            </Button>
          </Flex>
          <Typography.Text size="xl">Logs</Typography.Text>
        </header>
      )}
      <div className="border-b border-solid border-gray-300 dark:border-gray-800">
        {/* filters */}
        <div
          className={cn(
            "flex lg:space-x-4 justify-stretch max-lg:flex-wrap items-center",
            {
              "px-10 mb-7": fullScreen,
              "px-3 py-3": !fullScreen,
            }
          )}
        >
          <Flex className="!flex-1 !w-full">{children}</Flex>
          {!fullScreen && (
            <div className="max-lg:order-4 ml-2">
              <Button
                tooltip={{ content: "Maximize", place: "bottom-end" }}
                onClick={maximize}
              >
                <ArrowsPointingOutIcon className="w-4 aspect-square" />
              </Button>
            </div>
          )}
        </div>
      </div>
      <div
        className="flex flex-col flex-1 pb-4 overflow-y-scroll p-2"
        ref={monitorRef}
      >
        {!loading &&
          logs.map((log, index) => {
            return (
              <div
                key={index}
                className="antialiased font-mono font-normal text-xs leading-6 tracking-tight group relative space-x-4 hover:bg-gray-200 dark:hover:bg-black/10 py-1"
              >
                <time>
                  <Typography.Text
                    uiType="secondary"
                    size="sm"
                    className="!inline"
                  >
                    {new Date(log.time).toLocaleString()}{" "}
                    {/* Adjust date formatting as needed */}
                  </Typography.Text>
                </time>
                <Typography.Text
                  size="xs"
                  className="whitespace-pre-wrap break-all inline"
                >
                  {log.content}
                </Typography.Text>
              </div>
            );
          })}

        {/* empty log */}
        {logs.length === 0 && !loading && (
          <Flex items="center" justify="center" className="h-full px-4 w-full">
            <Flex
              direction="col"
              items="center"
              justify="center"
              className="gap-3 w-full"
            >
              <Typography.Text
                size="base"
                className="antialiased font-sans font-medium text-base leading-6"
              >
                No logs to show
              </Typography.Text>
              <Typography.Text
                size="sm"
                className="antialiased font-sans font-normal text-sm leading-6 max-w-md"
              >
                New log entries that match your search parameters will appear
                here.{" "}
              </Typography.Text>
            </Flex>
          </Flex>
        )}

        {/* loading */}
        {loading && (
          <div className="h-full flex items-center justify-center px-4">
            <Loading borderSize={2} />
          </div>
        )}

        {/* results count */}
        {/* <div className="antialiased font-mono font-normal text-xs leading-6 tracking-tight w-full border-dashed border-t border-gray-300 dark:border-gray-800 px-4 pt-4 mt-auto text-center">
            <Text>All 7 matching logs loaded</Text>
        </div> */}
      </div>
    </div>
  );
};

const LogTerminalContext = createContext<LogTerminalContextType | null>(null);

const LogTerminalProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const monitorRef = useRef<HTMLDivElement | null>(null);
  const [fullScreen, setFullScreen] = useState<boolean>(false);

  const maximize = useCallback(() => {
    setFullScreen(true);
  }, []);

  const minimize = useCallback(() => {
    setFullScreen(false);
  }, []);

  const scrollToEnd = useCallback(() => {
    if (monitorRef.current) {
      monitorRef.current.scrollTop = monitorRef.current.scrollHeight;
    }
  }, []);

  const clearLogTerminal = useCallback(() => {}, []);

  return (
    <LogTerminalContext.Provider
      value={{
        monitorRef,
        fullScreen,
        maximize,
        minimize,
        scrollToEnd,
        clearLogTerminal,
      }}
    >
      {children}
    </LogTerminalContext.Provider>
  );
};

const useLogTerminal = () => {
  const context = useContext(LogTerminalContext);
  if (!context)
    throw new Error("useLogTerminal must be used within LogTerminalProvider");
  return context;
};

export type { LogsTerminalLog };
export { LogTerminalProvider, useLogTerminal };
export default LogTerminal;
