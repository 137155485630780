import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import AccountProvider from "../../providers/AccountProvider";
import { useCallback, useEffect, useMemo } from "react";
import Header from "./Header";
import SearchProvider from "../../providers/SearchProvider";
import ConfettiExplosion from "react-confetti-explosion";
import { useAppDispatch, useAppSelector } from "../../hooks";
import {
  getMeAsync,
  selectConfettiStatus,
  selectOnStageEnv,
  selectUserLoading,
} from "../../store/auth/authSlice";
import {
  getServiceTypesAsync,
  selectServiceTypes,
  selectServiceTypesLoading,
} from "../../store/publicSlice";
import { ReactComponent as LogoTextIcon } from "./../../assets/icons/logo-text.svg";
import { ReactComponent as Logo } from "./../../assets/icons/logo.svg";

import { ReactComponent as StudiosIcon } from "./../../assets/icons/home-modern.svg";
import { ReactComponent as APIIcon } from "./../../assets/icons/rss.svg";
import { ReactComponent as StorageIcon } from "./../../assets/icons/server-stack.svg";
import { ReactComponent as DatabaseAndAnalyticsIcon } from "./../../assets/icons/chart-pie.svg";
import { ReactComponent as ComputeIcon } from "./../../assets/icons/cpu-chip.svg";

import { ReactComponent as SettingIcon } from "./../../assets/icons/setting.svg";
import { ReactComponent as CreditCardIcon } from "./../../assets/icons/credit-card.svg";
import { ReactComponent as BookIcon } from "./../../assets/icons/book-open.svg";
import { ReactComponent as LifebouyIcon } from "./../../assets/icons/lifebouy.svg";
import {
  PanelLayout as DjunoPanelLayout,
  PanelSidebar,
  Sidebar as DjunoSidebar,
  SidebarItem,
  PanelHeader as DjunoHeader,
  Typography,
  Flex,
  Tag,
  PanelLayoutRenderSidebarProps,
  PanelLayoutRenderHeaderProps,
  Loading,
  Alert,
} from "djuno-design";
import {
  BlockApiUrl,
  BlockEventNotificationsUrl,
  DataServicesUrl,
  HomeUrl,
  InstancesUrl,
  IpfsFilesUrl,
  MarketApiUrl,
  NftApiUrl,
  RpcEndpointsUrl,
  S3BucketsUrl,
  SettingsBillingUrl,
  SettingsProfileUrl,
  DomainsUrl,
  WalletApiUrl,
  Web3AuthEndpointsUrl,
  WebAppsUrl,
  WorkflowsUrl,
  DappStudioUrl,
  BlockchainsUrl,
  K8ServicesUrl,
} from "../../utils/urls";
import CustomLink from "../general/CustomLink";
import NotificationSocket from "../settings/notifications/NotificationSocket";
import { getNotificationsAsync } from "../../store/notifications/notificationsSlice";
import { IServiceType } from "../../types";

const mainMenu: (SidebarItem & { data?: any })[] = [
  {
    id: "endpoints",
    activeConditions: [
      {
        index: 0,
        value: "rpc-endpoints",
      },
    ],
    link: RpcEndpointsUrl,
    testId: "2th-sidebar",
    data: { serviceTypeId: process.env.REACT_APP_RPC_SERVICE_TYPE },
  },
  {
    id: "web-apps",
    activeConditions: [
      {
        index: 0,
        value: "web-apps",
      },
    ],
    link: WebAppsUrl,
    testId: "3th-sidebar",
    data: { serviceTypeId: process.env.REACT_APP_WEB_APPS_SERVICE_TYPE },
  },
  {
    id: "workflow-studio",
    activeConditions: [
      {
        index: 0,
        value: "workflow-studio",
      },
    ],
    link: WorkflowsUrl,
    testId: "4th-sidebar",
    data: { serviceTypeId: process.env.REACT_APP_WORKFLOW_SERVICE_TYPE },
  },
  {
    id: "dapp-studio",
    activeConditions: [
      {
        index: 0,
        value: "dapp-studio",
      },
    ],
    link: DappStudioUrl,
    testId: "5th-sidebar",
    data: { serviceTypeId: process.env.REACT_APP_DAPP_SERVICE_TYPE },
  },
  {
    id: "blockchain-studio",
    activeConditions: [
      {
        index: 0,
        value: "blockchains",
      },
    ],
    link: BlockchainsUrl,
    testId: "6th-sidebar",
    data: {
      serviceTypeId: process.env.REACT_APP_BLOCKCHAIN_STUDIO_SERVICE_TYPE,
    },
  },
  {
    id: "web3-auth",
    activeConditions: [
      {
        index: 0,
        value: "web3-auth",
      },
    ],
    link: Web3AuthEndpointsUrl,
    testId: "7th-sidebar",
    data: { serviceTypeId: process.env.REACT_APP_WEB3AUTH_SERVICE_TYPE },
  },
  {
    id: "wallet-api",
    activeConditions: [
      {
        index: 0,
        value: "wallet-api",
      },
    ],
    link: WalletApiUrl,
    testId: "8th-sidebar",
    data: { serviceTypeId: process.env.REACT_APP_WALLET_API_SERVICE_TYPE },
  },
  {
    id: "nft-api",
    activeConditions: [
      {
        index: 0,
        value: "nft-api",
      },
    ],
    link: NftApiUrl,
    testId: "9th-sidebar",
    data: { serviceTypeId: process.env.REACT_APP_NFT_API_SERVICE_TYPE },
  },
  {
    id: "market-api",
    activeConditions: [
      {
        index: 0,
        value: "market-api",
      },
    ],
    link: MarketApiUrl,
    testId: "10th-sidebar",
    data: { serviceTypeId: process.env.REACT_APP_MARKET_API_SERVICE_TYPE },
  },
  {
    id: "block-api",
    activeConditions: [
      {
        index: 0,
        value: "block-api",
      },
    ],
    link: BlockApiUrl,
    testId: "11th-sidebar",
    data: { serviceTypeId: process.env.REACT_APP_BLOCK_API_SERVICE_TYPE },
  },
  {
    id: "block-events",
    activeConditions: [
      {
        index: 0,
        value: "block-events",
      },
    ],
    link: BlockEventNotificationsUrl,
    testId: "12th-sidebar",
    data: { serviceTypeId: process.env.REACT_APP_BLOCK_EVENT_SERVICE_TYPE },
  },
  {
    id: "ipfs",
    activeConditions: [
      {
        index: 0,
        value: "ipfs",
      },
    ],
    link: IpfsFilesUrl,
    testId: "13th-sidebar",
    data: { serviceTypeId: process.env.REACT_APP_IPFS_SERVICE_TYPE },
  },
  {
    id: "s3",
    activeConditions: [
      {
        index: 0,
        value: "s3",
      },
    ],
    link: S3BucketsUrl,
    testId: "14th-sidebar",
    data: { serviceTypeId: process.env.REACT_APP_SIMPLE_STORAGE_SERVICE_TYPE },
  },
  {
    id: "database",
    activeConditions: [
      {
        index: 0,
        value: "database",
      },
    ],
    link: DataServicesUrl("operational"),
    testId: "15th-sidebar",
    data: { serviceTypeId: process.env.REACT_APP_DATABASES_SERVICE_TYPE },
  },
  {
    id: "instance",
    activeConditions: [
      {
        index: 0,
        value: "instances",
      },
    ],
    link: InstancesUrl,
    testId: "16th-sidebar",
    data: { serviceTypeId: process.env.REACT_APP_INSTANCES_SERVICE_TYPE },
  },
  {
    id: "domains",
    activeConditions: [
      {
        index: 0,
        value: "domains",
      },
    ],
    link: DomainsUrl,
    testId: "16th-sidebar",
    data: { serviceTypeId: process.env.REACT_APP_DOMAINS_SERVICE_TYPE },
  },
  {
    id: "data-streaming",
    activeConditions: [
      {
        index: 0,
        value: "data-streaming",
      },
    ],
    link: DataServicesUrl("streaming"),
    testId: "16th-sidebar",
    data: { serviceTypeId: process.env.REACT_APP_DATA_STREAMING_SERVICE_TYPE },
  },
  {
    id: "data-analysis",
    activeConditions: [
      {
        index: 0,
        value: "data-analysis",
      },
    ],
    link: DataServicesUrl("analysis"),
    testId: "17th-sidebar",
    data: { serviceTypeId: process.env.REACT_APP_DATA_ANALYSIS_SERVICE_TYPE },
  },
  {
    id: "managed-kubernetes",
    activeConditions: [
      {
        index: 0,
        value: "kubernetes-services",
      },
    ],
    link: K8ServicesUrl,
    testId: "managed-kubernetes",
    data: { serviceTypeId: process.env.REACT_APP_KUBERNETES_SERVICE_TYPE },
  },
];

const PanelLayout = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  //get user data
  useEffect(() => {
    dispatch(getMeAsync({}));
  }, [dispatch]);

  //get active service types
  useEffect(() => {
    dispatch(getServiceTypesAsync());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getNotificationsAsync({ withoutLoading: true }));
  }, [dispatch]);

  const confettiStatus = useAppSelector(selectConfettiStatus);
  const userLoading = useAppSelector(selectUserLoading);
  const serviceTypes = useAppSelector(selectServiceTypes);
  const serviceTypesLoading = useAppSelector(selectServiceTypesLoading);
  const onStageEnv = useAppSelector(selectOnStageEnv);

  const generalLoading = useMemo(() => {
    // return true;
    return userLoading || serviceTypesLoading;
  }, [serviceTypesLoading, userLoading]);

  const handleSidebarNavigation = useCallback(
    (item: SidebarItem | undefined) => {
      const route = `${item?.link}`;
      navigate(route);
    },
    [navigate]
  );

  const sidebarItems = useCallback(
    (hideSidebar: () => void) => {
      const _serviceTypes = [...serviceTypes];
      const serviceTypeItems = _serviceTypes
        .filter((st) => st.IsMenuItem)
        .filter((st) => st.IsActive)
        .sort((a, b) => a.OrdrIndx - b.OrdrIndx);

      // Group by ServiceGroupId
      const groupedItems = serviceTypeItems.reduce<
        Record<number, IServiceType[]>
      >((acc, serviceType) => {
        if (!acc[serviceType.ServiceGroupId]) {
          acc[serviceType.ServiceGroupId] = [];
        }
        acc[serviceType.ServiceGroupId].push(serviceType);
        return acc;
      }, {});

      let menuItems: SidebarItem[] = [];

      Object.entries(groupedItems).forEach(([groupId, services]) => {
        // Get the first item to extract group details
        const group = services[0];

        const children: SidebarItem[] = services
          .map((serviceTypeItem) => {
            const data = mainMenu.find(
              (i) =>
                i.data.serviceTypeId.toString() ===
                serviceTypeItem.Id.toString()
            );
            if (!data) return null;

            return {
              ...data,
              label: () => (
                <Flex items="center" justify="between" className="w-full gap-1">
                  {serviceTypeItem.ServiceName}
                  {serviceTypeItem.Tag && (
                    <Tag className="!text-xs" color="success" bordered={false}>
                      {serviceTypeItem.Tag}
                    </Tag>
                  )}
                </Flex>
              ),
              disabled: !serviceTypeItem.Enabled,
              onClick: (item: SidebarItem | undefined) => {
                hideSidebar();
                handleSidebarNavigation(item);
              },
            };
          })
          .filter(Boolean) as SidebarItem[];

        if (children.length > 0) {
          menuItems.push({
            id: `group-${groupId}`,
            label: group.ServiceGroupName,
            icon: SidebarGroupIcon(group.ServiceGroupId),
            children,
          });
        }
      });

      return menuItems;
    },
    [handleSidebarNavigation, serviceTypes]
  );

  const sidebarSubItems = useCallback(
    (hideSidebar: () => void): SidebarItem[] => {
      return [
        {
          id: "settings",
          label: "Settings",
          activeConditions: [
            {
              index: 0,
              value: "settings",
            },
            {
              index: 1,
              value: "profile",
            },
            {
              operator: "or",
              index: 1,
              value: "teams",
            },
            {
              operator: "or",
              index: 1,
              value: "registeries",
            },
            {
              operator: "or",
              index: 1,
              value: "notifications",
            },
          ],
          link: SettingsProfileUrl,
          icon: SettingIcon,
          onClick: (item: SidebarItem | undefined) => {
            handleSidebarNavigation(item);
            hideSidebar();
          },
          testId: "17th-sidebar",
        },
        {
          id: "billing",
          label: "Usage & Billing",
          activeConditions: [
            {
              index: 0,
              value: "settings",
            },
            {
              index: 1,
              value: "billing",
            },
          ],
          link: SettingsBillingUrl,
          icon: CreditCardIcon,
          onClick: (item: SidebarItem | undefined) => {
            handleSidebarNavigation(item);
            hideSidebar();
          },
          testId: "18th-sidebar",
          isVisible: onStageEnv?.IsAccountOwner,
        },
        {
          id: "documentation",
          label: "Documentation",
          activeConditions: [
            {
              index: 0,
              value: "documentation",
            },
          ],
          onClick: () => {
            hideSidebar();
            window.open(process.env.REACT_APP_DOCS_URL || "");
          },

          icon: BookIcon,
          testId: "19th-sidebar",
        },
        {
          id: "support",
          label: "Support",
          activeConditions: [
            {
              index: 0,
              value: "support",
            },
          ],
          disabled: false,
          icon: LifebouyIcon,
          onClick: () => {
            if (window.Intercom) {
              window.Intercom("showMessages");
              hideSidebar();
            }
          },
          testId: "20th-sidebar",
        },
      ];
    },
    [handleSidebarNavigation, onStageEnv]
  );

  return (
    <SearchProvider>
      <NotificationSocket />
      <div className="h-screen w-screen bg-white dark:bg-dark-1">
        <DjunoPanelLayout
          type="normal"
          pathname={location.pathname}
          renderSidebar={(props: PanelLayoutRenderSidebarProps) => {
            return (
              <PanelSidebar
                {...props}
                sidebarHeader={
                  <Link to={HomeUrl} className="px-4">
                    <LogoTextIcon className="text-black/60 dark:text-gray-300 h-9" />
                  </Link>
                }
              >
                <DjunoSidebar
                  type={props.type}
                  items={() => sidebarItems(props.handleHideSidebar)}
                  segments={props.segments}
                  subItems={() => sidebarSubItems(props.handleHideSidebar)}
                  navItemHeight={38}
                />
              </PanelSidebar>
            );
          }}
          renderHeader={(props: PanelLayoutRenderHeaderProps) => {
            return (
              <DjunoHeader
                {...props}
                mobileIcon={
                  <CustomLink to={HomeUrl}>
                    <Logo className="w-[25px] h-[25px] text-[#0074E4]" />
                  </CustomLink>
                }
              >
                <Header />
              </DjunoHeader>
            );
          }}
          globalLoading={generalLoading}
          globalLoadingContent={<GlobalLoadingContent />}
          enableGoToTopAfterScroll={false}
        >
          {confettiStatus && (
            <div className="absolute top-0 left-0 bottom-0 right-0 w-full h-full flex justify-center items-center -translate-y-44">
              <ConfettiExplosion
                force={0.4}
                duration={2500}
                particleCount={100}
                width={850}
              />
            </div>
          )}
          <div className="max-w-7xl mx-auto min-h-full flex flex-col">
            <AccountProvider>
              {onStageEnv &&
                onStageEnv.EnvironmentStatus === 4 &&
                onStageEnv.IsAccountOwner &&
                !onStageEnv.HaveCardAttempt && (
                  <Alert
                    uiType="warning"
                    className="!text-xs !mb-3"
                    closable
                    showIcon
                    banner
                    message={
                      <Typography.Text className="!text-sm">
                        No payment method has been set up yet, which may limit
                        your access to the platform. To enjoy full access,{" "}
                        <CustomLink
                          to={SettingsBillingUrl + "#Payment Method"}
                          className="!text-primary-500 dark:!text-primary-100 font-medium"
                        >
                          add a payment method
                        </CustomLink>
                        .
                      </Typography.Text>
                    }
                  />
                )}
              <Outlet />
            </AccountProvider>
          </div>
        </DjunoPanelLayout>
      </div>
    </SearchProvider>
  );
};

export const GlobalLoadingContent = () => {
  return (
    <Flex direction="col" items="center" className="gap-4">
      <Flex direction="col" items="center" className="gap-2">
        <Logo className="w-20 h-20" />
        <Typography.Text size="sm" className="!font-semibold">
          Djuno Cloud
        </Typography.Text>
      </Flex>
      <Flex items="center" className="gap-1">
        <Loading borderSize={2} uiSize={15} />
        <Typography.Text size="sm">Loading data...</Typography.Text>
      </Flex>
    </Flex>
  );
};

export const SidebarGroupIcon = (group: string | number) => {
  switch (group) {
    case "Studios":
    case 1:
      return StudiosIcon;
    case "API":
    case 2:
      return APIIcon;
    case "Storage":
    case 3:
      return StorageIcon;
    case "Databases  & Analytics":
    case 4:
      return DatabaseAndAnalyticsIcon;
    case "Compute":
    case 5:
      return ComputeIcon;
  }
};
export default PanelLayout;

// const sidebarItems = useMemo(() => {
//   const _serviceTypes = [...serviceTypes];
//   const serviceTypeItems = _serviceTypes
//     .filter((st) => st.IsMenuItem)
//     .filter((st) => st.IsActive)
//     .sort((a, b) => a.OrdrIndx - b.OrdrIndx);

//   let menuItems: (SidebarItem & { data?: any })[] = [];
//   serviceTypeItems.forEach((serviceTypeItem) => {
//     const data = mainMenu.find(
//       (i) => i.data.serviceTypeId.toString() === serviceTypeItem.Id.toString()
//     );
//     if (!data) return;
//     menuItems.push({
//       ...data,
//       label: () => (
//         <Flex items="center" justify="between" className="w-full gap-1">
//           {serviceTypeItem.ServiceName}
//           {serviceTypeItem.Tag && (
//             <Tag className="!text-xs" color="success" bordered={false}>
//               {serviceTypeItem.Tag}
//             </Tag>
//           )}
//         </Flex>
//       ),
//       disabled: !serviceTypeItem.Enabled,
//       // onClick: handleSidebarNavigation,
//     });
//   });
//   return [...menuItems]; //homeItem,
// }, [serviceTypes]);
