import { Button, Flex, Skeleton, Tabs, Typography } from "djuno-design";
import { Helmet } from "react-helmet";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ReactComponent as RightArrow } from "../../../assets/icons/arrow-right.svg";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import {
  createDSSConnectorAsync,
  getDSSCapabilitiesConnectorAsync,
  getDSSCapabilitiesConnectorsAsync,
  getDSSConnectorAsync,
  getDSSConnectorsConfigurationAsync,
  getDSSConnectorTransformsAsync,
  selectDatabaseService,
  selectDSSCapabilitiesConnector,
  selectDSSCapabilitiesConnectorLoading,
  selectDSSCapabilitiesConnectors,
  selectDSSConnector,
  selectDSSConnectorLoading,
  selectDSSConnectorsConfiguration,
  updateDSSConnectorAsync,
} from "../../../store/database/serviceSlice";
import { useEffect, useMemo, useState } from "react";
import { ConnectorConfiguration } from "../../../types/database";
import { DataServiceConnectorsUrl } from "../../../utils/urls";
import ConnectorTab from "../../../components/data-streaming/connectors/ConnectorTab";

export type GroupType = {
  title: string;
  parameters: ConnectorConfiguration[];
};

export interface ConnectorTabProps {
  group?: GroupType;
  configData?: Record<string, any>;
  onChange?: (group: string, paramName: string, value: any) => void;
}

export interface GroupedConfiguration {
  [group: string]: {
    title: string;
    parameters: ConnectorConfiguration[];
    hasRequiredField: boolean;
  };
}

const ConnectorPage = () => {
  const [configData, setConfigData] = useState<Record<string, string>>({});

  const [selectedTab, setSelectedTab] = useState(0);
  const { connectorId } = useParams();
  const service = useAppSelector(selectDatabaseService);
  const capabilitiesConnectors = useAppSelector(
    selectDSSCapabilitiesConnectors
  );
  const capabilitiesConnector = useAppSelector(selectDSSCapabilitiesConnector);
  const capabilitiesConnectorLoading = useAppSelector(
    selectDSSCapabilitiesConnectorLoading
  );
  const connector = useAppSelector(selectDSSConnector);
  const connectorLoading = useAppSelector(selectDSSConnectorLoading);

  const configuration = useAppSelector(selectDSSConnectorsConfiguration);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const isEditMode = location.pathname.includes("edit");

  useEffect(() => {
    !isEditMode &&
      configuration.length === 0 &&
      connectorId &&
      service &&
      dispatch(
        getDSSConnectorsConfigurationAsync({
          engine: service?.engine,
          clusterId: service?.id,
          connectorId,
        })
      );
    !isEditMode &&
      connectorId &&
      service &&
      dispatch(
        getDSSCapabilitiesConnectorAsync({
          engine: service.engine,
          clusterId: service.id,
          connectorId,
        })
      );
    !isEditMode &&
      connectorId &&
      service &&
      dispatch(
        getDSSConnectorTransformsAsync({
          engine: service.engine,
          clusterId: service.id,
          connectorId,
        })
      );
  }, [isEditMode, service, connectorId, configuration.length, dispatch]);

  useEffect(() => {
    if (!connector || !service) return;

    if (isEditMode && configuration.length === 0) {
      dispatch(
        getDSSConnectorsConfigurationAsync({
          engine: service.engine,
          clusterId: service.id,
          connectorId: connector.connectorId,
        })
      );
    }

    dispatch(
      getDSSCapabilitiesConnectorAsync({
        engine: service.engine,
        clusterId: service.id,
        connectorId: connector.connectorId,
      })
    );

    dispatch(
      getDSSConnectorTransformsAsync({
        engine: service.engine,
        clusterId: service.id,
        connectorId: connector.connectorId,
      })
    );
  }, [
    service?.engine,
    service?.id,
    connector?.connectorId,
    configuration.length,
    isEditMode,
    dispatch,
  ]);

  useEffect(() => {
    if (!connectorId) return;
    if (!isEditMode) {
      setConfigData({});
    } else if (connector && connector.configuration) {
      const formattedConfig = formatPayload(connector.configuration);
      setConfigData(formattedConfig);
    }
  }, [connectorId, isEditMode, connector]);

  const groupedConfig: GroupedConfiguration = useMemo(() => {
    return configuration.reduce<GroupedConfiguration>((acc, item) => {
      const groupTitle = item.group || "Default Group";
      if (!acc[groupTitle]) {
        acc[groupTitle] = {
          title: groupTitle,
          parameters: [],
          hasRequiredField: false,
        };
      }
      acc[groupTitle].parameters.push(item);

      if (item.required) {
        acc[groupTitle].hasRequiredField = true;
      }

      return acc;
    }, {});
  }, [configuration]);

  // console.log("groupedConfigtest", groupedConfig);

  const handleConfigurationChange = (
    group: string,
    paramName: string,
    value: any
  ) => {
    setConfigData((prev) => ({
      ...prev,
      [paramName]: value,
    }));

    // console.log("Config Data:", configData);
  };

  // console.log("Updated configData:", configData);

  const tabOptions = Object.values(groupedConfig).map((group) => ({
    label: (
      <div className="flex items-center gap-2">
        {group.title}
        {group.hasRequiredField && (
          <Typography.Text className="!text-2xl !text-red-700" color="warning">
            *
          </Typography.Text>
        )}
      </div>
    ),
    element: (
      <ConnectorTab
        group={group}
        configData={configData}
        setConfigData={setConfigData}
        onChange={handleConfigurationChange}
      />
    ),
  }));

  const handleTabChange = ({ index }: { index?: number }) => {
    if (index !== undefined) {
      // console.log(`Selected tab index: ${index}`);
      setSelectedTab(index);
    }
  };

  const formatPayload = (
    configData: Record<string, string | Record<string, string>>
  ): Record<string, string> => {
    const formattedData: Record<string, string> = {};

    Object.entries(configData).forEach(([key, value]) => {
      // Check if value is an object and format accordingly
      if (
        typeof value === "object" &&
        Object.keys(value).every((k) => !isNaN(Number(k))) // Ensure keys are numbers
      ) {
        formattedData[key] = Object.values(value).join(""); // Convert to string
      } else if (typeof value === "string") {
        formattedData[key] = value; // Keep as string
      }
    });

    return formattedData;
  };

  const handleSubmitForm = () => {
    if (!service) {
      return;
    }

    const formattedConfigData = formatPayload(configData);

    const formdata = {
      engine: service.engine,
      id: service.id,
      data: {
        configuration: formattedConfigData,
        name: formattedConfigData["name"],
        connectorId,
      },
    };
    const updateFormdata = {
      engine: service.engine,
      clusterId: service.id,
      connectorId: connector?.id ?? "",
      data: {
        configuration: formattedConfigData,
      },
    };
    if (connector) {
      dispatch(updateDSSConnectorAsync(updateFormdata)).then((action) => {
        if (action.type === "service/connector/update/fulfilled") {
          navigate(DataServiceConnectorsUrl(service.engine, service.id));
        }
      });
    } else {
      dispatch(createDSSConnectorAsync(formdata)).then((action) => {
        if (action.type === "service/connector/create/fulfilled") {
          dispatch(
            getDSSCapabilitiesConnectorsAsync({
              engine: service.engine,
              clusterId: service.id,
            })
          );
          dispatch(
            getDSSCapabilitiesConnectorsAsync({
              engine: service.engine,
              clusterId: service.id,
            })
          );
          navigate(DataServiceConnectorsUrl(service.engine, service.id));

          setConfigData({});
        }
      });
    }
  };

  useEffect(() => {
    isEditMode &&
      connectorId &&
      service &&
      dispatch(
        getDSSConnectorAsync({
          engine: service?.engine,
          clusterId: service?.id,
          connectorId,
        })
      );
  }, [connectorId, dispatch, isEditMode, service]);

  const isFormValid = useMemo(() => {
    return Object.values(groupedConfig).every((group) => {
      return (
        !group.hasRequiredField ||
        group.parameters.every((param) => {
          if (isEditMode && param.name === "name") {
            return true;
          }

          return (
            !param.required ||
            (configData[param.name] && configData[param.name].trim() !== "")
          );
        })
      );
    });
  }, [groupedConfig, configData, isEditMode]);

  return (
    <>
      <Helmet>
        <title>
          {process.env.REACT_APP_NAME} | Database (
          {capabilitiesConnector ? capabilitiesConnector.name : ""})
        </title>
        <meta
          name="description"
          content="Deploy an option from our range of instances, and harness the flexibility of the cloud to grow in a way that suits your needs."
        />
      </Helmet>
      <Flex items="center" justify="between" className="h-20">
        <Flex items="center" justify="between" className="px-6 flex-1">
          <Flex items="center" className="gap-2">
            <RightArrow
              onClick={() =>
                service &&
                navigate(DataServiceConnectorsUrl(service.engine, service.id))
              }
              className="rotate-180 w-5 h-5 hover:scale-110 transition-all duration-500 text-slate-800 dark:text-slate-100 cursor-pointer"
            />
            {capabilitiesConnector && !capabilitiesConnectorLoading && (
              <div className="flex flex-col">
                <Typography.Title level={5} className="!mb-0">
                  {capabilitiesConnector.name}
                </Typography.Title>
                <Typography.Text className="!text-xs !mb-0">
                  Version: {capabilitiesConnector.version} | Author:{" "}
                  {capabilitiesConnector.author}
                </Typography.Text>
              </div>
            )}
            {capabilitiesConnectorLoading && (
              <Skeleton shape="rectangle" style={{ width: 200, height: 30 }} />
            )}
          </Flex>
          <Flex>
            <Button
              uiType="primary"
              onClick={handleSubmitForm}
              disabled={!isFormValid}
            >
              {connector && isEditMode
                ? "Modify the connector"
                : "Add a connector"}
            </Button>
          </Flex>
        </Flex>
      </Flex>
      <div className="px-6">
        <Tabs
          options={tabOptions}
          selectedIndex={selectedTab}
          onChange={handleTabChange}
          listClassName="custom-tab-list"
          panelClassName="custom-tab-panel"
          tabType="default"
        />
      </div>
    </>
  );
};

export default ConnectorPage;
