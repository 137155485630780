import { ReactComponent as InboxIcon } from "./../../assets/icons/inbox.svg";
// import { ReactComponent as EmptyState } from "./../../assets/icons/empty-state.svg";
import { ReactComponent as CubeIcon } from "./../../assets/icons/cube.svg";
import { ReactComponent as Square3StackIcon } from "./../../assets/icons/square-3stack.svg";
import { ReactComponent as SettingIcon } from "./../../assets/icons/setting.svg";
import { ReactComponent as NoData } from "./../../assets/icons/exclamation-triangle-icon.svg";
import { ReactComponent as WorkflowIcon } from "./../../assets/icons/workflow.svg";
import { ReactComponent as CubeTransparentIcon } from "./../../assets/icons/cube-transparent.svg";
import { ReactComponent as GlobIcon } from "./../../assets/icons/glob-alt.svg";
import { ReactComponent as CommandPaletteIcon } from "./../../assets/icons/command-line.svg";

import { Helmet } from "react-helmet";
import { Button, cn, EmptyState, Flex, Typography } from "djuno-design";
import { useAppDispatch, useAppSelector } from "../../hooks";
import {
  activateIpfsAsync,
  selectIpfsActionLoading,
  selectIpfsNodeStatus,
  selectIpfsStatusMessage,
} from "../../store/ipfs/ipfsPublicSlice";
import {
  activateS3Async,
  selectS3ActionLoading,
  selectS3NodeStatus,
  selectS3StatusMessage,
} from "../../store/s3/s3PublicSlice";
import useServiceType from "../../hooks/useServiceType";
import {
  activateWorkflowAsync,
  selectWorkflowNodeStatus,
  selectWorkflowsActionLoading,
} from "../../store/workflows/workflowsSlice";

interface NotExistProps {
  text?: string;
  buttonText?: string;
  secondButtonText?: string;
  url?: () => void;
  secondUrl?: () => void;
}

const NotData = ({
  text,
  icon,
  className,
  iconClassName,
  textClassName,
}: {
  text?: string | React.ReactNode;
  icon?: React.ReactNode;
  className?: string;
  iconClassName?: string;
  textClassName?: string;
}) => {
  return (
    <div
      className={cn(
        "w-full min-h-[150px] flex flex-col gap-1 justify-center items-center  text-slate-400 dark:text-gray-600",
        {
          [className || ""]: className,
        }
      )}
    >
      {icon || <InboxIcon className={cn("w-14", iconClassName)} />}
      <span className={cn("text-sm font-medium", textClassName)}>
        {text || "No data"}
      </span>
    </div>
  );
};

export const EmptyFileList = () => {
  return (
    <Flex
      direction="col"
      items="center"
      justify="center"
      className="border-0 w-full p-16 text-center gap-2 text-slate-400 dark:text-gray-600"
    >
      <Flex direction="col" items="center">
        <InboxIcon className="w-14" />
        <Typography.Text uiType="secondary" className="!text-xl !font-medium">
          Drop files here
        </Typography.Text>
      </Flex>
      <p className="text-sm text-slate-400">Or use “Upload file” button.</p>
    </Flex>
  );
};

export const EmptyList = ({ text }: { text?: string | React.ReactNode }) => {
  return (
    <div className="text-sm dark:text-slate-500 text-slate-600 px-4 py-6 w-full text-center font-semibold">
      {text}
    </div>
  );
};

export const NotActiveTab: React.FC<
  React.PropsWithChildren<{
    text?: string | React.ReactElement;
    icon?: React.ReactElement;
  }>
> = ({ text, icon, children }) => {
  return (
    <Flex
      direction="col"
      items="center"
      justify="center"
      className="w-full min-h-[300px]"
    >
      {icon}
      <Typography.Text className="!px-4 !py-4 !w-full !text-center">
        {text}
      </Typography.Text>
      {children}
    </Flex>
  );
};

export const IpfsNotActiveTab = () => {
  const message = useAppSelector(selectIpfsStatusMessage);
  const { serviceType } = useServiceType(
    process.env.REACT_APP_IPFS_SERVICE_TYPE
  );
  const ipfsStatus = useAppSelector(selectIpfsNodeStatus);
  const loading = useAppSelector(selectIpfsActionLoading);

  const dispatch = useAppDispatch();
  if (ipfsStatus === 5) {
    return (
      <NotActiveTab
        icon={<CubeIcon className="w-14 text-primary-400 " />}
        text={
          <div className="flex flex-col gap-2 items-center justify-center">
            <Typography.Text className="!text-sm !mb-0 ">
              Managed Ipfs server, basic "pay as you go" plan for free
            </Typography.Text>

            <div className="flex mt-5">
              <Button
                uiType="primary"
                onClick={() => dispatch(activateIpfsAsync({ isActive: true }))}
                loading={loading}
              >
                Activate
              </Button>
            </div>
          </div>
        }
      />
    );
  }

  if (ipfsStatus === -1 || ipfsStatus === 3) {
    return <CreationErrorTab />;
  }

  return (
    <NotActiveTab
      icon={<CubeIcon className="w-14 text-primary-400 animate-bounce" />}
      text={
        message ||
        `We are processing your ${
          serviceType ? serviceType.ServiceName : "service"
        }. Please wait...`
      }
    />
  );
};

export const S3NotActiveTab = () => {
  const message = useAppSelector(selectS3StatusMessage);
  const { serviceType } = useServiceType(
    process.env.REACT_APP_SIMPLE_STORAGE_SERVICE_TYPE
  );
  const s3Status = useAppSelector(selectS3NodeStatus);
  const loading = useAppSelector(selectS3ActionLoading);
  const dispatch = useAppDispatch();

  if (s3Status === 5) {
    return (
      <NotActiveTab
        icon={<Square3StackIcon className="w-14 text-primary-400" />}
        text={
          <div className="flex flex-col gap-2 items-center justify-center">
            <Typography.Text className="!text-sm !mb-0 ">
              Start object storage, basic "pay as you go" plan for free
            </Typography.Text>

            <div className="flex mt-5">
              <Button
                uiType="primary"
                onClick={() => dispatch(activateS3Async({ isActive: true }))}
                loading={loading}
              >
                Activate
              </Button>
            </div>
          </div>
        }
      />
    );
  }

  if (s3Status === -1 || s3Status === 3) {
    return <CreationErrorTab />;
  }

  return (
    <NotActiveTab
      icon={
        <Square3StackIcon className="w-14 text-primary-400 animate-bounce" />
      }
      text={
        message ||
        `We are processing your ${
          serviceType ? serviceType.ServiceName : "service"
        }. Please wait...`
      }
    />
  );
};

export const WorkflowNotActiveTab = () => {
  const workflowStatus = useAppSelector(selectWorkflowNodeStatus);
  const loading = useAppSelector(selectWorkflowsActionLoading);
  const dispatch = useAppDispatch();

  if (workflowStatus === 5) {
    return (
      <NotActiveTab
        icon={<WorkflowIcon className="w-14 text-primary-400 " />}
        text={
          <div className="flex flex-col gap-2 items-center justify-center">
            <Typography.Text className="!text-sm !mb-0 ">
              Start Workflow studio basic "pay as you go" plan for free
            </Typography.Text>

            <div className="flex mt-5">
              <Button
                uiType="primary"
                onClick={() =>
                  dispatch(activateWorkflowAsync({ isActive: true }))
                }
                loading={loading}
              >
                Activate
              </Button>
            </div>
          </div>
        }
      />
    );
  }

  if (workflowStatus === -1 || workflowStatus === 3) {
    return <CreationErrorTab />;
  }

  return (
    <NotActiveTab
      icon={<WorkflowIcon className="w-14 text-primary-400 animate-bounce" />}
      text="We are processing your workflow. Please wait..."
    />
  );
};

export const RpcNotActiveTab = () => {
  return (
    <NotActiveTab
      icon={
        <CubeTransparentIcon className="w-14 text-primary-400 animate-bounce" />
      }
      text="We are processing your RPC. Please wait..."
    />
  );
};

export const WebAppNotActiveTab = () => {
  return (
    <NotActiveTab
      icon={<GlobIcon className="w-14 text-primary-400 animate-bounce" />}
      text="We are processing your web app. Please wait..."
    />
  );
};

export const DomainNotActiveTab = () => {
  return (
    <NotActiveTab
      icon={
        <CubeTransparentIcon className="w-14 text-primary-400 animate-bounce" />
      }
      text="We are processing your domain. Please wait..."
    />
  );
};

export const DappNotActiveTab = () => {
  const { serviceType } = useServiceType(
    process.env.REACT_APP_DAPP_SERVICE_TYPE
  );
  return (
    <NotActiveTab
      icon={
        <CommandPaletteIcon className="w-14 text-primary-400 animate-bounce" />
      }
      text={`We are processing your ${
        serviceType ? serviceType.ServiceName : "service"
      }. Please wait...`}
    />
  );
};

export const BlockchainNotActiveTab = () => {
  const { serviceType } = useServiceType(
    process.env.REACT_APP_BLOCKCHAIN_STUDIO_SERVICE_TYPE
  );
  return (
    <NotActiveTab
      icon={<GlobIcon className="w-14 text-primary-400 animate-bounce" />}
      text={`We are processing your  ${
        serviceType ? serviceType.ServiceName : "service"
      }. Please wait...`}
    />
  );
};

export const NotExist: React.FC<NotExistProps> = ({
  text,
  buttonText,
  secondButtonText,
  url,
  secondUrl,
}) => {
  return (
    <NotActiveTab
      icon={<NoData className="w-14 " />}
      text={
        <div className="flex flex-col gap-2 items-center justify-center">
          <Typography.Title level={4} className="!mb-0">
            404
          </Typography.Title>
          <Typography.Text size="sm">
            {text || "This is not the web page you are looking for"}
          </Typography.Text>
          <div className="flex gap-4">
            {url ? (
              <Button onClick={url}>{buttonText || "Go Back"}</Button>
            ) : null}
            {secondUrl ? (
              <Button uiType="primary" onClick={secondUrl}>
                {secondButtonText || "Dashboard"}
              </Button>
            ) : null}
          </div>
        </div>
      }
    />
  );
};

export const WorkingPage: React.FunctionComponent<{ title?: string }> = ({
  title,
}) => {
  return (
    <>
      <Helmet>
        <title>
          {process.env.REACT_APP_NAME} {title ? `| ${title}` : ""}
        </title>
        <meta name="description" content={title} />
      </Helmet>
      <div className="w-full h-[400px] flex items-center justify-center">
        <div className="flex flex-col items-center gap-1">
          <SettingIcon className="w-11 aspect-square animate-spin-slow text-primary-400" />
          <Typography.Text
            uiType="secondary"
            className="!text-primary-400 font-medium"
          >
            Under Construction
          </Typography.Text>
        </div>
      </div>
    </>
  );
};

export const EmptyLineChart: React.FC<{ className?: string }> = ({
  className,
}) => (
  <Flex justify="center" items="center" className="w-full h-full">
    <EmptyState
      text="There is no data to display"
      usingIcon={true}
      className={cn("w-full h-full rounded-lg", className)}
    />
  </Flex>
);

export const CreationErrorTab = () => {
  return (
    <NotActiveTab
      icon={<CubeTransparentIcon className="w-14 text-primary-400" />}
      text="Something went wrong, contact support..."
    >
      {window.Intercom && (
        <Button
          onClick={() => {
            if (window.Intercom) window.Intercom("showMessages");
          }}
        >
          Contact
        </Button>
      )}
    </NotActiveTab>
  );
};

export default NotData;
