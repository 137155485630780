import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { isAxiosError } from "axios";
import toast from "react-hot-toast";
import { IThunkRejectValue, RootState } from "../../../../types";
import { S3Lifecycle, S3LifecycleState } from "../../../../types/s3-lifecycle";
import {
  createBucketLifecycleApi,
  updateBucketLifecycleApi,
  createMultiBucketLifecycleApi,
  deleteBucketLifecyclesApi,
  getBucketLifecyclesApi,
} from "../../../../apis/s3/lifecycleAPI";
import { changeS3NodeStatus, S3UpdatingMessage } from "../../s3PublicSlice";
import { getExtractErrors } from "../../../../apis";
import { CustomErrorToast } from "../../../../components/general/Toast";

const initialState: S3LifecycleState = {
  lifecycles: [],
  showLifecycleEditor: false,
  lifecycleLoading: false,
  lifecycleActionLoading: false,
  tierLoading: false,
  selectedLifecycle: null,
};

export const getBucketLifecyclesAsync = createAsyncThunk<
  { lifecycles: S3Lifecycle[] },
  { name: string },
  IThunkRejectValue
>(
  "bucket/lifecycles",
  async ({ name }, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
      const response = await getBucketLifecyclesApi(name);

      const { Result, NodeStatus } = response.data;
      const lifecycles = Result || [];

      dispatch(changeS3NodeStatus({ status: NodeStatus }));

      return fulfillWithValue({ lifecycles });
    } catch (e) {
      if (isAxiosError(e)) {
        dispatch(
          changeS3NodeStatus({
            status: e.response?.data.NodeStatus || 4,
            message: S3UpdatingMessage,
          })
        );
        return fulfillWithValue({ lifecycles: [] });
      } else {
        return rejectWithValue({ message: getExtractErrors(e) });
      }
    }
  }
);

export const createBucketLifecycleAsync = createAsyncThunk<
  any,
  {
    bucketName: string;
    data: any;
  },
  IThunkRejectValue
>(
  "bucket/lifecycles/create",
  async ({ bucketName, data }, { rejectWithValue }) => {
    try {
      const responce = await createBucketLifecycleApi(bucketName, data);
      return responce.data;
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);

export const updateBucketLifecycleAsync = createAsyncThunk<
  any,
  {
    bucketName: string;
    id: string;
    data: any;
  },
  IThunkRejectValue
>(
  "bucket/lifecycles/update",
  async ({ bucketName, id, data }, { rejectWithValue }) => {
    try {
      const responce = await updateBucketLifecycleApi(bucketName, id, data);
      return responce.data;
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);

export const createMultiBucketLifecycleAsync = createAsyncThunk<
  any,
  {
    data: any;
  },
  IThunkRejectValue
>(
  "bucket/multilifecycles/create",
  async ({ data }, { rejectWithValue, fulfillWithValue }) => {
    try {
      const result = await createMultiBucketLifecycleApi(data);
      return fulfillWithValue({ buckets: result.data.Result.results });
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);

export const deleteBucketLifecycleAsync = createAsyncThunk<
  any,
  {
    bucketName: string;
    id: string;
  },
  IThunkRejectValue
>(
  "bucket/lifecycle/delete",
  async ({ bucketName, id }, { rejectWithValue }) => {
    try {
      const responce = await deleteBucketLifecyclesApi(bucketName, id);
      return responce.data;
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);

export const lifecycleSlice = createSlice({
  name: "bucket-lifecycle",
  initialState,
  reducers: {
    handleShowLifecycleEditor: (
      state,
      action: { payload: { lifecycle?: S3Lifecycle } }
    ) => {
      state.showLifecycleEditor = true;
      if (typeof action.payload.lifecycle !== "undefined") {
        state.selectedLifecycle = action.payload.lifecycle;
      }
    },
    handleHideLifecycleEditor: (state) => {
      state.showLifecycleEditor = false;
      state.selectedLifecycle = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBucketLifecyclesAsync.pending, (state) => {
        state.lifecycleLoading = true;
      })
      .addCase(getBucketLifecyclesAsync.fulfilled, (state, action) => {
        state.lifecycles = action.payload.lifecycles;
        state.lifecycleLoading = false;
      })
      .addCase(getBucketLifecyclesAsync.rejected, (state, { payload }) => {
        state.lifecycleLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(createBucketLifecycleAsync.pending, (state) => {
        state.lifecycleActionLoading = true;
      })
      .addCase(createBucketLifecycleAsync.fulfilled, (state) => {
        state.lifecycleActionLoading = false;
      })
      .addCase(createBucketLifecycleAsync.rejected, (state, { payload }) => {
        state.lifecycleActionLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(updateBucketLifecycleAsync.pending, (state) => {
        state.lifecycleActionLoading = true;
      })
      .addCase(updateBucketLifecycleAsync.fulfilled, (state) => {
        state.lifecycleActionLoading = false;
      })
      .addCase(updateBucketLifecycleAsync.rejected, (state, { payload }) => {
        state.lifecycleActionLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(createMultiBucketLifecycleAsync.pending, (state) => {
        state.lifecycleActionLoading = true;
      })
      .addCase(
        createMultiBucketLifecycleAsync.fulfilled,
        (state, { payload }) => {
          state.lifecycleActionLoading = false;
        }
      )
      .addCase(
        createMultiBucketLifecycleAsync.rejected,
        (state, { payload }) => {
          state.lifecycleActionLoading = false;
          if (payload?.message)
            toast.error(() => CustomErrorToast(payload?.message));
        }
      );

    builder
      .addCase(deleteBucketLifecycleAsync.pending, (state) => {
        state.lifecycleActionLoading = true;
      })
      .addCase(deleteBucketLifecycleAsync.fulfilled, (state) => {
        state.lifecycleActionLoading = false;
      })
      .addCase(deleteBucketLifecycleAsync.rejected, (state, { payload }) => {
        state.lifecycleActionLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });
  },
});

export const selectBucketLifecycles = (state: RootState) =>
  state.s3Lifecycle.lifecycles;
export const selectBucketLifecyclesLoading = (state: RootState) =>
  state.s3Lifecycle.lifecycleLoading;
export const selectBucketLifecycleActionLoading = (state: RootState) =>
  state.s3Lifecycle.lifecycleActionLoading;
export const selectShowBucketLifecycleEditor = (state: RootState) =>
  state.s3Lifecycle.showLifecycleEditor;
export const selectSelectedLifecycle = (state: RootState) =>
  state.s3Lifecycle.selectedLifecycle;

export const { handleShowLifecycleEditor, handleHideLifecycleEditor } =
  lifecycleSlice.actions;
export default lifecycleSlice.reducer;
