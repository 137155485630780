import { useNavigate, Outlet, useLocation } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import { useEffect } from "react";
import { useAppDispatch } from "./hooks";
import { syncState } from "./store/auth/authSlice";
import { ToastClasses } from "./components/modals/alerts";
import { DDProvider } from "djuno-design";
import TourProvider from "./providers/TourProvider";
import IntercomProvider from "./providers/IntercomProvider";
import ReactGA from "react-ga4";

ReactGA.initialize([
  {
    trackingId: process.env.REACT_APP_GTAG || "",
    gaOptions: {},
    gtagOptions: {},
  },
]);

function App() {
  const dispatch = useAppDispatch();
  const navigator = useNavigate();
  const location = useLocation();
  useEffect(() => {
    dispatch(syncState());
  }, [dispatch]);

  useEffect(() => {
    if (process.env.REACT_APP_GTAG)
      ReactGA.send({
        hitType: "pageview",
        page: location.pathname + location.search,
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  return (
    <IntercomProvider>
      <DDProvider navigator={(url) => url && navigator(url)}>
        <TourProvider>
          <Outlet />
          <Toaster
            toastOptions={{
              className: ToastClasses,
            }}
          />
        </TourProvider>
      </DDProvider>
    </IntercomProvider>
  );
}

export default App;
