import { Outlet, useNavigate, useParams } from "react-router-dom";
import {
  DataServiceGeneralInformationUrl,
  DataServiceUsersUrl,
  DataServiceAclUrl,
  DataServiceDatabasesUrl,
  DataServiceAuthorisedIPsUrl,
  DataServiceQueryStatisticsUrl,
  DataServiceCurrentQueriesUrl,
  DataServicePoolsUrl,
  DataServiceLogsUrl,
  DataServiceMetricsUrl,
  DataServiceTopicsUrl,
  DataServiceIntegrationsUrl,
  DataServiceReplicationFlowsUrl,
  DataServiceConnectorsUrl,
  DataServiceBackupsUrl,
  DataServiceAdvancedConfigurationsUrl,
  DataServiceNamespacesUrl,
  DataServicesUrl,
  DataServiceIndexesUrl,
} from "../../utils/urls";
import { ReactComponent as RightArrow } from "./../../assets/icons/arrow-right.svg";
import { useEffect, useMemo } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { Helmet } from "react-helmet";
import { selectLoading } from "../../store/rpc/servicesSlice";
import {
  getDatabaseServiceAsync,
  handleClearServiceSlice,
  selectDatabaseService,
} from "../../store/database/serviceSlice";
import {
  Skeleton,
  TabOption,
  TabOptions,
  Tabs,
  Typography,
} from "djuno-design";
import {
  DBSCategory,
  DBSEngineType,
  DatabaseService,
} from "../../types/database";
import { ReactComponent as UsersIcon } from "./../../assets/icons/users.svg";
import { ReactComponent as BeatIcon } from "./../../assets/icons/beat.svg";
import { ReactComponent as MetricsIcon } from "./../../assets/icons/adjustments-vertical.svg";
import { ReactComponent as CircleStackIcon } from "./../../assets/icons/circle-stack.svg";
import { ReactComponent as NoticeIcon } from "./../../assets/icons/logs/notice.svg";
import { ReactComponent as QueryIcon } from "./../../assets/icons/presentation-chart-line.svg";
import { ReactComponent as IPIcon } from "./../../assets/icons/ip.svg";
import { ReactComponent as BackupIcon } from "./../../assets/icons/arrow-up-on-square.svg";
import { ReactComponent as SettingIcon } from "./../../assets/icons/setting.svg";
import { ReactComponent as PoolsIcon } from "./../../assets/icons/archive-box2.svg";
import { ReactComponent as NamespaceIcon } from "./../../assets/icons/bars-2.svg";
import { ReactComponent as SquareIcon } from "./../../assets/icons/square-3stack.svg";
import { ReactComponent as ListBulletIcon } from "./../../assets/icons/list-bullet.svg";

export const generateServicePermissions = () => {
  const permissions: Record<string, DBSEngineType[] | "*"> = {
    generalInformation: "*",
    users: [
      "mongodb",
      "mysql",
      "postgresql",
      "redis",
      "cassandra",
      "m3db",
      "kafka",
      "kafkaConnect",
      "opensearch",
    ],
    acl: ["kafka", "opensearch"],
    indexes: ["opensearch"],
    databases: ["mysql", "postgresql"],
    backups: [
      "mongodb",
      "mysql",
      "postgresql",
      "redis",
      "cassandra",
      "m3db",
      "opensearch",
      "grafana",
    ],
    allowedIps: [
      "mongodb",
      "mysql",
      "postgresql",
      "redis",
      "cassandra",
      "m3db",
      "kafka",
      "kafkaConnect",
      "opensearch",
      "grafana",
    ],
    queryStatistics: ["mysql", "postgresql"],
    currentQueries: ["mysql", "mongodb", "postgresql"],
    pools: ["postgresql"],
    logs: [
      "mongodb",
      "mysql",
      "postgresql",
      "redis",
      "cassandra",
      "m3db",
      "m3aggregator",
      "kafka",
      "kafkaMirrorMaker",
      "kafkaConnect",
      "opensearch",
      "grafana",
    ],
    metrics: [
      "mongodb",
      "mysql",
      "postgresql",
      "redis",
      "cassandra",
      "m3db",
      "m3aggregator",
      "kafka",
      "kafkaMirrorMaker",
      "kafkaConnect",
      "opensearch",
      "grafana",
    ],
    topics: ["kafka"],
    integrations: [
      "mysql",
      "postgresql",
      "redis",
      "cassandra",
      "m3db",
      "m3aggregator",
      "kafka",
      "kafkaMirrorMaker",
      "kafkaConnect",
      "opensearch",
      "grafana",
    ],
    replicationFlows: ["kafkaMirrorMaker"],
    connectors: ["kafkaConnect"],
    advancedConfigurations: [
      "mysql",
      "postgresql",
      "redis",
      "cassandra",
      "kafka",
      "kafkaConnect",
      "opensearch",
      "grafana",
    ],
    namespaces: ["m3db"],
  };

  return permissions;
};

export const generateServiceRoutes = (service: DatabaseService) => {
  const options: TabOptions = [];

  const generalInformationOption = {
    label: (
      <div className="flex items-center gap-1">
        <NoticeIcon className="w-3" />
        General information
      </div>
    ),
    url: DataServiceGeneralInformationUrl(service.engine, service.id),
    testId: "1th-tab",
  };

  const usersOption: TabOption = {
    label: (
      <div className="flex items-center gap-1">
        <UsersIcon className="w-3" />
        Users
      </div>
    ),
    url: DataServiceUsersUrl(service.engine, service.id),
    disabled: service.status !== "READY",
    testId: "2th-tab",
  };

  const aclOption: TabOption = {
    label: (
      <div className="flex items-center gap-1">
        <SquareIcon className="w-3" />
        ACL
      </div>
    ),
    url: DataServiceAclUrl(service.engine, service.id),
    disabled: service.status !== "READY",
    testId: "aclOption",
  };

  const indexesOption: TabOption = {
    label: (
      <div className="flex items-center gap-1">
        <ListBulletIcon className="w-3" />
        Indexes
      </div>
    ),
    url: DataServiceIndexesUrl(service.engine, service.id),
    disabled: service.status !== "READY",
    testId: "aclOption",
  };

  const databasesOption = {
    label: (
      <div className="flex items-center gap-1">
        <CircleStackIcon className="w-3" />
        Databases
      </div>
    ),
    url: DataServiceDatabasesUrl(service.engine, service.id),
    disabled: service.status !== "READY",
    testId: "3th-tab",
  };

  const authorisedIPsOption = {
    label: (
      <div className="flex items-center gap-1">
        <IPIcon className="w-3" />
        Authorised IPs
      </div>
    ),
    url: DataServiceAuthorisedIPsUrl(service.engine, service.id),
    disabled: service.status !== "READY",
    testId: "4th-tab",
  };

  const querryStatisticsOption = {
    label: (
      <div className="flex items-center gap-1">
        <QueryIcon className="w-3" />
        Query Statistics
      </div>
    ),
    url: DataServiceQueryStatisticsUrl(service.engine, service.id),
    disabled: service.status !== "READY",
    testId: "5th-tab",
  };

  const queriesInProgressOption = {
    label: (
      <div className="flex items-center gap-1">
        <QueryIcon className="w-3" />
        Queries in progress
      </div>
    ),
    url: DataServiceCurrentQueriesUrl(service.engine, service.id),
    disabled: service.status !== "READY",
    testId: "6th-tab",
  };

  const poolsOption = {
    label: (
      <div className="flex items-center gap-1">
        <PoolsIcon className="w-3" />
        Pools
      </div>
    ),
    url: DataServicePoolsUrl(service.engine, service.id),
    disabled: service.status !== "READY",
    testId: "7th-tab",
  };

  const logsOption = {
    label: (
      <div className="flex items-center gap-1">
        <BeatIcon className="w-3" />
        Logs
      </div>
    ),
    url: DataServiceLogsUrl(service.engine, service.id),
    disabled: service.status !== "READY",
    testId: "8th-tab",
  };

  const metricsOption = {
    label: (
      <div className="flex items-center gap-1">
        <MetricsIcon className="w-3" />
        Metrics
      </div>
    ),
    url: DataServiceMetricsUrl(service.engine, service.id),
    disabled: service.status !== "READY",
    testId: "9th-tab",
  };

  const topicsOption = {
    label: (
      <div className="flex items-center gap-1">
        <MetricsIcon className="w-3" />
        Topics
      </div>
    ),
    url: DataServiceTopicsUrl(service.engine, service.id),
    disabled: service.status !== "READY",
    testId: "topicsOption",
  };

  const integrationsOption = {
    label: (
      <div className="flex items-center gap-1">
        <MetricsIcon className="w-3" />
        Service integration
      </div>
    ),
    url: DataServiceIntegrationsUrl(service.engine, service.id),
    disabled: service.status !== "READY",
    testId: "10th-tab",
  };

  const replicationFlowsOption = {
    label: (
      <div className="flex items-center gap-1">
        <MetricsIcon className="w-3" />
        Replication Flows
      </div>
    ),
    url: DataServiceReplicationFlowsUrl(service.engine, service.id),
    disabled: service.status !== "READY",
    testId: "replicationFlowsOption",
  };

  const connectorsOption = {
    label: (
      <div className="flex items-center gap-1">
        <MetricsIcon className="w-3" />
        Connectors
      </div>
    ),
    url: DataServiceConnectorsUrl(service.engine, service.id),
    disabled: service.status !== "READY",
    testId: "connectorsOption",
  };

  const backupsOption = {
    label: (
      <div className="flex items-center gap-1">
        <BackupIcon className="w-3" />
        Backups
      </div>
    ),
    url: DataServiceBackupsUrl(service.engine, service.id),
    disabled: service.status !== "READY",
    testId: "11th-tab",
  };

  const advancedOption = {
    label: (
      <div className="flex items-center gap-1">
        <SettingIcon className="w-3" />
        Advanced configuration
      </div>
    ),
    url: DataServiceAdvancedConfigurationsUrl(service.engine, service.id),
    disabled: service.status !== "READY",
    testId: "12th-tab",
  };

  const namespacesOption = {
    label: (
      <div className="flex items-center gap-1">
        <NamespaceIcon className="w-3" />
        Namespaces
      </div>
    ),
    url: DataServiceNamespacesUrl(service.engine, service.id),
    disabled: service.status !== "READY",
    testId: "13th-tab",
  };

  options.push(generalInformationOption);

  if (
    service.engine === "mongodb" ||
    service.engine === "mysql" ||
    service.engine === "postgresql" ||
    service.engine === "redis" ||
    service.engine === "cassandra" ||
    service.engine === "m3db" ||
    service.engine === "kafka" ||
    service.engine === "kafkaConnect" ||
    service.engine === "opensearch"
  ) {
    options.push(usersOption);
  }

  if (service.engine === "kafka" || service.engine === "opensearch") {
    options.push(aclOption);
  }

  if (service.engine === "opensearch") {
    options.push(indexesOption);
  }

  if (service.engine === "mysql" || service.engine === "postgresql") {
    options.push(databasesOption);
  }

  if (
    (service.capabilities.backups && service.engine !== "kafkaConnect") ||
    service.engine === "opensearch" ||
    service.engine === "grafana"
  ) {
    options.push(backupsOption);
  }

  if (
    service.engine === "mongodb" ||
    service.engine === "mysql" ||
    service.engine === "postgresql" ||
    service.engine === "redis" ||
    service.engine === "cassandra" ||
    service.engine === "m3db" ||
    service.engine === "kafka" ||
    service.engine === "kafkaConnect" ||
    service.engine === "opensearch" ||
    service.engine === "grafana"
  ) {
    options.push(authorisedIPsOption);
  }

  if (service.engine === "mysql" || service.engine === "postgresql") {
    options.push(querryStatisticsOption);
  }

  if (service.engine === "mysql" || service.engine === "postgresql") {
    options.push(queriesInProgressOption);
  }

  if (service.engine === "postgresql") {
    options.push(poolsOption);
  }

  if (
    service.engine === "mongodb" ||
    service.engine === "mysql" ||
    service.engine === "postgresql" ||
    service.engine === "redis" ||
    service.engine === "cassandra" ||
    service.engine === "m3db" ||
    service.engine === "m3aggregator" ||
    service.engine === "kafka" ||
    service.engine === "kafkaMirrorMaker" ||
    service.engine === "kafkaConnect" ||
    service.engine === "opensearch" ||
    service.engine === "grafana"
  ) {
    options.push(logsOption);
  }

  if (
    service.engine === "mongodb" ||
    service.engine === "mysql" ||
    service.engine === "postgresql" ||
    service.engine === "redis" ||
    service.engine === "cassandra" ||
    service.engine === "m3db" ||
    service.engine === "m3aggregator" ||
    service.engine === "kafka" ||
    service.engine === "kafkaMirrorMaker" ||
    service.engine === "kafkaConnect" ||
    service.engine === "opensearch" ||
    service.engine === "grafana"
  ) {
    options.push(metricsOption);
  }

  if (service.engine === "kafka") {
    options.push(topicsOption);
  }

  if (
    service.engine === "mysql" ||
    service.engine === "postgresql" ||
    service.engine === "redis" ||
    service.engine === "cassandra" ||
    service.engine === "m3db" ||
    service.engine === "m3aggregator" ||
    service.engine === "kafka" ||
    service.engine === "kafkaMirrorMaker" ||
    service.engine === "kafkaConnect" ||
    service.engine === "opensearch" ||
    service.engine === "grafana"
  ) {
    options.push(integrationsOption);
  }

  if (service.engine === "kafkaMirrorMaker") {
    options.push(replicationFlowsOption);
  }

  if (service.engine === "kafkaConnect") {
    options.push(connectorsOption);
  }

  if (
    service.engine === "mysql" ||
    service.engine === "postgresql" ||
    service.engine === "redis" ||
    service.engine === "cassandra" ||
    service.engine === "kafka" ||
    service.engine === "kafkaConnect" ||
    service.engine === "opensearch" ||
    service.engine === "grafana"
  ) {
    options.push(advancedOption);
  }

  if (service.engine === "m3db") {
    options.push(namespacesOption);
  }

  return options;
};

const ServicePage: React.FC<{ category: DBSCategory }> = ({ category }) => {
  const { databaseId, engine } = useParams();

  const service = useAppSelector(selectDatabaseService);
  const loading = useAppSelector(selectLoading);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const DataservicePageMenus = useMemo(() => {
    if (service) {
      return generateServiceRoutes(service);
    }
    return [];
  }, [service]);

  useEffect(() => {
    if (databaseId && engine) {
      dispatch(getDatabaseServiceAsync({ id: databaseId, engine }));
    }

    return () => {
      dispatch(handleClearServiceSlice());
    };
  }, [dispatch, databaseId, engine]);

  useEffect(() => {
    let interval: NodeJS.Timeout | undefined;
    if (service && ["UPDATING", "CREATING"].includes(service.status)) {
      interval = setInterval(() => {
        dispatch(
          getDatabaseServiceAsync({
            id: service.id,
            engine: service.engine,
            withoutLoading: true,
          })
        );
      }, 20000);
    } else {
      if (interval) clearInterval(interval);
    }
    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [dispatch, service]);

  return (
    <>
      <Helmet>
        <title>
          {process.env.REACT_APP_NAME} | Database (
          {service ? service.description : ""})
        </title>
        <meta name="description" content="" />
      </Helmet>
      <div className="flex items-center justify-between h-16">
        <div className="px-6 items-center justify-between flex flex-1 transition duration-150">
          <div className="flex items-center gap-2">
            <RightArrow
              onClick={() => {
                navigate(DataServicesUrl(category));
              }}
              className="rotate-180 w-5 h-5 hover:scale-110 transition-all duration-500 text-slate-800 dark:text-slate-100 cursor-pointer"
            />
            {service && !loading && (
              <>
                <div className="flex flex-col">
                  <Typography.Title level={5} className="!mb-0">
                    {service.description}
                  </Typography.Title>
                </div>
              </>
            )}
            {loading && (
              <>
                <Skeleton
                  shape="rectangle"
                  style={{ width: 200, height: 30 }}
                />
              </>
            )}
          </div>
        </div>
      </div>

      <div className="px-6 mt-5">
        <div className="h-11">
          <Tabs options={DataservicePageMenus} />
        </div>
        <div className="py-6">
          <Outlet />
        </div>
      </div>
    </>
  );
};
export default ServicePage;
