import React, { useEffect, useMemo, useState } from "react";
import socketIOClient from "socket.io-client"; // Import your Redux action
import {
  addNotification,
  selectNotificationsAudioAllowed,
} from "../../../store/notifications/notificationsSlice";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { selectUser } from "../../../store/auth/authSlice";
import {
  Notification,
  SocketNotificationBody,
} from "../../../types/notifications";
import toast from "react-hot-toast";
import { NotificationToastItem } from "./SettingsNotificationsTab";

const NotificationSocket: React.FC = () => {
  const dispatch = useAppDispatch();
  const user = useAppSelector(selectUser);
  const [docIsAllowed, setDocIsAllowed] = useState(false);
  const audio = useMemo(() => new Audio("/notif-sound.mp3"), []);
  const audioAllowed = useAppSelector(selectNotificationsAudioAllowed);

  const soundedNotifs = useMemo(() => {
    return docIsAllowed && audioAllowed;
  }, [audioAllowed, docIsAllowed]);

  useEffect(() => {
    // Create a socket connection to the server
    const socket = socketIOClient("", {
      path: "/app-notif",
      auth: {
        userId: user?.Id,
      },
      withCredentials: true,
      transports: ["websocket", "polling"],
    });

    // Listen for new notifications from the socket server
    socket.on("notification", (message: SocketNotificationBody) => {
      const { notification, alert = true, action, data } = message;

      const notifData: Notification = { ...notification, IsRead: false };

      if (alert) {
        if (soundedNotifs) {
          audio.play();
          setTimeout(() => {
            audio.pause();
            audio.currentTime = 0;
          }, 300);
        }
        toast.custom(
          (t) => <NotificationToastItem t={t} notification={notifData} />,
          {
            position: "top-right",
          }
        );
      }

      dispatch(addNotification(notifData));

      //TODO: use action and data to reload or update store
    });

    return () => {
      socket.disconnect();
    };
  }, [audio, soundedNotifs, dispatch, user?.Id]);

  useEffect(() => {
    document.addEventListener("click", () => {
      setDocIsAllowed(true);
    });
    return () => {
      document.removeEventListener("click", () => {});
    };
  });
  return null;
};

export default NotificationSocket;
