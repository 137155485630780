import { useEffect, useState } from "react";
import { ReactComponent as MoreIcon } from "./../../../../assets/icons/more.svg";
import { ReactComponent as ArchiveIcon } from "./../../../../assets/icons/archive-box.svg";
import {
  selectBucketDetails,
  selectBucketDetailsLoading,
} from "../../../../store/s3/buckets/bucketSlice";
import {
  getBucketLifecyclesAsync,
  selectBucketLifecycleActionLoading,
  selectBucketLifecycles,
  selectBucketLifecyclesLoading,
  deleteBucketLifecycleAsync,
  handleShowLifecycleEditor,
} from "../../../../store/s3/buckets/setting/lifecycleSlice";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import { S3NotActiveTab } from "../../../layouts/NotData";
import { DeleteModal } from "../../../modals/QuestionModal";
import S3LifecycleEditorModal from "../modals/buckets/S3LifecycleEditorModal";
import { selectS3NodeStatus } from "../../../../store/s3/s3PublicSlice";
import { ReactComponent as EditIcon } from "./../../../../assets/icons/pencil-square.svg";
import {
  Button,
  Dropdown,
  EmptyState,
  SimpleTable,
  Typography,
} from "djuno-design";

const S3BucketLifecycleTab = () => {
  const s3Status = useAppSelector(selectS3NodeStatus);
  const bucketDetails = useAppSelector(selectBucketDetails);
  const bucketDetailsLoading = useAppSelector(selectBucketDetailsLoading);

  const lifecycles = useAppSelector(selectBucketLifecycles);
  const loading = useAppSelector(selectBucketLifecyclesLoading);
  const actionLoading = useAppSelector(selectBucketLifecycleActionLoading);

  const [deleteLifecycle, setDeleteLifecycle] = useState<string | null>(null);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (bucketDetails && !bucketDetailsLoading) {
      dispatch(getBucketLifecyclesAsync({ name: bucketDetails.name }));
    }
  }, [bucketDetails, bucketDetailsLoading, dispatch]);

  const handleDelete = () => {
    if (deleteLifecycle && bucketDetails)
      dispatch(
        deleteBucketLifecycleAsync({
          bucketName: bucketDetails.name,
          id: deleteLifecycle,
        })
      ).then((action) => {
        if (action.type === "bucket/lifecycle/delete/fulfilled") {
          dispatch(getBucketLifecyclesAsync({ name: bucketDetails.name }));
          setDeleteLifecycle(null);
        }
      });
  };

  if (s3Status !== 2) {
    return <S3NotActiveTab />;
  }
  return (
    <>
      <div className="flex transition-all duration-500 items-center justify-between gap-3">
        <Typography.Title
          level={5}
          className="!mb-0 whitespace-nowrap font-medium"
        >
          Lifecycle Rules
        </Typography.Title>
        <div className="flex items-center justify-end gap-1 md:gap-2 ">
          <Button
            uiType="primary"
            onClick={() => dispatch(handleShowLifecycleEditor({}))}
          >
            Add Lifecycle Rule
          </Button>
        </div>
      </div>
      <div className="mt-5 w-full">
        <SimpleTable loading={loading} containerClassName="min-h-[240px]">
          <SimpleTable.Head>
            <SimpleTable.Row>
              <SimpleTable.TH lable="Type" />
              <SimpleTable.TH lable="Version" />
              <SimpleTable.TH lable="Tier" />
              <SimpleTable.TH lable="Prefix" />
              <SimpleTable.TH lable="After" />
              <SimpleTable.TH lable="Status" />
              <SimpleTable.TH lable="" />
            </SimpleTable.Row>
          </SimpleTable.Head>
          <SimpleTable.Body>
            {lifecycles?.map((lifecycle, i) => (
              <SimpleTable.Row key={i}>
                <SimpleTable.TD>
                  <Typography.Text className="!text-xs md:!text-sm font-semibold whitespace-nowrap">
                    {lifecycle.transition.storage_class ||
                    lifecycle.transition.noncurrent_storage_class
                      ? "Transition"
                      : "Expiry"}
                  </Typography.Text>
                </SimpleTable.TD>
                <SimpleTable.TD>
                  <Typography.Text className="!text-xs md:!text-sm whitespace-nowrap">
                    {"noncurrent_expiration_days" in lifecycle.expiration ||
                    "noncurrent_transition_days" in lifecycle.transition
                      ? "Non-Current"
                      : "Current"}
                  </Typography.Text>
                </SimpleTable.TD>
                <SimpleTable.TD>
                  <Typography.Text className="!text-xs md:!text-sm whitespace-nowrap">
                    {lifecycle.transition.storage_class ||
                      lifecycle.transition.noncurrent_storage_class}
                  </Typography.Text>
                </SimpleTable.TD>
                <SimpleTable.TD>
                  <Typography.Text className="!text-xs md:!text-sm whitespace-nowrap">
                    {lifecycle.prefix}
                  </Typography.Text>
                </SimpleTable.TD>
                <SimpleTable.TD>
                  <Typography.Text className="!text-xs md:!text-sm whitespace-nowrap">
                    {lifecycle.transition.days ||
                      lifecycle.transition.noncurrent_transition_days ||
                      lifecycle.expiration.days ||
                      lifecycle.expiration.noncurrent_expiration_days}{" "}
                    days
                  </Typography.Text>
                </SimpleTable.TD>
                <SimpleTable.TD>
                  <Typography.Text className="!text-xs md:!text-sm whitespace-nowrap">
                    {lifecycle.status}
                  </Typography.Text>
                </SimpleTable.TD>
                <SimpleTable.TD className="w-32 gap-1">
                  <div className="h-full w-full inline-flex items-center justify-end gap-1">
                    <div className="w-8 flex justify-center items-center">
                      <Dropdown
                        itemsClassName="!w-40"
                        anchor="bottom end"
                        menu={[
                          {
                            key: "edit",
                            label: (
                              <div className="flex itmes-center gap-1">
                                <EditIcon className="w-4"></EditIcon>
                                Edit
                              </div>
                            ),
                            onClick: () =>
                              dispatch(
                                handleShowLifecycleEditor({ lifecycle })
                              ),
                          },
                          {
                            key: "end",
                            label: (
                              <div className="flex items-center gap-1">
                                <ArchiveIcon className="w-4" />
                                Delete
                              </div>
                            ),
                            danger: true,
                            onClick: (_, close) => {
                              close();
                              setDeleteLifecycle(lifecycle.id);
                            },
                          },
                        ]}
                      >
                        <Button
                          uiType="icon"
                          uiSize="small"
                          className="!px-2 rounded-md"
                        >
                          <MoreIcon className="w-4 h-4" />
                        </Button>
                      </Dropdown>
                    </div>
                  </div>
                </SimpleTable.TD>
              </SimpleTable.Row>
            ))}

            {lifecycles?.length === 0 && (
              <SimpleTable.Row withoutHoverStyle={true} className="h-[200px]">
                <SimpleTable.TD colSpan={7} className="!border-0">
                  <EmptyState text="You have not added any lifecycle yet" />
                </SimpleTable.TD>
              </SimpleTable.Row>
            )}
          </SimpleTable.Body>
        </SimpleTable>
      </div>
      <S3LifecycleEditorModal />
      <DeleteModal
        isOpen={deleteLifecycle !== null}
        onClose={() => setDeleteLifecycle(null)}
        onConfirm={handleDelete}
        loading={actionLoading || bucketDetailsLoading}
        title="Delete Lifecycle Rule"
        // description={`Are you sure you want to delete the ${deleteLifecycle} rule?`}
        confirmButtonType="danger"
        // confirmString={lifecycles.find((l) => l.id === deleteLifecycle)?.id}
      />
    </>
  );
};

export default S3BucketLifecycleTab;
