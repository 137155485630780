import { Helmet } from "react-helmet";
import {
  addDBSNodeAsync,
  deleteDBSNodeAsync,
  getDatabaseServiceAsync,
  selectDatabaseService,
  selectDBSNodesActionLoading,
} from "../../../store/database/serviceSlice";
import { ReactComponent as RightArrow } from "./../../../assets/icons/arrow-up.svg";
import { useNavigate } from "react-router-dom";
import { DataServiceGeneralInformationUrl } from "../../../utils/urls";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { useEffect, useMemo, useState } from "react";
import {
  dbsPeriodItems,
  getDBSPriceAmount,
  groupAvailabilityByPlan,
  NumberOfNodesInput,
} from "../ServiceCreateComponents";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { DatabaseServiceUpdateNumberOfNodesSchema } from "../../../utils/validations";
import {
  DBSCatalog,
  DBSAvailability,
  DBSCategory,
  DBSGroupAvailability,
} from "../../../types/database";
import {
  getDBSAvailabilityAsync,
  getDBSCapabilitiesAsync,
  getDBSCatalogsAsync,
  selectDBSAvailability,
  selectDBSAvailabilityLoading,
  selectDBSCapabilities,
  selectDBSCapabilitiesLoading,
  selectDBSCatalogs,
  selectDBSCatalogsLoading,
  selectDatabaseServicesActionLoading,
} from "../../../store/database/servicesSlice";
import RadioGrouper from "../../general/RadioGrouper";
import { Button, Flex, Typography } from "djuno-design";
import QuestionModal from "../../modals/QuestionModal";

const DBSUpgradeNumberOfNodesTab: React.FC<{ category: DBSCategory }> = ({
  category,
}) => {
  const service = useAppSelector(selectDatabaseService);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const capabilities = useAppSelector(selectDBSCapabilities);
  const capabilitiesLoading = useAppSelector(selectDBSCapabilitiesLoading);

  const availability = useAppSelector(selectDBSAvailability);
  const availabilityLoading = useAppSelector(selectDBSAvailabilityLoading);

  const catalogs = useAppSelector(selectDBSCatalogs);
  const catalogsLoading = useAppSelector(selectDBSCatalogsLoading);

  const nodesActionLoading = useAppSelector(selectDBSNodesActionLoading);
  const actionLoading = useAppSelector(selectDatabaseServicesActionLoading);

  const {
    control,
    formState: { errors },
    watch,
    setValue,
  } = useForm({
    resolver: yupResolver(DatabaseServiceUpdateNumberOfNodesSchema()),
    reValidateMode: "onChange",
  });

  const numberOfNodes = watch("NumberOfNodes");

  const [changeModal, setChangeModal] = useState<
    "increase" | "decrease" | null
  >(null);

  // selected planGroup
  const [selectedPlanGroup, setSelectedPlanGroup] = useState<
    DBSGroupAvailability | undefined
  >(undefined);

  const [planAvailability, setPlanAvailability] = useState<
    Array<DBSAvailability>
  >([]);

  // make plan groups from planAvailability
  const planGruops: Array<DBSGroupAvailability> = useMemo(() => {
    if (capabilities) {
      const { planGroups } = groupAvailabilityByPlan(
        planAvailability,
        capabilities
      );
      return planGroups;
    } else {
      return [];
    }
  }, [capabilities, planAvailability]);

  // set selectedPlanGroup state when selected plan is changing
  useEffect(() => {
    if (service && planGruops) {
      const planGroup = planGruops.find((pg) => pg.plan === service.plan);
      setSelectedPlanGroup(planGroup);
    }
  }, [planGruops, service]);

  const [selectedCatalog, setSelectedCatalog] = useState<DBSCatalog>();
  const [period, setPeriod] = useState<"month" | "hour">("hour");

  useEffect(() => {
    if (capabilities === undefined) dispatch(getDBSCapabilitiesAsync());
  }, [dispatch, capabilities]);

  useEffect(() => {
    if (availability === undefined) dispatch(getDBSAvailabilityAsync());
  }, [dispatch, availability]);

  useEffect(() => {
    if (catalogs === undefined) dispatch(getDBSCatalogsAsync());
  }, [dispatch, catalogs]);

  // select node type
  useEffect(() => {
    if (service) {
      setValue("NumberOfNodes", service.nodeNumber);
    }
  }, [service, setValue]);

  // process plansAvailability
  useEffect(() => {
    if (service) {
      const plansAvailability = availability?.filter(
        (a) =>
          a.lifecycle.status === "STABLE" &&
          a.engine === service.engine &&
          a.network === "public" &&
          a.version === service.version &&
          a.plan === service.plan
      );
      setPlanAvailability(plansAvailability || []);
    }
  }, [availability, service]);

  //filter addons by selected engine and period
  useEffect(() => {
    if (catalogs && service) {
      const planCode = `databases.${service.engine}-${service.plan}-${service.flavor}.${period}.consumption`;
      const filteredCatalog = catalogs.find((c) =>
        c.planCode.includes(planCode)
      );
      setSelectedCatalog(filteredCatalog);
    }
  }, [catalogs, period, service]);

  const handleSubmitForm = () => {
    if (service && !actionLoading && !nodesActionLoading) {
      if (changeModal === "decrease" && service.nodes.length > 0) {
        const node = service.nodes[service.nodes.length - 1];
        dispatch(
          deleteDBSNodeAsync({
            engine: service.engine,
            clusterId: service.id,
            nodeId: node.id,
          })
        ).then((action) => {
          if (action.type === "service/nodes/delete/fulfilled") {
            dispatch(
              getDatabaseServiceAsync({
                engine: service.engine,
                id: service.id,
              })
            );

            navigate(
              DataServiceGeneralInformationUrl(service.engine, service.id)
            );
          }
        });
      }

      if (changeModal === "increase") {
        dispatch(
          addDBSNodeAsync({
            engine: service.engine,
            clusterId: service.id,
            data: {
              flavor: service.flavor,
              region: service.nodes[0].region,
            },
          })
        ).then((action) => {
          if (action.type === "service/nodes/add/fulfilled") {
            dispatch(
              getDatabaseServiceAsync({
                engine: service.engine,
                id: service.id,
              })
            );

            navigate(
              DataServiceGeneralInformationUrl(service.engine, service.id)
            );
          }
        });
      }
    }
  };

  useEffect(() => {
    if (service && numberOfNodes !== service.nodeNumber) {
      if (numberOfNodes > service.nodeNumber) {
        setChangeModal("increase");
      } else if (numberOfNodes < service.nodeNumber) {
        setChangeModal("decrease");
      } else {
        setChangeModal(null);
      }
    }
  }, [numberOfNodes, service]);

  return (
    <>
      <Helmet>
        <title>{process.env.REACT_APP_NAME} | Upgrade node </title>
        <meta name="description" content="" />
      </Helmet>
      <div className="p-4">
        {service && (
          <div className="flex items-center justify-between">
            <div
              className="group px-1 items-center flex transition-all duration-150 cursor-pointer gap-x-0.5"
              onClick={() =>
                navigate(
                  DataServiceGeneralInformationUrl(service.engine, service.id)
                )
              }
            >
              <RightArrow className="-rotate-90 w-4 h-4 transition-all duration-500 text-primary-500 group-hover:translate-x-[-4px]" />
              <Typography.Text className="!text-sm !text-primary-500 ">
                Back to “Information”
              </Typography.Text>
            </div>
          </div>
        )}

        {/* <form onSubmit={handleSubmit(handleSubmitForm)}> */}
        <div className="mt-10 w-full">
          {selectedPlanGroup && (
            <NumberOfNodesInput
              plan={selectedPlanGroup}
              control={control}
              errorMessage={errors.NumberOfNodes?.message}
            />
          )}
        </div>
        <div className="flex items-center gap-3 mt-5">
          <Button
            onClick={() => {
              if (service)
                navigate(
                  DataServiceGeneralInformationUrl(service.engine, service.id)
                );
            }}
          >
            Cancel
          </Button>
          {/* <Button
              loading={
                actionLoading ||
                capabilitiesLoading ||
                availabilityLoading ||
                catalogLoading
              }
              disabled={service?.nodeNumber === numberOfNodes}
              uiType="primary"
              type="submit"
            >
              Upgrade number of nodes
            </Button> */}
        </div>
        {/* </form> */}
        <QuestionModal
          title={changeModal === "increase" ? "Add node" : "Delete a node"}
          isOpen={changeModal !== null}
          description={
            <Flex className="mt-5 gap-3" direction="col">
              <Flex className="gap-1" direction="col">
                <Typography.Text size="sm">Display price</Typography.Text>
                <RadioGrouper
                  items={dbsPeriodItems}
                  selected={dbsPeriodItems.find((i) => i.value === period)}
                  setSelected={(item) => setPeriod(item.value)}
                  dir="row"
                />
              </Flex>
              <Typography.Text size="sm">
                You currently have {service?.nodeNumber} nodes. Are you sure you
                want to {changeModal === "increase" ? "add" : "delete"} one?{" "}
                {selectedCatalog && (
                  <Typography.Text size="sm">
                    Your bill will{" "}
                    {changeModal === "increase" ? "increase" : "reduce"} by $$
                    {getDBSPriceAmount(selectedCatalog.pricings[0].price)} ex.
                    VAT{" "}
                    <Typography.Text size="xs">
                      ($$
                      {getDBSPriceAmount(
                        selectedCatalog.pricings[0].price +
                          selectedCatalog.pricings[0].tax
                      )}{" "}
                      incl. VAT)
                    </Typography.Text>{" "}
                    per {period}.
                  </Typography.Text>
                )}
              </Typography.Text>
            </Flex>
          }
          onClose={() => {
            setChangeModal(null);
            if (service) setValue("NumberOfNodes", service.nodeNumber);
          }}
          confirmButtonText="Validate"
          onConfirm={handleSubmitForm}
          loading={nodesActionLoading}
        />
      </div>
    </>
  );
};

export default DBSUpgradeNumberOfNodesTab;
