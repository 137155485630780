import { useCallback, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "./../../../../hooks";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Flex, Input, Modal, Typography } from "djuno-design";
import {
  getK8ServiceAsync,
  handleToggleK8NameModal,
  selectK8NameModal,
  selectK8Service,
  selectK8ServiceActionLoading,
  selectK8ServiceLoading,
  updateK8serviceAsync,
} from "./../../../../store/kubernetes/k8ServiceSlice";
import { K8NameSchema } from "./../../../../utils/validations";
import { getK8ServicesAsync } from "./../../../../store/kubernetes/k8ServicesSlice";

const K8RenameModal = () => {
  const [isK8NameChanged, setK8NameChanged] = useState(false);

  const isOpen = useAppSelector(selectK8NameModal);
  const k8Service = useAppSelector(selectK8Service);
  const k8ServiceLoading = useAppSelector(selectK8ServiceLoading);
  const k8ServiceActionLoading = useAppSelector(selectK8ServiceActionLoading);
  const dispatch = useAppDispatch();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors: errorsName },
    watch,
    setValue,
  } = useForm({
    resolver: yupResolver(K8NameSchema),
    mode: "all",
  });

  const currentName = watch("name");

  //Name
  useEffect(() => {
    if (k8Service && isOpen) {
      setValue("name", k8Service.name);
    }
  }, [k8Service, setValue, isOpen]);

  useEffect(() => {
    if (isOpen && k8Service) {
      setK8NameChanged(currentName !== k8Service.name);
    }
  }, [currentName, isOpen, k8Service]);

  const onSubmit = (data: any) => {
    if (k8Service && !k8ServiceLoading) {
      dispatch(
        updateK8serviceAsync({
          id: k8Service.id,
          data,
        })
      ).then((action) => {
        if (action.type === "k8-service/update/fulfilled") {
          handleOnClose();
          dispatch(getK8ServicesAsync({ withoutLoading: false }));
          dispatch(getK8ServiceAsync({ id: k8Service.id }));
        }
      });
    }
  };

  const handleOnClose = useCallback(() => {
    reset();
    setK8NameChanged(false);
    dispatch(handleToggleK8NameModal(false));
  }, [dispatch, reset]);

  useEffect(() => {
    return () => {
      handleOnClose();
    };
  }, [handleOnClose]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleOnClose}
      contentClassName="max-w-lg"
      title="Change the name"
    >
      <form onSubmit={handleSubmit(onSubmit)} className="w-full">
        <Flex items="center" className="mt-6 gap-1">
          <Typography.Text size="sm">Default name</Typography.Text>
          <Typography.Text className="!text-sm">
            {k8Service?.id}
          </Typography.Text>
        </Flex>

        <div>
          <Input
            containerClassName="!mt-6"
            label="Custom name"
            {...register("name")}
            error={errorsName.name?.message}
          />
        </div>

        <Flex items="center" justify="end" className="gap-2 mt-5">
          <Button onClick={handleOnClose}>Cancel</Button>
          <Button
            uiType="primary"
            type="submit"
            disabled={!isK8NameChanged || k8ServiceActionLoading}
            loading={k8ServiceActionLoading}
          >
            Edit
          </Button>
        </Flex>
      </form>
    </Modal>
  );
};

export default K8RenameModal;
