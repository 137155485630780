import { useCallback, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import HighlighterText from "../../general/HighlighterText";
import { WorkflowNotActiveTab } from "../../layouts/NotData";
import {
  deleteWorkflowContractAsync,
  getWorkflowContractsAsync,
  selectWorkflowContracts,
  selectWorkflowContractsActionLoading,
  selectWorkflowContractsLoading,
} from "../../../store/workflows/contractsSlice";
import { IWorkflowContract } from "../../../types/workflows";
import { useSearch } from "../../../providers/SearchProvider";
import { Helmet } from "react-helmet";
import { ReactComponent as PlusIcon } from "./../../../assets/icons/plus.svg";
import { ReactComponent as EditIcon } from "./../../../assets/icons/pencil.svg";
import { ReactComponent as MoreIcon } from "./../../../assets/icons/more.svg";
import { ReactComponent as ArchiveIcon } from "./../../../assets/icons/archive-box.svg";
import Jazzicon, { jsNumberForAddress } from "react-jazzicon";
import { networks } from "../wallets/constant";
import { timeAgo } from "../../../utils/date";
import ContractDialog from "./ContractDialog";
import { selectWorkflowNodeStatus } from "../../../store/workflows/workflowsSlice";
import {
  Button,
  Dropdown,
  SimpleTable,
  Typography,
  Input,
  EmptyState,
  Flex,
} from "djuno-design";
import toast from "react-hot-toast";
import { ToastClasses } from "../../modals/alerts";
import { DeleteModal } from "../../modals/QuestionModal";

const WorkflowsContractsTab = () => {
  const workflowStatus = useAppSelector(selectWorkflowNodeStatus);

  const contracts = useAppSelector(selectWorkflowContracts);
  const actionLoading = useAppSelector(selectWorkflowContractsActionLoading);

  const contractsLoading = useAppSelector(selectWorkflowContractsLoading);
  const [filteredContracts, setFilteredContracts] = useState<
    IWorkflowContract[]
  >([]);

  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [dialogProps, setDialogProps] = useState({});
  const [deleteContract, setDeleteContract] =
    useState<IWorkflowContract | null>(null);

  const dispatch = useAppDispatch();
  const { value: searchValue } = useSearch();

  useEffect(() => {
    dispatch(getWorkflowContractsAsync());
  }, [dispatch]);

  useEffect(() => {
    const lookedUp = contracts.filter((contract) =>
      contract.name.toLowerCase().includes(searchValue.toLowerCase())
    );
    setFilteredContracts(lookedUp);
  }, [dispatch, searchValue, contracts]);

  const getNetworkItem = useCallback((network: string) => {
    return networks.find((ntw) => ntw.name === network);
  }, []);

  const addNew = useCallback(() => {
    const dialogProp = {
      title: "Add New Contract",
      type: "ADD",
      cancelButtonName: "Cancel",
      confirmButtonName: "Add",
    };
    setDialogProps(dialogProp);
    setShowDialog(true);
  }, []);

  const edit = useCallback((id: string) => {
    const dialogProp = {
      title: "Edit Contract",
      type: "EDIT",
      cancelButtonName: "Cancel",
      confirmButtonName: "Save",
      id,
    };
    setDialogProps(dialogProp);
    setShowDialog(true);
  }, []);

  const onConfirm = useCallback(() => {
    setShowDialog(false);
    dispatch(getWorkflowContractsAsync());
  }, [dispatch]);

  const handleDeleteContract = async () => {
    if (deleteContract) {
      dispatch(deleteWorkflowContractAsync(deleteContract._id)).then(
        (action) => {
          if (action.type === "workflows/contract/delete/fulfilled") {
            toast.success("Contract deleted", { className: ToastClasses });
            setDeleteContract(null);
            onConfirm();
          }
        }
      );
    }
  };

  if (workflowStatus !== 2) {
    return <WorkflowNotActiveTab />;
  }

  // const handleClose = () => {
  //   dispatch(handleHideContractDeleteModal());
  // };

  return (
    <>
      <Helmet>
        <title>{process.env.REACT_APP_NAME} | Workflow Contracts </title>
        <meta name="description" content="" />
      </Helmet>
      <div className="flex transition-all duration-500 items-center justify-between gap-3">
        <Typography.Text className="whitespace-nowrap font-medium"></Typography.Text>
        <div className="flex items-center justify-end gap-1 md:gap-2 ">
          <Button uiType="primary" onClick={addNew} className="group">
            Add New
            <PlusIcon className="w-3 h-3 group-hover:rotate-90 group-hover:scale-110 transition-all duration-500" />
          </Button>
        </div>
      </div>
      <div className="mt-5 w-full">
        <SimpleTable
          loading={contractsLoading}
          className="table-auto"
          containerClassName="min-h-[240px]"
        >
          <SimpleTable.Head>
            <SimpleTable.Row>
              <SimpleTable.TH lable="Name" />
              <SimpleTable.TH lable="Address" />
              <SimpleTable.TH lable="Network" />
              <SimpleTable.TH lable="Created" />
              <SimpleTable.TH lable="" />
            </SimpleTable.Row>
          </SimpleTable.Head>
          <SimpleTable.Body>
            {filteredContracts.map((contract, index) => (
              <SimpleTable.Row
                key={index}
                onClick={() => edit(contract._id)}
                className="cursor-pointer"
              >
                <SimpleTable.TD>
                  <div className="flex items-center">
                    {contract.address && (
                      <div className="w-[35px] h-[35px] rounded-full bg-white dark:bg-dark-1 mr-3">
                        <Jazzicon
                          diameter={35}
                          seed={jsNumberForAddress(contract.address)}
                        />
                      </div>
                    )}
                    <Flex>
                      <HighlighterText
                        searchValue={searchValue}
                        textToHighlight={contract.name}
                        className="max-w-[110px] md:max-w-[400px] lg:max-w-[350px] xl:max-w-[400px] truncate"
                      />
                    </Flex>
                  </div>
                </SimpleTable.TD>
                <SimpleTable.TD>
                  <Input
                    copyable={{ text: contract.address }}
                    value={`${contract.address.slice(
                      0,
                      8
                    )}...${contract.address.slice(-4)}`}
                    className="text-xs md:text-sm"
                    label={""}
                  />
                </SimpleTable.TD>
                <SimpleTable.TD>
                  <Typography.Text
                    className="!text-xs md:!text-sm !text-slate-50 px-1 py-0.5 rounded-md"
                    style={{
                      backgroundColor:
                        getNetworkItem(contract.network)?.color || "#666666",
                    }}
                  >
                    {getNetworkItem(contract.network)?.label}
                  </Typography.Text>
                </SimpleTable.TD>
                <SimpleTable.TD>
                  <Typography.Text className="!text-xs md:!text-sm !whitespace-nowrap">
                    {timeAgo(contract.createdDate)}
                  </Typography.Text>
                </SimpleTable.TD>
                <SimpleTable.TD className="w-20 gap-1">
                  <div className="h-full w-full inline-flex items-center justify-end gap-1">
                    <div className="w-8 flex justify-center items-center">
                      <Dropdown
                        anchor="bottom end"
                        menu={[
                          {
                            key: "1",
                            label: (
                              <div className="flex items-center gap-1">
                                <EditIcon className="w-4" />
                                Edit
                              </div>
                            ),
                            onClick: (_, close) => {
                              close();
                              edit(contract._id);
                            },
                          },
                          {
                            type: "divider",
                          },
                          {
                            key: "end",
                            label: (
                              <div className="flex items-center gap-1">
                                <ArchiveIcon className="w-4" />
                                Delete
                              </div>
                            ),
                            danger: true,
                            onClick: (_, close) => {
                              close();
                              setDeleteContract(contract);
                            },
                          },
                        ]}
                      >
                        <Button uiSize="small" uiType="icon" className="!px-2">
                          <MoreIcon className="w-4 h-4" />
                        </Button>
                      </Dropdown>
                    </div>
                  </div>
                </SimpleTable.TD>
              </SimpleTable.Row>
            ))}
            {contracts.length === 0 && (
              <SimpleTable.Row withoutHoverStyle={true} className="h-[200px]">
                <SimpleTable.TD colSpan={5} className="!border-none">
                  <EmptyState text="No contracts created yet" />
                </SimpleTable.TD>
              </SimpleTable.Row>
            )}
          </SimpleTable.Body>
        </SimpleTable>
        <ContractDialog
          show={showDialog}
          dialogProps={dialogProps}
          onCancel={() => setShowDialog(false)}
          onConfirm={onConfirm}
        />
        <DeleteModal
          isOpen={deleteContract !== null}
          title="Delete contract"
          onClose={() => setDeleteContract(null)}
          description=""
          confirmButtonText="Confirm"
          confirmButtonType="danger"
          confirmButtonClassName="w-full"
          loading={actionLoading}
          onConfirm={handleDeleteContract}
          confirmString={deleteContract?.name}
        />
      </div>
    </>
  );
};

export default WorkflowsContractsTab;
