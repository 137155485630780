import { useForm } from "react-hook-form";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Modal, Select } from "djuno-design";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  getDSSCapabilitiesConnectorsAsync,
  handleToggleDSSConnectorModal,
  selectDatabaseService,
  selectDSSConnectorsActionLoading,
  selectDSSCapabilitiesConnectors,
  selectDSSCapabilitiesConnectorsLoading,
  selectDSSShowConnectorditorModal,
  getDSSConnectorsConfigurationAsync,
  getDSSCapabilitiesConnectorAsync,
  getDSSConnectorTransformsAsync,
  selectDSSConnectorsConfigurationLoading,
} from "../../../store/database/serviceSlice";
import { DataStreamingConnecterSchema } from "../../../utils/validations";
import { useNavigate } from "react-router-dom";
import { DataServiceConnectorCraeteUrl } from "../../../utils/urls";

export const ConnectorCreateModal: React.FC = () => {
  const [connecter, setConnector] = useState<string>("");

  const isOpen = useAppSelector(selectDSSShowConnectorditorModal);
  const service = useAppSelector(selectDatabaseService);
  const capabilitiesConnectors = useAppSelector(
    selectDSSCapabilitiesConnectors
  );
  const capabilitiesConnectorsLoading = useAppSelector(
    selectDSSCapabilitiesConnectorsLoading
  );
  const configurationLoading = useAppSelector(
    selectDSSConnectorsConfigurationLoading
  );
  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const methods = useForm({
    resolver: yupResolver(DataStreamingConnecterSchema),
    mode: "all",
  });
  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
    setValue,
    control,
  } = methods;

  useEffect(() => {
    service &&
      isOpen &&
      !capabilitiesConnectors &&
      dispatch(
        getDSSCapabilitiesConnectorsAsync({
          clusterId: service.id,
          engine: service?.engine,
        })
      );
  }, [isOpen, service, capabilitiesConnectors, dispatch]);

  useEffect(() => {
    if (isOpen) {
      setConnector("");
    }
  }, [isOpen]);

  const selectedConnectorId = useMemo(() => {
    return capabilitiesConnectors.find((c) => c.id === connecter)?.id;
  }, [connecter]);

  const handleSubmitForm = (data: any) => {
    if (!capabilitiesConnectorsLoading) {
      service &&
        dispatch(
          getDSSConnectorsConfigurationAsync({
            engine: service?.engine,
            clusterId: service?.id,
            connectorId: selectedConnectorId || "",
          })
        ).then((action) => {
          if (action.type === "service/connector/configuration/fulfilled") {
            handleOnClose();
            selectedConnectorId &&
              service &&
              navigate(
                DataServiceConnectorCraeteUrl(
                  service.engine,
                  service.id,
                  selectedConnectorId
                )
              );
            selectedConnectorId &&
              dispatch(
                getDSSCapabilitiesConnectorAsync({
                  engine: service.engine,
                  clusterId: service.id,
                  connectorId: selectedConnectorId,
                })
              );
            selectedConnectorId &&
              dispatch(
                getDSSConnectorTransformsAsync({
                  engine: service.engine,
                  clusterId: service.id,
                  connectorId: selectedConnectorId,
                })
              );
          }
        });
    }
  };

  const handleOnClose = useCallback(() => {
    reset();
    dispatch(handleToggleDSSConnectorModal(false));
  }, [dispatch, reset]);

  useEffect(() => {
    return () => {
      handleOnClose();
    };
  }, [handleOnClose]);

  useEffect(() => {
    setValue("connecter", connecter);
  }, [connecter, setValue]);
  useEffect(() => {
    isOpen && setValue("connecter", "");
  }, [isOpen, setValue]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleOnClose}
      contentClassName="!max-w-xl overflow-hidden"
      title={"Add a connector"}
    >
      <form className="mt-5" onSubmit={handleSubmit(handleSubmitForm)}>
        <div className="flex flex-col gap-3">
          <Select
            label="Connector"
            value={connecter}
            onChange={(value) => setConnector(value ?? "")}
            options={capabilitiesConnectors.map((c) => ({
              value: c.id,
              label: c.name,
            }))}
            loading={capabilitiesConnectorsLoading}
            optionsClassName="h-40"
          />

          <div className="flex justify-end gap-2">
            <Button uiType="simple" onClick={handleOnClose}>
              Cancel
            </Button>
            <Button
              uiType="primary"
              type="submit"
              loading={capabilitiesConnectorsLoading || configurationLoading}
            >
              Add a connector
            </Button>
          </div>
        </div>
      </form>
    </Modal>
  );
};
