import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import {
  handleShowEditor,
  selectS3TiersLoading,
} from "../../../store/s3/tiersSlice";
import { ReactComponent as MoreIcon } from "./../../../assets/icons/more.svg";
import { ReactComponent as EditIcon } from "./../../../assets/icons/pencil-square.svg";
import { ReactComponent as PlusIcon } from "./../../../assets/icons/plus.svg";
import { ReactComponent as RefreshIcon } from "./../../../assets/icons/arrow-path.svg";
import { S3NotActiveTab } from "../../layouts/NotData";
import { useSearch } from "../../../providers/SearchProvider";
import { S3Tier } from "../../../types/s3-tiers";
import { getS3TiersAsync, selectS3Tiers } from "../../../store/s3/tiersSlice";
import { S3TierAddUrl } from "../../../utils/urls";
import { useNavigate } from "react-router-dom";
import UpdateCredentialsModal from "./UpdateCredentialsModal";
import HighlighterText from "../../general/HighlighterText";
import { selectS3NodeStatus } from "../../../store/s3/s3PublicSlice";
import {
  Button,
  cn,
  Dropdown,
  EmptyState,
  SimpleTable,
  Typography,
} from "djuno-design";

const S3TiersTab = () => {
  const s3Status = useAppSelector(selectS3NodeStatus);
  const [filteredTiers, setFilteredTiers] = useState<S3Tier[]>([]);
  const tiers = useAppSelector(selectS3Tiers);
  const loading = useAppSelector(selectS3TiersLoading);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { value: searchValue } = useSearch();

  useEffect(() => {
    // if (tiers.length === 0) {
    dispatch(getS3TiersAsync());
    // }
  }, [dispatch]);

  useEffect(() => {
    const lookedUp = tiers.filter((t) =>
      t[t.type]?.name.toLowerCase().includes(searchValue.toLowerCase())
    );
    setFilteredTiers(lookedUp);
  }, [dispatch, searchValue, tiers]);

  if (s3Status !== 2) {
    return <S3NotActiveTab />;
  }
  return (
    <>
      <div className="flex items-center justify-between">
        <div className="items-center justify-between flex flex-1 transition duration-150">
          <Typography.Title level={5} className="!mb-0">
            Tiers
          </Typography.Title>
        </div>
        <div className="flex items-center gap-2">
          <Button
            uiType="light"
            onClick={() => dispatch(getS3TiersAsync())}
            className="group"
            tooltip={{ content: "Refresh" }}
          >
            <RefreshIcon className="w-4 h-4 group-hover:rotate-90 group-hover:scale-110 transition-all duration-500" />
          </Button>
          <Button
            uiType="primary"
            onClick={() => navigate(S3TierAddUrl)}
            className="group"
          >
            Create Tier
            <PlusIcon className="w-3 h-3 group-hover:rotate-90 group-hover:scale-110 transition-all duration-500" />
          </Button>
        </div>
      </div>
      <div className="mt-5 w-full ">
        <SimpleTable loading={loading} containerClassName="min-h-[240px]">
          <SimpleTable.Head>
            <SimpleTable.Row>
              <SimpleTable.TH lable="Tire Name" />
              <SimpleTable.TH lable="Status" />
              <SimpleTable.TH lable="Type" />
              <SimpleTable.TH lable="Endpoint" />
              <SimpleTable.TH lable="Bucket" />
              <SimpleTable.TH lable="Prefix" />
              <SimpleTable.TH lable="Region" />
              <SimpleTable.TH lable="Usage" />
              <SimpleTable.TH lable="Objects" />
              <SimpleTable.TH lable="Versions" />
              <SimpleTable.TH lable="" />
            </SimpleTable.Row>
          </SimpleTable.Head>
          <SimpleTable.Body>
            {filteredTiers.map((tier, i) => (
              <SimpleTable.Row key={i}>
                <SimpleTable.TD>
                  <div className="flex items-center flex-row justify-between space-x-4">
                    <HighlighterText
                      searchValue={searchValue}
                      textToHighlight={tier[tier.type].name}
                      className="max-w-[110px] md:max-w-[400px] lg:max-w-[350px] xl:max-w-[400px] truncate"
                    />
                  </div>
                </SimpleTable.TD>

                <SimpleTable.TD>
                  <Typography.Text
                    className={cn("!text-xs md:!text-sm", {
                      "!text-red-500": !tier.status,
                    })}
                  >
                    {tier.status ? "Active" : "Inactive"}
                  </Typography.Text>
                </SimpleTable.TD>
                <SimpleTable.TD>
                  {tier.type && (
                    <img
                      src={`https://cdn.djuno.cloud/tiers/${tier.type}.svg`}
                      alt={tier.type}
                      className="w-8 h-8 rounded-full"
                    />
                  )}
                </SimpleTable.TD>
                <SimpleTable.TD>
                  <Typography.Text className="!text-xs md:!text-sm">
                    {tier[tier.type].endpoint.substring(0, 15) + "..."}
                  </Typography.Text>
                </SimpleTable.TD>
                <SimpleTable.TD>
                  <Typography.Text className="!text-xs md:!text-sm">
                    {tier[tier.type].bucket}
                  </Typography.Text>
                </SimpleTable.TD>
                <SimpleTable.TD>
                  <Typography.Text className="!text-xs md:!text-sm">
                    {tier[tier.type].prefix}
                  </Typography.Text>
                </SimpleTable.TD>
                <SimpleTable.TD>
                  {/* TODO */}
                  <Typography.Text className="!text-xs md:!text-sm">
                    {""}
                  </Typography.Text>
                </SimpleTable.TD>
                <SimpleTable.TD>
                  <Typography.Text className="!text-xs md:!text-sm">
                    {tier[tier.type].usage}
                  </Typography.Text>
                </SimpleTable.TD>
                <SimpleTable.TD>
                  <Typography.Text className="!text-xs md:!text-sm">
                    {tier[tier.type].objects}
                  </Typography.Text>
                </SimpleTable.TD>
                <SimpleTable.TD>
                  <Typography.Text className="!text-xs md:!text-sm">
                    {tier[tier.type].versions}
                  </Typography.Text>
                </SimpleTable.TD>
                <SimpleTable.TD className="w-20 gap-1">
                  <div className="h-full w-full inline-flex items-center justify-end gap-1">
                    <div className="w-8 flex justify-end items-center">
                      <Dropdown
                        itemsClassName="!w-40"
                        anchor="bottom end"
                        menu={[
                          {
                            key: "1",
                            label: (
                              <div className="flex items-center gap-1">
                                <EditIcon className="w-4" />
                                Edit
                              </div>
                            ),
                            onClick: (_, close) => {
                              close();
                              dispatch(handleShowEditor({ tier }));
                            },
                          },
                          {
                            type: "divider",
                          },
                        ]}
                      >
                        <Button uiType="icon" uiSize="small" className="!px-2">
                          <MoreIcon className="w-4 h-4" />
                        </Button>
                      </Dropdown>
                    </div>
                  </div>
                </SimpleTable.TD>
              </SimpleTable.Row>
            ))}

            {tiers.length === 0 && (
              <SimpleTable.Row withoutHoverStyle={true} className="h-[200px]">
                <SimpleTable.TD colSpan={11} className="!border-0">
                  <EmptyState text="You have not created any Tier yet" />
                </SimpleTable.TD>
              </SimpleTable.Row>
            )}
          </SimpleTable.Body>
        </SimpleTable>
      </div>
      <UpdateCredentialsModal />
    </>
  );
};

export default S3TiersTab;
