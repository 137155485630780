import {
  Controller,
  UseFormReturn,
  useFieldArray,
  useForm,
} from "react-hook-form";
import { WebAppCreateFormData, WebAppSecretFile } from "../../../types/web-app";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { ReactComponent as ArchiveIcon } from "./../../../assets/icons/archive-box.svg";
import { ReactComponent as PlusIcon } from "./../../../assets/icons/plus.svg";
import { ReactComponent as GithubIcon } from "./../../../assets/icons/socials/github.svg";
import { useCallback, useEffect, useRef, useState } from "react";
import { WebAppSecretFileSchema } from "../../../utils/validations";
import { yupResolver } from "@hookform/resolvers/yup";
import ImportFromEnvFileModal from "../../settings/env-variables/ImportFromEnvFileModal";
import { handleShowImportFromEnvFileModal } from "../../../store/settings/env-variables/envVariablesSlice";
import { WebAppPlansGroup } from "../single-page/WebAppPlanTab";
import {
  Flex,
  Input,
  Modal,
  Textarea,
  Button,
  Card,
  Typography,
  AnimatedFormError,
  Select,
} from "djuno-design";
import {
  getWebAppCheckNameAsync,
  handleChangeWebAppNameValidation,
  handleSetWebAppBuildCommand,
  handleSetWebAppGitBranch,
  handleSetWebAppRuntime,
  handleSetWebAppStartCommand,
  selectWebAppBuildCommand,
  selectWebAppGitBranch,
  selectWebAppGitRepo,
  selectWebAppNameValidation,
  selectWebAppNameValidationLoading,
  selectWebAppRuntime,
  selectWebAppSelectedDeployType,
  selectWebAppStartCommand,
} from "../../../store/web-app/webAppCreateSlice";
import {
  getWebAppsAsync,
  selectWebApps,
  selectWebAppsLoading,
} from "../../../store/web-app/webAppsSlice";
import { formatTimestamp } from "../../../utils/date";
import { getGithubRepoBranchesApi } from "../../../apis/repositoriesAPI";
import { selectRepoAccounts } from "../../../store/settings/registeries/repositoriesSlice";
import { GitBranch } from "../../../types/repositories";

const runtimes = [
  {
    id: "nodejs",
    name: "Node.js",
    version: "18.x",
    build_command: "npm run build",
    start_command: "npm run start",
  },
  {
    id: "python",
    name: "Python",
    version: "3.10",
    build_command: "pip install -r requirements.txt",
    start_command: "python main.py",
  },
  {
    id: "go",
    name: "Go",
    version: "1.19",
    build_command: "go build -o app",
    start_command: "./app",
  },
  {
    id: "ruby",
    name: "Ruby",
    version: "3.1",
    build_command: "bundle install",
    start_command: "ruby main.rb",
  },
  {
    id: "php",
    name: "PHP",
    version: "8.1",
    build_command: "",
    start_command: "php -S localhost:8000 -t public",
  },
];

const ConfigsStep: React.FC<{
  form: UseFormReturn<WebAppCreateFormData>;
  showPort?: boolean;
  showEnvVars?: boolean;
}> = ({ form, showPort = true, showEnvVars = true }) => {
  // use prev web-apps to calc limits in WebAppPlansGroup
  const webApps = useAppSelector(selectWebApps);
  const webAppsLoading = useAppSelector(selectWebAppsLoading);

  const desployType = useAppSelector(selectWebAppSelectedDeployType);

  const repoAccounts = useAppSelector(selectRepoAccounts);
  const selectedGitRepo = useAppSelector(selectWebAppGitRepo);
  const selectedBranch = useAppSelector(selectWebAppGitBranch);
  const [branchesLoading, setBranchesLoading] = useState<boolean>(false);
  const [branches, setBranches] = useState<Array<GitBranch>>([]);
  const selectedRuntime = useAppSelector(selectWebAppRuntime);
  const buildCommand = useAppSelector(selectWebAppBuildCommand);
  const startCommand = useAppSelector(selectWebAppStartCommand);

  const dispatch = useAppDispatch();
  const nameValidationLoading = useAppSelector(
    selectWebAppNameValidationLoading
  );

  useEffect(() => {
    if (webApps.length === 0) {
      dispatch(getWebAppsAsync({}));
    }
  }, [dispatch, webApps.length]);

  const {
    register,
    formState: { errors, isValidating },
    control,
    trigger,
    watch,
    setValue,
  } = form;

  const {
    fields: ENVsFields,
    append: ENVappend,
    remove: ENVremove,
  } = useFieldArray({
    control,
    name: "EvironmentVariables",
  });

  useEffect(() => {
    if (isValidating) {
      trigger("EvironmentVariables");
    }
  }, [isValidating, trigger]);

  const nameValidation = useAppSelector(selectWebAppNameValidation);
  const currentWebAppName = watch("AppName");

  const timeout = useRef<NodeJS.Timeout | null>(null);
  const handleCheckWebAppName = useCallback(
    (value: string) => {
      dispatch(handleChangeWebAppNameValidation(null));
      timeout.current && clearTimeout(timeout.current);
      if (value && value !== "" && errors.AppName?.message === undefined) {
        timeout.current = setTimeout(
          () => dispatch(getWebAppCheckNameAsync({ name: value })),
          700
        );
      } else {
      }
    },
    [dispatch, errors.AppName]
  );

  useEffect(() => {
    handleCheckWebAppName(currentWebAppName);
  }, [errors.AppName, currentWebAppName, handleCheckWebAppName]);

  const handleChangeWebAppName = useCallback(
    (value: string) => {
      handleCheckWebAppName(value);
    },
    [handleCheckWebAppName]
  );

  useEffect(() => {
    if (selectedGitRepo) {
      setValue("AppName", selectedGitRepo.name);
    }
  }, [selectedGitRepo, setValue]);

  useEffect(() => {
    if (selectedGitRepo && repoAccounts && repoAccounts.GithubAccessToken) {
      setBranchesLoading(true);
      getGithubRepoBranchesApi(
        repoAccounts.GithubAccessToken,
        selectedGitRepo.owner.login,
        selectedGitRepo.name
      )
        .then((response) => setBranches(response.data))
        .catch((error) => console.error(error))
        .finally(() => setBranchesLoading(false));
    }
  }, [selectedGitRepo, repoAccounts, setValue]);

  useEffect(() => {
    if (selectedRuntime) {
      const runtimeData = runtimes.find(
        (runtime) => runtime.id === selectedRuntime
      );
      if (runtimeData) {
        dispatch(handleSetWebAppBuildCommand(runtimeData.build_command));
        dispatch(handleSetWebAppStartCommand(runtimeData.start_command));
      }
    }
  }, [dispatch, selectedRuntime]);

  return (
    <div className="flex my-5 mb-20 w-full">
      <div className="flex flex-col space-y-12 md:space-y-16 w-full">
        {/* Source Code */}
        {selectedGitRepo && (
          <div className="grid grid-cols-3 gap-x-10">
            <Flex direction="col" className="col-span-3 md:col-span-1 text-sm">
              <Typography.Text className="!font-medium !flex !items-center !gap-1.5">
                Source Code
              </Typography.Text>
            </Flex>
            <div className="col-span-3 md:col-span-2 border dark:border-dark-2 rounded-lg p-2">
              <Flex items="center" justify="between" className="gap-2">
                <Flex items="center" className="flex-1 gap-2">
                  <GithubIcon className="w-4 flex-shrink-0 dark:text-slate-200" />
                  <Flex items="center" className="gap-1">
                    <Typography.Text size="sm" className="font-medium">
                      {selectedGitRepo.owner.login}
                    </Typography.Text>
                    <Typography.Text size="xs" uiType="secondary">
                      {" "}
                      /{" "}
                    </Typography.Text>
                    <Typography.Text size="sm">
                      {selectedGitRepo.name}
                    </Typography.Text>
                    <Typography.Text
                      size="xs"
                      uiType="secondary"
                      className="ml-2"
                    >
                      {
                        formatTimestamp(
                          selectedGitRepo.pushed_at,
                          "MM/DD/YYYY HH:mm"
                        ).datetime
                      }
                    </Typography.Text>
                  </Flex>
                </Flex>
              </Flex>
            </div>
          </div>
        )}

        {/* App Name */}
        <div className="grid grid-cols-3 gap-x-10">
          <Flex direction="col" className="col-span-3 md:col-span-1 text-sm">
            <Typography.Text className="!font-medium !flex !items-center !gap-1.5">
              Name
            </Typography.Text>
            <Typography.Text
              uiType="secondary"
              className="!text-xs md:!text-sm "
            >
              A unique name for your web service.
            </Typography.Text>
          </Flex>
          <div className="col-span-3 md:col-span-2">
            <Controller
              control={control}
              name="AppName"
              render={({ field: { value, onChange } }) => (
                <Input
                  onChange={(e) => {
                    onChange(e);
                    handleChangeWebAppName(e.target.value);
                  }}
                  value={value}
                  placeholder="example-app-name"
                  error={
                    nameValidation === null
                      ? errors.AppName?.message
                      : nameValidation
                  }
                  loading={nameValidationLoading}
                />
              )}
            />
          </div>
        </div>

        {/* Instance Type */}
        <div className="mb-6">
          <Card title="Instance Type">
            <WebAppPlansGroup control={control}>
              <AnimatedFormError error={errors.PlanId?.message} />
            </WebAppPlansGroup>
          </Card>
        </div>

        {desployType === "git" && selectedGitRepo && (
          <>
            {/* branches */}
            <div className="grid grid-cols-3 gap-x-10">
              <Flex
                direction="col"
                className="col-span-3 md:col-span-1 text-sm"
              >
                <Typography.Text className="!font-medium !flex !items-center !gap-1.5">
                  Branch
                </Typography.Text>
                <Typography.Text
                  uiType="secondary"
                  className="!text-xs md:!text-sm "
                >
                  The Git branch to build and deploy.
                </Typography.Text>
              </Flex>
              <div className="col-span-3 md:col-span-2">
                <Select
                  onChange={(sb) =>
                    dispatch(handleSetWebAppGitBranch(sb || null))
                  }
                  value={selectedBranch || undefined}
                  options={[
                    ...branches.map((b) => ({ label: b.name, value: b.name })),
                  ]}
                  loading={branchesLoading}
                />
              </div>
            </div>

            {/* runtime */}
            <div className="grid grid-cols-3 gap-x-10">
              <Flex
                direction="col"
                className="col-span-3 md:col-span-1 text-sm"
              >
                <Typography.Text className="!font-medium !flex !items-center !gap-1.5">
                  Runtime
                </Typography.Text>
              </Flex>
              <div className="col-span-3 md:col-span-2">
                <Select
                  onChange={(runtime_id) =>
                    dispatch(handleSetWebAppRuntime(runtime_id || null))
                  }
                  value={selectedRuntime || undefined}
                  options={[
                    ...runtimes.map((runtime) => ({
                      label: (
                        <Flex items="center" className="gap-2">
                          <Typography.Text size="sm">
                            {runtime.name}
                          </Typography.Text>
                          <Typography.Text size="sm" uiType="secondary">
                            version: {runtime.version}
                          </Typography.Text>
                        </Flex>
                      ),
                      value: runtime.id,
                    })),
                  ]}
                />
              </div>
            </div>

            {/* Build Configuration */}
            <div className="grid grid-cols-3 gap-x-10">
              <Flex
                direction="col"
                className="col-span-3 md:col-span-1 text-sm"
              >
                <Typography.Text className="!font-medium !flex !items-center !gap-1.5">
                  Build Command{" "}
                  <Typography.Text
                    className="!text-xs !font-normal"
                    uiType="secondary"
                  >
                    Optional
                  </Typography.Text>
                </Typography.Text>
              </Flex>
              <div className="col-span-3 md:col-span-2">
                <Input
                  onChange={(e) =>
                    dispatch(handleSetWebAppBuildCommand(e.target.value))
                  }
                  value={buildCommand || ""}
                />
              </div>
            </div>

            {/* Start Configuration */}
            <div className="grid grid-cols-3 gap-x-10">
              <Flex
                direction="col"
                className="col-span-3 md:col-span-1 text-sm"
              >
                <Typography.Text className="!font-medium !flex !items-center !gap-1.5">
                  Start Command{" "}
                  <Typography.Text
                    className="!text-xs !font-normal"
                    uiType="secondary"
                  >
                    Optional
                  </Typography.Text>
                </Typography.Text>
              </Flex>
              <div className="col-span-3 md:col-span-2">
                <Input
                  onChange={(e) =>
                    dispatch(handleSetWebAppStartCommand(e.target.value))
                  }
                  value={startCommand || ""}
                />
              </div>
            </div>
          </>
        )}

        {/* port */}
        {showPort && (
          <div className="grid grid-cols-3 gap-x-10">
            <Flex direction="col" className="col-span-3 md:col-span-1 text-sm">
              <Typography.Text className="!font-medium !flex !items-center !gap-1.5">
                Port{" "}
                <Typography.Text
                  className="!text-xs !font-normal"
                  uiType="secondary"
                >
                  Optional
                </Typography.Text>
              </Typography.Text>
            </Flex>
            <div className="col-span-3 md:col-span-2">
              <Controller
                control={control}
                name="Port"
                render={({ field: { value, onChange } }) => (
                  <Input
                    onChange={onChange}
                    value={value !== null ? value : 80}
                    type="number"
                    error={errors.Port?.message}
                  />
                )}
              />
            </div>
          </div>
        )}

        {/* environment variables */}
        {showEnvVars && (
          <div className="grid grid-cols-3 gap-x-10">
            <div className="col-span-3 md:col-span-1 text-sm">
              <Typography.Text className="!font-medium !flex !items-center !gap-1.5">
                Environment Variables{" "}
                <Typography.Text
                  className="!text-xs !font-normal"
                  uiType="secondary"
                >
                  Optional
                </Typography.Text>
              </Typography.Text>
              <Typography.Text uiType="secondary" size="sm">
                Set environment-specific config and secrets (such as API keys),
                then read those values from your code.{" "}
                <Typography.Link className="!text-sm">
                  Learn more
                </Typography.Link>
                .
              </Typography.Text>
            </div>
            <div className="col-span-3 md:col-span-2">
              <div className="flex flex-col w-full">
                {ENVsFields.map((_, index) => (
                  <div key={index} className="grid gap-3 grid-cols-12 pb-2">
                    <div className="col-span-4">
                      <Input
                        label=""
                        {...register(
                          `EvironmentVariables.${index}.Name` as const
                        )}
                        error={
                          errors.EvironmentVariables &&
                          errors.EvironmentVariables[index] &&
                          errors.EvironmentVariables[index]?.Name &&
                          errors.EvironmentVariables[index]?.Name?.message
                        }
                        placeholder="Name"
                      />
                    </div>
                    <div className="col-span-7">
                      <div className="flex gap-2">
                        <div className="flex-1">
                          <Input
                            label=""
                            {...register(
                              `EvironmentVariables.${index}.Value` as const
                            )}
                            error={
                              errors.EvironmentVariables &&
                              errors.EvironmentVariables[index] &&
                              errors.EvironmentVariables[index]?.Value &&
                              errors.EvironmentVariables[index]?.Value?.message
                            }
                            placeholder="Value"
                            className="flex-1"
                          />
                        </div>
                        {/* {!getValues(`EvironmentVariables.${index}.Value`) && (
                        <Button
                          onClick={(e) => {
                            e.preventDefault();
                            handleGenerateEnvVal(index);
                          }}
                        >
                          generate
                        </Button>
                      )} */}
                      </div>
                    </div>
                    <div className="col-span-1 flex items-start justify-center">
                      <Button
                        uiType="icon"
                        className="!px-2 group"
                        disabled={false}
                        onClick={(e) => {
                          e.preventDefault();
                          ENVremove(index);
                        }}
                      >
                        <ArchiveIcon className="w-5 text-slate-700 dark:text-slate-300 group-hover:text-red-500 group-hover:dark:text-red-400" />
                      </Button>
                    </div>
                  </div>
                ))}
                <AnimatedFormError
                  error={errors.EvironmentVariables?.root?.message}
                />
                <AnimatedFormError
                  error={errors.EvironmentVariables?.message}
                />
                <div className="flex justify-start gap-2 mt-2">
                  <Button
                    uiType="light"
                    onClick={(e) => {
                      e.preventDefault();
                      ENVappend({
                        Name: "",
                        Value: "",
                      });
                    }}
                  >
                    <PlusIcon className="w-4" />
                    <Typography.Text
                      size="sm"
                      uiType="transparent"
                      className="hidden md:block"
                    >
                      Add Environment Variable
                    </Typography.Text>
                    <Typography.Text
                      size="sm"
                      uiType="transparent"
                      className="md:hidden"
                    >
                      Add Variable
                    </Typography.Text>
                  </Button>
                  <Button
                    uiType="light"
                    onClick={(e) => {
                      e.preventDefault();
                      dispatch(handleShowImportFromEnvFileModal());
                    }}
                  >
                    <Typography.Text size="sm" uiType="transparent">
                      Add from .env
                    </Typography.Text>
                  </Button>
                  <ImportFromEnvFileModal callback={ENVappend} />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export const SecretFileModal: React.FC<{
  isOpen: boolean;
  onClose: () => void;
  callback?: any;
  selectedFile?: WebAppSecretFile;
}> = ({ isOpen, onClose, callback, selectedFile }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
  } = useForm({
    resolver: yupResolver(WebAppSecretFileSchema),
  });

  const handleAddSecret = (data: any) => {
    if (callback) {
      callback(data);
      onClose();
    }
  };

  useEffect(() => {
    if (selectedFile) {
      setValue("Filename", selectedFile.Filename || "");
      setValue("FileContents", selectedFile.FileContents || "");
    } else {
      setValue("Filename", "");
      setValue("FileContents", "");
    }
  }, [selectedFile, setValue]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        reset();
        onClose();
      }}
      title="Secret File"
      contentClassName="max-w-xl"
    >
      <form onSubmit={handleSubmit(handleAddSecret)} className="w-full">
        <Flex direction="col" className="gap-4 mt-5">
          <Input
            label="Filename"
            placeholder="file.txt"
            {...register("Filename")}
            error={errors.Filename?.message}
          />
          <Textarea
            label="File Contents"
            placeholder=""
            {...register("FileContents")}
            error={errors.FileContents?.message}
            rows={10}
          />
          <Flex justify="end" className="w-full">
            <Button uiType="primary" type="submit">
              Save
            </Button>
          </Flex>
        </Flex>
      </form>
    </Modal>
  );
};

export default ConfigsStep;
