import { useEffect, useState } from "react";
import { ReactComponent as MoreIcon } from "./../../../../assets/icons/more.svg";
import { ReactComponent as ArchiveIcon } from "./../../../../assets/icons/archive-box.svg";
import {
  deleteBucketEventsAsync,
  getBucketEventsAsync,
  handleShowEventEditor,
  selectBucketDetails,
  selectBucketDetailsLoading,
  selectBucketEvents,
  selectBucketEventsActionLoading,
  selectBucketEventsLoading,
} from "../../../../store/s3/buckets/bucketSlice";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import { S3NotActiveTab } from "../../../layouts/NotData";
import QuestionModal, { DeleteModal } from "../../../modals/QuestionModal";
import { S3BucketEvent } from "../../../../types/s3-bucket";
import S3SubscribeEventsModal from "../modals/buckets/S3SubscribeEventsModal";
import { selectS3NodeStatus } from "../../../../store/s3/s3PublicSlice";
import {
  Button,
  Dropdown,
  EmptyState,
  SimpleTable,
  Typography,
} from "djuno-design";

const S3BucketEventsTab = () => {
  const s3Status = useAppSelector(selectS3NodeStatus);
  const bucketDetails = useAppSelector(selectBucketDetails);
  const bucketDetailsLoading = useAppSelector(selectBucketDetailsLoading);

  const bucketEvents = useAppSelector(selectBucketEvents);
  const bucketEventsLoading = useAppSelector(selectBucketEventsLoading);
  const bucketEventsActionLoading = useAppSelector(
    selectBucketEventsActionLoading
  );

  const [deleteEvent, setDeleteEvent] = useState<S3BucketEvent | null>(null);
  const dispatch = useAppDispatch();
  // const navigate = useNavigate();

  useEffect(() => {
    if (bucketDetails && !bucketDetailsLoading) {
      dispatch(getBucketEventsAsync({ name: bucketDetails.name }));
    }
  }, [bucketDetails, dispatch, bucketDetailsLoading]);

  const handleDelete = () => {
    if (deleteEvent && bucketDetails) {
      const deleteData: any = {
        events: deleteEvent.events,
        prefix: deleteEvent.prefix,
        suffix: deleteEvent.suffix,
      };
      dispatch(
        deleteBucketEventsAsync({
          name: bucketDetails.name,
          arn: deleteEvent.arn,
          data: deleteData,
        })
      ).then((action) => {
        if (action.type === "bucket/events/delete/fulfilled") {
          dispatch(getBucketEventsAsync({ name: bucketDetails.name }));
          setDeleteEvent(null);
        }
      });
    }
  };

  if (s3Status !== 2) {
    return <S3NotActiveTab />;
  }
  return (
    <>
      <div className="flex transition-all duration-500 items-center justify-between gap-3">
        <Typography.Title
          level={5}
          className="!mb-0 whitespace-nowrap font-medium"
        >
          Bucket Events
        </Typography.Title>
        <div className="flex items-center justify-end gap-1 md:gap-2 ">
          <Button
            uiType="primary"
            onClick={() => dispatch(handleShowEventEditor({}))}
          >
            Subscribe to Event
          </Button>
        </div>
      </div>
      <div className="mt-5 w-full">
        <SimpleTable
          loading={bucketEventsLoading}
          containerClassName="min-h-[240px]"
        >
          <SimpleTable.Head>
            <SimpleTable.Row>
              <SimpleTable.TH lable="SQS" />
              <SimpleTable.TH lable="Events" />
              <SimpleTable.TH lable="Prefix" />
              <SimpleTable.TH lable="Suffix" />
              <SimpleTable.TH lable="" />
            </SimpleTable.Row>
          </SimpleTable.Head>
          <SimpleTable.Body>
            {bucketEvents?.map((event, i) => (
              <SimpleTable.Row key={i}>
                <SimpleTable.TD>
                  <Typography.Text className="!text-xs md:!text-sm font-semibold whitespace-nowrap">
                    {event.arn}
                  </Typography.Text>
                </SimpleTable.TD>
                <SimpleTable.TD>
                  <Typography.Text className="!text-xs md:!text-sm whitespace-nowrap">
                    {event.events.join(", ")}
                  </Typography.Text>
                </SimpleTable.TD>

                <SimpleTable.TD>
                  <Typography.Text className="!text-xs md:!text-sm whitespace-nowrap">
                    {event.prefix}
                  </Typography.Text>
                </SimpleTable.TD>

                <SimpleTable.TD>
                  <Typography.Text className="!text-xs md:!text-sm whitespace-nowrap">
                    {event.suffix}
                  </Typography.Text>
                </SimpleTable.TD>

                <SimpleTable.TD className="w-20 gap-1">
                  <div className="h-full w-full inline-flex items-center justify-end gap-1">
                    <div className="w-8 flex justify-center items-center">
                      <Dropdown
                        itemsClassName="!w-40"
                        anchor="bottom end"
                        menu={[
                          {
                            key: "end",
                            label: (
                              <div className="flex items-center gap-1">
                                <ArchiveIcon className="w-4" />
                                Delete
                              </div>
                            ),
                            danger: true,
                            onClick: () => setDeleteEvent(event),
                          },
                        ]}
                      >
                        <Button uiType="icon" uiSize="small" className="!px-2">
                          <MoreIcon className="w-4 h-4" />
                        </Button>
                      </Dropdown>
                    </div>
                  </div>
                </SimpleTable.TD>
              </SimpleTable.Row>
            ))}

            {bucketEvents?.length === 0 && (
              <SimpleTable.Row withoutHoverStyle={true} className="h-[200px]">
                <SimpleTable.TD colSpan={5} className="!border-0">
                  <EmptyState text="You have not subscribed any event yet" />
                </SimpleTable.TD>
              </SimpleTable.Row>
            )}
          </SimpleTable.Body>
        </SimpleTable>
      </div>
      <S3SubscribeEventsModal />
      <QuestionModal
        isOpen={deleteEvent !== null}
        onClose={() => setDeleteEvent(null)}
        onConfirm={handleDelete}
        loading={bucketEventsActionLoading}
        confirmButtonType="danger"
      />
      <DeleteModal
        title="Delete Event"
        isOpen={deleteEvent !== null}
        onClose={() => setDeleteEvent(null)}
        onConfirm={handleDelete}
        loading={bucketEventsActionLoading}
        // confirmString={
        //   bucketEvents.find((event) => event.arn === deleteEvent?.arn)
        //     ?.arn
        // }
        confirmButtonType="danger"
      />
    </>
  );
};

export default S3BucketEventsTab;
