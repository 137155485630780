import { useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import {
  getWebAppAsync,
  handleShowWebAppUpdateImageModal,
  selectWebApp,
  selectWebAppLoading,
  selectWebAppOverview,
  selectWebAppUpdateLoading,
  updateWebAppAsync,
  handleHideWebAppUpdateImageModal,
} from "../../../../store/web-app/webAppSlice";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  WebAppBuildCommandSchema,
  WebAppNameSchema,
  WebAppPortSchema,
  WebAppStartCommandSchema,
} from "../../../../utils/validations";
import WebAppImageEditorModal from "./WebAppImageEditorModal";
import {
  deleteWebAppAsync,
  selectWebAppsActionLoading,
  selectWebAppsPlansLoading,
  selectWebAppsPlans,
  suspendWebAppAsync,
} from "../../../../store/web-app/webAppsSlice";
import { Link, useNavigate } from "react-router-dom";
import { WebAppScalingUrl, WebAppsUrl } from "../../../../utils/urls";
import { Button, Card, Flex, Input, Loading, Typography } from "djuno-design";
import QuestionModal, { DeleteModal } from "../../../modals/QuestionModal";
import {
  getCredentialsAsync,
  selectCredentials,
} from "../../../../store/settings/registeries/registeriesSlice";
import {
  handleClearWebAppCreationSlice,
  handleSetWebAppCredentialId,
  handleSetWebAppImageQuery,
  handleSetWebAppImageValue,
  handleSetWebAppTagQuery,
  handleSetWebAppTagValue,
} from "../../../../store/web-app/webAppCreateSlice";
import { ReactComponent as GithubIcon } from "./../../../../assets/icons/socials/github.svg";

const WebAppSettingsTab = () => {
  return (
    <div className="flex flex-col gap-10">
      <General />
      <Deploy />
      <CustomDomains />
      {/* <HealthChecks /> */}
      <DangerZone />
    </div>
  );
};

const General = () => {
  const webApp = useAppSelector(selectWebApp);

  const updateLoading = useAppSelector(selectWebAppUpdateLoading);

  const [canEditName, setCanEditName] = useState<boolean>(false);
  const [canEditPort, setCanEditPort] = useState<boolean>(false);

  const webAppPlans = useAppSelector(selectWebAppsPlans);
  const webAppPlansLoading = useAppSelector(selectWebAppsPlansLoading);

  const plan = useMemo(() => {
    return webAppPlans.find((p) => p.Id === webApp?.PlanId);
  }, [webApp?.PlanId, webAppPlans]);

  const dispatch = useAppDispatch();

  const {
    handleSubmit,
    register,
    formState: { isValid },
    setValue,
  } = useForm({
    mode: "all",
    resolver: yupResolver(WebAppNameSchema),
  });

  const {
    handleSubmit: handleSubmitPort,
    register: registerPort,
    formState: { errors: portErrors, isValid: isValidPort },
    setValue: setValuePort,
    setFocus: setFocusPort,
  } = useForm({
    mode: "all",
    resolver: yupResolver(WebAppPortSchema),
  });

  useEffect(() => {
    if (webApp) {
      setValue("Name", webApp.ServiceName);
      setValuePort("Port", webApp.Port ? webApp.Port.toString() : "80");
    }
  }, [setValue, setValuePort, webApp]);

  const handleChangeName = (data: any) => {
    if (webApp) {
      dispatch(
        updateWebAppAsync({
          webAppId: webApp.Id.toString(),
          data: { ...webApp, Name: data.Name },
        })
      ).then((action) => {
        if (action.type === "web-app/update/fulfilled") {
          setCanEditName(false);
          dispatch(
            getWebAppAsync({
              webAppId: webApp.Id.toString(),
              withoutLoading: true,
            })
          );
        }
      });
    }
  };

  const handleChangePort = (data: any) => {
    // console.log(data);
    if (webApp) {
      dispatch(
        updateWebAppAsync({
          webAppId: webApp.Id.toString(),
          data: { ...webApp, Port: Number(data.Port) },
        })
      ).then((action) => {
        if (action.type === "web-app/update/fulfilled") {
          setCanEditPort(false);
          dispatch(
            getWebAppAsync({
              webAppId: webApp.Id.toString(),
              withoutLoading: true,
            })
          );
        }
      });
    }
  };

  return (
    <Card title="General">
      <form onSubmit={handleSubmit(handleChangeName)}>
        <div className="grid grid-cols-3 gap-x-10 my-10">
          <Flex direction="col" className="col-span-3 md:col-span-1">
            <Typography.Text size="sm" className="font-medium">
              Name
            </Typography.Text>
            <Typography.Text size="sm" className="mt-1" uiType="secondary">
              A unique name for your Web Service.
            </Typography.Text>
          </Flex>
          <div className="col-span-3 md:col-span-2">
            <Input disabled={!canEditName} {...register("Name")} />
            <div className="mt-2 w-full flex justify-end gap-3">
              {/* {!canEditName && (
                <Button
                  onClick={(e) => {
                    e.preventDefault();
                    setCanEditName(true);
                    setTimeout(() => setFocus("Name"), 100);
                  }}
                >
                  Edit
                </Button>
              )} */}
              {canEditName && (
                <>
                  <Button
                    onClick={(e) => {
                      e.preventDefault();
                      setCanEditName(false);
                      setValue("Name", webApp?.ServiceName || "");
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    disabled={!isValid}
                    type="submit"
                    uiType="primary"
                    loading={updateLoading}
                  >
                    Save Changes
                  </Button>
                </>
              )}
            </div>
          </div>
        </div>
      </form>

      <form onSubmit={handleSubmitPort(handleChangePort)}>
        <div className="grid grid-cols-3 gap-x-10 my-10">
          <Flex direction="col" className="col-span-3 md:col-span-1">
            <Typography.Text size="sm" className="font-medium">
              Port
            </Typography.Text>
          </Flex>
          <div className="col-span-3 md:col-span-2">
            <Input
              disabled={!canEditPort}
              type="number"
              {...registerPort("Port")}
              error={portErrors.Port?.message}
            />
            <div className="mt-2 w-full flex justify-end gap-3">
              {!canEditPort && !webApp?.IsSuspended && (
                <Button
                  onClick={(e) => {
                    e.preventDefault();
                    setCanEditPort(true);
                    setTimeout(() => setFocusPort("Port"), 100);
                  }}
                >
                  Edit
                </Button>
              )}
              {canEditPort && (
                <>
                  <Button
                    onClick={(e) => {
                      e.preventDefault();
                      setCanEditPort(false);
                      setValuePort("Port", webApp?.Port?.toString() || "80");
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    disabled={!isValidPort}
                    type="submit"
                    uiType="primary"
                    loading={updateLoading}
                  >
                    Save Changes
                  </Button>
                </>
              )}
            </div>
          </div>
        </div>
      </form>

      {/* <div className="grid grid-cols-3 gap-x-10 my-10">
        <Flex direction="col">
          <Typography.Text size="sm" className="font-medium">
            Region
          </Typography.Text>
          <Typography.Text size="sm" className="mt-1" uiType="secondary">
            The region where your web service runs.
          </Typography.Text>
        </Flex>
        <div className="col-span-2">
          <Input value={webApp?.Region || ""} disabled />
        </div>
      </div> */}

      <div className="grid grid-cols-3 gap-x-10 my-10">
        <div className="col-span-3 md:col-span-1 text-sm">
          <Typography.Text size="sm" className="font-medium">
            Instance Type
          </Typography.Text>
        </div>
        <div className="col-span-3 md:col-span-2">
          {webAppPlansLoading && <Loading borderSize={2} />}
          {!webAppPlansLoading && (
            <div className="antialiasedleading-6 py-3 px-4 bg-slate-100 dark:bg-gray-700 rounded border border-solid border-slate-200 dark:border-gray-600">
              <div className="flex justify-between flex-col sm:flex-row items-start sm:items-center space-x-0 space-y-4 sm:space-y-0 sm:space-x-6">
                <div className="flex flex-1 flex-col lg:flex-row items-start lg:items-center">
                  <Typography.Text size="sm">{plan?.Name}</Typography.Text>
                  <div className="w-[1px] h-[24px] bg-slate-200 dark:bg-gray-600 mx-4 hidden lg:block" />
                  <div className="flex space-x-5 text-sm">
                    {plan?.PlanDescription && (
                      <div className="flex gap-1">
                        <Typography.Text size="sm" className="font-medium">
                          {JSON.parse(plan.PlanDescription)?.CPU} CPU
                        </Typography.Text>
                      </div>
                    )}

                    {plan?.PlanDescription && (
                      <div className="flex gap-1">
                        <Typography.Text size="sm" className="font-medium">
                          {JSON.parse(plan.PlanDescription)?.RAM}
                        </Typography.Text>
                      </div>
                    )}
                  </div>
                </div>
                {webApp && !webApp.IsSuspended && (
                  <Link to={WebAppScalingUrl(webApp.Id.toString())}>
                    <Button>Update</Button>
                  </Link>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </Card>
  );
};

const Deploy = () => {
  const dispatch = useAppDispatch();

  const webApp = useAppSelector(selectWebApp);
  const webAppLoading = useAppSelector(selectWebAppLoading);
  const updateLoading = useAppSelector(selectWebAppUpdateLoading);

  const credentials = useAppSelector(selectCredentials);

  const registery = useMemo(() => {
    return credentials.find((c) => c.Id === webApp?.RegistryId);
  }, [credentials, webApp?.RegistryId]);

  const [canEditBuildCommand, setCanEditBuildCommand] =
    useState<boolean>(false);
  const {
    handleSubmit: handleSubmitBuildCommand,
    register: registerBuildCommand,
    formState: { isValid: isValidBuildCommand },
    setValue: setValueBuildCommand,
    setFocus: setFocusBuildCommand,
  } = useForm({
    mode: "all",
    resolver: yupResolver(WebAppBuildCommandSchema),
  });

  const [canEditStartCommand, setCanEditStartCommand] =
    useState<boolean>(false);
  const {
    handleSubmit: handleSubmitStartCommand,
    register: registerStartCommand,
    formState: { isValid: isValidStartCommand },
    setValue: setValueStartCommand,
    setFocus: setFocusStartCommand,
  } = useForm({
    mode: "all",
    resolver: yupResolver(WebAppStartCommandSchema),
  });

  useEffect(() => {
    if (credentials.length === 0) dispatch(getCredentialsAsync());
  }, [credentials.length, dispatch]);

  useEffect(() => {
    return () => {
      dispatch(handleClearWebAppCreationSlice({}));
      dispatch(handleHideWebAppUpdateImageModal());
    };
  }, [dispatch]);

  const handleChangeBuildCommand = (data: any) => {
    // console.log(data);
    if (webApp) {
      dispatch(
        updateWebAppAsync({
          webAppId: webApp.Id.toString(),
          data: { ...webApp, BuildCommand: data.BuildCommand },
        })
      ).then((action) => {
        if (action.type === "web-app/update/fulfilled") {
          setCanEditBuildCommand(false);
          dispatch(
            getWebAppAsync({
              webAppId: webApp.Id.toString(),
              withoutLoading: true,
            })
          );
        }
      });
    }
  };

  const handleChangeStartCommand = (data: any) => {
    // console.log(data);
    if (webApp) {
      dispatch(
        updateWebAppAsync({
          webAppId: webApp.Id.toString(),
          data: { ...webApp, StartCommand: data.StartCommand },
        })
      ).then((action) => {
        if (action.type === "web-app/update/fulfilled") {
          setCanEditBuildCommand(false);
          dispatch(
            getWebAppAsync({
              webAppId: webApp.Id.toString(),
              withoutLoading: true,
            })
          );
        }
      });
    }
  };

  useEffect(() => {
    if (webApp) {
      setValueBuildCommand("BuildCommand", webApp.BuildCommand || "");
    }
  }, [setValueBuildCommand, webApp]);

  useEffect(() => {
    if (webApp) {
      setValueStartCommand("StartCommand", webApp.StartCommand || "");
    }
  }, [setValueStartCommand, webApp]);

  if (webApp?.IsSmart) return null;
  return (
    <Card title="Deploy">
      {webApp?.Runtime === "docker" && (
        <div className="grid grid-cols-3 gap-x-10 my-10">
          <Flex direction="col" className="col-span-3 md:col-span-1">
            <Typography.Text size="sm" className="font-medium">
              Image
            </Typography.Text>
            <Typography.Text size="sm" uiType="secondary" className="mt-1">
              The image URL and credential used for your Web Service.
            </Typography.Text>
          </Flex>
          <div className="col-span-3 md:col-span-2">
            <div className="mb-4 flex justify-end">
              {webApp && (
                <Button
                  onClick={() => {
                    dispatch(
                      handleSetWebAppCredentialId(
                        webApp.RegistryId ? webApp.RegistryId.toString() : null
                      )
                    );
                    dispatch(handleSetWebAppImageQuery(webApp.ImageName || ""));
                    dispatch(handleSetWebAppTagQuery(webApp.ImageTag || ""));
                    dispatch(handleSetWebAppImageValue(webApp.ImageName || ""));
                    dispatch(handleSetWebAppTagValue(webApp.ImageTag || ""));
                    dispatch(handleShowWebAppUpdateImageModal());
                  }}
                  disabled={webAppLoading || webApp === null}
                >
                  Edit
                </Button>
              )}

              <WebAppImageEditorModal />
            </div>
            <div className="flex flex-col space-y-6 bg-slate-50/50 dark:bg-gray-700 border border-solid border-slate-200 dark:border-gray-600 rounded-lg p-4">
              <div>
                <Input
                  label="Image URL"
                  readOnly
                  value={`${webApp?.ImageName}:${webApp?.ImageTag}`}
                  disabled
                />
              </div>
              <div>
                <Input
                  label="Credential (Optional)"
                  readOnly
                  value={registery?.Name || "No Credential"}
                  disabled
                />
              </div>
            </div>
          </div>
        </div>
      )}

      {webApp && webApp.RepositoryUrl && (
        <>
          <div className="grid grid-cols-3 gap-x-10 my-10">
            <Flex direction="col" className="col-span-3 md:col-span-1">
              <Typography.Text size="sm" className="font-medium">
                Source Code
              </Typography.Text>
            </Flex>
            <div className="col-span-3 md:col-span-2">
              <div className="col-span-3 md:col-span-2 border dark:border-dark-2 rounded-lg p-2">
                <Flex items="center" justify="between" className="gap-2">
                  <Flex items="center" className="flex-1 gap-2">
                    <GithubIcon className="w-4 flex-shrink-0 dark:text-slate-200" />
                    <Flex items="center" className="gap-1">
                      <Typography.Text size="sm" className="font-medium">
                        {webApp?.RepositoryUrl} : {webApp?.Branch}
                      </Typography.Text>
                    </Flex>
                  </Flex>
                </Flex>
              </div>
            </div>
          </div>
          <form onSubmit={handleSubmitBuildCommand(handleChangeBuildCommand)}>
            <div className="grid grid-cols-3 gap-x-10 my-10">
              <Flex direction="col" className="col-span-3 md:col-span-1">
                <Typography.Text size="sm" className="font-medium">
                  Build Command
                </Typography.Text>
              </Flex>
              <div className="col-span-3 md:col-span-2">
                <Input
                  disabled={!canEditBuildCommand}
                  {...registerBuildCommand("BuildCommand")}
                />
                <div className="mt-2 w-full flex justify-end gap-3">
                  {!canEditBuildCommand && !webApp?.IsSuspended && (
                    <Button
                      onClick={(e) => {
                        e.preventDefault();
                        setCanEditBuildCommand(true);
                        setTimeout(
                          () => setFocusBuildCommand("BuildCommand"),
                          100
                        );
                      }}
                    >
                      Edit
                    </Button>
                  )}
                  {canEditBuildCommand && (
                    <>
                      <Button
                        onClick={(e) => {
                          e.preventDefault();
                          setCanEditBuildCommand(false);
                          setValueBuildCommand(
                            "BuildCommand",
                            webApp?.BuildCommand || ""
                          );
                        }}
                      >
                        Cancel
                      </Button>
                      <Button
                        disabled={!isValidBuildCommand}
                        type="submit"
                        uiType="primary"
                        loading={updateLoading}
                      >
                        Save Changes
                      </Button>
                    </>
                  )}
                </div>
              </div>
            </div>
          </form>
          <form onSubmit={handleSubmitStartCommand(handleChangeStartCommand)}>
            <div className="grid grid-cols-3 gap-x-10 my-10">
              <Flex direction="col" className="col-span-3 md:col-span-1">
                <Typography.Text size="sm" className="font-medium">
                  Start Command
                </Typography.Text>
              </Flex>
              <div className="col-span-3 md:col-span-2">
                <Input
                  disabled={!canEditStartCommand}
                  {...registerStartCommand("StartCommand")}
                />
                <div className="mt-2 w-full flex justify-end gap-3">
                  {!canEditStartCommand && !webApp?.IsSuspended && (
                    <Button
                      onClick={(e) => {
                        e.preventDefault();
                        setCanEditStartCommand(true);
                        setTimeout(
                          () => setFocusStartCommand("StartCommand"),
                          100
                        );
                      }}
                    >
                      Edit
                    </Button>
                  )}
                  {canEditStartCommand && (
                    <>
                      <Button
                        onClick={(e) => {
                          e.preventDefault();
                          setCanEditStartCommand(false);
                          setValueStartCommand(
                            "StartCommand",
                            webApp?.StartCommand || ""
                          );
                        }}
                      >
                        Cancel
                      </Button>
                      <Button
                        disabled={!isValidStartCommand}
                        type="submit"
                        uiType="primary"
                        loading={updateLoading}
                      >
                        Save Changes
                      </Button>
                    </>
                  )}
                </div>
              </div>
            </div>
          </form>
        </>
      )}
    </Card>
  );
};

const CustomDomains = () => {
  const docsUrl = process.env.REACT_APP_DOCS_URL || "";
  const webAppOverview = useAppSelector(selectWebAppOverview);

  const url = useMemo(() => {
    if (webAppOverview?.Production?.Spec?.Domain) {
      return `https://${webAppOverview?.Production?.Spec?.Domain}`;
    }
    return undefined;
  }, [webAppOverview]);

  return (
    <Card title="Custom Domains">
      <div className="flex flex-col gap-2 mt-4">
        <Typography.Text size="sm" className="text-sm">
          Your service is always available at{" "}
          <Typography.Link className="!text-sm" href={url} target="_blank">
            {url}
          </Typography.Link>
          .
        </Typography.Text>
        <Typography.Text size="sm">
          You can also point custom domains you own to this service. See{" "}
          <Typography.Link
            href={docsUrl + "/en/articles/10077258-how-to-set-a-custom-domain"}
            className="!text-sm"
          >
            DNS configuration instructions
          </Typography.Link>
          .
        </Typography.Text>
      </div>
      {/* <div className="mt-4">
        <Button>Add Custom Domain</Button>
      </div> */}
    </Card>
  );
};

const DangerZone = () => {
  const webApp = useAppSelector(selectWebApp);
  const webAppLoading = useAppSelector(selectWebAppLoading);

  // const webAppOverview = useAppSelector(selectWebAppOverview);
  // const webAppOverviewLoading = useAppSelector(selectWebAppOverviewLoading);

  const actionLoading = useAppSelector(selectWebAppsActionLoading);
  const webAppUpdateLoading = useAppSelector(selectWebAppUpdateLoading);

  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [showSuspendModal, setShowSuspendModal] = useState<boolean>(false);
  const [showResumeModal, setShowResumeModal] = useState<boolean>(false);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const replicaCount = useMemo(() => {
    return webApp?.ReplicaCounts;
  }, [webApp]);

  const handleDelete = () => {
    if (webApp)
      dispatch(deleteWebAppAsync({ id: webApp.Id })).then((action) => {
        if (action.type === "web-apps/delete/fulfilled") {
          navigate(WebAppsUrl);
        }
      });
  };

  const handleSuspend = () => {
    if (webApp)
      dispatch(suspendWebAppAsync({ id: webApp.Id })).then((action) => {
        if (action.type === "web-apps/suspend/fulfilled") {
          setShowSuspendModal(false);
          dispatch(getWebAppAsync({ webAppId: webApp.Id.toString() }));
        }
      });
  };

  const handleResume = () => {
    if (webApp)
      dispatch(
        updateWebAppAsync({
          webAppId: webApp.Id.toString(),
          data: {
            ...webApp,
            ReplicaCounts: 1,
          },
        })
      ).then((action) => {
        // dispatch(resumeWebAppAsync({ id: webApp.Id })).then((action) => {
        if (action.type === "web-app/update/fulfilled") {
          // if (action.type === "web-apps/resume/fulfilled") {
          setShowResumeModal(false);
          dispatch(getWebAppAsync({ webAppId: webApp.Id.toString() }));
          // dispatch(getWebAppOverviewAsync({ webAppId: webApp.Id.toString() }));
        }
      });
  };

  return (
    <div className="flex gap-3">
      <Button
        uiType="danger"
        disabled={webAppLoading}
        onClick={() => setShowDeleteModal(true)}
      >
        Delete Web App
      </Button>
      {replicaCount !== undefined && (
        <>
          {replicaCount === 0 && (
            <Button
              uiType="light"
              disabled={webAppLoading}
              onClick={() => setShowResumeModal(true)}
            >
              Resume Web App
            </Button>
          )}
          {replicaCount > 0 && (
            <Button
              uiType="dangerLight"
              disabled={webAppLoading}
              onClick={() => setShowSuspendModal(true)}
            >
              Suspend Web App
            </Button>
          )}
        </>
      )}

      <DeleteModal
        title="Delete Web App"
        description={
          <Flex direction="col" className="!mt-6">
            <Typography.Text size="sm">
              All resources for {webApp?.ServiceName} will stop working
              immediately. This action cannot be undone.
            </Typography.Text>
            <Typography.Text size="sm" className="mt-2 text-sm">
              Are you sure you want to delete this web service?
            </Typography.Text>
          </Flex>
        }
        isOpen={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        confirmString={webApp?.ServiceName}
        confirmButtonType="danger"
        confirmButtonText="Delete Web App"
        onConfirm={handleDelete}
        loading={actionLoading}
      />

      <DeleteModal
        title="Suspend Web App"
        description={
          <Flex direction="col" className="!mt-6">
            <Typography.Text size="sm">
              All resources for {webApp?.ServiceName} will stop working
              immediately.
            </Typography.Text>
            <Typography.Text size="sm" className="mt-2 text-sm">
              Are you sure you want to suspend this web service?
            </Typography.Text>
          </Flex>
        }
        isOpen={showSuspendModal}
        onClose={() => setShowSuspendModal(false)}
        confirmString={webApp?.ServiceName}
        confirmButtonType="danger"
        confirmButtonText="Suspend Web App"
        onConfirm={handleSuspend}
        loading={actionLoading}
      />

      <QuestionModal
        title="Resume Web App"
        description={
          <Flex direction="col" className="!mt-4">
            <Typography.Text size="sm" className="mt-2 text-sm">
              Are you sure you want to resume this web service?
            </Typography.Text>
          </Flex>
        }
        isOpen={showResumeModal}
        onClose={() => setShowResumeModal(false)}
        confirmButtonType="primary"
        confirmButtonText="Yes, I'm sure"
        onConfirm={handleResume}
        loading={actionLoading || webAppUpdateLoading}
      />
    </div>
  );
};
export default WebAppSettingsTab;
