import { Helmet } from "react-helmet";
import {
  Button,
  Dropdown,
  EmptyState,
  Flex,
  SimpleTable,
  Tag,
  Typography,
  SecureCopy,
} from "djuno-design";
import useServiceType from "../../hooks/useServiceType";
import { DeleteModal } from "../../components/modals/QuestionModal";
import { ReactComponent as RefreshIcon } from "./../../assets/icons/arrow-path.svg";
import { ReactComponent as PlusIcon } from "./../../assets/icons/plus.svg";
import { ReactComponent as DetailsIcon } from "./../../assets/icons/bars-3-bottom-left.svg";
import { ReactComponent as ArchiveIcon } from "./../../assets/icons/archive-box.svg";
import { ReactComponent as MoreIcon } from "./../../assets/icons/more.svg";
import { ReactComponent as ArrowRightIcon } from "./../../assets/icons/arrow-right.svg";

import { useNavigate } from "react-router-dom";
import { K8ServicesCreateUrl, K8GeneralInformationUrl } from "../../utils/urls";
import HighlighterText from "../../components/general/HighlighterText";
import { useSearch } from "../../providers/SearchProvider";
import { formatTimestamp } from "../../utils/date";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { useEffect, useState } from "react";
import {
  deleteK8ServiceAsync,
  getK8ServicesAsync,
  K8RefreshStatus,
  selectK8Services,
  selectK8ServicesActionLoading,
  selectK8ServicesLoading,
} from "../../store/kubernetes/k8ServicesSlice";
import { K8Service } from "../../types/kubernetes";

const K8ServicesPage = () => {
  const { serviceType } = useServiceType(
    process.env.REACT_APP_KUBERNETES_SERVICE_TYPE
  );

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { value: searchValue } = useSearch();

  const services = useAppSelector(selectK8Services);
  console.log({ services });
  const loading = useAppSelector(selectK8ServicesLoading);

  const [deleteService, setDeleteService] = useState<K8Service | null>(null);

  const [filteredServices, setFilteredServices] = useState<K8Service[]>([]);

  const actionLoading = useAppSelector(selectK8ServicesActionLoading);

  useEffect(() => {
    dispatch(getK8ServicesAsync({}));
  }, [dispatch]);

  useEffect(() => {
    const lookedUpFiles = services?.filter((service) =>
      service.name.toLowerCase().includes(searchValue.toLowerCase())
    );
    setFilteredServices(lookedUpFiles);
  }, [dispatch, searchValue, services]);

  //refresh the list
  useEffect(() => {
    let interval: NodeJS.Timeout | undefined;
    let intervalTime =
      Number(process.env.REACT_APP_NODES_REFRESH_INTERVAL) || 20000;

    const shouldSetInterval = services.some((service) =>
      K8RefreshStatus.includes(service.status)
    );

    if (shouldSetInterval) {
      interval = setInterval(() => {
        dispatch(getK8ServicesAsync({ withoutLoading: true }));
      }, intervalTime);
    } else {
      if (interval) clearInterval(interval);
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [dispatch, services]);

  return (
    <>
      <Helmet>
        <title>
          {process.env.REACT_APP_NAME} | {serviceType?.ServiceName || ""}
        </title>
        <meta name="description" content="" />
      </Helmet>
      <div className="flex items-center justify-between h-20">
        <div className="px-6 items-center justify-between flex flex-1 transition duration-150">
          <Typography.Title level={5} className="!mb-0">
            {serviceType?.ServiceName}
          </Typography.Title>
        </div>
        {/* <div className="mr-6 w-96"></div> */}
      </div>
      <div className="mx-6">
        <div className="py-6">
          <div className="">
            <div className="flex items-center justify-between databases-header">
              <div className="items-center justify-between flex flex-1 transition duration-150">
                <Typography.Text className="font-medium text-lg"></Typography.Text>
              </div>
              <div className="flex items-center gap-2">
                <Button
                  //   onClick={() => {
                  //     run();
                  //   }}
                  disabled
                >
                  Start Tour
                </Button>
                <Button
                  onClick={() => dispatch(getK8ServicesAsync({}))}
                  className="refresh-button group"
                  tooltip={{ content: "Refresh" }}
                >
                  <RefreshIcon className="w-4 h-4 group-hover:rotate-90 group-hover:scale-110 transition-all duration-500" />
                </Button>
                <Button
                  uiType="primary"
                  onClick={() => navigate(K8ServicesCreateUrl)}
                  className="create-database-button group"
                >
                  Create Service
                  <PlusIcon className="w-3 h-3 group-hover:rotate-90 group-hover:scale-110 transition-all duration-500" />
                </Button>
              </div>
            </div>

            <div className="mt-5 w-full database-table">
              <SimpleTable loading={loading} containerClassName="min-h-[240px]">
                <SimpleTable.Head>
                  <SimpleTable.Row>
                    <SimpleTable.TH lable="Name" />
                    <SimpleTable.TH lable="ID" />
                    <SimpleTable.TH lable="Region" />
                    <SimpleTable.TH lable="Version" />
                    <SimpleTable.TH lable="Creation date" />
                    <SimpleTable.TH lable="Status" />
                    <SimpleTable.TH lable="" />
                  </SimpleTable.Row>
                </SimpleTable.Head>
                <SimpleTable.Body>
                  {filteredServices.map((service, i) => (
                    <SimpleTable.Row
                      key={i}
                      onClick={() =>
                        navigate(K8GeneralInformationUrl(service.id))
                      }
                    >
                      <SimpleTable.TD className="cursor-pointer">
                        <Flex>
                          <HighlighterText
                            searchValue={searchValue}
                            textToHighlight={service.name}
                            className="max-w-[110px] md:max-w-[400px] lg:max-w-[350px] xl:max-w-[400px] truncate"
                          />
                        </Flex>
                      </SimpleTable.TD>

                      <SimpleTable.TD className="cursor-pointer">
                        <SecureCopy text={service.id} type="copy" />
                      </SimpleTable.TD>

                      <SimpleTable.TD className="cursor-pointer">
                        <Typography.Text className="!text-xs md:!text-sm">
                          {service.region}
                        </Typography.Text>
                      </SimpleTable.TD>

                      <SimpleTable.TD className="cursor-pointer">
                        <Typography.Text className="!text-xs md:!text-sm">
                          {service.version}
                        </Typography.Text>
                      </SimpleTable.TD>

                      <SimpleTable.TD className="cursor-pointer">
                        <Typography.Text className="!text-xs md:!text-sm">
                          {
                            formatTimestamp(service.createdAt, "DD/MM/YYYY")
                              .date
                          }
                        </Typography.Text>
                      </SimpleTable.TD>
                      <SimpleTable.TD className="cursor-pointer">
                        <Tag
                          className="text-xs"
                          color={
                            service.status === "CREATING"
                              ? "warning"
                              : service.status === "READY"
                              ? "success"
                              : "processing"
                          }
                        >
                          {service.status}
                        </Tag>
                      </SimpleTable.TD>

                      <SimpleTable.TD className="w-32 gap-1">
                        <div className="h-full w-full inline-flex items-center justify-end gap-1">
                          <div className="w-8 flex justify-center items-center">
                            <Dropdown
                              anchor="bottom end"
                              itemsClassName="!w-48"
                              menu={[
                                {
                                  key: "1",
                                  label: (
                                    <div className="flex items-center gap-1">
                                      <DetailsIcon className="w-4" />
                                      General Information
                                    </div>
                                  ),
                                  onClick: (_, close) => {
                                    close();
                                    navigate(
                                      K8GeneralInformationUrl(service.id)
                                    );
                                  },
                                  disabled: loading,
                                },
                                {
                                  type: "divider",
                                },
                                {
                                  key: "end",
                                  label: (
                                    <div className="flex items-center gap-1">
                                      <ArchiveIcon className="w-4" />
                                      Delete
                                    </div>
                                  ),
                                  danger: true,
                                  onClick: (_, close) => {
                                    close();
                                    setDeleteService(service);
                                  },
                                  disabled:
                                    loading || service.status !== "READY",
                                },
                              ]}
                            >
                              <Button
                                uiType="icon"
                                uiSize="small"
                                className="!px-2"
                              >
                                <MoreIcon className="w-4 h-4" />
                              </Button>
                            </Dropdown>
                          </div>
                          <ArrowRightIcon className="w-4 h-4 dark:text-slate-100 text-slate-800 cursor-pointer" />
                        </div>
                      </SimpleTable.TD>
                    </SimpleTable.Row>
                  ))}

                  {services.length === 0 && (
                    <SimpleTable.Row
                      withoutHoverStyle={true}
                      className="h-[200px]"
                    >
                      <SimpleTable.TD colSpan={7} className="!border-0">
                        <EmptyState text="You have not created any kubernetes yet" />
                      </SimpleTable.TD>
                    </SimpleTable.Row>
                  )}
                </SimpleTable.Body>
              </SimpleTable>
            </div>
            <DeleteModal
              title="Delete Managed Kubernetes Service"
              isOpen={deleteService !== null}
              onClose={() => setDeleteService(null)}
              description=""
              confirmButtonText="Confirm"
              confirmButtonType="danger"
              confirmButtonClassName="w-full"
              loading={actionLoading}
              onConfirm={() => {
                deleteService &&
                  dispatch(
                    deleteK8ServiceAsync({
                      id: deleteService.id,
                    })
                  ).then((action) => {
                    if (action.type === "k8-services/delete/fulfilled") {
                      dispatch(getK8ServicesAsync({}));
                      setDeleteService(null);
                    }
                  });
              }}
              confirmString={deleteService?.name}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default K8ServicesPage;
