import { useCallback, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "./../../../../hooks";

import {
  Accordion,
  Button,
  Flex,
  Input,
  Modal,
  Switcher,
  Textarea,
  Typography,
} from "djuno-design";
import {
  getK8ServiceAsync,
  handleToggleK8ConfigureOIDCModal,
  K8ConfigureOIDCAsync,
  selectK8ConfigureOIDCModal,
  selectK8OpenIdConnect,
  selectK8OpenIdConnectLoading,
  selectK8Service,
  selectK8ServiceActionLoading,
  selectK8ServiceLoading,
} from "./../../../../store/kubernetes/k8ServiceSlice";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { K8ConfigureOIDCSchema } from "./../../../../utils/validations";
import { getK8ServicesAsync } from "../../../../store/kubernetes/k8ServicesSlice";

const K8ConfigureOIDCModal = () => {
  const [selectedAlgorithms, setSelectedAlgorithms] = useState<string[]>([]);
  const isOpen = useAppSelector(selectK8ConfigureOIDCModal);
  const k8Service = useAppSelector(selectK8Service);
  const k8ServiceLoading = useAppSelector(selectK8ServiceLoading);
  const k8ServiceActionLoading = useAppSelector(selectK8ServiceActionLoading);
  const openIdConnect = useAppSelector(selectK8OpenIdConnect);
  const openIdConnectLoading = useAppSelector(selectK8OpenIdConnectLoading);
  const dispatch = useAppDispatch();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(K8ConfigureOIDCSchema),
    mode: "all",
  });

  const onChange = (algorithm: string) => {
    setSelectedAlgorithms((prev) =>
      prev.includes(algorithm)
        ? prev.filter((a) => a !== algorithm)
        : [...prev, algorithm]
    );
  };

  useEffect(() => {
    if (isOpen && openIdConnect) {
      reset({
        issuerUrl: openIdConnect.issuerUrl || "",
        clientId: openIdConnect.clientId || "",
        caContent: openIdConnect.caContent || "",
        groupsClaim: openIdConnect.groupsClaim?.[0] || "",
        groupsPrefix: openIdConnect.groupsPrefix || "",
        requiredClaim: openIdConnect.requiredClaim?.join(", ") || "",
        usernameClaim: openIdConnect.usernameClaim || "sub",
        usernamePrefix: openIdConnect.usernamePrefix || "oidc:",
      });

      setSelectedAlgorithms(openIdConnect.signingAlgorithms || []);
    }
  }, [isOpen, openIdConnect, reset]);

  //TODO
  const onSubmit = (data: any) => {
    if (k8Service && !k8ServiceLoading) {
      dispatch(
        K8ConfigureOIDCAsync({
          kubeId: k8Service.id,
          data: {
            issuerUrl: data.issuerUrl,
            clientId: data.clientId,
            caContent: data.caContent || "",
            groupsClaim: data.groupsClaim ? [data.groupsClaim] : [],
            groupsPrefix: data.groupsPrefix || "",
            requiredClaim: data.requiredClaim
              ? data.requiredClaim.split(",").map((item: string) => item.trim())
              : [],
            signingAlgorithms: selectedAlgorithms, // بدون مقدار پیش‌فرض
            usernameClaim: data.usernameClaim || "sub",
            usernamePrefix: data.usernamePrefix || "oidc:",
          },
        })
      ).then((action) => {
        if (action.type === "k8-service/openIdConnect/fulfilled") {
          handleOnClose();
          dispatch(getK8ServicesAsync({ withoutLoading: false }));
          dispatch(getK8ServiceAsync({ id: k8Service.id }));
        }
      });
    }
  };

  const handleOnClose = useCallback(() => {
    reset();
    setSelectedAlgorithms([]);
    dispatch(handleToggleK8ConfigureOIDCModal(false));
  }, [dispatch, reset]);

  useEffect(() => {
    return () => {
      handleOnClose();
    };
  }, [handleOnClose]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleOnClose}
      contentClassName="max-w-xl"
      title="Configure the OIDC provider"
    >
      <form onSubmit={handleSubmit(onSubmit)} className="w-full">
        {" "}
        <div className="flex flex-col mt-5 overflow-hidden w-full max-h-[70vh] overflow-y-auto p-4 scrollbar-thin scrollbar-thumb-gray-400">
          <div className="space-y-5 mt-4">
            <Typography.Text className="!text-sm">
              You can use most OIDC providers like Dex, Keycloak or a SaaS
              provider, provided that they expose the “OpenID Connect discovery”
              feature. The OIDC provider must be accessible from a HTTPS URL,
              and a signed CA certificate is mandatory.
            </Typography.Text>
          </div>
          <div className="flex flex-col mt-4">
            <Typography.Text strong className="!text-xs">
              Provider URL (required)
            </Typography.Text>
            <Typography.Text className="!text-sm">
              Enter your provider's discovery URL here, without a path. The URL
              must point to the previous level of
              .well-known/openid-configuration. You must use a URL starting with
              https://
            </Typography.Text>

            <Input
              containerClassName="!mt-6"
              {...register("issuerUrl")}
              error={errors.issuerUrl?.message}
              placeholder="https://your-identity-provider.com"
            />
          </div>
          <div className=" flex flex-col  mt-4">
            <Typography.Text strong className="!text-xs">
              Client ID (required)
            </Typography.Text>
            <Typography.Text className="!text-sm">
              Enter the Kubernetes cluster ID here from your OIDC provider, e.g.
              "cluster-kubernetes-production". This is the character string of
              your choice (note that some OIDC providers may be more
              restrictive).
            </Typography.Text>

            <Input
              containerClassName="!mt-6"
              {...register("clientId")}
              error={errors.clientId?.message}
              placeholder="k8s-client"
            />
          </div>

          <div className="flex flex-col  mt-4">
            <Accordion
              items={[
                {
                  label: "Optional configuration",
                  panel: (
                    <div className="flex flex-col w-full max-h-[70vh] overflow-y-auto p-4 scrollbar-thin scrollbar-thumb-gray-400 ">
                      <div className=" flex flex-col  mt-4">
                        <Typography.Text strong className="!text-xs">
                          caContent (optional)
                        </Typography.Text>
                        <Typography.Text className="!text-sm">
                          The content of the certificate authority (CA)
                          certificate, in base64 format, that signed your ID
                          provider's web certificate. By default, uses the
                          host's root CA.
                        </Typography.Text>

                        <Textarea
                          {...register("caContent")}
                          error={errors.caContent?.message}
                          placeholder='"LS0tLS1CRUdJTiBDRVJUSUZJQ0FURS0tLS0t...
	                       <base64-encoded CA content continues here>"'
                        />
                      </div>
                      <div className=" flex flex-col  mt-4">
                        <Typography.Text strong className="!text-xs">
                          groupsClaim (optional)
                        </Typography.Text>
                        <Typography.Text className="!text-sm">
                          The JWT claim to use as the user group. If the claim
                          is used, it must be a table of character strings.
                        </Typography.Text>

                        <Input
                          {...register("groupsClaim")}
                          error={errors.groupsClaim?.message}
                          placeholder="groups"
                        />
                      </div>
                      <div className=" flex flex-col  mt-4">
                        <Typography.Text strong className="!text-xs">
                          groupsPrefix (optional)
                        </Typography.Text>
                        <Typography.Text className="!text-sm">
                          A prefix added to group claims (groupsClaim) to avoid
                          conflicts with existing names (such as system:groups).
                          For example, the value oidc: will create group names
                          like oidc:engineering and oidc:infra.
                        </Typography.Text>

                        <Input placeholder="oidc" />
                      </div>
                      <div className=" flex flex-col  mt-4">
                        <Typography.Text strong className="!text-sm">
                          requiredClaim (optional)
                        </Typography.Text>
                        <Typography.Text className="!text-sm">
                          Key=value pairs that describe the claims required in
                          the ID token. If defined, the claims are verified to
                          be included in the ID token with a corresponding
                          value.
                        </Typography.Text>

                        <Input placeholder="group=admin,group=dev-team" />
                      </div>
                      <div className=" flex flex-col mt-4">
                        <Typography.Text strong className="!text-xs">
                          signingAlgorithms (optional)
                        </Typography.Text>
                        <Typography.Text className="!text-sm">
                          Accepted signature algorithms. By default, it is
                          recommended to use the "RS256" algorithm.
                        </Typography.Text>
                        <div className="w-full max-w-4xl mx-auto grid grid-cols-3 gap-4 mt-5">
                          {[
                            "ES256",
                            "ES384",
                            "ES512",
                            "PS256",
                            "PS384",
                            "PS512",
                            "RS256",
                            "RS384",
                            "RS512",
                          ].map((algorithm) => (
                            <div
                              key={algorithm}
                              className="flex items-center gap-2"
                            >
                              <Switcher
                                value={selectedAlgorithms.includes(algorithm)}
                                onChange={() => onChange(algorithm)}
                              />
                              <Typography.Text className="!text-xs">
                                {algorithm}
                              </Typography.Text>
                            </div>
                          ))}
                        </div>
                      </div>
                      <div className=" flex flex-col  mt-4">
                        <Typography.Text strong className="!text-sm">
                          usernameClaim (Optional)
                        </Typography.Text>
                        <Typography.Text className="!text-sm">
                          Claim JWT to be used as username. By default, this is
                          "sub", which is supposed to be a unique identifier for
                          the end user. Administrators can choose other claims,
                          such as email or name, depending on their provider.
                          However, claims other than email will be prefixed by
                          the issuer URL (Provider URL) to avoid name conflicts
                          with other plugins.
                        </Typography.Text>
                        <Input placeholder="sub" />
                      </div>
                      <div className=" flex flex-col  mt-4">
                        <Typography.Text strong className="!text-xs">
                          usernamePrefix (optional)
                        </Typography.Text>
                        <Typography.Text className="!text-sm">
                          A prefix added to user name claims to avoid conflicts
                          with existing names (such as system:users). For
                          example, the value oidc: will create usernames like
                          oidc:jane.doe. If this field is not set and
                          usernameClaim has a value other than email, the
                          default prefix will be (Issuer URL)# where (Issuer
                          URL) is the value of Provider URL. The "-" value can
                          be used to disable any prefix.
                        </Typography.Text>
                        <Input placeholder="oidc" />
                      </div>
                    </div>
                  ),
                },
              ]}
            />
          </div>

          <Flex items="center" justify="end" className="gap-2 mt-5">
            <Button onClick={handleOnClose}>Cancel</Button>
            <Button
              uiType="primary"
              type="submit"
              loading={k8ServiceActionLoading}
            >
              Add
            </Button>
          </Flex>
        </div>
      </form>
    </Modal>
  );
};

export default K8ConfigureOIDCModal;
