import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { IThunkRejectValue, RootState } from "../../../types";
import { isAxiosError } from "axios";
import toast from "react-hot-toast";
import {
  S3BucketDetails,
  S3BucketEncryption,
  S3BucketEncryptionDataType,
  S3BucketEvent,
  S3BucketObject,
  S3BucketObjectLocking,
  S3BucketPolicy,
  S3BucketQuota,
  S3BucketRetention,
  S3BucketSetAccessPolictDataType,
  S3BucketSetQuotaDataType,
  S3BucketSetRetentionDataType,
  S3BucketState,
  S3BucketVersioning,
  S3BucketVersioningDataType,
} from "../../../types/s3-bucket";
import {
  getBucketObjectLockingApi,
  getBucketDetailsApi,
  getBucketObjectsApi,
  getBucketQuotaApi,
  getBucketRetentionApi,
  getBucketVersioningApi,
  setBucketAccessPolicyApi,
  setBucketQuotaApi,
  setBucketRetentionApi,
  setBucketVersioningApi,
  updateBucketTagsApi,
  getBucketEventsApi,
  getBucketPoliciesApi,
  setBucketEncryptionApi,
  getBucketUsersApi,
  getBucketEncryptionApi,
  setBucketRewindApi,
  deleteBucketEventsApi,
  addBucketEventsApi,
} from "../../../apis/s3API";
import { fileParentFinder } from "../../../utils/file";
import { FileBreadCrumbItem } from "../../../types/ipfs-file";
import { changeS3NodeStatus, S3UpdatingMessage } from "../s3PublicSlice";
import { getExtractErrorCode, getExtractErrors } from "../../../apis";
import { CustomErrorToast } from "../../../components/general/Toast";
import { uuid } from "djuno-design";

export const objectsBreadcrumbDefaultItem = () => ({
  title: "Objects",
  id: uuid(),
});

const initialState: S3BucketState = {
  bucketDetails: null,
  bucketDetailsLoading: false,
  objects: [],
  objectsLoading: false,
  breadCrumbItems: [objectsBreadcrumbDefaultItem()],
  withVersions: false,
  checkedObjects: [],
  showCreateFolderModal: false,
  encryption: null,
  encryptionLoading: false,
  showEncryptionEditor: false,
  quata: null,
  quataLoading: false,
  showQuotaEditor: false,
  showAccessPolicyEditor: false,
  accessPolicyLoading: false,
  objectLocking: null,
  objectLockingLoading: false,
  tagsActionLoading: false,
  showTagEditor: false,
  retention: null,
  retentionLoading: false,
  showRetentionEditor: false,
  versioning: null,
  versioningLoading: false,
  showVersioningEditor: false,
  actionLoading: false,
  events: [],
  eventsLoading: false,
  eventActionLoading: false,
  showEventEditor: false,
  selectedEvent: null,
  policies: [],
  policiesLoading: false,
  users: [],
  usersLoading: false,
  rewindLoading: false,
  showRewindModal: false,
};

export const getBucketDetailsAsync = createAsyncThunk<
  { bucketDetails: S3BucketDetails },
  { name: string },
  IThunkRejectValue
>(
  "bucket",
  async ({ name }, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
      const response = await getBucketDetailsApi(name);

      const { Result, NodeStatus } = response.data;
      const bucketDetails = Result;

      dispatch(changeS3NodeStatus({ status: NodeStatus }));

      return fulfillWithValue({ bucketDetails });
    } catch (e) {
      if (isAxiosError(e)) {
        dispatch(
          changeS3NodeStatus({
            status: e.response?.data.NodeStatus || 4,
            message: S3UpdatingMessage,
          })
        );
        return fulfillWithValue({ bucketDetails: null });
      } else {
        return rejectWithValue({ message: getExtractErrors(e) });
      }
    }
  }
);

export const getBucketObjectsAsync = createAsyncThunk<
  { objects: S3BucketObject[] },
  { name: string },
  IThunkRejectValue
>(
  "bucket/objects",
  async (
    { name },
    { rejectWithValue, fulfillWithValue, getState, dispatch }
  ) => {
    try {
      const { s3Bucket } = getState() as RootState;
      const breadCrumbItems = s3Bucket.breadCrumbItems;
      const prefix = fileParentFinder(breadCrumbItems, true);

      //if we want to have deleted objects, we should pass withVersions=true //TODO: it's not a true way for deleted objects
      const withVersions = s3Bucket.withVersions;
      const response = await getBucketObjectsApi(name, prefix, withVersions);

      const { Result, NodeStatus } = response.data;
      const objects = Result || [];

      dispatch(changeS3NodeStatus({ status: NodeStatus }));

      return fulfillWithValue({ objects });
    } catch (e) {
      if (isAxiosError(e)) {
        dispatch(
          changeS3NodeStatus({
            status: e.response?.data.NodeStatus || 4,
            message: S3UpdatingMessage,
          })
        );
        return fulfillWithValue({ objects: [] });
      } else {
        return rejectWithValue({
          message: getExtractErrors(e),
          code: getExtractErrorCode(e),
        });
      }
    }
  }
);

export const getBucketEncryptionAsync = createAsyncThunk<
  {
    encryption: S3BucketEncryption;
  },
  { name: string },
  IThunkRejectValue
>(
  "bucket/encryption",
  async ({ name }, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
      const response = await getBucketEncryptionApi(name);

      const { Result, NodeStatus } = response.data;
      const encryption = Result;

      dispatch(changeS3NodeStatus({ status: NodeStatus }));

      return fulfillWithValue({ encryption });
    } catch (e) {
      if (isAxiosError(e)) {
        dispatch(
          changeS3NodeStatus({
            status: e.response?.data.NodeStatus || 4,
            message: S3UpdatingMessage,
          })
        );
        return fulfillWithValue({ encryption: null });
      } else {
        return rejectWithValue({ message: getExtractErrors(e) });
      }
    }
  }
);

export const setBucketEncryptionAsync = createAsyncThunk<
  any,
  { name: string; data: S3BucketEncryptionDataType },
  IThunkRejectValue
>("bucket/encryption/set", async ({ name, data }, { rejectWithValue }) => {
  try {
    const response = await setBucketEncryptionApi(name, data);
    return response.data;
  } catch (e) {
    return rejectWithValue({ message: getExtractErrors(e) });
  }
});

export const getBucketQuotaAsync = createAsyncThunk<
  { quota: S3BucketQuota },
  { name: string },
  IThunkRejectValue
>(
  "bucket/quota",
  async ({ name }, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
      const response = await getBucketQuotaApi(name);

      const { Result, NodeStatus } = response.data;
      const quota = Result;

      dispatch(changeS3NodeStatus({ status: NodeStatus }));

      return fulfillWithValue({ quota });
    } catch (e) {
      if (isAxiosError(e)) {
        dispatch(
          changeS3NodeStatus({
            status: e.response?.data.NodeStatus || 4,
            message: S3UpdatingMessage,
          })
        );
        return fulfillWithValue({ quota: null });
      } else {
        return rejectWithValue({ message: getExtractErrors(e) });
      }
    }
  }
);

export const setBucketQuotaAsync = createAsyncThunk<
  any,
  { name: string; formData: S3BucketSetQuotaDataType },
  IThunkRejectValue
>("bucket/quota/set", async ({ name, formData }, { rejectWithValue }) => {
  try {
    const response = await setBucketQuotaApi(name, formData);
    return response.data;
  } catch (e) {
    return rejectWithValue({ message: getExtractErrors(e) });
  }
});

export const setBucketAccessPolicyAsync = createAsyncThunk<
  any,
  { name: string; formData: S3BucketSetAccessPolictDataType },
  IThunkRejectValue
>(
  "bucket/access-policy/set",
  async ({ name, formData }, { rejectWithValue }) => {
    try {
      const response = await setBucketAccessPolicyApi(name, formData);
      return response.data;
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);

export const getBucketObjectLockingAsync = createAsyncThunk<
  { objectLocking: S3BucketObjectLocking },
  { name: string },
  IThunkRejectValue
>(
  "bucket/object-locking",
  async ({ name }, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
      const response = await getBucketObjectLockingApi(name);

      const { Result, NodeStatus } = response.data;
      const objectLocking = Result;

      dispatch(changeS3NodeStatus({ status: NodeStatus }));

      return fulfillWithValue({ objectLocking });
    } catch (e) {
      if (isAxiosError(e)) {
        dispatch(
          changeS3NodeStatus({
            status: e.response?.data.NodeStatus || 4,
            message: S3UpdatingMessage,
          })
        );
        return fulfillWithValue({ objectLocking: null });
      } else {
        return rejectWithValue({ message: getExtractErrors(e) });
      }
    }
  }
);

export const updateBucketTagsAsync = createAsyncThunk<
  any,
  { name: string; tags: { [key: string]: string } },
  IThunkRejectValue
>("bucket/tags/update", async ({ name, tags }, { rejectWithValue }) => {
  try {
    const responce = await updateBucketTagsApi(name, tags);
    return responce.data;
  } catch (e) {
    return rejectWithValue({ message: getExtractErrors(e) });
  }
});

export const getBucketRetentionAsync = createAsyncThunk<
  { retention: S3BucketRetention },
  { name: string },
  IThunkRejectValue
>(
  "bucket/retention",
  async ({ name }, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
      const response = await getBucketRetentionApi(name);

      const { Result, NodeStatus } = response.data;
      const retention = Result;

      dispatch(changeS3NodeStatus({ status: NodeStatus }));

      return fulfillWithValue({ retention });
    } catch (e) {
      if (isAxiosError(e)) {
        dispatch(
          changeS3NodeStatus({
            status: e.response?.data.NodeStatus || 4,
            message: S3UpdatingMessage,
          })
        );
        return fulfillWithValue({ retention: null });
      } else {
        return rejectWithValue({ message: getExtractErrors(e) });
      }
    }
  }
);

export const setBucketRetentionAsync = createAsyncThunk<
  any,
  { name: string; formData: S3BucketSetRetentionDataType },
  IThunkRejectValue
>("bucket/retention/set", async ({ name, formData }, { rejectWithValue }) => {
  try {
    const responce = await setBucketRetentionApi(name, formData);
    return responce.data;
  } catch (e) {
    return rejectWithValue({ message: getExtractErrors(e) });
  }
});

export const getBucketVersioningAsync = createAsyncThunk<
  { versioning: S3BucketVersioning },
  { name: string },
  IThunkRejectValue
>(
  "bucket/versioning",
  async ({ name }, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
      const response = await getBucketVersioningApi(name);

      const { Result, NodeStatus } = response.data;
      const versioning = Result;

      dispatch(changeS3NodeStatus({ status: NodeStatus }));

      return fulfillWithValue({ versioning });
    } catch (e) {
      if (isAxiosError(e)) {
        dispatch(
          changeS3NodeStatus({
            status: e.response?.data.NodeStatus || 4,
            message: S3UpdatingMessage,
          })
        );
        return fulfillWithValue({ versioning: null });
      } else {
        return rejectWithValue({ message: getExtractErrors(e) });
      }
    }
  }
);

export const getBucketEventsAsync = createAsyncThunk<
  { events: S3BucketEvent[] },
  { name: string },
  IThunkRejectValue
>(
  "bucket/events",
  async ({ name }, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
      const response = await getBucketEventsApi(name);

      const { Result, NodeStatus } = response.data;
      const events = Result || [];

      dispatch(changeS3NodeStatus({ status: NodeStatus }));

      return fulfillWithValue({ events });
    } catch (e) {
      if (isAxiosError(e)) {
        dispatch(
          changeS3NodeStatus({
            status: e.response?.data.NodeStatus || 4,
            message: S3UpdatingMessage,
          })
        );
        return fulfillWithValue({ events: [] });
      } else {
        return rejectWithValue({ message: getExtractErrors(e) });
      }
    }
  }
);

export const addBucketEventsAsync = createAsyncThunk<
  any,
  { bucketName: string; data: any },
  IThunkRejectValue
>("bucket/events/add", async ({ bucketName, data }, { rejectWithValue }) => {
  try {
    const response = await addBucketEventsApi(bucketName, data);
    return response.data;
    // const { Result, NodeStatus } = response.data;
    // const events = (Result || []) as S3BucketEvent[];
    // dispatch(changeS3NodeStatus(NodeStatus === 2));
    // return fulfillWithValue({ events });
  } catch (e) {
    return rejectWithValue({ message: getExtractErrors(e) });
  }
});

export const deleteBucketEventsAsync = createAsyncThunk<
  any,
  { name: string; arn: string; data: any },
  IThunkRejectValue
>("bucket/events/delete", async ({ name, arn, data }, { rejectWithValue }) => {
  try {
    const response = await deleteBucketEventsApi(name, arn, data);
    return response.data;
    // const { Result, NodeStatus } = response.data;
    // const events = (Result || []) as S3BucketEvent[];

    // dispatch(changeS3NodeStatus(NodeStatus === 2));

    // return fulfillWithValue({ events });
  } catch (e) {
    return rejectWithValue({ message: getExtractErrors(e) });
  }
});

export const getBucketPoliciesAsync = createAsyncThunk<
  { policies: S3BucketPolicy[] },
  { name: string },
  IThunkRejectValue
>(
  "bucket/policies",
  async ({ name }, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
      const response = await getBucketPoliciesApi(name);

      const { Result, NodeStatus } = response.data;
      const policies = Result || [];

      dispatch(changeS3NodeStatus({ status: NodeStatus }));

      return fulfillWithValue({ policies });
    } catch (e) {
      if (isAxiosError(e)) {
        dispatch(
          changeS3NodeStatus({
            status: e.response?.data.NodeStatus || 4,
            message: S3UpdatingMessage,
          })
        );
        return fulfillWithValue({ policies: [] });
      } else {
        return rejectWithValue({ message: getExtractErrors(e) });
      }
    }
  }
);

export const getBucketUsersAsync = createAsyncThunk<
  { users: string[] },
  { name: string },
  IThunkRejectValue
>(
  "bucket/users",
  async ({ name }, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
      const response = await getBucketUsersApi(name);

      const { Result, NodeStatus } = response.data;
      const users = Result || [];

      dispatch(changeS3NodeStatus({ status: NodeStatus }));

      return fulfillWithValue({ users });
    } catch (e) {
      if (isAxiosError(e)) {
        dispatch(
          changeS3NodeStatus({
            status: e.response?.data.NodeStatus || 4,
            message: S3UpdatingMessage,
          })
        );
        return fulfillWithValue({ users: [] });
      } else {
        return rejectWithValue({ message: getExtractErrors(e) });
      }
    }
  }
);

export const setBucketVersioningAsync = createAsyncThunk<
  any,
  { name: string; formData: S3BucketVersioningDataType },
  IThunkRejectValue
>("bucket/versioning/set", async ({ name, formData }, { rejectWithValue }) => {
  try {
    const responce = await setBucketVersioningApi(name, formData);
    return responce.data;
  } catch (e) {
    return rejectWithValue({ message: getExtractErrors(e) });
  }
});

export const setBucketRewindAsync = createAsyncThunk<
  any,
  { name: string; date: string },
  IThunkRejectValue
>("bucket/rewind/set", async ({ name, date }, { rejectWithValue }) => {
  try {
    const responce = await setBucketRewindApi(name, date);
    return responce.data;
  } catch (e) {
    return rejectWithValue({ message: getExtractErrors(e) });
  }
});

export const bucketSlice = createSlice({
  name: "bucket",
  initialState,
  reducers: {
    handleCleanUpBucket(state) {
      state.breadCrumbItems = [objectsBreadcrumbDefaultItem()];
      state.checkedObjects = [];
    },
    setBreadCrumb(
      state,
      action: PayloadAction<{ breadCrumbItems: Array<FileBreadCrumbItem> }>
    ) {
      state.breadCrumbItems = action.payload.breadCrumbItems;
    },
    updateBreadCrumb(state, action: PayloadAction<{ title: string }>) {
      state.breadCrumbItems = [
        ...state.breadCrumbItems,
        {
          title: action.payload.title,
          // id: Math.random().toString(16).slice(2),
        },
      ];
    },
    breadCrumbRedirect(state, action) {
      const breadCrumbIndex = action.payload;
      if (typeof breadCrumbIndex !== "undefined") {
        state.breadCrumbItems = state.breadCrumbItems.slice(
          0,
          breadCrumbIndex + 1
        );
      }
    },
    handleHideCreateFolderModal: (state) => {
      state.showCreateFolderModal = false;
    },
    handleShowCreateFolderModal: (state) => {
      state.showCreateFolderModal = true;
    },
    handleHideEncryptionModal: (state) => {
      state.showEncryptionEditor = false;
    },
    handleShowEncryptionModal: (state) => {
      state.showEncryptionEditor = true;
    },
    handleHideQuotaEditor: (state) => {
      state.showQuotaEditor = false;
    },
    handleShowQuotaEditor: (state) => {
      state.showQuotaEditor = true;
    },
    handleHideAccessPolictEditor: (state) => {
      state.showAccessPolicyEditor = false;
    },
    handleShowAccessPolictEditor: (state) => {
      state.showAccessPolicyEditor = true;
    },
    handleHideTagEditor: (state) => {
      state.showTagEditor = false;
    },
    handleShowTagEditor: (state) => {
      state.showTagEditor = true;
    },
    handleHideRetentionEditor: (state) => {
      state.showRetentionEditor = false;
    },
    handleShowRetentionEditor: (state) => {
      state.showRetentionEditor = true;
    },
    handleHideVerioningEditor: (state) => {
      state.showVersioningEditor = false;
    },
    handleShowVersioningEditor: (state) => {
      state.showVersioningEditor = true;
    },
    handleHideEventEditor: (state) => {
      state.showEventEditor = false;
      state.selectedEvent = null;
    },
    handleShowEventEditor: (
      state,
      action: { payload: { event?: S3BucketEvent } }
    ) => {
      state.showEventEditor = true;
      if (typeof action.payload.event !== "undefined") {
        state.selectedEvent = action.payload.event;
      }
    },
    handleHideRewindModal: (state) => {
      state.showRewindModal = false;
    },
    handleShowRewindModal: (state) => {
      state.showRewindModal = true;
    },
    handleChangeWithVersions: (state, action: PayloadAction<boolean>) => {
      state.withVersions = action.payload;
    },
    handleChangeCheckedObjects: (
      state,
      action: PayloadAction<S3BucketObject>
    ) => {
      const obj = action.payload;
      const isSelected =
        state.checkedObjects.find((o) => o.name === obj.name) || false;
      const filteredObjects = state.checkedObjects.filter(
        (o) => o.name !== obj.name
      );
      if (isSelected) {
        state.checkedObjects = [...filteredObjects];
      } else {
        state.checkedObjects = [...filteredObjects, obj];
      }
    },
    handleClearCheckedObjects: (state) => {
      state.checkedObjects = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBucketDetailsAsync.pending, (state) => {
        state.bucketDetailsLoading = true;
      })
      .addCase(getBucketDetailsAsync.fulfilled, (state, action) => {
        const { bucketDetails } = action.payload;

        state.bucketDetails = bucketDetails;
        state.bucketDetailsLoading = false;
      })
      .addCase(getBucketDetailsAsync.rejected, (state, { payload }) => {
        state.bucketDetailsLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(getBucketObjectsAsync.pending, (state) => {
        state.objectsLoading = true;
      })
      .addCase(getBucketObjectsAsync.fulfilled, (state, action) => {
        const { objects } = action.payload;
        state.objects = objects;
        state.objectsLoading = false;
      })
      .addCase(getBucketObjectsAsync.rejected, (state, { payload }) => {
        state.objectsLoading = false;
        if (payload?.code !== 404)
          if (payload?.message)
            toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(getBucketEncryptionAsync.pending, (state) => {
        state.encryptionLoading = true;
      })
      .addCase(getBucketEncryptionAsync.fulfilled, (state, action) => {
        state.encryption = action.payload.encryption;
        state.encryptionLoading = false;
      })
      .addCase(getBucketEncryptionAsync.rejected, (state, { payload }) => {
        state.encryptionLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(setBucketEncryptionAsync.pending, (state) => {
        state.encryptionLoading = true;
      })
      .addCase(setBucketEncryptionAsync.fulfilled, (state) => {
        state.encryptionLoading = false;
      })
      .addCase(setBucketEncryptionAsync.rejected, (state, { payload }) => {
        state.encryptionLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(getBucketQuotaAsync.pending, (state) => {
        state.quataLoading = true;
      })
      .addCase(getBucketQuotaAsync.fulfilled, (state, action) => {
        state.quata = action.payload.quota;
        state.quataLoading = false;
      })
      .addCase(getBucketQuotaAsync.rejected, (state, { payload }) => {
        state.quataLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(setBucketQuotaAsync.pending, (state) => {
        state.quataLoading = true;
      })
      .addCase(setBucketQuotaAsync.fulfilled, (state, action) => {
        state.quataLoading = false;
      })
      .addCase(setBucketQuotaAsync.rejected, (state, { payload }) => {
        state.quataLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(setBucketAccessPolicyAsync.pending, (state) => {
        state.accessPolicyLoading = true;
      })
      .addCase(setBucketAccessPolicyAsync.fulfilled, (state, action) => {
        state.accessPolicyLoading = false;
      })
      .addCase(setBucketAccessPolicyAsync.rejected, (state, { payload }) => {
        state.accessPolicyLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(getBucketObjectLockingAsync.pending, (state) => {
        state.objectLockingLoading = true;
      })
      .addCase(getBucketObjectLockingAsync.fulfilled, (state, action) => {
        state.objectLocking = action.payload.objectLocking;
        state.objectLockingLoading = false;
      })
      .addCase(getBucketObjectLockingAsync.rejected, (state, { payload }) => {
        state.objectLockingLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(updateBucketTagsAsync.pending, (state) => {
        state.tagsActionLoading = true;
      })
      .addCase(updateBucketTagsAsync.fulfilled, (state, action) => {
        state.tagsActionLoading = false;
      })
      .addCase(updateBucketTagsAsync.rejected, (state, { payload }) => {
        state.tagsActionLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(getBucketRetentionAsync.pending, (state) => {
        state.retentionLoading = true;
      })
      .addCase(getBucketRetentionAsync.fulfilled, (state, action) => {
        state.retention = action.payload.retention;
        state.retentionLoading = false;
      })
      .addCase(getBucketRetentionAsync.rejected, (state, { payload }) => {
        state.retentionLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(setBucketRetentionAsync.pending, (state) => {
        state.retentionLoading = true;
      })
      .addCase(setBucketRetentionAsync.fulfilled, (state, action) => {
        state.retentionLoading = false;
      })
      .addCase(setBucketRetentionAsync.rejected, (state, { payload }) => {
        state.retentionLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(getBucketVersioningAsync.pending, (state) => {
        state.versioningLoading = true;
      })
      .addCase(getBucketVersioningAsync.fulfilled, (state, action) => {
        state.versioning = action.payload.versioning;
        state.versioningLoading = false;
      })
      .addCase(getBucketVersioningAsync.rejected, (state, { payload }) => {
        state.versioningLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(setBucketVersioningAsync.pending, (state) => {
        state.versioningLoading = true;
      })
      .addCase(setBucketVersioningAsync.fulfilled, (state, action) => {
        state.versioningLoading = false;
      })
      .addCase(setBucketVersioningAsync.rejected, (state, { payload }) => {
        state.versioningLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(getBucketEventsAsync.pending, (state) => {
        state.eventsLoading = true;
      })
      .addCase(getBucketEventsAsync.fulfilled, (state, action) => {
        state.events = action.payload.events;
        state.eventsLoading = false;
      })
      .addCase(getBucketEventsAsync.rejected, (state, { payload }) => {
        state.eventsLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(addBucketEventsAsync.pending, (state) => {
        state.eventActionLoading = true;
      })
      .addCase(addBucketEventsAsync.fulfilled, (state, action) => {
        state.eventActionLoading = false;
      })
      .addCase(addBucketEventsAsync.rejected, (state, { payload }) => {
        state.eventActionLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(deleteBucketEventsAsync.pending, (state) => {
        state.eventActionLoading = true;
      })
      .addCase(deleteBucketEventsAsync.fulfilled, (state, action) => {
        state.eventActionLoading = false;
      })
      .addCase(deleteBucketEventsAsync.rejected, (state, { payload }) => {
        state.eventActionLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(getBucketPoliciesAsync.pending, (state) => {
        state.policiesLoading = true;
      })
      .addCase(getBucketPoliciesAsync.fulfilled, (state, action) => {
        state.policies = action.payload.policies;
        state.policiesLoading = false;
      })
      .addCase(getBucketPoliciesAsync.rejected, (state, { payload }) => {
        state.policiesLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(getBucketUsersAsync.pending, (state) => {
        state.usersLoading = true;
      })
      .addCase(getBucketUsersAsync.fulfilled, (state, action) => {
        state.users = action.payload.users;
        state.usersLoading = false;
      })
      .addCase(getBucketUsersAsync.rejected, (state, { payload }) => {
        state.usersLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(setBucketRewindAsync.pending, (state) => {
        state.rewindLoading = true;
      })
      .addCase(setBucketRewindAsync.fulfilled, (state) => {
        state.rewindLoading = false;
      })
      .addCase(setBucketRewindAsync.rejected, (state, { payload }) => {
        state.rewindLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });
  },
});

//details
export const selectBucketDetails = (state: RootState) =>
  state.s3Bucket.bucketDetails;
export const selectBucketDetailsLoading = (state: RootState) =>
  state.s3Bucket.bucketDetailsLoading;

//objects
export const selectBucketObjects = (state: RootState) => state.s3Bucket.objects;
export const selectObjectsLoading = (state: RootState) =>
  state.s3Bucket.objectsLoading;
export const selectWithVersions = (state: RootState) =>
  state.s3Bucket.withVersions;
export const selectCheckedObjects = (state: RootState) =>
  state.s3Bucket.checkedObjects;

//breadcrumb
export const selectBucketBreadCrumbItems = (state: RootState) =>
  state.s3Bucket.breadCrumbItems;

//folder
export const selectShowCreateFolderModal = (state: RootState) =>
  state.s3Bucket.showCreateFolderModal;

//encryption
export const selectBucketEncryption = (state: RootState) =>
  state.s3Bucket.encryption;
export const selectShowEncryptionEditor = (state: RootState) =>
  state.s3Bucket.showEncryptionEditor;
export const selectEncryptionLoading = (state: RootState) =>
  state.s3Bucket.encryptionLoading;

//quota
export const selectBucketQuota = (state: RootState) => state.s3Bucket.quata;
export const selectBucketQuotaLoading = (state: RootState) =>
  state.s3Bucket.quataLoading;
export const selectShowQuotaEditor = (state: RootState) =>
  state.s3Bucket.showQuotaEditor;

//access-policy
export const selectBucketAccessPolicyLoading = (state: RootState) =>
  state.s3Bucket.accessPolicyLoading;
export const selectShowAccessPolicyEditor = (state: RootState) =>
  state.s3Bucket.showAccessPolicyEditor;

//object-locking
export const selectBucketObjectLocking = (state: RootState) =>
  state.s3Bucket.objectLocking;
export const selectBucketObjectLockingLoading = (state: RootState) =>
  state.s3Bucket.objectLockingLoading;

//tags
export const selectBucketTagsActionLoading = (state: RootState) =>
  state.s3Bucket.tagsActionLoading;
export const selectShowTagEditor = (state: RootState) =>
  state.s3Bucket.showTagEditor;

//retention
export const selectBucketRetention = (state: RootState) =>
  state.s3Bucket.retention;
export const selectBucketRetentionLoading = (state: RootState) =>
  state.s3Bucket.retentionLoading;
export const selectShowRetentionEditor = (state: RootState) =>
  state.s3Bucket.showRetentionEditor;

//retention
export const selectBucketVersioning = (state: RootState) =>
  state.s3Bucket.versioning;
export const selectBucketVersioningLoading = (state: RootState) =>
  state.s3Bucket.versioningLoading;
export const selectShowVersioningEditor = (state: RootState) =>
  state.s3Bucket.showVersioningEditor;

//action
export const selectActionLoading = (state: RootState) =>
  state.s3Bucket.actionLoading;

//bucket-events
export const selectBucketEvents = (state: RootState) => state.s3Bucket.events;
export const selectBucketEventsLoading = (state: RootState) =>
  state.s3Bucket.eventsLoading;
export const selectBucketEventsActionLoading = (state: RootState) =>
  state.s3Bucket.eventActionLoading;
export const selectShowBucketEventEditor = (state: RootState) =>
  state.s3Bucket.showEventEditor;

//access-logs
export const selectBucketPolicies = (state: RootState) =>
  state.s3Bucket.policies;
export const selectBucketPoliciesLoading = (state: RootState) =>
  state.s3Bucket.policiesLoading;
export const selectBucketUsers = (state: RootState) => state.s3Bucket.users;
export const selectBucketUsersLoading = (state: RootState) =>
  state.s3Bucket.usersLoading;

//rewind
export const selectBucketRewindLoading = (state: RootState) =>
  state.s3Bucket.rewindLoading;
export const selectShowRewindModal = (state: RootState) =>
  state.s3Bucket.showRewindModal;

export const {
  handleCleanUpBucket,
  setBreadCrumb,
  updateBreadCrumb,
  breadCrumbRedirect,
  handleShowCreateFolderModal,
  handleHideCreateFolderModal,
  handleShowEncryptionModal,
  handleHideEncryptionModal,
  handleShowQuotaEditor,
  handleHideQuotaEditor,
  handleShowAccessPolictEditor,
  handleHideAccessPolictEditor,
  handleShowTagEditor,
  handleHideTagEditor,
  handleShowRetentionEditor,
  handleHideRetentionEditor,
  handleShowVersioningEditor,
  handleHideVerioningEditor,
  handleShowEventEditor,
  handleHideEventEditor,
  handleShowRewindModal,
  handleHideRewindModal,
  handleChangeWithVersions,
  handleChangeCheckedObjects,
  handleClearCheckedObjects,
} = bucketSlice.actions;
export default bucketSlice.reducer;
