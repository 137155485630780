import { useAppDispatch, useAppSelector } from "../../../hooks";
import { IpfsNotActiveTab } from "../../layouts/NotData";
import {
  activateIpfsAsync,
  changeIpfsNodeStatus,
  selectIpfsNodeStatus,
} from "../../../store/ipfs/ipfsPublicSlice";
import { PlansTab } from "../../plans/UniversalPlansBox";
import DeactivatePlan from "../../plans/DeactivatePlan";
import { useNavigate } from "react-router-dom";
import { IpfsFilesUrl } from "../../../utils/urls";

const IpfsPlansTab = () => {
  const ipfsServiceType = Number(process.env.REACT_APP_IPFS_SERVICE_TYPE) || 12;
  const ipfsStatus = useAppSelector(selectIpfsNodeStatus);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  if (ipfsStatus !== 2) {
    return <IpfsNotActiveTab />;
  }
  return (
    <>
      <PlansTab serviceType={ipfsServiceType} />
      <DeactivatePlan
        deactivateFunction={() => {
          dispatch(activateIpfsAsync({ isActive: false })).then((action) => {
            if (action.type === "ipfs/activate/fulfilled") {
              navigate(IpfsFilesUrl);
            }
          });
          // dispatch(changeIpfsNodeStatus({ status: 5 }));
        }}
      />
    </>
  );
};

export default IpfsPlansTab;
