import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import toast from "react-hot-toast";
import { IThunkRejectValue, RootState } from "../../types";
import { getExtractErrors } from "../../apis";
import { CustomErrorToast } from "../../components/general/Toast";
import {
  DataStreamServicesState,
  DataStreamService,
} from "../../types/data-straming";
import { getDatabaseServicesApi } from "../../apis/databaseAPI";

const initialState: DataStreamServicesState = {
  services: [],
  loading: false,
};

export const getDataStreamServicesAsync = createAsyncThunk<
  { services: Array<DataStreamService> },
  { withoutLoading?: boolean },
  IThunkRejectValue
>(
  "data-stream-services",
  async (
    { withoutLoading = false },
    { rejectWithValue, fulfillWithValue, dispatch, requestId }
  ) => {
    try {
      dispatch(
        getDataStreamServicesAsync.pending(requestId, {
          withoutLoading,
        })
      );

      const response = await getDatabaseServicesApi("streaming");
      const services = response.data.Result;
      return fulfillWithValue({ services });
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);

export const dataSreamServicesSlice = createSlice({
  name: "data-sream-services",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getDataStreamServicesAsync.pending, (state, action) => {
        if (!action.meta.arg.withoutLoading) state.loading = true;
      })
      .addCase(getDataStreamServicesAsync.fulfilled, (state, action) => {
        const { services } = action.payload;
        state.services = services;
        state.loading = false;
      })
      .addCase(getDataStreamServicesAsync.rejected, (state, { payload }) => {
        state.loading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });
  },
});

export const selectDataStreamServices = (state: RootState) =>
  state.dataStreamServices.services;
export const selectDataStreamServicesLoading = (state: RootState) =>
  state.dataStreamServices.loading;

// export const {} = dataSreamServicesSlice.actions;
export default dataSreamServicesSlice.reducer;
