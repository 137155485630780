import { useCallback, useMemo } from "react";
import { useAppDispatch, useAppSelector } from ".";
import {
  getMeAsync,
  selectLimitationsEnum,
  selectLimitationsEnumLoading,
  selectOnStageEnv,
  selectUser,
  selectUserLoading,
} from "../store/auth/authSlice";
import { selectEnvironments } from "../store/environments/environmentsSlice";
import { IPlan } from "../types/billing";
import { DBSCatalog } from "../types/database";

type LimitationCase =
  | "PlanPrice"
  | "NumberOfEnvironment"
  | "NumberOfWebApps"
  | "NumberOfBlockChains"
  | "Cpu"
  | "Memory"
  | "NumberOfDatabases"
  | "NumberOfDatastreaming"
  | "NumberOfDataAnalysis"
  | "NumberOfInstances";

export type ServiceReplicaWithPlan = {
  replicaCounts: number;
  plan?: IPlan;
  catalog?: DBSCatalog;
};

const useLimitations = (props: {
  cases?: LimitationCase[];
  existingServices?: ServiceReplicaWithPlan[];
}) => {
  const cases = props.cases;

  const dispatch = useAppDispatch();

  const user = useAppSelector(selectUser);
  const env = useAppSelector(selectOnStageEnv);
  const userLoading = useAppSelector(selectUserLoading);

  const allEnvs = useAppSelector(selectEnvironments);

  const limitsEnum = useAppSelector(selectLimitationsEnum);
  const limitsEnumLoading = useAppSelector(selectLimitationsEnumLoading);

  const refreshLimits = useCallback(() => {
    dispatch(getMeAsync({ withoutLoading: true }));
  }, [dispatch]);

  const loading = useMemo(() => userLoading, [userLoading]);

  // limits
  const accountLimits = useMemo(() => {
    return user?.AccountLimitations || [];
  }, [user?.AccountLimitations]);

  const envLimits = useMemo(() => {
    return env?.EnvironmentLimitations || [];
  }, [env?.EnvironmentLimitations]);

  const prevServicesData = useMemo(() => {
    let prevCpu = 0;
    let prevMemory = 0;
    let prevDisk = 0;

    if (props.existingServices) {
      for (const existingService of props.existingServices) {
        let CPU = 0;
        let RAM = 0;
        let DISK = 0;

        const { replicaCounts, plan, catalog } = existingService;
        if (plan) {
          const planData = JSON.parse(plan.PlanDescription || "{}");
          CPU =
            planData.CPU !== undefined
              ? parseFloat(planData.CPU.replace("Gi", ""))
              : 0;
          RAM =
            planData.RAM !== undefined
              ? parseFloat(planData.RAM.replace("Gi", ""))
              : 0;
          DISK =
            planData.DISK !== undefined
              ? parseFloat(planData.DISK.replace("Gi", ""))
              : 0;
        }

        if (catalog) {
          const technicalData = catalog.blobs?.technical;
          if (technicalData) {
            const { cpu, memory } = technicalData;
            CPU = cpu?.cores || 0;
            RAM = memory?.size || 0;
            DISK = 0; //TODO
          }
        }

        prevCpu += replicaCounts * CPU;
        prevMemory += replicaCounts * RAM;
        prevDisk += replicaCounts * DISK;
      }
    }

    return { prevCpu, prevMemory, prevDisk };
  }, [props.existingServices]);

  // plan price settings
  const planPriceLimitEnum = useMemo(() => {
    return limitsEnum.find((e) => e.Text === "PlanPrice");
  }, [limitsEnum]);

  const planPriceLimit = useMemo(() => {
    if (
      !limitsEnumLoading &&
      planPriceLimitEnum &&
      cases?.includes("PlanPrice")
    ) {
      const accountPlanPriceLimits = accountLimits.filter(
        (l) => l.LimitationType === planPriceLimitEnum.Value
      );
      const envPlanPriceLimits = envLimits.filter(
        (l) => l.LimitationType === planPriceLimitEnum.Value
      );
      const planPriceLimits = [
        ...accountPlanPriceLimits,
        ...envPlanPriceLimits,
      ];

      if (planPriceLimits.length === 0) {
        return null;
      }

      return planPriceLimits.reduce((min, current) =>
        current.LimitationValue < min.LimitationValue ? current : min
      );
    }
    return null;
  }, [limitsEnumLoading, planPriceLimitEnum, cases, accountLimits, envLimits]);

  const planPriceLimitChecker = useCallback(
    (price: number | undefined, replicaCounts: number = 1) => {
      // exceeded or not
      if (planPriceLimit && price) {
        return price * replicaCounts > planPriceLimit.LimitationValue;
      }
      return false;
    },
    [planPriceLimit]
  );

  // number of environment settings
  const numberOfEnvironmentLimitEnum = useMemo(() => {
    return limitsEnum.find((e) => e.Text === "NumberOfEnvironment");
  }, [limitsEnum]);

  const numberOfEnvironmentLimit = useMemo(() => {
    if (
      !limitsEnumLoading &&
      numberOfEnvironmentLimitEnum &&
      cases?.includes("NumberOfEnvironment")
    ) {
      const accountEnvsLimits = accountLimits.filter(
        (l) => l.LimitationType === numberOfEnvironmentLimitEnum.Value
      );
      const envEnvsLimits = envLimits.filter(
        (l) => l.LimitationType === numberOfEnvironmentLimitEnum.Value
      );
      const numberOfEnvironmentsLimits = [
        ...accountEnvsLimits,
        ...envEnvsLimits,
      ];

      if (numberOfEnvironmentsLimits.length === 0) {
        return null;
      }

      return numberOfEnvironmentsLimits.reduce((min, current) =>
        current.LimitationValue < min.LimitationValue ? current : min
      );
    }
    return null;
  }, [
    accountLimits,
    cases,
    envLimits,
    limitsEnumLoading,
    numberOfEnvironmentLimitEnum,
  ]);

  const numberOfEnvironmentLimitChecker = useCallback(() => {
    // exceeded or not
    if (numberOfEnvironmentLimit) {
      return allEnvs.length >= numberOfEnvironmentLimit.LimitationValue;
    }
    return false;
  }, [numberOfEnvironmentLimit, allEnvs]);

  // number of web-app settings
  const numberOfWebAppLimitEnum = useMemo(() => {
    return limitsEnum.find((e) => e.Text === "NumberOfWebApps");
  }, [limitsEnum]);

  const numberOfWebAppsLimit = useMemo(() => {
    if (
      !limitsEnumLoading &&
      numberOfWebAppLimitEnum &&
      cases?.includes("NumberOfWebApps")
    ) {
      const accountWebAppsLimits = accountLimits.filter(
        (l) => l.LimitationType === numberOfWebAppLimitEnum.Value
      );
      const envWebAppsLimits = envLimits.filter(
        (l) => l.LimitationType === numberOfWebAppLimitEnum.Value
      );
      const numberOfWebAppsLimits = [
        ...accountWebAppsLimits,
        ...envWebAppsLimits,
      ];

      if (numberOfWebAppsLimits.length === 0) {
        return null;
      }

      return numberOfWebAppsLimits.reduce((min, current) =>
        current.LimitationValue < min.LimitationValue ? current : min
      );
    }
    return null;
  }, [
    accountLimits,
    cases,
    envLimits,
    limitsEnumLoading,
    numberOfWebAppLimitEnum,
  ]);

  const numberOfWebAppsLimitChecker = useCallback(
    (webAppsLength: number) => {
      // exceeded or not
      if (numberOfWebAppsLimit) {
        return webAppsLength >= numberOfWebAppsLimit.LimitationValue;
      }
      return false;
    },
    [numberOfWebAppsLimit]
  );

  // number of blockchain settings
  const numberOfBlockchainLimitEnum = useMemo(() => {
    return limitsEnum.find((e) => e.Text === "NumberOfBlockChains");
  }, [limitsEnum]);

  const numberOfBlockchainsLimit = useMemo(() => {
    if (
      !limitsEnumLoading &&
      numberOfBlockchainLimitEnum &&
      cases?.includes("NumberOfBlockChains")
    ) {
      const accountWebAppsLimits = accountLimits.filter(
        (l) => l.LimitationType === numberOfBlockchainLimitEnum.Value
      );
      const envWebAppsLimits = envLimits.filter(
        (l) => l.LimitationType === numberOfBlockchainLimitEnum.Value
      );
      const numberOfBlockchainsLimits = [
        ...accountWebAppsLimits,
        ...envWebAppsLimits,
      ];

      if (numberOfBlockchainsLimits.length === 0) {
        return null;
      }

      return numberOfBlockchainsLimits.reduce((min, current) =>
        current.LimitationValue < min.LimitationValue ? current : min
      );
    }
    return null;
  }, [
    accountLimits,
    cases,
    envLimits,
    limitsEnumLoading,
    numberOfBlockchainLimitEnum,
  ]);

  const numberOfBlockchainsLimitChecker = useCallback(
    (blockchainsLength: number) => {
      // exceeded or not
      if (numberOfBlockchainsLimit) {
        return blockchainsLength >= numberOfBlockchainsLimit.LimitationValue;
      }
      return false;
    },
    [numberOfBlockchainsLimit]
  );

  // amount of CPU settings
  const amountOfCpuLimitEnum = useMemo(() => {
    return limitsEnum.find((e) => e.Text === "Cpu");
  }, [limitsEnum]);

  const amountOfCpuLimit = useMemo(() => {
    if (!limitsEnumLoading && amountOfCpuLimitEnum && cases?.includes("Cpu")) {
      const accountCpuLimits = accountLimits.filter(
        (l) => l.LimitationType === amountOfCpuLimitEnum.Value
      );
      const envCpuLimits = envLimits.filter(
        (l) => l.LimitationType === amountOfCpuLimitEnum.Value
      );
      const amountOfCpuLimits = [...accountCpuLimits, ...envCpuLimits];

      if (amountOfCpuLimits.length === 0) {
        return null;
      }

      return amountOfCpuLimits.reduce((min, current) =>
        current.LimitationValue < min.LimitationValue ? current : min
      );
    }
    return null;
  }, [
    accountLimits,
    amountOfCpuLimitEnum,
    cases,
    envLimits,
    limitsEnumLoading,
  ]);

  const amountOfCpuLimitChecker = useCallback(
    (Cpu: string | number | undefined, replicaCounts: number = 1) => {
      if (Cpu === undefined) return false;
      const cpu = Number(Cpu);

      // exceeded or not
      if (amountOfCpuLimit) {
        return (
          prevServicesData.prevCpu + cpu * replicaCounts >
          amountOfCpuLimit.LimitationValue
        );
      }
      return false;
    },
    [amountOfCpuLimit, prevServicesData.prevCpu]
  );

  // amount of Memory settings
  const amountOfMemoryLimitEnum = useMemo(() => {
    return limitsEnum.find((e) => e.Text === "Memory");
  }, [limitsEnum]);

  const amountOfMemoryLimit = useMemo(() => {
    if (
      !limitsEnumLoading &&
      amountOfMemoryLimitEnum &&
      cases?.includes("Memory")
    ) {
      const accountMemoryLimits = accountLimits.filter(
        (l) => l.LimitationType === amountOfMemoryLimitEnum.Value
      );
      const envMemoryLimits = envLimits.filter(
        (l) => l.LimitationType === amountOfMemoryLimitEnum.Value
      );
      const amountOfMemoryLimits = [...accountMemoryLimits, ...envMemoryLimits];

      if (amountOfMemoryLimits.length === 0) {
        return null;
      }

      return amountOfMemoryLimits.reduce((min, current) =>
        current.LimitationValue < min.LimitationValue ? current : min
      );
    }
    return null;
  }, [
    accountLimits,
    amountOfMemoryLimitEnum,
    cases,
    envLimits,
    limitsEnumLoading,
  ]);

  const amountOfMemoryLimitChecker = useCallback(
    (Memory: string | undefined, replicaCounts: number = 1) => {
      if (Memory === undefined) return false;

      const memory = parseFloat(Memory.replace("Gi", ""));

      // exceeded or not
      if (amountOfMemoryLimit && memory) {
        return (
          prevServicesData.prevMemory + memory * replicaCounts >
          amountOfMemoryLimit.LimitationValue
        );
      }
      return false;
    },
    [amountOfMemoryLimit, prevServicesData.prevMemory]
  );

  // number of database settings
  const numberOfDatabaseLimitEnum = useMemo(() => {
    return limitsEnum.find((e) => e.Text === "NumberOfDatabases");
  }, [limitsEnum]);

  const numberOfDatabaseLimit = useMemo(() => {
    if (
      !limitsEnumLoading &&
      numberOfDatabaseLimitEnum &&
      cases?.includes("NumberOfDatabases")
    ) {
      const accountDatabaseLimits = accountLimits.filter(
        (l) => l.LimitationType === numberOfDatabaseLimitEnum.Value
      );
      const envDatabaseLimits = envLimits.filter(
        (l) => l.LimitationType === numberOfDatabaseLimitEnum.Value
      );
      const numberOfDatabaseLimits = [
        ...accountDatabaseLimits,
        ...envDatabaseLimits,
      ];

      if (numberOfDatabaseLimits.length === 0) {
        return null;
      }

      return numberOfDatabaseLimits.reduce((min, current) =>
        current.LimitationValue < min.LimitationValue ? current : min
      );
    }
    return null;
  }, [
    accountLimits,
    cases,
    envLimits,
    limitsEnumLoading,
    numberOfDatabaseLimitEnum,
  ]);

  const numberOfDatabaseLimitChecker = useCallback(
    (databaseLength: number) => {
      // exceeded or not
      if (numberOfDatabaseLimit) {
        return databaseLength >= numberOfDatabaseLimit.LimitationValue;
      }
      return false;
    },
    [numberOfDatabaseLimit]
  );

  // number of data-stream settings
  const numberOfDataStreamLimitEnum = useMemo(() => {
    return limitsEnum.find((e) => e.Text === "NumberOfDatastreaming");
  }, [limitsEnum]);

  const numberOfDataStreamLimit = useMemo(() => {
    if (
      !limitsEnumLoading &&
      numberOfDataStreamLimitEnum &&
      cases?.includes("NumberOfDatastreaming")
    ) {
      const accountDataStreamLimits = accountLimits.filter(
        (l) => l.LimitationType === numberOfDataStreamLimitEnum.Value
      );
      const envDataStreamLimits = envLimits.filter(
        (l) => l.LimitationType === numberOfDataStreamLimitEnum.Value
      );
      const numberOfDataStreamLimits = [
        ...accountDataStreamLimits,
        ...envDataStreamLimits,
      ];

      if (numberOfDataStreamLimits.length === 0) {
        return null;
      }

      return numberOfDataStreamLimits.reduce((min, current) =>
        current.LimitationValue < min.LimitationValue ? current : min
      );
    }
    return null;
  }, [
    accountLimits,
    cases,
    envLimits,
    limitsEnumLoading,
    numberOfDataStreamLimitEnum,
  ]);

  const numberOfDataStreamLimitChecker = useCallback(
    (dataStreamLength: number) => {
      // exceeded or not
      if (numberOfDataStreamLimit) {
        return dataStreamLength >= numberOfDataStreamLimit.LimitationValue;
      }
      return false;
    },
    [numberOfDataStreamLimit]
  );

  // number of data-analysis settings
  const numberOfDataAnalysisLimitEnum = useMemo(() => {
    return limitsEnum.find((e) => e.Text === "NumberOfDataAnalysis");
  }, [limitsEnum]);

  const numberOfDataAnalysisLimit = useMemo(() => {
    if (
      !limitsEnumLoading &&
      numberOfDataAnalysisLimitEnum &&
      cases?.includes("NumberOfDataAnalysis")
    ) {
      const accountDataAnalysisLimits = accountLimits.filter(
        (l) => l.LimitationType === numberOfDataAnalysisLimitEnum.Value
      );
      const envDataAnalysisLimits = envLimits.filter(
        (l) => l.LimitationType === numberOfDataAnalysisLimitEnum.Value
      );
      const numberOfDataAnalysisLimits = [
        ...accountDataAnalysisLimits,
        ...envDataAnalysisLimits,
      ];

      if (numberOfDataAnalysisLimits.length === 0) {
        return null;
      }

      return numberOfDataAnalysisLimits.reduce((min, current) =>
        current.LimitationValue < min.LimitationValue ? current : min
      );
    }
    return null;
  }, [
    accountLimits,
    cases,
    envLimits,
    limitsEnumLoading,
    numberOfDataAnalysisLimitEnum,
  ]);

  const numberOfDataAnalysisLimitChecker = useCallback(
    (dataAnalysisLength: number) => {
      // exceeded or not
      if (numberOfDataAnalysisLimit) {
        return dataAnalysisLength >= numberOfDataAnalysisLimit.LimitationValue;
      }
      return false;
    },
    [numberOfDataAnalysisLimit]
  );

  // number of instances settings
  const numberOfInstanceLimitEnum = useMemo(() => {
    return limitsEnum.find((e) => e.Text === "NumberOfInstances");
  }, [limitsEnum]);

  const numberOfInstanceLimit = useMemo(() => {
    if (
      !limitsEnumLoading &&
      numberOfInstanceLimitEnum &&
      cases?.includes("NumberOfInstances")
    ) {
      const accountInstanceLimits = accountLimits.filter(
        (l) => l.LimitationType === numberOfInstanceLimitEnum.Value
      );
      const envInstanceLimits = envLimits.filter(
        (l) => l.LimitationType === numberOfInstanceLimitEnum.Value
      );
      const numberOfInstanceLimits = [
        ...accountInstanceLimits,
        ...envInstanceLimits,
      ];

      if (numberOfInstanceLimits.length === 0) {
        return null;
      }

      return numberOfInstanceLimits.reduce((min, current) =>
        current.LimitationValue < min.LimitationValue ? current : min
      );
    }
    return null;
  }, [
    accountLimits,
    cases,
    envLimits,
    limitsEnumLoading,
    numberOfInstanceLimitEnum,
  ]);

  const numberOfInstanceLimitChecker = useCallback(
    (InstanceLength: number) => {
      // exceeded or not
      if (numberOfInstanceLimit) {
        return InstanceLength >= numberOfInstanceLimit.LimitationValue;
      }
      return false;
    },
    [numberOfInstanceLimit]
  );

  return {
    loading,
    refreshLimits,

    // plan price
    planPriceLimit,
    planPriceLimitChecker,

    // number of envs
    numberOfEnvironmentLimit,
    numberOfEnvironmentLimitChecker,

    // number of web-apps
    numberOfWebAppsLimit,
    numberOfWebAppsLimitChecker,

    // number of blockchains
    numberOfBlockchainsLimit,
    numberOfBlockchainsLimitChecker,

    // amount of cpu
    amountOfCpuLimit,
    amountOfCpuLimitChecker,

    // amount of memory
    amountOfMemoryLimit,
    amountOfMemoryLimitChecker,

    // number of database
    numberOfDatabaseLimit,
    numberOfDatabaseLimitChecker,

    // number of data-stream
    numberOfDataStreamLimit,
    numberOfDataStreamLimitChecker,

    // number of data-analysis
    numberOfDataAnalysisLimit,
    numberOfDataAnalysisLimitChecker,

    // number of instance
    numberOfInstanceLimit,
    numberOfInstanceLimitChecker,
  };
};

export default useLimitations;
