import { useAppDispatch, useAppSelector } from "../../../hooks";
import {
  deleteDASIndexesAsync,
  deleteDASPatternsAsync,
  getDASIndexesAsync,
  getDASPatternsAsync,
  handleToggleDASPatternModal,
  selectDASIndexes,
  selectDASIndexesActionLoading,
  selectDASIndexesLoading,
  selectDASPatterns,
  selectDASPatternsActionLoading,
  selectDASPatternsLoading,
  selectDatabaseService,
} from "../../../store/database/serviceSlice";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Dropdown,
  EmptyState,
  Flex,
  SimpleTable,
  Typography,
} from "djuno-design";
import HighlighterText from "../../general/HighlighterText";
import { useSearch } from "../../../providers/SearchProvider";
import { ReactComponent as MoreIcon } from "./../../../assets/icons/more.svg";
import { ReactComponent as ArchiveIcon } from "./../../../assets/icons/archive-box.svg";
import { ReactComponent as RefreshIcon } from "./../../../assets/icons/arrow-path.svg";
import { ReactComponent as PlusIcon } from "./../../../assets/icons/plus.svg";
import { DeleteModal } from "../../modals/QuestionModal";
import { Helmet } from "react-helmet";
import { generateServicePermissions } from "../../../pages/databases/ServicePage";
import { DataServiceGeneralInformationUrl } from "../../../utils/urls";
import { DASIndex, DASPattern } from "../../../types/database";
import { PatternCreateModal } from "./PatternCreateModal";

const IndexesTab = () => {
  const service = useAppSelector(selectDatabaseService);

  const patterns = useAppSelector(selectDASPatterns);
  const patternsLoading = useAppSelector(selectDASPatternsLoading);
  const [filteredPatterns, setFilteredPatterns] = useState<DASPattern[]>([]);
  const patternsActionLoading = useAppSelector(selectDASPatternsActionLoading);

  const indexes = useAppSelector(selectDASIndexes);
  const indexesLoading = useAppSelector(selectDASIndexesLoading);
  const [filteredIndexes, setFilteredIndexes] = useState<DASIndex[]>([]);
  const indexesActionLoading = useAppSelector(selectDASIndexesActionLoading);

  const [deletePattern, setDeletePattern] = useState<DASPattern | null>(null);
  const [deleteIndex, setDeleteIndex] = useState<DASIndex | null>(null);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { value: searchValue } = useSearch();

  useEffect(() => {
    if (service) {
      const permissions = generateServicePermissions().indexes;
      if (!permissions.includes(service.engine)) {
        navigate(DataServiceGeneralInformationUrl(service.engine, service.id));
      } else {
        dispatch(
          getDASPatternsAsync({
            engine: service.engine,
            clusterId: service.id,
          })
        );
        dispatch(
          getDASIndexesAsync({
            engine: service.engine,
            clusterId: service.id,
          })
        );
      }
    }
  }, [dispatch, navigate, searchValue, service]);

  useEffect(() => {
    if (patterns && patterns.length > 0) {
      const lookedUpFiles = patterns.filter((p) =>
        p.pattern?.toLowerCase().includes(searchValue.toLowerCase())
      );
      setFilteredPatterns(lookedUpFiles);
    }
  }, [dispatch, searchValue, patterns]);

  useEffect(() => {
    if (indexes && indexes.length > 0) {
      const lookedUpFiles = indexes.filter((i) =>
        i.name?.toLowerCase().includes(searchValue.toLowerCase())
      );
      setFilteredIndexes(lookedUpFiles);
    }
  }, [dispatch, searchValue, indexes]);

  return (
    <>
      <Helmet>
        <title>{process.env.REACT_APP_NAME}</title>
        <meta name="description" content="" />
      </Helmet>
      {service && (
        <>
          <div className="flex items-center justify-between">
            <div className="items-center justify-between flex flex-1 transition duration-150">
              <Typography.Title level={5} className="!mb-0">
                Models
              </Typography.Title>
            </div>
            <div className="flex items-center gap-2">
              <Button
                uiType="light"
                onClick={() => {
                  if (service) {
                    dispatch(
                      getDASPatternsAsync({
                        engine: service.engine,
                        clusterId: service.id,
                      })
                    );
                  }
                }}
                disabled={!service}
                className="group"
                tooltip={{ content: "Refresh" }}
              >
                <RefreshIcon className="w-4 h-4 group-hover:rotate-90 group-hover:scale-110 transition-all duration-500" />
              </Button>
              <Button
                uiType="primary"
                onClick={() => dispatch(handleToggleDASPatternModal(true))}
                className="group"
              >
                Add a template
                <PlusIcon className="w-3 h-3 group-hover:rotate-90 group-hover:scale-110 transition-all duration-500" />
              </Button>
            </div>
          </div>

          <div className="mt-5 w-full database-table">
            <SimpleTable
              loading={patternsLoading}
              containerClassName="min-h-[240px]"
            >
              <SimpleTable.Head>
                <SimpleTable.Row>
                  <SimpleTable.TH lable="Template" />
                  <SimpleTable.TH lable="Maximum number of indexes" />
                  <SimpleTable.TH lable="" />
                </SimpleTable.Row>
              </SimpleTable.Head>
              <SimpleTable.Body>
                {filteredPatterns.map((pattern, i) => (
                  <SimpleTable.Row key={i}>
                    <SimpleTable.TD>
                      <div className="flex items-center flex-row justify-between space-x-3">
                        <Flex>
                          <HighlighterText
                            searchValue={searchValue}
                            textToHighlight={pattern.pattern}
                            className="max-w-[110px] md:max-w-[400px] lg:max-w-[350px] xl:max-w-[400px] truncate"
                          />
                        </Flex>
                      </div>
                    </SimpleTable.TD>
                    <SimpleTable.TD>
                      <Typography.Text className="!text-xs md:!text-sm">
                        {pattern.maxIndexCount}
                      </Typography.Text>
                    </SimpleTable.TD>
                    <SimpleTable.TD className="w-20 gap-1">
                      <div className="h-full w-full inline-flex items-center justify-end gap-1">
                        <div className="w-8 flex justify-center items-center">
                          <Dropdown
                            anchor="bottom end"
                            itemsClassName="!w-48"
                            menu={[
                              {
                                key: "end",
                                label: (
                                  <div className="flex items-center gap-1">
                                    <ArchiveIcon className="w-4" />
                                    Delete
                                  </div>
                                ),
                                danger: true,
                                onClick: (_, close) => {
                                  close();
                                  setDeletePattern(pattern);
                                },
                              },
                            ]}
                          >
                            <Button
                              uiType="icon"
                              uiSize="small"
                              className="!px-2"
                            >
                              <MoreIcon className="w-4 h-4" />
                            </Button>
                          </Dropdown>
                        </div>
                      </div>
                    </SimpleTable.TD>
                  </SimpleTable.Row>
                ))}

                {patterns.length === 0 && (
                  <SimpleTable.Row
                    withoutHoverStyle={true}
                    className="h-[200px]"
                  >
                    <SimpleTable.TD colSpan={10} className="!border-0">
                      <EmptyState text="You have not created any template yet" />
                    </SimpleTable.TD>
                  </SimpleTable.Row>
                )}
              </SimpleTable.Body>
            </SimpleTable>
          </div>
          <PatternCreateModal />
          <DeleteModal
            title="Delete model"
            isOpen={deletePattern !== null}
            onClose={() => setDeletePattern(null)}
            description=""
            confirmButtonText="Confirm"
            confirmButtonType="danger"
            confirmButtonClassName="w-full"
            onConfirm={() => {
              deletePattern &&
                service &&
                dispatch(
                  deleteDASPatternsAsync({
                    clusterId: service.id,
                    engine: service.engine,
                    patternId: deletePattern.id,
                  })
                ).then((action) => {
                  if (action.type === "service/patterns/delete/fulfilled") {
                    dispatch(
                      getDASPatternsAsync({
                        engine: service.engine,
                        clusterId: service.id,
                      })
                    );
                    setDeletePattern(null);
                  }
                });
            }}
            loading={patternsActionLoading}
          />

          {/* //////////////////////////////////////////////////////////////////// */}
          <div className="flex items-center justify-between mt-10">
            <div className="items-center justify-between flex flex-1 transition duration-150">
              <Typography.Title level={5} className="!mb-0">
                Indexes
              </Typography.Title>
            </div>
            <div className="flex items-center gap-2">
              <Button
                uiType="light"
                onClick={() => {
                  if (service) {
                    dispatch(
                      getDASIndexesAsync({
                        engine: service.engine,
                        clusterId: service.id,
                      })
                    );
                  }
                }}
                disabled={!service}
                className="group"
                tooltip={{ content: "Refresh" }}
              >
                <RefreshIcon className="w-4 h-4 group-hover:rotate-90 group-hover:scale-110 transition-all duration-500" />
              </Button>
            </div>
          </div>
          <div className="mt-5 w-full database-table pb-10">
            <SimpleTable
              loading={indexesLoading}
              containerClassName="min-h-[240px]"
            >
              <SimpleTable.Head>
                <SimpleTable.Row>
                  <SimpleTable.TH lable="Index" />
                  <SimpleTable.TH lable="Shards" />
                  <SimpleTable.TH lable="Replicas" />
                  <SimpleTable.TH lable="Size" />
                  <SimpleTable.TH lable="Document" />
                  <SimpleTable.TH lable="Creation date" />
                  <SimpleTable.TH lable="" />
                </SimpleTable.Row>
              </SimpleTable.Head>
              <SimpleTable.Body>
                {filteredIndexes
                  .sort((a, b) => a.documents - b.documents)
                  .map((index, i) => (
                    <SimpleTable.Row key={i}>
                      <SimpleTable.TD>
                        <div className="flex items-center flex-row justify-between space-x-3">
                          <Flex>
                            <HighlighterText
                              searchValue={searchValue}
                              textToHighlight={index.name}
                              className="max-w-[110px] md:max-w-[400px] lg:max-w-[350px] xl:max-w-[400px] truncate"
                            />
                          </Flex>
                        </div>
                      </SimpleTable.TD>
                      <SimpleTable.TD>
                        <Typography.Text className="!text-xs md:!text-sm">
                          {index.shardsNumber}
                        </Typography.Text>
                      </SimpleTable.TD>
                      <SimpleTable.TD>
                        <Typography.Text className="!text-xs md:!text-sm">
                          {index.replicasNumber}
                        </Typography.Text>
                      </SimpleTable.TD>
                      <SimpleTable.TD>
                        <Typography.Text className="!text-xs md:!text-sm">
                          {index.size}
                        </Typography.Text>
                      </SimpleTable.TD>
                      <SimpleTable.TD>
                        <Typography.Text className="!text-xs md:!text-sm">
                          {index.documents}
                        </Typography.Text>
                      </SimpleTable.TD>
                      <SimpleTable.TD>
                        <Typography.Text className="!text-xs md:!text-sm">
                          {index.createdAt}
                        </Typography.Text>
                      </SimpleTable.TD>
                      <SimpleTable.TD className="w-20 gap-1">
                        <div className="h-full w-full inline-flex items-center justify-end gap-1">
                          <div className="w-8 flex justify-center items-center">
                            <Dropdown
                              anchor="bottom end"
                              itemsClassName="!w-48"
                              menu={[
                                {
                                  key: "end",
                                  label: (
                                    <div className="flex items-center gap-1">
                                      <ArchiveIcon className="w-4" />
                                      Delete
                                    </div>
                                  ),
                                  danger: true,
                                  onClick: (_, close) => {
                                    close();
                                    setDeleteIndex(index);
                                  },
                                },
                              ]}
                            >
                              <Button
                                uiType="icon"
                                uiSize="small"
                                className="!px-2"
                              >
                                <MoreIcon className="w-4 h-4" />
                              </Button>
                            </Dropdown>
                          </div>
                          {/* <ArrowRightIcon className="w-4 h-4 dark:text-slate-100 text-slate-800 cursor-pointer" /> */}
                        </div>
                      </SimpleTable.TD>
                    </SimpleTable.Row>
                  ))}

                {indexes.length === 0 && (
                  <SimpleTable.Row
                    withoutHoverStyle={true}
                    className="h-[200px]"
                  >
                    <SimpleTable.TD colSpan={10} className="!border-0">
                      <EmptyState text="You have not any Indexes" />
                    </SimpleTable.TD>
                  </SimpleTable.Row>
                )}
              </SimpleTable.Body>
            </SimpleTable>
          </div>
          <DeleteModal
            title="Delete Index"
            isOpen={deleteIndex !== null}
            onClose={() => setDeleteIndex(null)}
            description=""
            confirmButtonText="Confirm"
            confirmButtonType="danger"
            confirmButtonClassName="w-full"
            onConfirm={() => {
              deleteIndex &&
                service &&
                dispatch(
                  deleteDASIndexesAsync({
                    clusterId: service.id,
                    engine: service.engine,
                    indexId: deleteIndex.id,
                  })
                ).then((action) => {
                  if (action.type === "service/indexes/delete/fulfilled") {
                    dispatch(
                      getDASIndexesAsync({
                        engine: service.engine,
                        clusterId: service.id,
                      })
                    );
                    setDeleteIndex(null);
                  }
                });
            }}
            loading={indexesActionLoading}
          />
        </>
      )}
    </>
  );
};

export default IndexesTab;
