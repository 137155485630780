import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { cn, EmptyState, Flex, Loading, Typography } from "djuno-design";
import {
  getWebAppBuildHistoriesAsync,
  selectWebApp,
  selectWebAppBuildHistories,
  selectWebAppBuildHistoriesLoading,
} from "../../../store/web-app/webAppSlice";
import { WebAppBuildHistory } from "../../../types/web-app";
import { formatTimestamp } from "../../../utils/date";
import MarkdownText from "../../general/MarkdownText";

const WebAppBuildHistoriesTab = () => {
  const webApp = useAppSelector(selectWebApp);
  const histories = useAppSelector(selectWebAppBuildHistories);
  const historiesLoading = useAppSelector(selectWebAppBuildHistoriesLoading);
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (webApp) {
      dispatch(
        getWebAppBuildHistoriesAsync({ webAppId: webApp?.Id.toString() })
      );
    }
  }, [dispatch, webApp]);

  return (
    <>
      <div className="flex transition-all duration-500">
        <Typography.Text className="whitespace-nowrap font-medium mt-1.5 !mb-0 !text-sm">
          {webApp?.RepositoryUrl}
        </Typography.Text>
        <div className="ml-auto flex items-center justify-end gap-1 md:gap-2"></div>
      </div>

      {
        <div className="mt-5 w-full">
          {histories.length > 0 && !historiesLoading && (
            <ol className="relative border-s border-gray-200 dark:border-gray-700">
              {histories.map((history, i) => (
                <HistoryItem key={i} {...history} />
              ))}
            </ol>
          )}
          {historiesLoading && (
            <Flex
              items="center"
              justify="center"
              className="w-full !min-h-[300px]"
            >
              <Loading borderSize={2} />
            </Flex>
          )}

          {!historiesLoading && histories.length === 0 && (
            <EmptyState className="w-full !min-h-[300px]" />
          )}
        </div>
      }
    </>
  );
};

const HistoryItem = ({
  status,
  logs,
  created,
  duration,
  name,
  runtime,
}: WebAppBuildHistory) => {
  return (
    <li className="mb-10 ms-6 last:mb-0 relative pb-5">
      <span
        className={cn(
          "absolute flex items-center justify-center w-2 h-2 rounded-full -left-7 top-1 ring-4",
          {
            "bg-primary-300 ring-primary-50 dark:ring-primary-500/10 dark:bg-primary-400":
              status === undefined || status === "building",
            "bg-red-500 ring-red-50 dark:ring-red-500/10 dark:bg-red-500":
              status === "failed",
            "bg-green-400 ring-green-50 dark:ring-green-500/10 dark:bg-green-400":
              status === "success",
          }
        )}
      />
      <div className="flex flex-col gap-1">
        {/* Reason */}
        <div className="flex items-start justify-between">
          <div className="flex items-center gap-1">
            <Typography.Title level={6} className="!text-sm !mb-0">
              {name}
            </Typography.Title>
          </div>
          {created && (
            <div className="flex items-start gap-1">
              <Typography.Text className="!text-xs" uiType="secondary">
                {formatTimestamp(created).datetime}
              </Typography.Text>
            </div>
          )}
        </div>
        <div className="flex items-start justify-between">
          <div className="flex items-center gap-1">
            <Typography.Text className="!text-sm">
              runtime: {runtime}
            </Typography.Text>
          </div>
        </div>
        <div className="flex items-start justify-between">
          <div className="flex items-center gap-1">
            <Typography.Text className="!text-sm">
              duration: {duration}
            </Typography.Text>
          </div>
        </div>

        {/* logs */}
        {logs && (
          <div className="w-full rounded-lg bg-slate-50 dark:bg-dark-2 p-4 pt-0 mt-2">
            <Typography.Text size="sm">
              <MarkdownText text={logs} />
            </Typography.Text>
          </div>
        )}
      </div>
    </li>
  );
};

export default WebAppBuildHistoriesTab;
