import { Button, Card, Flex, Input, Tabs, Typography } from "djuno-design";
import CardDetailsBox from "../settings/billing/CardDetailsBox";
import { useAppDispatch, useAppSelector } from "../../hooks";
import {
  makeBillingIntentAsync,
  activeWithPromoAsync,
  selectLoadingCardDetail,
  selectActiveWithPromoLoading,
  selectMakeIntentLoading,
  waitingStatusAsync,
} from "../../store/billing/billingSlice";
import { BillingIntentResponse } from "../../types/billing";
import { selectOnStageEnv, selectUser } from "../../store/auth/authSlice";
import { useCallback, useEffect, useRef, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { ShineBorder } from "./../../components/general/ShineBorder";
import { ReactComponent as GiftIcon } from "./../../assets/icons/gift.svg";

const CardInformation: React.FC<{
  handleValidateSelectedEnv: () => void;
  validateEnvLoading: boolean;
}> = ({ handleValidateSelectedEnv, validateEnvLoading }) => {
  const freeTrialPromoCode = process.env.REACT_APP_FREE_TRIAL_PROMO_CODE;

  const dispatch = useAppDispatch();

  const selectedEnv = useAppSelector(selectOnStageEnv);
  const user = useAppSelector(selectUser);

  const makeIntentLoading = useAppSelector(selectMakeIntentLoading);
  const activeWithPromoLoading = useAppSelector(selectActiveWithPromoLoading);
  const savingLoading = useAppSelector(selectLoadingCardDetail);

  const [selectedMethod, setSelectedMethod] = useState(0);

  const [promoCode, setPromoCode] = useState("");
  const promoRef = useRef<HTMLInputElement>(null);

  const handleBeforInitPaymentMethod = useCallback(async () => {
    if (selectedEnv) {
      const envStatus = selectedEnv.EnvironmentStatus; //available status: 0,1,2
      if (envStatus === 0 || envStatus === 1 || envStatus === 2) {
        return dispatch(makeBillingIntentAsync()).then((action) => {
          if (action.type === "billing/intent/fulfilled") {
            const orderToken = (action.payload as BillingIntentResponse).Token;
            return orderToken;
            // return "4ba308d2-f98e-44e6-9785-e427ab034814";
          } else {
            return undefined;
          }
        });
      } else {
        if (selectedMethod === 0) handleValidateSelectedEnv();
      }
    }
  }, [dispatch, handleValidateSelectedEnv, selectedEnv, selectedMethod]);

  const handleAfterInitPaymentMethod = async (orderToken: string) => {
    if (selectedEnv)
      dispatch(waitingStatusAsync({ token: orderToken })).then((action) => {
        if (action.type === "billing/waiting-status/fulfilled") {
          handleValidateSelectedEnv();
        }
      });
  };

  const handleSavePromoCode = useCallback(() => {
    if (promoCode) {
      dispatch(activeWithPromoAsync({ PromoCode: promoCode })).then(
        (action) => {
          if (action.type === "billing/active-with-promo/fulfilled") {
            handleValidateSelectedEnv();
          }
        }
      );
    }
  }, [dispatch, handleValidateSelectedEnv, promoCode]);

  const handleStartTrial = useCallback(() => {
    if (
      freeTrialPromoCode &&
      !activeWithPromoLoading &&
      !savingLoading &&
      !validateEnvLoading
    ) {
      dispatch(activeWithPromoAsync({ PromoCode: freeTrialPromoCode })).then(
        (action) => {
          if (action.type === "billing/active-with-promo/fulfilled") {
            handleValidateSelectedEnv();
          }
        }
      );
    }
  }, [
    activeWithPromoLoading,
    dispatch,
    freeTrialPromoCode,
    handleValidateSelectedEnv,
    savingLoading,
    validateEnvLoading,
  ]);

  useEffect(() => {
    if (selectedMethod === 2 && promoRef && promoRef.current) {
      promoRef.current.focus();
    } else {
      setPromoCode("");
    }
  }, [selectedMethod]);

  return (
    <div className="w-full h-[calc(100vh-4rem)] flex justify-center items-center">
      <div className="!relative !overflow-hidden rounded-xl">
        <ShineBorder shineColor={["#0074E5", "#A07CFE", "#FFBE7B"]} />
        <Card title="Payment details" className="max-w-xl">
          <Tabs
            tabType="creamy"
            options={[
              { label: "Free trial + $50 Sign up bonus" },
              { label: "Credit card + $100 Sign up bonus " },
            ]}
            onChange={({ index }) => setSelectedMethod(index || 0)}
            selectedIndex={selectedMethod}
          />

          <div className="min-h-[150px] flex flex-col">
            <AnimatePresence>
              {selectedMethod === 0 && (
                <motion.div className="flex-1 flex flex-col justify-between">
                  <div className="pt-10 w-full flex justify-center">
                    <Typography.Text size="base">
                      Get 50 $ cloud credit and start building now
                    </Typography.Text>
                  </div>
                  <Flex items="center" justify="center" className="w-full mb-7">
                    <Button
                      className="w-[40px]"
                      uiType="primary"
                      loading={
                        validateEnvLoading ||
                        activeWithPromoLoading ||
                        savingLoading
                      }
                      disabled={!freeTrialPromoCode}
                      onClick={handleStartTrial}
                    >
                      <GiftIcon className="w-4 aspect-square flex-shrink-0" />
                      Start free trial
                    </Button>
                  </Flex>
                </motion.div>
              )}
              {selectedMethod === 1 && (
                <motion.div className="flex-1 flex flex-col justify-between">
                  <div className="pt-8 w-full">
                    <Typography.Text size="sm">
                      Complete your registeration and redeem 100 $ sign up bonus
                    </Typography.Text>
                  </div>
                  <CardDetailsBox
                    beforInitFn={handleBeforInitPaymentMethod}
                    afterInitFn={handleAfterInitPaymentMethod}
                    initLoading={makeIntentLoading || validateEnvLoading}
                    savingLoading={savingLoading}
                    username={user?.FullName}
                  />
                </motion.div>
              )}
              {selectedMethod === 2 && (
                <motion.div className="flex-1 flex flex-col justify-between">
                  <div className="pt-8">
                    <Input
                      label="Promo Code"
                      placeholder="Enter your Promo Code"
                      onChange={(e) => setPromoCode(e.target.value)}
                      value={promoCode}
                      ref={promoRef}
                      disabled={
                        validateEnvLoading ||
                        activeWithPromoLoading ||
                        savingLoading
                      }
                    />
                  </div>
                  <Flex items="center" justify="end" className="w-full">
                    <Button
                      className="w-[40px]"
                      uiType="primary"
                      loading={
                        validateEnvLoading ||
                        activeWithPromoLoading ||
                        savingLoading
                      }
                      disabled={
                        validateEnvLoading ||
                        activeWithPromoLoading ||
                        savingLoading
                      }
                      onClick={handleSavePromoCode}
                    >
                      Save
                    </Button>
                  </Flex>
                </motion.div>
              )}
            </AnimatePresence>
          </div>
        </Card>
      </div>
    </div>
  );
};

export default CardInformation;
