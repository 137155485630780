import { Link } from "react-router-dom";
import { ReactComponent as BellIcon } from "./../../assets/icons/bell.svg";
import { ReactComponent as CheckIcon } from "./../../assets/icons/check.svg";
import {
  Typography,
  Popover,
  Flex,
  EmptyState,
  Loading,
  Button,
} from "djuno-design";
import { useAppDispatch, useAppSelector } from "../../hooks";
import {
  handleReadAllNotif,
  markAsReadNotificationAsync,
  selectNotifications,
  selectNotificationsLoading,
} from "../../store/notifications/notificationsSlice";
import { NotificationToastItem } from "../settings/notifications/SettingsNotificationsTab";
import { useCallback, useMemo, useState } from "react";
import { SettingsNotificationsUrl } from "../../utils/urls";

const NotifDropdown: React.FunctionComponent = () => {
  const dispatch = useAppDispatch();

  const notifications = useAppSelector(selectNotifications);
  const notificationsLoading = useAppSelector(selectNotificationsLoading);

  const notReadedNotifications = useMemo(() => {
    return notifications.filter((notif) => !notif.IsRead);
  }, [notifications]);

  const [markAllLoading, setMarkAllLoading] = useState(false);
  const handleMardAllAsRead = useCallback(() => {
    if (!markAllLoading && notReadedNotifications.length > 0) {
      setMarkAllLoading(true);
      dispatch(markAsReadNotificationAsync()).then((action) => {
        if (action.type === "notifications/mark-as-read/fulfilled") {
          dispatch(handleReadAllNotif());
        }
        setMarkAllLoading(false);
      });
    }
  }, [dispatch, markAllLoading, notReadedNotifications.length]);

  return (
    <Popover
      anchor="bottom end"
      content={
        <Flex
          direction="col"
          className="!z-[51] min-w-[200px] max-w-md p-3 py-4 pb-2 border rounded-xl bg-white dark:bg-dark-1 dark:border-dark-2"
        >
          {notReadedNotifications.length === 0 && !notificationsLoading && (
            <EmptyState
              icon={<EmptyState.PRESENTED_IMAGE_DEFAULT className="!w-8" />}
              text=""
            />
          )}
          <Flex direction="col" className="gap-2 max-h-[300px] overflow-y-auto">
            {notificationsLoading && (
              <Flex items="center" justify="center" className="min-h-[100px]">
                <Loading borderSize={2} uiSize={15} />
              </Flex>
            )}
            {!notificationsLoading && (
              <Flex className="gap-2 flex-col w-full">
                {notReadedNotifications.length > 1 && (
                  <Flex className="w-full justify-end">
                    <Button
                      uiSize="small"
                      uiType="icon"
                      className="group"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleMardAllAsRead();
                      }}
                      loading={markAllLoading}
                    >
                      <CheckIcon className="w-4 h-4 flex-shrink-0" />
                      <Typography.Text
                        size="xs"
                        className="!text-primary-400 group-hover:!text-primary-500"
                      >
                        mark all as read
                      </Typography.Text>
                    </Button>
                  </Flex>
                )}
                {notReadedNotifications.map((notification, i) => (
                  <NotificationToastItem key={i} notification={notification} />
                ))}
              </Flex>
            )}
          </Flex>
          <div className="w-full dark:border-dark-2 border-t mt-3 mb-1" />
          <Flex items="center" justify="center" className="w-full">
            <Link to={SettingsNotificationsUrl} className="group">
              <Typography.Text
                size="xs"
                className="group-hover:text-primary-400"
              >
                See All Notifications
              </Typography.Text>
            </Link>
          </Flex>
        </Flex>
      }
      panelClassName="!z-40 !mt-1 !min-w-[320px] !rounded-xl !border !border-slate-200 dark:!border-dark-2"
    >
      <div className="flex items-center gap-2">
        <div className="w-5 h-5 aspect-square rounded-full relative group cursor-pointer">
          {notReadedNotifications.length > 0 && (
            <div className="w-4 h-4 aspect-square rounded-full bg-red-500 absolute z-40 text-[0.65rem] flex items-center justify-center text-slate-200 dark:text-slate-900 font-medium p-0.5 px-2 -top-1.5 left-2.5 shadow-md">
              {notReadedNotifications.length}
            </div>
          )}
          <BellIcon className="w-full h-full group-hover:scale-110" />
        </div>
      </div>
    </Popover>
  );
};
export default NotifDropdown;
