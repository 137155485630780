import { removeANSIEscapeSequences } from "../../../utils";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import {
  getDBSLogsAsync,
  selectDBSLogs,
  selectDBSLogsLoading,
  selectDatabaseService,
} from "../../../store/database/serviceSlice";
import { useEffect, useRef, useState } from "react";
import { formatTimestamp } from "../../../utils/date";
import { useNavigate } from "react-router-dom";
import { generateServicePermissions } from "../../../pages/databases/ServicePage";
import { DataServiceGeneralInformationUrl } from "../../../utils/urls";
import { cn, Loading, Switcher, Typography } from "djuno-design";
import { DBSCategory } from "../../../types/database";

const DatabaseLogsTab: React.FC<{ category: DBSCategory }> = ({ category }) => {
  const service = useAppSelector(selectDatabaseService);

  const logs = useAppSelector(selectDBSLogs);
  const logsLoading = useAppSelector(selectDBSLogsLoading);
  const [autoRefresh, setAutoRefresh] = useState<boolean>(false);
  const logsContainerRef = useRef<HTMLDivElement | null>(null);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (service) {
      const permissions = generateServicePermissions().logs;
      if (!permissions.includes(service.engine)) {
        navigate(DataServiceGeneralInformationUrl(service.engine, service.id));
      } else {
        dispatch(
          getDBSLogsAsync({ engine: service.engine, clusterId: service.id })
        );
      }
    }
  }, [dispatch, navigate, category, service]);

  useEffect(() => {
    let interval: NodeJS.Timeout | undefined;
    if (service && autoRefresh) {
      interval = setInterval(() => {
        dispatch(
          getDBSLogsAsync({
            engine: service.engine,
            clusterId: service.id,
            withoutLoading: true,
          })
        );
      }, 30000);
    }
    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [dispatch, service, autoRefresh]);

  useEffect(() => {
    if (logs && logsContainerRef && logsContainerRef.current) {
      if (logs.length > 0 && !logsLoading) {
        setTimeout(() => {
          if (logsContainerRef.current)
            logsContainerRef.current.scrollTop =
              logsContainerRef.current?.scrollHeight || 400;
        }, 10);
      }
    }
  }, [logs, logsLoading]);

  return (
    <div>
      <div className="flex flex-col items-start w-full justify-between gap-2 mb-5">
        <Typography.Text className="!text-sm">
          To help you monitor and manage your database service, view the latest
          events (logs) below in almost-real time. Their retention period
          depends on your service solution.
        </Typography.Text>
        <div className="flex items-center gap-0.5">
          <Typography.Text className="!text-xs">Auto-Refresh</Typography.Text>
          <Switcher value={autoRefresh} onChange={setAutoRefresh} />
        </div>
      </div>

      <div className="flex flex-col w-full bg-gray-100 dark:bg-dark-3 relative rounded-tl-xl rounded-tr-xl rounded-xl py-2.5 h-[400px]">
        <div
          className="flex-1 flex flex-col pb-4 overflow-y-scroll"
          ref={logsContainerRef}
        >
          {/* line */}
          {!logsLoading &&
            logs.map((log, index) => {
              //   const status = log.labels.find((l) => l.label === "LEVEL")?.value;
              return (
                <div
                  key={index}
                  className={cn(
                    "antialiased font-mono font-normal leading-6 tracking-tight group relative space-x-4 hover:bg-gray-200 dark:hover:bg-black/10 py-1 px-3"
                    // {
                    //   "bg-rose-500/10 hover:!bg-rose-500/10":
                    //     status === "error",
                    // }
                  )}
                >
                  <Typography.Text className="!text-xs inline-block space-x-1 uppercase group/line-level relative mr-2">
                    {formatTimestamp(log.timestamp).datetime}
                  </Typography.Text>
                  <Typography.Text className="!text-xs inline-block space-x-1 uppercase group/line-level relative mr-2">
                    {log.hostname}
                  </Typography.Text>
                  <Typography.Text className="!text-xs whitespace-pre-wrap break-all inline">
                    {removeANSIEscapeSequences(log.message)}
                  </Typography.Text>
                </div>
              );
            })}

          {/* empty log */}
          {logs.length === 0 && !logsLoading && (
            <div className="h-full flex items-center justify-center px-4">
              <div className="flex flex-col items-center">
                <div className="text-center space-y-1">
                  <Typography.Text className="antialiased font-sans font-medium text-base leading-6">
                    No logs to show
                  </Typography.Text>
                </div>
              </div>
            </div>
          )}

          {/* loading */}
          {logsLoading && (
            <div className="h-full flex items-center justify-center px-4">
              <Loading borderSize={2} />
            </div>
          )}

          {/* results count */}
          {/* <div className="antialiased font-mono font-normal text-xs leading-6 tracking-tight w-full border-dashed border-t border-gray-300 dark:border-gray-800 px-4 pt-4 mt-auto text-center">
                <Text>All 7 matching logs loaded</Text>
              </div> */}
        </div>
      </div>
    </div>
  );
};

export default DatabaseLogsTab;
