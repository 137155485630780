import { useAppDispatch, useAppSelector } from "../../../hooks";

import {
  getDSSUserAccessAsync,
  handleToggleDSSUserAccessKeyModal,
  selectDatabaseService,
  selectDatabaseUserAccesskey,
  selectDSSuserAccess,
  selectDSSuserAccessLoading,
} from "../../../store/database/serviceSlice";
import { useEffect } from "react";
import { Button, Flex, Loading, Modal, SecureCopy } from "djuno-design";
import { textJsonDownloader } from "../../../utils/file";
import { IDBSUser } from "../../../types/database";

const UserAccessKeyModal = ({ selectedUser }: { selectedUser: IDBSUser }) => {
  const isOpen = useAppSelector(selectDatabaseUserAccesskey);
  const service = useAppSelector(selectDatabaseService);
  const userAccess = useAppSelector(selectDSSuserAccess);
  const userAccessloading = useAppSelector(selectDSSuserAccessLoading);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (service && selectedUser && isOpen) {
      dispatch(
        getDSSUserAccessAsync({
          clusterId: service.id,
          engine: service.engine,
          userId: selectedUser.id,
        })
      );
    }
  }, [service, selectedUser, isOpen, dispatch]);

  const handleClose = () => {
    dispatch(handleToggleDSSUserAccessKeyModal());
  };

  const handleDownload = () => {
    if (userAccess) {
      textJsonDownloader(
        userAccess,
        `${userAccess.key}_${new Date()
          .toLocaleDateString("en-CA")
          .replaceAll("-", "")}`
      );
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      contentClassName="max-w-xl "
      title="Access key"
    >
      {userAccessloading ? (
        <Flex items="center" justify="center" className="min-h-[50px]">
          <Loading borderSize={2} />
        </Flex>
      ) : (
        <div className="flex w-full justify-between mt-5 gap-5">
          <div className="w-full">
            <SecureCopy
              type="copy"
              text={(userAccess && userAccess.key) || ""}
            />
          </div>

          <div className="flex justify-end">
            <Button onClick={handleDownload}>Download</Button>
          </div>
        </div>
      )}
      <div className="mt-8 flex justify-end">
        <Button uiType="primary" onClick={handleClose}>
          Close
        </Button>
      </div>
    </Modal>
  );
};

export default UserAccessKeyModal;
