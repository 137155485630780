import { useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import { ReactComponent as ArrowUpIcon } from "./../../../../assets/icons/arrow-up.svg";
import { ReactComponent as SearchIcon } from "./../../../../assets/icons/search.svg";
import { Button, cn, Input, Select } from "djuno-design";
import LogTerminal, {
  LogsTerminalLog,
  useLogTerminal,
} from "./../../../general/LogTerminal";
import {
  getK8LogsAsync,
  getK8LogsUrlAsync,
  selectK8Logs,
  selectK8LogsLoading,
  selectK8LogsUrl,
  selectK8LogsUrlLoading,
  selectK8Service,
  selectK8ServiceLoading,
} from "../../../../store/kubernetes/k8ServiceSlice";

const logsTimesOptions = [
  {
    label: "Live tail",
    value: "live",
  },
  {
    label: "Last hour",
    value: "last_hour",
  },
  {
    label: "Last 4 hours",
    value: "last_4_hours",
  },
  {
    label: "Last 24 hours",
    value: "last_24_hours",
  },
  {
    label: "Last 2 days",
    value: "last_2_days",
  },
  {
    label: "Last 7 days",
    value: "last_7_days",
  },
  {
    label: "Last 4 hours",
    value: "last_4_hours",
  },
];

const K8LogsTab = () => {
  const k8Service = useAppSelector(selectK8Service);
  const k8ServiceLoading = useAppSelector(selectK8ServiceLoading);

  const logsUrl = useAppSelector(selectK8LogsUrl);
  const logsUrlLoading = useAppSelector(selectK8LogsUrlLoading);

  const logs = useAppSelector(selectK8Logs);
  const logsLoading = useAppSelector(selectK8LogsLoading);

  const { scrollToEnd } = useLogTerminal();

  const dispatch = useAppDispatch();

  //states
  const [searchInputValue, setSearchInputValue] = useState("");

  //form states
  const [order, setOrder] = useState<"last" | "first">("last");
  const [logTime, setLogTime] = useState("live");
  const [search, setSearch] = useState("");

  useEffect(() => {
    if (k8Service) {
      dispatch(
        getK8LogsUrlAsync({
          id: k8Service.id,
        })
      );
    }
  }, [dispatch, k8Service]);

  useEffect(() => {
    if (!logsUrlLoading && logsUrl !== null) {
      dispatch(
        getK8LogsAsync({
          url: logsUrl.url,
          withoutLoading: false,
        })
      );
    }
  }, [dispatch, logsUrl, logsUrlLoading]);

  const handleSearch = () => {
    if (search !== searchInputValue) {
      setSearch(searchInputValue);
      //get search
    }
  };

  const getTimeFilterRange = (logTime: string) => {
    const now = new Date();
    switch (logTime) {
      case "last_hour":
        return new Date(now.getTime() - 60 * 60 * 1000); // 1 hour ago
      case "last_4_hours":
        return new Date(now.getTime() - 4 * 60 * 60 * 1000); // 4 hours ago
      case "last_24_hours":
        return new Date(now.getTime() - 24 * 60 * 60 * 1000); // 24 hours ago
      case "last_2_days":
        return new Date(now.getTime() - 2 * 24 * 60 * 60 * 1000); // 2 days ago
      case "last_7_days":
        return new Date(now.getTime() - 7 * 24 * 60 * 60 * 1000); // 7 days ago
      default:
        return null;
    }
  };

  useEffect(() => {
    let interval: NodeJS.Timeout | null = null;

    if (!logsUrlLoading && logsUrl !== null && logsUrl.url) {
      if (logTime === "live") {
        dispatch(
          getK8LogsAsync({
            url: logsUrl.url,
            withoutLoading: true,
          })
        );

        interval = setInterval(() => {
          dispatch(
            getK8LogsAsync({
              url: logsUrl.url,
              withoutLoading: true,
            })
          );
        }, 10000);
      } else {
        dispatch(
          getK8LogsAsync({
            url: logsUrl.url,
            withoutLoading: true,
          })
        );
      }
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [dispatch, logTime, logsUrl, logsUrlLoading]);

  const orderedLogs: Array<LogsTerminalLog> = useMemo(() => {
    const messages = logs?.messages || [];
    const _logs = messages.map((m) => m.message);

    const filterStartTime = getTimeFilterRange(logTime);

    // Filter logs by time range
    let filteredLogs = _logs;
    if (filterStartTime) {
      filteredLogs = filteredLogs.filter(
        (log) => new Date(log.timestamp) >= filterStartTime
      );
    }

    // Filter logs by search term
    if (search) {
      filteredLogs = filteredLogs.filter((log) =>
        log.message.toLowerCase().includes(search.toLowerCase())
      );
    }

    const finalLogs = order === "first" ? filteredLogs.reverse() : filteredLogs;

    const resultLogs: Array<LogsTerminalLog> = finalLogs.map((l) => ({
      time: l.timestamp,
      content: `${l.audit_verb} ${l.audit_authorizationDecision} ${l.audit_responseStatus}\n${l.audit_user}\n${l.audit_requestURI}\n${l.audit_groups} ${l.audit_userAgent}\n${l.audit_auditID}`,
    }));

    return resultLogs;
  }, [logs, order, logTime, search]);

  useEffect(() => {
    scrollToEnd();
  }, [logsLoading, scrollToEnd]);

  return (
    <div>
      <LogTerminal
        loading={logsLoading || logsUrlLoading || k8ServiceLoading}
        logs={orderedLogs}
        serviceName={k8Service?.name}
      >
        <div className="flex gap-2 w-full flex-1 items-center">
          <div className="flex-1 max-lg:order-5 grow shrink max-lg:basis-full min-w-0">
            <Input
              containerClassName="!mt-0"
              className="!border-slate-200 dark:!border-secondary-700"
              placeholder="Search"
              onChange={(e: any) => setSearchInputValue(e.target.value)}
              onKeyDown={(e: any) => {
                if (e.code === "Enter") handleSearch();
              }}
              AfterComponent={
                <button
                  onClick={handleSearch}
                  className={cn(
                    "w-10 flex items-center justify-center dark:text-white border-l border-solid border-transparent rounded-r-xl",
                    {
                      "bg-primary-400 text-white": search !== searchInputValue,
                    }
                  )}
                >
                  <SearchIcon className="w-4" />
                </button>
              }
            />
          </div>
          <div className="max-lg:order-2 max-lg:grow">
            <Select
              options={logsTimesOptions}
              value={logTime}
              onChange={(v) => setLogTime(v || "live")}
              buttonClassName="!border-slate-200 dark:!border-secondary-700 !pr-7"
              optionsClassName="!w-44 !max-h-none"
            />
          </div>
          <div className="max-lg:order-3">
            <Button
              uiSize="medium"
              tooltip={{ content: `Newest ${order}`, place: "bottom" }}
              onClick={() =>
                setOrder((prev) => (prev === "last" ? "first" : "last"))
              }
            >
              <ArrowUpIcon
                className={cn("w-4 aspect-square", {
                  "rotate-180": order === "first",
                })}
              />
            </Button>
          </div>
        </div>
      </LogTerminal>
    </div>
  );
};

export default K8LogsTab;
