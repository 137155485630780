import { Helmet } from "react-helmet";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { useEffect, useMemo, useState } from "react";
import { ReactComponent as PlusIcon } from "./../../../assets/icons/plus.svg";
import { ReactComponent as MoreIcon } from "./../../../assets/icons/more.svg";
import { ReactComponent as ArchiveIcon } from "./../../../assets/icons/archive-box.svg";
import { ReactComponent as RefreshIcon } from "./../../../assets/icons/arrow-path.svg";
import { useNavigate } from "react-router-dom";
import {
  DataServiceGeneralInformationUrl,
  DataServiceUsersCreateUrl,
} from "../../../utils/urls";
import { DeleteModal } from "../../modals/QuestionModal";
import { useSearch } from "../../../providers/SearchProvider";
import {
  deleteDBSUserAsync,
  getDBSUsersAsync,
  getDatabaseServiceAsync,
  handleToggleUserAclDeleteModal,
  handleToggleUserAclModal,
  selectDatabaseService,
  selectDatabaseServiceUserLoading,
  selectDatabaseServiceUsers,
  selectDatabaseServiceUsersLoading,
} from "../../../store/database/serviceSlice";
import { IDBSUser } from "../../../types/database";
import { generateServicePermissions } from "../../../pages/databases/ServicePage";
import {
  Button,
  Dropdown,
  EmptyState,
  Flex,
  SimpleTable,
  Switcher,
  Tag,
  Typography,
} from "djuno-design";
import HighlighterText from "../../general/HighlighterText";
import {
  selectDatabaseServicesActionLoading,
  updateDBSAsync,
} from "../../../store/database/servicesSlice";
import { UserAclCreateModal } from "./UserAclCreateModal";
import { UserAclDeleteModal } from "./UserAclDeleteModal";

const UserAclTab = () => {
  const [deleteUser, setDeleteUser] = useState<IDBSUser | null>(null);
  const [p_users, setPUsers] = useState<IDBSUser[]>([]);
  const [filteredUsers, setFilteredUsers] = useState<IDBSUser[]>([]);

  const service = useAppSelector(selectDatabaseService);
  const users = useAppSelector(selectDatabaseServiceUsers);
  const usersLoading = useAppSelector(selectDatabaseServiceUsersLoading);
  const userLoading = useAppSelector(selectDatabaseServiceUserLoading);
  const actionLoading = useAppSelector(selectDatabaseServicesActionLoading);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [aclMode, setAclMode] = useState(service?.aclsEnabled || false);
  const { value: searchValue } = useSearch();

  useEffect(() => {
    if (service) {
      const permissions = generateServicePermissions().acl;
      if (!permissions.includes(service.engine)) {
        navigate(DataServiceGeneralInformationUrl(service.engine, service.id));
      } else {
        dispatch(getDBSUsersAsync({ id: service.id, engine: service.engine }));
      }
    }
  }, [dispatch, navigate, service]);

  useEffect(() => {
    setPUsers(users.filter((user) => user.acls?.length));
  }, [dispatch, searchValue, users]);

  useEffect(() => {
    const lookedUpUsers = p_users.filter((user) =>
      user.username?.toLowerCase().includes(searchValue.toLowerCase())
    );
    setFilteredUsers(lookedUpUsers);
  }, [dispatch, p_users, searchValue]);

  const isAnyUserDeleting = useMemo(() => {
    return users.some((user) => user.status === "DELETING");
  }, [users]);

  const handleToggleAclMode = (value: boolean) => {
    if (service && !actionLoading) {
      setAclMode(value);
      dispatch(
        updateDBSAsync({
          id: service.id,
          engine: service.engine,
          data: { aclsEnabled: value },
        })
      ).then((action) => {
        if (action.type === "db-services/update/fulfilled") {
          dispatch(
            getDatabaseServiceAsync({
              id: service.id,
              engine: service.engine,
            })
          );
        }
      });
    }
  };

  useEffect(() => {
    if (service) {
      setAclMode(service.aclsEnabled || false);
    }
  }, [service]);

  return (
    <>
      <Helmet>
        <title>{process.env.REACT_APP_NAME} | Database</title>
        <meta name="description" content="" />
      </Helmet>

      {service && (
        <>
          <div className="flex items-center justify-between">
            <div className="items-center justify-between flex flex-1 transition duration-150">
              <Flex items="center" className="gap-1">
                <Typography.Text size="sm">Enable ACLs</Typography.Text>
                <Switcher
                  value={aclMode}
                  onChange={handleToggleAclMode}
                  loading={actionLoading}
                />
              </Flex>
            </div>
            <div className="flex items-center gap-2">
              <Button
                uiType="light"
                onClick={() => {
                  if (service)
                    dispatch(
                      getDBSUsersAsync({
                        id: service.id,
                        engine: service.engine,
                      })
                    );
                }}
                disabled={!service}
                className="group"
                tooltip={{ content: "Refresh" }}
              >
                <RefreshIcon className="w-4 h-4 group-hover:rotate-90 group-hover:scale-110 transition-all duration-500" />
              </Button>
              <Button
                uiType="primary"
                onClick={() => dispatch(handleToggleUserAclModal(true))}
                disabled={isAnyUserDeleting || service?.aclsEnabled === false}
                className="group"
              >
                Add an ACL
                <PlusIcon className="w-3 h-3 group-hover:rotate-90 group-hover:scale-110 transition-all duration-500" />
              </Button>
            </div>
          </div>
          <div className="mt-5 w-full">
            <SimpleTable
              loading={usersLoading || actionLoading}
              containerClassName="min-h-[240px]"
            >
              <SimpleTable.Head>
                <SimpleTable.Row>
                  <SimpleTable.TH lable="Username" />
                  <SimpleTable.TH lable="Permission" />
                  <SimpleTable.TH lable="Index model" />
                  <SimpleTable.TH lable="" />
                </SimpleTable.Row>
              </SimpleTable.Head>

              <SimpleTable.Body>
                {filteredUsers.map((user, i) => (
                  <SimpleTable.Row key={i}>
                    <SimpleTable.TD>
                      <div className="flex items-center flex-row justify-between space-x-3">
                        <HighlighterText
                          searchValue={searchValue}
                          textToHighlight={user.username}
                          className="max-w-[110px] md:max-w-[400px] lg:max-w-[350px] xl:max-w-[400px] truncate"
                        />
                      </div>
                    </SimpleTable.TD>
                    <SimpleTable.TD>
                      {user.acls?.map((acl, j) => (
                        <Typography.Text
                          key={j}
                          className="!text-xs md:!text-sm block"
                        >
                          {acl.permission}
                        </Typography.Text>
                      ))}
                    </SimpleTable.TD>

                    <SimpleTable.TD>
                      {user.acls?.map((acl, k) => (
                        <Typography.Text
                          key={k}
                          className="!text-xs md:!text-sm block"
                        >
                          {acl.pattern}
                        </Typography.Text>
                      ))}
                    </SimpleTable.TD>

                    <SimpleTable.TD className="w-20 gap-1">
                      <div className="h-full w-full inline-flex items-center justify-end gap-1">
                        <div className="w-8">
                          <Dropdown
                            anchor="bottom end"
                            itemsClassName="!w-48"
                            menu={[
                              {
                                key: "end",
                                label: (
                                  <div className="flex items-center gap-1">
                                    <ArchiveIcon className="w-4" />
                                    Delete
                                  </div>
                                ),
                                danger: true,
                                onClick: (_, close) => {
                                  dispatch(
                                    handleToggleUserAclDeleteModal({
                                      status: true,
                                      user,
                                    })
                                  );
                                  close();
                                },
                                disabled:
                                  isAnyUserDeleting ||
                                  user.status === "PENDING" ||
                                  user.status === "UPDATING" ||
                                  service?.aclsEnabled === false,
                              },
                            ]}
                          >
                            <Button
                              uiType="icon"
                              uiSize="small"
                              className="!px-2"
                            >
                              <MoreIcon className="w-4 h-4" />
                            </Button>
                          </Dropdown>
                        </div>
                      </div>
                    </SimpleTable.TD>
                  </SimpleTable.Row>
                ))}

                {p_users.length === 0 && !usersLoading && (
                  <SimpleTable.Row
                    withoutHoverStyle={true}
                    className="h-[200px]"
                  >
                    <SimpleTable.TD colSpan={10} className="!border-0">
                      <EmptyState text="You have not created any acl yet" />
                    </SimpleTable.TD>
                  </SimpleTable.Row>
                )}
              </SimpleTable.Body>
            </SimpleTable>
          </div>
          <UserAclCreateModal />
          <UserAclDeleteModal />
          <DeleteModal
            title="Delete ACL"
            isOpen={deleteUser !== null}
            onClose={() => setDeleteUser(null)}
            description=""
            confirmButtonText="Confirm"
            confirmButtonType="danger"
            confirmButtonClassName="w-full"
            onConfirm={() => {
              deleteUser &&
                service &&
                dispatch(
                  deleteDBSUserAsync({
                    id: service.id,
                    engine: service.engine,
                    userId: deleteUser.id,
                  })
                ).then((action) => {
                  if (action.type === "service/user/delete/fulfilled") {
                    dispatch(
                      getDatabaseServiceAsync({
                        id: service.id,
                        engine: service.engine,
                      })
                    );
                    dispatch(
                      getDBSUsersAsync({
                        id: service.id,
                        engine: service.engine,
                      })
                    );
                  } else {
                  }
                  setDeleteUser(null);
                });
            }}
            loading={userLoading}
            confirmString={deleteUser?.username}
          />
        </>
      )}
    </>
  );
};

export default UserAclTab;
