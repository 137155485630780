import { AnimatePresence, motion } from "framer-motion";
import { useAppSelector } from "../../../hooks";
import {
  selectWebAppsTemplates,
  selectWebAppsTemplatesCategories,
  selectWebAppsTemplatesLoading,
} from "../../../store/web-app/webAppsSlice";
import { useCallback, useEffect, useMemo, useState } from "react";
import { WebAppTemplate } from "../../../types/web-app";
import { cn, Flex, Loading, Select, Tag, Typography } from "djuno-design";

import { ReactComponent as Star } from "./../../../assets/icons/star.svg";
import { ReactComponent as DocumentIcon } from "./../../../assets/icons/document-text.svg";
import { useSearch } from "../../../providers/SearchProvider";

const SelectTemplateStep: React.FC<{
  selectedTemplate?: WebAppTemplate;
  handleSelectTemplate: (template: WebAppTemplate) => void;
  selectedCategory?: string;
  handleSelectCategory: (category: string) => void;
}> = ({
  selectedTemplate,
  handleSelectTemplate,
  selectedCategory,
  handleSelectCategory,
}) => {
  const templates = useAppSelector(selectWebAppsTemplates);
  const templatesLoading = useAppSelector(selectWebAppsTemplatesLoading);
  const [categoryTemplates, setCategoryTempaltes] = useState<WebAppTemplate[]>(
    []
  );
  const [filteredTempaltes, setFilteredTempaltes] = useState<WebAppTemplate[]>(
    []
  );

  const categories = useAppSelector(selectWebAppsTemplatesCategories);

  const templateOptions = useMemo(() => {
    const options = Object.keys(categories).map((key, index) => ({
      label: key,
      value: String(index + 1),
    }));

    return [
      {
        label: "All",
        value: "0",
      },
      ...options,
    ];
  }, [categories]);

  const formatLicense = useCallback((license: string) => {
    if (!license) return "";

    const versionRegex = /v?(\d+(\.\d+)*$)/;
    const versionMatch = license.match(versionRegex);
    const version = versionMatch ? versionMatch[0] : "";

    const licenseWithoutVersion = license.replace(versionRegex, "").trim();

    const words = licenseWithoutVersion.split(" ");
    if (words.length > 2) {
      const acronym = words.map((word) => word[0].toUpperCase()).join("");

      return version ? `${acronym}-${version}` : acronym;
    }

    return license;
  }, []);

  const { value: searchValue } = useSearch();

  useEffect(() => {
    if (selectedCategory === "0") {
      setCategoryTempaltes(templates);
    } else {
      const selectedLabel = templateOptions.find(
        (option) => option.value === selectedCategory
      )?.label;
      if (selectedLabel) {
        const filtered = templates.filter((template) =>
          template.Categories.some(
            (category) => category.toLowerCase() === selectedLabel.toLowerCase()
          )
        );
        setCategoryTempaltes(filtered);
      } else {
        setCategoryTempaltes([]);
      }
    }
  }, [selectedCategory, templateOptions, templates]);

  useEffect(() => {
    const lookedUpFiles = categoryTemplates?.filter((t) =>
      t.Name.toLowerCase().includes(searchValue.toLowerCase())
    );
    setFilteredTempaltes(lookedUpFiles);
  }, [categoryTemplates, searchValue]);

  return (
    <Flex direction="col" className="w-full">
      <div className="flex justify-start mb-5 gap-5">
        <Select
          label="Select Category:"
          value={selectedCategory}
          onChange={(v) => handleSelectCategory(v || "0")}
          options={templateOptions.map((option) => ({
            label: option.label,
            value: option.value,
          }))}
          buttonClassName="!w-64"
          optionsClassName=" !max-h-[200px]"
        />
      </div>
      <AnimatePresence>
        <>
          {!templatesLoading && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="grid grid-cols-12 w-full gap-6"
            >
              {Array.isArray(filteredTempaltes) &&
                filteredTempaltes.length > 0 &&
                filteredTempaltes.map(
                  (template: WebAppTemplate, index: number) => (
                    <WebAppTemplateCard
                      key={index}
                      TemplateDescription={template.Description}
                      TemplateImage={template.Icon}
                      TemplateName={template.Name}
                      Stars={template.Stars}
                      License={formatLicense(template.License)}
                      selected={selectedTemplate?.Name === template.Name}
                      onClick={() => handleSelectTemplate(template)}
                    />
                  )
                )}
            </motion.div>
          )}
          {templatesLoading && (
            <motion.div
              initial={{ opacity: 1 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="min-h-[300px] w-full flex justify-center items-center"
            >
              <Loading borderSize={2} />
            </motion.div>
          )}
        </>
      </AnimatePresence>
    </Flex>
  );
};

export interface WebAppTemplateCardProps {
  TemplateDescription: string;
  TemplateImage: string;
  TemplateName: string;
  Stars: number;
  License: string;
}

export const WebAppTemplateCard = ({
  TemplateDescription,
  TemplateImage,
  TemplateName,
  Stars,
  License,
  selected,
  onClick,
}: WebAppTemplateCardProps & {
  selected?: boolean;
  onClick?: () => void;
}) => {
  return (
    <div
      className={cn(
        "col-span-12 md:col-span-4 xl:col-span-3 border-2 text-md rounded-xl dark:bg-dark-3 dark:border-gray-400/10 bg-white shadow hover:shadow-lg transition-all duration-300",
        {
          "border-primary-400 dark:border-primary-400": selected,
          "cursor-pointer": onClick,
        }
      )}
      onClick={() => (onClick ? onClick() : {})}
    >
      <Flex direction="col" items="center">
        <Flex
          items="center"
          justify="center"
          className="relative h-20 w-20  mt-5"
        >
          {TemplateImage ? (
            <img
              className="w-20 h-20 rounded-lg  "
              alt=""
              src={TemplateImage}
            />
          ) : (
            ""
          )}
        </Flex>
      </Flex>
      <Flex direction="col" className="gap-1 m-5 !items-start" items="start">
        <Typography.Text size="sm" className="font-semibold whitespace-pre">
          {TemplateName}
        </Typography.Text>
        <div className="flex flex-row">
          <Tag color="processing" className="ml-1 ">
            <div className="!flex !flex-row">
              <Star className="w-4 h-4" /> {Stars}
            </div>
          </Tag>
          <Tag color="processing" className="ml-1">
            <div className="!flex !flex-row">
              <DocumentIcon className="w-4 h-4" /> {License}
            </div>
          </Tag>
        </div>

        <Typography.Text
          size="sm"
          uiType="secondary"
          className="flex !flex-wrap !whitespace-normal !break-words max-w-[200px] max-h-[60px] overflow-hidden"
        >
          {TemplateDescription}
        </Typography.Text>
      </Flex>
    </div>
  );
};

export default SelectTemplateStep;
