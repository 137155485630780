import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { IThunkRejectValue, RootState } from "../../types";
import toast from "react-hot-toast";
import { WebAppCreateState, WebAppDeployTypes } from "../../types/web-app";
import {
  createWebAppsApi,
  getWebAppCheckNameApi,
  getWebAppImagesApi,
  getWebAppTagsApi,
} from "../../apis/webAppsAPI";
import { getExtractErrors } from "../../apis";
import { CustomErrorToast } from "../../components/general/Toast";
import { isAxiosError } from "axios";
import { GithubRepo } from "../../types/repositories";

const initialState: WebAppCreateState = {
  //------------------DeployingTypeStep-------------//
  deployType: "ai",

  //------------------DeployingSourceStep-------------//
  //----image & ai:
  imageCredentialId: null,

  selectedImage: "",
  imageQuery: "",
  imagesLoading: false,
  imagesSearchResult: [],

  selectedTag: "",
  tagQuery: "",
  tagsLoading: false,
  tagsSearchResult: [],

  //----git:
  gitRepo: null,
  branch: null,
  runtime: "nodejs",
  buildCommand: null,
  startCommand: null,

  //------------------ConfigsStep-------------//
  //notic: some data stored in form object in the WebAppCreatePage component
  nameValidation: null,
  nameValidationLoading: false,

  //------------------AiAssistantStep-------------//
  sourceCode: null,

  //general data
  createLoading: false,
};

export const getWebAppImagesAsync = createAsyncThunk<
  { images: string[] },
  { query: string },
  IThunkRejectValue
>(
  "web-apps/validate-image",
  async ({ query }, { rejectWithValue, getState, fulfillWithValue }) => {
    try {
      const { webAppCreate } = getState() as RootState;
      const { imageCredentialId } = webAppCreate;
      const response = await getWebAppImagesApi(
        query,
        imageCredentialId || undefined
      );
      return fulfillWithValue({ images: response.data.Result });
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);

export const getWebAppTagsAsync = createAsyncThunk<
  { tags: string[] },
  undefined,
  IThunkRejectValue
>(
  "web-apps/validate-tag",
  async (_, { rejectWithValue, getState, fulfillWithValue }) => {
    try {
      const { webAppCreate } = getState() as RootState;
      const { selectedImage, imageCredentialId } = webAppCreate;
      const response = await getWebAppTagsApi(
        selectedImage,
        imageCredentialId || undefined
      );
      return fulfillWithValue({ tags: response.data.Result });
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);

export const getWebAppCheckNameAsync = createAsyncThunk<
  undefined,
  { name: string },
  IThunkRejectValue
>(
  "web-apps/check-name",
  async ({ name }, { rejectWithValue, fulfillWithValue }) => {
    try {
      await getWebAppCheckNameApi(name);
    } catch (e) {
      if (isAxiosError(e))
        return rejectWithValue({ message: e.response?.data.message });
    }
  }
);

export const createWebAppsAsync = createAsyncThunk<
  boolean,
  { data: any },
  IThunkRejectValue
>("web-apps/create", async ({ data }, { rejectWithValue }) => {
  try {
    await createWebAppsApi(data);
    return true;
  } catch (e) {
    return rejectWithValue({ message: getExtractErrors(e) });
  }
});

export const webAppCreateSlice = createSlice({
  name: "webAppCreate",
  initialState,
  reducers: {
    handleClearWebAppCreationSlice: (
      state,
      action: PayloadAction<{ withoutDeployType?: boolean }>
    ) => {
      if (!action?.payload.withoutDeployType)
        state.deployType = initialState.deployType;
      state.imageCredentialId = initialState.imageCredentialId;
      state.selectedImage = initialState.selectedImage;
      state.imageQuery = initialState.imageQuery;
      state.imagesSearchResult = initialState.imagesSearchResult;
      state.imagesLoading = initialState.imagesLoading;
      state.selectedTag = initialState.selectedTag;
      state.tagQuery = initialState.tagQuery;
      state.tagsSearchResult = initialState.tagsSearchResult;
      state.tagsLoading = initialState.tagsLoading;
      state.gitRepo = initialState.gitRepo;
      state.branch = initialState.branch;
      state.runtime = initialState.runtime;
      state.buildCommand = initialState.buildCommand;
      state.startCommand = initialState.startCommand;
      state.createLoading = initialState.createLoading;
      state.nameValidation = initialState.nameValidation;
      state.sourceCode = initialState.sourceCode;
    },

    //DeployingTypeStep
    handleChangeWebAppDeployType: (
      state,
      action: PayloadAction<WebAppDeployTypes>
    ) => {
      state.deployType = action.payload;
    },

    //DeployingSourceStep
    handleSetWebAppCredentialId: (
      state,
      action: PayloadAction<string | number | null>
    ) => {
      state.imageCredentialId = action.payload
        ? action.payload.toString()
        : null;
    },
    handleSetWebAppImageValue: (state, action: PayloadAction<string>) => {
      state.selectedImage = action.payload;
    },
    handleSetWebAppImageQuery: (state, action: PayloadAction<string>) => {
      state.imageQuery = action.payload;
    },
    handleSetWebAppImagesResult: (state, action: PayloadAction<string[]>) => {
      state.imagesSearchResult = action.payload;
    },

    handleSetWebAppTagValue: (state, action: PayloadAction<string>) => {
      state.selectedTag = action.payload;
    },
    handleSetWebAppTagQuery: (state, action: PayloadAction<string>) => {
      state.tagQuery = action.payload;
    },
    handleSetWebAppTagsResult: (state, action: PayloadAction<string[]>) => {
      state.tagsSearchResult = action.payload;
    },

    handleSetWebAppGitRepo: (state, action: PayloadAction<GithubRepo>) => {
      state.gitRepo = action.payload;
      state.branch = action.payload.default_branch;
    },

    //ConfigsStep
    handleChangeWebAppNameValidation: (
      state,
      action: PayloadAction<string | null>
    ) => {
      state.nameValidation = action.payload;
    },
    handleSetWebAppGitBranch: (state, action: PayloadAction<string | null>) => {
      state.branch = action.payload;
    },
    handleSetWebAppRuntime: (state, action: PayloadAction<string | null>) => {
      state.runtime = action.payload;
    },
    handleSetWebAppBuildCommand: (
      state,
      action: PayloadAction<string | null>
    ) => {
      state.buildCommand = action.payload;
    },
    handleSetWebAppStartCommand: (
      state,
      action: PayloadAction<string | null>
    ) => {
      state.startCommand = action.payload;
    },

    //AiAssistantStep
    handleSetWebAppSourceCode: (
      state,
      action: PayloadAction<string | null>
    ) => {
      state.sourceCode = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getWebAppImagesAsync.pending, (state) => {
        state.imagesLoading = true;
      })
      .addCase(getWebAppImagesAsync.fulfilled, (state, { payload }) => {
        state.imagesLoading = false;
        state.imagesSearchResult = payload.images;
      })
      .addCase(getWebAppImagesAsync.rejected, (state, { payload }) => {
        state.imagesLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(getWebAppTagsAsync.pending, (state) => {
        state.tagsLoading = true;
      })
      .addCase(getWebAppTagsAsync.fulfilled, (state, { payload }) => {
        state.tagsSearchResult = payload.tags;
        state.tagsLoading = false;
      })
      .addCase(getWebAppTagsAsync.rejected, (state, { payload }) => {
        state.tagsLoading = false;
      });

    builder
      .addCase(createWebAppsAsync.pending, (state) => {
        state.createLoading = true;
      })
      .addCase(createWebAppsAsync.fulfilled, (state) => {
        state.createLoading = false;
      })
      .addCase(createWebAppsAsync.rejected, (state, { payload }) => {
        state.createLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(getWebAppCheckNameAsync.pending, (state) => {
        state.nameValidationLoading = true;
      })
      .addCase(getWebAppCheckNameAsync.fulfilled, (state, action) => {
        state.nameValidationLoading = false;
        state.nameValidation = null;
      })
      .addCase(getWebAppCheckNameAsync.rejected, (state, { payload }) => {
        state.nameValidationLoading = false;
        state.nameValidation = payload?.message || null;
      });
  },
});

//DeployingTypeStep
export const selectWebAppSelectedDeployType = (state: RootState) =>
  state.webAppCreate.deployType;

//DeployingSourceStep
export const selectWebAppImageCredentialId = (state: RootState) =>
  state.webAppCreate.imageCredentialId;

export const selectWebAppImageValue = (state: RootState) =>
  state.webAppCreate.selectedImage;
export const selectWebAppImageQuery = (state: RootState) =>
  state.webAppCreate.imageQuery;
export const selectWebAppImagesLoading = (state: RootState) =>
  state.webAppCreate.imagesLoading;
export const selectWebAppSearchImages = (state: RootState) =>
  state.webAppCreate.imagesSearchResult;

export const selectWebAppTagValue = (state: RootState) =>
  state.webAppCreate.selectedTag;
export const selectWebAppTagQuery = (state: RootState) =>
  state.webAppCreate.tagQuery;
export const selectWebAppTagsLoading = (state: RootState) =>
  state.webAppCreate.tagsLoading;
export const selectWebAppSearchTags = (state: RootState) =>
  state.webAppCreate.tagsSearchResult;

export const selectWebAppGitRepo = (state: RootState) =>
  state.webAppCreate.gitRepo;

//ConfigsStep
export const selectWebAppNameValidation = (state: RootState) =>
  state.webAppCreate.nameValidation;
export const selectWebAppNameValidationLoading = (state: RootState) =>
  state.webAppCreate.nameValidationLoading;
export const selectWebAppGitBranch = (state: RootState) =>
  state.webAppCreate.branch;
export const selectWebAppRuntime = (state: RootState) =>
  state.webAppCreate.runtime;
export const selectWebAppBuildCommand = (state: RootState) =>
  state.webAppCreate.buildCommand;
export const selectWebAppStartCommand = (state: RootState) =>
  state.webAppCreate.startCommand;

//AI assistant
export const selectWebAppSourceCode = (state: RootState) =>
  state.webAppCreate.sourceCode;

// generals
export const selectWebAppCreateLoading = (state: RootState) =>
  state.webAppCreate.createLoading;

export const {
  handleClearWebAppCreationSlice,

  //DeployingTypeStep
  handleChangeWebAppDeployType,

  //DeployingSourceStep
  handleSetWebAppCredentialId,
  handleSetWebAppImageValue,
  handleSetWebAppImageQuery,
  handleSetWebAppImagesResult,
  handleSetWebAppTagValue,
  handleSetWebAppTagQuery,
  handleSetWebAppTagsResult,
  handleSetWebAppGitRepo,

  //ConfigsStep
  handleChangeWebAppNameValidation,
  handleSetWebAppGitBranch,
  handleSetWebAppRuntime,
  handleSetWebAppBuildCommand,
  handleSetWebAppStartCommand,

  //AiAssistantStep
  handleSetWebAppSourceCode,
} = webAppCreateSlice.actions;
export default webAppCreateSlice.reducer;
