import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import toast from "react-hot-toast";
import { IThunkRejectValue, RootState } from "../../types";
import { getExtractErrors } from "../../apis";
import { CustomErrorToast } from "../../components/general/Toast";
import {
  INodeResponse,
  IWorkflowResponse,
  WorkflowsState,
  WorkflowsTemplate,
} from "../../types/workflows";
import {
  createWorkflowApi,
  deleteWorkflowApi,
  deployWorkflowApi,
  craeteWorkflowsTemplateApi,
  getNodesApi,
  getWorkflowsApi,
  getWorkflowsTemplatesApi,
  updateWorkflowApi,
  deleteWorkflowsTemplateApi,
  activateWorkflowApi,
} from "../../apis/workflowsAPI";
import { getLocalStorage, setLocalStorage } from "djuno-design";

const initialState: WorkflowsState = {
  nodeStatus: null, //is workflow enabled or not?

  workflows: [],
  workflowsLoading: false,
  flowDirection: getLocalStorage("flow-direction", "ltr"),

  actionLoading: false,
  deployLoading: false,

  nodes: [],
  nodesLoading: false,

  workflowsTemplates: [],
  workflowsTemplatesLoading: false,
};

export const getWorkflowsAsync = createAsyncThunk<
  { workflows: Array<IWorkflowResponse> },
  undefined,
  IThunkRejectValue
>("workflows", async (_, { rejectWithValue, fulfillWithValue, dispatch }) => {
  try {
    const response = await getWorkflowsApi();
    const { NodeStatus, Result: workflows } = response.data;

    dispatch(changeWorkflowNodeStatus({ status: NodeStatus }));

    return fulfillWithValue({ workflows: workflows || [] });
  } catch (e) {
    return rejectWithValue({ message: getExtractErrors(e) });
  }
});

export const createWorkflowAsync = createAsyncThunk<
  { workflow: IWorkflowResponse },
  { data: any },
  IThunkRejectValue
>(
  "workflows/create",
  async ({ data }, { fulfillWithValue, rejectWithValue }) => {
    try {
      const response = await createWorkflowApi(data);
      const workflow = response.data.Result;

      return fulfillWithValue({ workflow });
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);

export const updateWorkflowAsync = createAsyncThunk<
  { workflow: IWorkflowResponse },
  { id: string; data: any },
  IThunkRejectValue
>(
  "workflows/update",
  async ({ id, data }, { fulfillWithValue, rejectWithValue }) => {
    try {
      const response = await updateWorkflowApi(id, data);
      const workflow = response.data.Result;

      return fulfillWithValue({ workflow });
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);

export const deployWorkflowAsync = createAsyncThunk<
  { workflow: IWorkflowResponse },
  { id: string; data: any },
  IThunkRejectValue
>(
  "workflows/deploy",
  async ({ id, data }, { fulfillWithValue, rejectWithValue }) => {
    try {
      const response = await deployWorkflowApi(id, data);
      const workflow = response.data.Result;
      return fulfillWithValue({ workflow });
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);

export const deleteWorkflowAsync = createAsyncThunk<
  boolean,
  { id: string },
  IThunkRejectValue
>("workflows/delete", async ({ id }, { rejectWithValue }) => {
  try {
    await deleteWorkflowApi(id);
    return true;
  } catch (e) {
    return rejectWithValue({ message: getExtractErrors(e) });
  }
});

export const getNodesAsync = createAsyncThunk<
  { nodes: Array<INodeResponse> },
  undefined,
  IThunkRejectValue
>("workflows/nodes", async (_, { rejectWithValue, fulfillWithValue }) => {
  try {
    const response = await getNodesApi();
    const nodes = response.data.Result;

    return fulfillWithValue({ nodes });
  } catch (e) {
    return rejectWithValue({ message: getExtractErrors(e) });
  }
});

export const getWorkflowsTemplatesAsync = createAsyncThunk<
  { workflowsTemplates: WorkflowsTemplate[] },
  undefined,
  IThunkRejectValue
>("workflows/templates", async (_, { rejectWithValue, fulfillWithValue }) => {
  try {
    const response = await getWorkflowsTemplatesApi();
    const workflowsTemplates = response.data.Result;

    return fulfillWithValue({ workflowsTemplates: workflowsTemplates || [] });
  } catch (e) {
    return rejectWithValue({ message: getExtractErrors(e) });
  }
});

export const craeteWorkflowTemplateAsync = createAsyncThunk<
  { workflowtemplate: WorkflowsTemplate },
  { data: any },
  IThunkRejectValue
>(
  "workflows/template/craete",
  async ({ data }, { fulfillWithValue, rejectWithValue }) => {
    try {
      const response = await craeteWorkflowsTemplateApi(data);
      const workflowtemplate = response.data.Result;
      return fulfillWithValue({ workflowtemplate });
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);

export const deleteWorkflowtemplateAsync = createAsyncThunk<
  boolean,
  number,
  IThunkRejectValue
>("workflow/template/delete", async (id, { rejectWithValue }) => {
  try {
    await deleteWorkflowsTemplateApi(id);
    return true;
  } catch (e) {
    return rejectWithValue({ message: getExtractErrors(e) });
  }
});

export const activateWorkflowAsync = createAsyncThunk<
  number,
  { isActive: boolean },
  IThunkRejectValue
>("workflow/activate", async ({ isActive }, { rejectWithValue }) => {
  try {
    const response = await activateWorkflowApi(isActive);
    return response.data.NodeStatus;
  } catch (e) {
    return rejectWithValue({ message: getExtractErrors(e) });
  }
});

export const workflowsSlice = createSlice({
  name: "workflows",
  initialState,
  reducers: {
    handleChangeFlowDir: (state) => {
      const prevDir = state.flowDirection;
      const newDir = prevDir === "ltr" ? "ttb" : "ltr";
      state.flowDirection = newDir;
      setLocalStorage("flow-direction", newDir);
    },
    changeWorkflowNodeStatus(state, action: PayloadAction<{ status: number }>) {
      state.nodeStatus =
        typeof action.payload.status === "number" ? action.payload.status : -1;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getWorkflowsAsync.pending, (state) => {
        state.workflowsLoading = true;
      })
      .addCase(getWorkflowsAsync.fulfilled, (state, action) => {
        const { workflows } = action.payload;
        state.workflows = workflows;
        state.workflowsLoading = false;
      })
      .addCase(getWorkflowsAsync.rejected, (state, { payload }) => {
        state.workflowsLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(createWorkflowAsync.pending, (state) => {
        state.actionLoading = true;
      })
      .addCase(
        createWorkflowAsync.fulfilled,
        (state, { payload: { workflow } }) => {
          state.actionLoading = false;
          state.workflows = [...state.workflows, workflow];
        }
      )
      .addCase(createWorkflowAsync.rejected, (state, { payload }) => {
        state.actionLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(updateWorkflowAsync.pending, (state) => {
        state.actionLoading = true;
      })
      .addCase(
        updateWorkflowAsync.fulfilled,
        (state, { payload: { workflow } }) => {
          state.actionLoading = false;
          // state.workflows = [...state.workflows, workflow];//TODO
        }
      )
      .addCase(updateWorkflowAsync.rejected, (state, { payload }) => {
        state.actionLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(deployWorkflowAsync.pending, (state) => {
        state.deployLoading = true;
      })
      .addCase(
        deployWorkflowAsync.fulfilled,
        (state, { payload: { workflow } }) => {
          state.deployLoading = false;
          // state.workflows = [...state.workflows, workflow];//TODO
        }
      )
      .addCase(deployWorkflowAsync.rejected, (state, { payload }) => {
        state.deployLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(deleteWorkflowAsync.pending, (state) => {
        state.actionLoading = true;
      })
      .addCase(deleteWorkflowAsync.fulfilled, (state) => {
        state.actionLoading = false;
      })
      .addCase(deleteWorkflowAsync.rejected, (state, { payload }) => {
        state.actionLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(getNodesAsync.pending, (state) => {
        state.nodesLoading = true;
      })
      .addCase(getNodesAsync.fulfilled, (state, { payload: { nodes } }) => {
        state.nodes = nodes;
        state.nodesLoading = false;
      })
      .addCase(getNodesAsync.rejected, (state, { payload }) => {
        state.nodesLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });
    builder
      .addCase(getWorkflowsTemplatesAsync.pending, (state) => {
        state.workflowsTemplatesLoading = true;
      })
      .addCase(
        getWorkflowsTemplatesAsync.fulfilled,
        (state, { payload: { workflowsTemplates } }) => {
          state.workflowsTemplates = workflowsTemplates;
          state.workflowsTemplatesLoading = false;
        }
      )
      .addCase(getWorkflowsTemplatesAsync.rejected, (state, { payload }) => {
        state.workflowsTemplatesLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(craeteWorkflowTemplateAsync.pending, (state) => {
        state.actionLoading = true;
      })
      .addCase(
        craeteWorkflowTemplateAsync.fulfilled,
        (state, { payload: { workflowtemplate } }) => {
          state.actionLoading = false;
        }
      )
      .addCase(craeteWorkflowTemplateAsync.rejected, (state, { payload }) => {
        state.actionLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });
    builder
      .addCase(deleteWorkflowtemplateAsync.pending, (state) => {
        state.actionLoading = true;
      })
      .addCase(deleteWorkflowtemplateAsync.fulfilled, (state) => {
        state.actionLoading = false;
      })
      .addCase(deleteWorkflowtemplateAsync.rejected, (state, { payload }) => {
        state.actionLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });
    builder
      .addCase(activateWorkflowAsync.pending, (state) => {
        state.actionLoading = true;
      })
      .addCase(activateWorkflowAsync.fulfilled, (state, { payload }) => {
        state.actionLoading = false;
        state.nodeStatus = payload;
      })
      .addCase(activateWorkflowAsync.rejected, (state, { payload }) => {
        state.actionLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });
  },
});

export const selectWorkflowNodeStatus = (state: RootState) =>
  state.workflows.nodeStatus;

export const selectWorkflows = (state: RootState) => state.workflows.workflows;
export const selectWorkflowsLoading = (state: RootState) =>
  state.workflows.workflowsLoading;
export const selectWorkflowDirection = (state: RootState) =>
  state.workflows.flowDirection;

export const selectWorkflowsActionLoading = (state: RootState) =>
  state.workflows.actionLoading;
export const selectWorkflowsDeployLoading = (state: RootState) =>
  state.workflows.deployLoading;

export const selectNodes = (state: RootState) => state.workflows.nodes;
export const selectNodesLoading = (state: RootState) =>
  state.workflows.nodesLoading;

export const selectWrokflowsTemplates = (state: RootState) =>
  state.workflows.workflowsTemplates;
export const selectWrokflowsTemplatesLoading = (state: RootState) =>
  state.workflows.workflowsTemplatesLoading;

export const { handleChangeFlowDir, changeWorkflowNodeStatus } =
  workflowsSlice.actions;
export default workflowsSlice.reducer;
