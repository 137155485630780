import { useCallback, useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import {
  handleSetWebAppImageValue,
  handleSetWebAppSourceCode,
  handleSetWebAppTagQuery,
} from "../../../store/web-app/webAppCreateSlice";
import {
  Alert,
  Button,
  cn,
  Flex,
  Input,
  Loading,
  sleep,
  Typography,
} from "djuno-design";
import { selectUser } from "../../../store/auth/authSlice";
import { ReactComponent as DjunoLogo } from "../../../assets/icons/logo.svg";
import { ReactComponent as AILogo } from "../../../assets/icons/ai-platform.svg";
import { ReactComponent as ArrowPathIcon } from "../../../assets/icons/arrow-path.svg";
import { UserAvatar } from "../../dropdowns/UserDropdown";
import { formatTimestamp } from "../../../utils/date";
import MarkdownText from "../../general/MarkdownText";
import { useAiChatContext } from "../../../providers/AiChatProvider";
import { UseFormReturn } from "react-hook-form";
import { WebAppCreateFormData } from "../../../types/web-app";

const AiExamples = [
  "Create API for having CRUD contact list",
  "Create a node js app bouncing ball on the screen when you click on the ball",
];

const AiAssistantStep: React.FC<{
  form: UseFormReturn<WebAppCreateFormData>;
}> = ({ form }) => {
  const [abortController, setAbortController] = useState<AbortController>();
  const dispatch = useAppDispatch();

  const { getValues, setValue } = form;
  const webAppName = getValues("AppName");
  const user = useAppSelector(selectUser);

  const [thinking, setThinking] = useState(false);
  const [errorBox, setErrorBox] = useState<null | string>(null);

  const {
    handleClearAiContext,
    thread,
    threadLoading,
    handleSetThread,
    handleGetThread,
    messages,
    messagesLoading,
    handleSendMessage,
    runLoading,
    handleSetRun,
    handleGetRun,
    handleSetParse,
  } = useAiChatContext();

  const [input, setInput] = useState("");
  const [deploying, setDeploying] = useState<boolean>(false);
  const [aiLastMessage, setAiLastMessage] = useState<string | null>(null);
  const messagesEndRef = useRef<HTMLDivElement>(null);

  const handleDeploy = useCallback(
    async (source: string) => {
      const controller = new AbortController(); // Create a new AbortController
      const { signal } = controller;
      try {
        setDeploying(true);

        const parseRes = await handleSetParse(webAppName, source, signal);
        if (!parseRes) throw Error("Failed to parse response");

        setDeploying(false);
        setAiLastMessage(null);

        setValue("Port", parseRes.port);
        setValue("EvironmentVariables", parseRes.EnvironmentVariables);
        dispatch(handleSetWebAppImageValue(parseRes.imagename));
        dispatch(handleSetWebAppTagQuery(parseRes.imagetag));
        dispatch(handleSetWebAppSourceCode(parseRes.sourceCode));
      } catch (e) {
        if (signal.aborted) {
          console.log("handleSetParse was aborted due to component unmount");
          return;
        }
        setDeploying(false);
        setErrorBox("An unexpected error occurred. Please try again later.");
        console.log("error:", e);
      }
    },
    [dispatch, handleSetParse, setValue, webAppName]
  );

  const handleGetRunUntilSuccess = useCallback(
    async (runId: string, threadId: string, signal?: AbortSignal) => {
      try {
        setThinking(true);
        await sleep(10000);
        const runRes = await handleGetRun(runId, threadId, signal);
        if (!runRes) throw Error("Failed to retrieve run data.");
        if (runRes?.status === "completed") {
          const lastMessage = await handleGetThread(threadId, signal);
          if (!lastMessage || !lastMessage.content?.[0]?.text?.value)
            throw Error("Failed to retrieve thread messages.");

          const source = lastMessage.content?.[0]?.text?.value;
          setAiLastMessage(source);
          setThinking(false);
          handleDeploy(source);
          setInput("");
        } else {
          handleGetRunUntilSuccess(runId, threadId, signal);
        }
      } catch (e) {
        setThinking(false);
        setErrorBox("An unexpected error occurred. Please try again later.");
        console.log("error:", e);
      }
    },
    [handleDeploy, handleGetRun, handleGetThread]
  );

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const handleSend = async (userMessage: string) => {
    if (
      userMessage &&
      thread &&
      !threadLoading &&
      !messagesLoading &&
      !runLoading
    ) {
      try {
        setErrorBox(null);
        setThinking(true);

        const controller = new AbortController();
        setAbortController(controller);

        const messageRes = await handleSendMessage(
          userMessage,
          thread.id,
          controller.signal
        );
        if (!messageRes) throw Error("Failed to send message.");
        const runRes = await handleSetRun(thread.id, controller.signal);
        if (runRes)
          await handleGetRunUntilSuccess(
            runRes.id,
            thread.id,
            controller.signal
          );
        scrollToBottom();
      } catch (e) {
        setThinking(false);
        setErrorBox("An unexpected error occurred. Please try again later.");
        console.log("error:", e);
      }
    }
  };

  const handleTryAgain = () => {
    setErrorBox(null);
    if (input !== "") {
      handleSend(input);
    } else if (aiLastMessage) {
      handleDeploy(aiLastMessage);
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  useEffect(() => {
    handleSetThread();
  }, [handleSetThread]);

  useEffect(() => {
    return () => {
      handleClearAiContext();
      dispatch(handleSetWebAppSourceCode(null));
    };
  }, [dispatch, handleClearAiContext]);

  useEffect(() => {
    return () => {
      if (abortController) {
        abortController.abort();
      }
    };
  }, [abortController]);

  return (
    <div className="max-w-[95%] lg:max-w-2xl mx-auto pb-12 flex flex-col">
      <div className="w-full flex flex-col gap-4">
        {/* Messages Container */}
        <div className="flex-grow overflow-y-auto p-4 gap-3">
          {messages.length === 0 && (
            <div className="flex w-full justify-center items-center">
              <Flex direction="col" items="center" className="gap-6">
                <Flex direction="col" items="center" className="gap-2">
                  <AILogo className="w-20 h-20 flex-shrink-0" />
                  <Typography.Text size="base" className="text-center">
                    Your Djuno AI assistant ready to help you, ask me any thing.
                  </Typography.Text>
                </Flex>
                {AiExamples.length > 0 && (
                  <Flex direction="col" className="gap-2">
                    <Typography.Text size="sm">For example:</Typography.Text>
                    <div className="grid grid-cols-12 gap-3">
                      {AiExamples.map((example, i) => (
                        <div
                          key={i}
                          onClick={() => {
                            if (
                              thread &&
                              !threadLoading &&
                              !messagesLoading &&
                              !runLoading
                            ) {
                              const message = example.trim();
                              setInput(message);
                              handleSend(message);
                            }
                          }}
                          className={cn(
                            "col-span-6 bg-slate-100 dark:bg-dark-3 rounded-lg p-3 cursor-pointer border-2 border-slate-200 hover:border-primary-200 dark:border-dark-2 hover:dark:border-primary-500/70 transition-all duration-300",
                            {
                              "!cursor-not-allowed":
                                !thread ||
                                threadLoading ||
                                messagesLoading ||
                                runLoading,
                            }
                          )}
                        >
                          <Typography.Text size="xs">{example}</Typography.Text>
                        </div>
                      ))}
                    </div>
                  </Flex>
                )}
              </Flex>
            </div>
          )}
          <Flex direction="col" className="gap-2">
            {messages.map((msg) => (
              <div
                key={msg.id}
                className={`p-2 md:p-4 rounded-3xl !text-sm w-[95%] ${
                  msg.role === "user"
                    ? "bg-blue-500 text-white self-end ml-auto !rounded-br-none"
                    : "bg-gray-200 text-gray-800 dark:bg-dark-2 dark:text-slate-100 self-start !rounded-bl-none"
                }`}
              >
                <Flex
                  items="center"
                  justify="between"
                  className="w-full border-b mb-3 pb-1 border-slate-300 dark:border-slate-600"
                >
                  <Typography.Text size="sm" uiType="transparent">
                    {msg.role === "user" ? (
                      <Flex items="center" className="gap-1">
                        <div className="w-4 h-4 flex-shrink-0">
                          <UserAvatar />
                        </div>
                        {user?.FullName}
                      </Flex>
                    ) : (
                      <Flex items="center" className="gap-1">
                        <DjunoLogo className="w-4 h-4 flex-shrink-0" />
                        Djuno AI
                      </Flex>
                    )}
                  </Typography.Text>
                  <Typography.Text size="xs" uiType="transparent">
                    {
                      formatTimestamp(msg.created_at, "HH:mm A", {
                        isUTC: false,
                      }).datetime
                    }
                  </Typography.Text>
                </Flex>
                <MarkdownText text={msg.content[0].text.value} />
              </div>
            ))}
            {(thinking || deploying) && (
              <Flex className="items-center gap-1">
                <Loading borderSize={2} uiSize={16} />
                <Typography.Text size="sm">
                  {thinking
                    ? "thinking"
                    : deploying
                    ? "preparing"
                    : "last preparing"}
                </Typography.Text>
              </Flex>
            )}
            {errorBox && (
              <Flex className="items-center gap-1 mt-3">
                <Alert uiType="error" showIcon className="!text-xs">
                  <Flex className="items-center justify-between gap-1 w-full">
                    <Typography.Text size="sm">{errorBox}</Typography.Text>
                    <Button
                      uiType="light"
                      uiSize="small"
                      onClick={handleTryAgain}
                      className="whitespace-nowrap"
                    >
                      <ArrowPathIcon className="w-4 h-4 flex-shrink-0" />
                      try again
                    </Button>
                  </Flex>
                </Alert>
              </Flex>
            )}
          </Flex>
          <div ref={messagesEndRef} />
        </div>

        {/* Input Section */}
        {messages.length === 0 && (
          <div className="p-2 border-t border-gray-300 bg-white dark:border-dark-2 dark:bg-dark-1 flex items-center space-x-2">
            <Input
              type="text"
              value={input}
              onChange={(e) => setInput(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter" && !e.shiftKey) {
                  e.preventDefault();
                  if (thread && input.trim() !== "") handleSend(input.trim());
                }
              }}
              placeholder="Type a message..."
              className="focus:outline-none focus:ring-2 focus:!ring-blue-500"
              containerClassName="!flex-grow"
            />
            <Button
              onClick={() => handleSend(input.trim())}
              uiType="primary"
              disabled={!thread || input.trim() === ""}
              loading={threadLoading || messagesLoading || runLoading}
            >
              Send
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default AiAssistantStep;
