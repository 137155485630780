import { useNavigate } from "react-router-dom";
// import { ReactComponent as BellAlertIcon } from "./../../assets/icons/bell-alert.svg";
import { ReactComponent as CloseIcon } from "./../../assets/icons/close.svg";
import { ReactComponent as ArrowRightIcon } from "./../../assets/icons/arrow-right.svg";
import { WebAppsUrl } from "../../utils/urls";
import { useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks";
import {
  WebAppCreateApiData,
  WebAppCreateLevels,
  WebAppCreationStep,
} from "../../types/web-app";
import {
  selectWebAppSelectedDeployType,
  handleClearWebAppCreationSlice,
  selectWebAppCreateLoading,
  selectWebAppImageValue,
  selectWebAppImageCredentialId,
  createWebAppsAsync,
  selectWebAppTagQuery,
  selectWebAppImageQuery,
  selectWebAppNameValidationLoading,
  selectWebAppNameValidation,
  selectWebAppSourceCode,
  selectWebAppGitRepo,
  selectWebAppRuntime,
  selectWebAppBuildCommand,
  selectWebAppStartCommand,
  selectWebAppGitBranch,
} from "../../store/web-app/webAppCreateSlice";
import DeployingTypeStep from "../../components/web-apps/create-page-steps/DeployingTypeStep";
import DeployingSourceStep from "../../components/web-apps/create-page-steps/DeployingSourceStep";
import ConfigsStep from "../../components/web-apps/create-page-steps/ConfigsStep";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { WebAppCreateSchema } from "../../utils/validations";
import { Button, cn, Steps, Typography } from "djuno-design";
import useConfigIntercomeSetting from "../../hooks/useConfigIntercomeSetting";
import {
  getWebAppsAsync,
  getWebAppsPlansAsync,
  selectWebApps,
} from "../../store/web-app/webAppsSlice";
import useLimitations from "../../hooks/useLimitations";
import AiAssistantStep from "../../components/web-apps/create-page-steps/AiAssistantStep";
import { useAiChatContext } from "../../providers/AiChatProvider";

const WebAppCreatePage = () => {
  const webApps = useAppSelector(selectWebApps);

  useConfigIntercomeSetting();
  const navigate = useNavigate();
  const [activeStepValue, setActiveStepValue] =
    useState<WebAppCreateLevels>("DeployingTypeStep");

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (webApps.length === 0) {
      dispatch(getWebAppsAsync({}));
    }
  }, [dispatch, webApps.length]);

  const { numberOfWebAppsLimitChecker } = useLimitations({
    cases: ["NumberOfWebApps"],
  });

  const isValidToCreateNewWebApp = useMemo(
    () => !numberOfWebAppsLimitChecker(webApps.length),
    [numberOfWebAppsLimitChecker, webApps.length]
  );

  if (!isValidToCreateNewWebApp) {
    navigate(WebAppsUrl);
  }

  const nameValidationLoading = useAppSelector(
    selectWebAppNameValidationLoading
  );
  const nameValidation = useAppSelector(selectWebAppNameValidation);

  const selectedDeployType = useAppSelector(selectWebAppSelectedDeployType);
  const createLoading = useAppSelector(selectWebAppCreateLoading);
  const imageValue = useAppSelector(selectWebAppImageValue);
  const imageQuery = useAppSelector(selectWebAppImageQuery);
  const imageCredentialId = useAppSelector(selectWebAppImageCredentialId);
  const tagQuery = useAppSelector(selectWebAppTagQuery);
  const sourceCode = useAppSelector(selectWebAppSourceCode);
  const gitRepo = useAppSelector(selectWebAppGitRepo);
  const branch = useAppSelector(selectWebAppGitBranch);
  const runtime = useAppSelector(selectWebAppRuntime);
  const buildCommand = useAppSelector(selectWebAppBuildCommand);
  const startCommand = useAppSelector(selectWebAppStartCommand);

  const { thread, parseLoading } = useAiChatContext();

  const steps: WebAppCreationStep[] = useMemo(() => {
    const deployingTypeOption: WebAppCreationStep = {
      title: "Deploying Type",
      value: "DeployingTypeStep",
    };

    const deployingSourceOption: WebAppCreationStep = {
      title: "Deploying Source",
      value: "DeployingSourceStep",
    };

    const formOption: WebAppCreationStep = {
      title: "App Data",
      value: "ConfigsStep",
    };

    const assistantOption: WebAppCreationStep = {
      title: "AI Assistant",
      value: "AiAssistantStep",
    };

    switch (selectedDeployType) {
      case "ai":
        return [deployingTypeOption, formOption, assistantOption];
      case "image":
        return [deployingTypeOption, deployingSourceOption, formOption];
      default:
        return [deployingTypeOption, deployingSourceOption, formOption];
    }
  }, [selectedDeployType]);

  const activeStepData = useMemo(() => {
    const stepsValues = steps.map((s) => s.value);
    const activeStep = steps.find((s) => s.value === activeStepValue);
    return {
      activeStep,
      activeStepIndex: stepsValues.indexOf(activeStepValue),
    };
  }, [activeStepValue, steps]);

  const form = useForm({
    resolver: yupResolver(WebAppCreateSchema()),
    mode: "all",
    defaultValues: {
      Port: "80",
    },
  });

  const continueButtonValidation = useMemo(() => {
    let disabled = true;

    if (activeStepValue === "DeployingTypeStep") {
      disabled = !!!activeStepValue;
    }

    if (activeStepValue === "DeployingSourceStep") {
      disabled =
        selectedDeployType === "image" || selectedDeployType === "ai"
          ? imageValue === "" || imageValue !== imageQuery || tagQuery === ""
          : gitRepo === null;
    }

    if (activeStepValue === "ConfigsStep") {
      disabled =
        !form.formState.isValid ||
        nameValidation !== null ||
        nameValidationLoading;
    }

    if (activeStepValue === "AiAssistantStep") {
      disabled =
        !form.formState.isValid ||
        imageValue === "" ||
        tagQuery === "" ||
        sourceCode === null ||
        thread === null ||
        parseLoading;
    }

    return { disabled };
  }, [
    activeStepValue,
    form.formState.isValid,
    gitRepo,
    imageQuery,
    imageValue,
    nameValidation,
    nameValidationLoading,
    parseLoading,
    selectedDeployType,
    sourceCode,
    tagQuery,
    thread,
  ]);

  const handleCreateWebApp = (data: any) => {
    if (!createLoading) {
      let formData: WebAppCreateApiData = {
        AppName: data.AppName,
        PlanId: data.PlanId,
        EvironmentVariables: data.EvironmentVariables,
        Autodeploy: true,
        ImageName: "",
        ImageTag: "",
        Port: data.Port || 80,
        ReplicaCounts: 1,
        RegistryId:
          imageCredentialId !== null ? Number(imageCredentialId) : null,
      };

      if (selectedDeployType === "image") {
        formData = {
          ...formData,
          ImageName: imageValue,
          ImageTag: tagQuery,
        };
      }

      if (selectedDeployType === "ai") {
        formData = {
          ...formData,
          ImageName: imageValue,
          ImageTag: tagQuery,
          ThreadId: thread?.id,
          WebAppSourceCode: sourceCode,
        };
      }

      if (selectedDeployType === "git") {
        formData = {
          ...formData,
          RepositoryUrl: gitRepo?.full_name,
          Branch: branch,
          Runtime: runtime,
          BuildCommand: buildCommand,
          StartCommand: startCommand,
        };
      }

      console.log(formData);
      dispatch(createWebAppsAsync({ data: formData })).then((action) => {
        if (action.type === "web-apps/create/fulfilled") {
          dispatch(getWebAppsAsync({}));
          navigate(WebAppsUrl);
        }
      });
    }
  };

  useEffect(() => {
    dispatch(getWebAppsPlansAsync({}));
  }, [dispatch]);

  useEffect(() => {
    form.setValue("AppName", "");
    form.setValue("EvironmentVariables", []);
  }, [form, selectedDeployType]);

  useEffect(() => {
    return () => {
      dispatch(handleClearWebAppCreationSlice({}));
    };
  }, [dispatch]);

  return (
    <>
      <div className="flex items-center justify-between h-16 px-6 sticky top-0 z-20 bg-white dark:bg-dark-1 border-b dark:border-dark-2">
        <div className="items-center justify-between flex flex-1 transition duration-150">
          <Typography.Text className="font-medium">
            Create Web App
          </Typography.Text>
        </div>
        <div className="">
          <Button
            uiType="light"
            uiSize="small"
            onClick={() => navigate(WebAppsUrl)}
            className="group"
          >
            <CloseIcon className="w-3 h-3 group-hover:rotate-90 group-hover:scale-110 transition-all duration-500" />
          </Button>
        </div>
      </div>

      <Steps
        step={activeStepValue}
        steps={[
          ...steps.map((step) => ({
            label: (
              <Typography.Text
                uiType="transparent"
                className="!text-xs md:!text-sm"
              >
                {step.title}
              </Typography.Text>
            ),
            value: step.value,
            callback: step.callback,
          })),
        ]}
        className="px-6"
      />
      <form>
        <div className="mt-5 w-full px-6">
          <div className="mx-auto flex-1 flex justify-start pb-14 w-full">
            {activeStepValue === "DeployingTypeStep" && <DeployingTypeStep />}
            {activeStepValue === "DeployingSourceStep" && (
              <DeployingSourceStep />
            )}
            {activeStepValue === "ConfigsStep" && (
              <ConfigsStep
                form={form}
                showEnvVars={selectedDeployType !== "ai"}
                showPort={selectedDeployType !== "ai"}
              />
            )}
            {activeStepValue === "AiAssistantStep" && (
              <AiAssistantStep form={form} />
            )}
          </div>
        </div>

        <div className="fixed bottom-0 right-0 left-0 flex items-center w-full h-16 border-t bg-white dark:bg-dark-1 dark:border-dark-2 px-6 lg:pl-80">
          <div
            className={cn("flex items-center justify-between w-full", {
              "!justify-end": activeStepData.activeStepIndex === 0,
            })}
          >
            {activeStepData.activeStepIndex > 0 && (
              <Button
                uiType="light"
                onClick={() => {
                  setActiveStepValue(
                    steps[activeStepData.activeStepIndex - 1].value
                  );
                }}
              >
                back
              </Button>
            )}

            <Button
              uiType="primary"
              disabled={continueButtonValidation.disabled}
              onClick={() => {
                if (activeStepData.activeStepIndex + 1 < steps.length) {
                  setActiveStepValue(
                    steps[activeStepData.activeStepIndex + 1].value
                  );
                }
                if (activeStepData.activeStepIndex + 1 === steps.length) {
                  form.handleSubmit(handleCreateWebApp)();
                }
              }}
              type="button"
              className="group w-[110px]"
              loading={createLoading || nameValidationLoading}
            >
              {activeStepData.activeStepIndex + 1 !== steps.length
                ? "Continue"
                : "Create Web App"}
              <ArrowRightIcon className="w-4 h-4 group-hover:scale-110 group-hover:translate-x-1 transition-all duration-300" />
            </Button>
          </div>
        </div>
      </form>
    </>
  );
};

export default WebAppCreatePage;
