import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { IThunkRejectValue, RootState } from "../../types";
import toast from "react-hot-toast";
import { WebAppCreateState, WebAppDeployTypes } from "../../types/web-app";
import { getExtractErrors } from "../../apis";
import { CustomErrorToast } from "../../components/general/Toast";
import { createK8ServiceApi } from "../../apis/kubernetesApi";
import { K8CreateState } from "../../types/kubernetes";
import { IPlanGroup } from "../../types/billing";
import { getPlansApi } from "../../apis/billingAPI";

const initialState: K8CreateState = {
  //general data
  createLoading: false,

  plans: [],
  plansLoading: false,
};

export const createK8ServiceAsync = createAsyncThunk<
  true,
  { data: any },
  IThunkRejectValue
>("k8-services/create", async ({ data }, { rejectWithValue }) => {
  try {
    await createK8ServiceApi(data);
    return true;
  } catch (e) {
    return rejectWithValue({ message: getExtractErrors(e) });
  }
});

export const getK8PlansAsync = createAsyncThunk<
  { plans: Array<IPlanGroup> },
  {
    withoutLoading?: boolean;
  },
  IThunkRejectValue
>(
  "k8-services/plans",
  async (
    { withoutLoading = false },
    { rejectWithValue, fulfillWithValue, requestId, dispatch }
  ) => {
    try {
      dispatch(
        getK8PlansAsync.pending(requestId, {
          withoutLoading,
        })
      );
      const response = await getPlansApi({
        serviceType: process.env.REACT_APP_KUBERNETES_SERVICE_TYPE,
      });
      const plans = response.data.Result;
      return fulfillWithValue({ plans });
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);

export const k8CreateSlice = createSlice({
  name: "k8Create",
  initialState,
  reducers: {
    handleClearK8CreationSlice: (state) => {
      state.createLoading = initialState.createLoading;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createK8ServiceAsync.pending, (state) => {
        state.createLoading = true;
      })
      .addCase(createK8ServiceAsync.fulfilled, (state) => {
        state.createLoading = false;
      })
      .addCase(createK8ServiceAsync.rejected, (state, { payload }) => {
        state.createLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(getK8PlansAsync.pending, (state, action) => {
        if (!action.meta.arg.withoutLoading) state.plansLoading = true;
      })
      .addCase(getK8PlansAsync.fulfilled, (state, { payload: { plans } }) => {
        state.plansLoading = false;
        if (plans.length) state.plans = plans[0].PlanDetail;
      })
      .addCase(getK8PlansAsync.rejected, (state, { payload }) => {
        state.plansLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });
  },
});

// generals
export const selectK8CreateLoading = (state: RootState) =>
  state.k8Create.createLoading;

export const selectK8Plans = (state: RootState) => state.k8Create.plans;
export const selectK8PlansLoading = (state: RootState) =>
  state.k8Create.plansLoading;

export const { handleClearK8CreationSlice } = k8CreateSlice.actions;
export default k8CreateSlice.reducer;
