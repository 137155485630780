import { useAppDispatch, useAppSelector } from "../../../hooks";
import { S3NotActiveTab } from "../../layouts/NotData";

import {
  activateS3Async,
  changeS3NodeStatus,
  selectS3NodeStatus,
} from "../../../store/s3/s3PublicSlice";
import { PlansTab } from "../../plans/UniversalPlansBox";
import DeactivatePlan from "../../plans/DeactivatePlan";
import { useNavigate } from "react-router-dom";
import { S3BucketsUrl } from "../../../utils/urls";

const S3PlansTab = () => {
  const s3ServiceType =
    Number(process.env.REACT_APP_SIMPLE_STORAGE_SERVICE_TYPE) || 13;
  const s3Status = useAppSelector(selectS3NodeStatus);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  if (s3Status !== 2) {
    return <S3NotActiveTab />;
  }
  return (
    <>
      <PlansTab serviceType={s3ServiceType} />
      <DeactivatePlan
        deactivateFunction={() => {
          dispatch(activateS3Async({ isActive: false })).then((action) => {
            if (action.type === "s3/activate/fulfilled") {
              navigate(S3BucketsUrl);
            }
          });
          // dispatch(changeS3NodeStatus({ status: 5 }));
        }}
      />
    </>
  );
};

export default S3PlansTab;
