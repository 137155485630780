import { useCallback, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "./../../../../hooks";

import { Alert, Button, Flex, Modal, Typography } from "djuno-design";
import {
  getK8ServiceAsync,
  handleToggleK8ResetkubeConfigModal,
  K8ResetkubeconfigAsync,
  selectK8ResetkubeConfigModal,
  selectK8Service,
  selectK8ServiceActionLoading,
  selectK8ServiceLoading,
} from "./../../../../store/kubernetes/k8ServiceSlice";
import { getK8ServicesAsync } from "./../../../../store/kubernetes/k8ServicesSlice";

const K8ResetConfigModal = () => {
  const isOpen = useAppSelector(selectK8ResetkubeConfigModal);

  const k8Service = useAppSelector(selectK8Service);
  const k8ServiceLoading = useAppSelector(selectK8ServiceLoading);
  const k8ServiceActionLoading = useAppSelector(selectK8ServiceActionLoading);
  const dispatch = useAppDispatch();

  const onSubmit = (data: any) => {
    if (k8Service && !k8ServiceLoading) {
      dispatch(
        K8ResetkubeconfigAsync({
          kubeId: k8Service.id,
        })
      ).then((action) => {
        if (action.type === "k8-service/reset-kubeconfig/fulfilled") {
          handleOnClose();
          dispatch(getK8ServicesAsync({ withoutLoading: false }));
          dispatch(getK8ServiceAsync({ id: k8Service.id }));
        }
      });
    }
  };

  const handleOnClose = useCallback(() => {
    dispatch(handleToggleK8ResetkubeConfigModal(false));
  }, [dispatch]);

  useEffect(() => {
    return () => {
      handleOnClose();
    };
  }, [handleOnClose]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleOnClose}
      contentClassName="max-w-lg"
      title="Reset kubeconfig"
    >
      <div className="flex flex-col w-full mt-5">
        <Alert
          description="Resetting the kubeconfig will result in a brief period of downtime for your services."
          showIcon
          uiType="warning"
        />

        <div className="space-y-5 mt-4">
          <Typography.Text className="!text-sm">
            This operation cannot be cancelled. Once it has been launched, you
            will lose access to your API server with your current kubeconfig.
            The nodes will also lose access to the API server, but the
            containers hosted will continue to work.
          </Typography.Text>
        </div>
        <div className="space-y-5 mt-4">
          <Typography.Text className="!text-sm">
            You will need to download a new kubeconfig file a few minutes later
            (the time it takes to generate the new certification authority, and
            update the control plane components with this new CA). Each node
            will then be reinstalled (one by one), then relocate access to the
            API server once the first step is complete.
          </Typography.Text>
        </div>
        <div className="space-y-5 mt-4">
          <Typography.Text className="!text-sm">
            All of your Kubernetes resources are preserved.
          </Typography.Text>
        </div>

        <Flex items="center" justify="end" className="gap-2 mt-5">
          <Button onClick={handleOnClose}>Cancel</Button>
          <Button
            uiType="primary"
            type="submit"
            onClick={onSubmit}
            loading={k8ServiceActionLoading}
          >
            Confirm
          </Button>
        </Flex>
      </div>
    </Modal>
  );
};

export default K8ResetConfigModal;
