import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IThunkRejectValue, RootState } from "../../types";
import { IpfsMetrics, IpfsPublicStateType } from "../../types/ipfs-file";
import { getExtractErrorCode, getExtractErrors } from "../../apis";
import { getIpfsMetricsApi, getIpfsUsageApi } from "../../apis/filesAPI";
import toast from "react-hot-toast";
import { CustomErrorToast } from "../../components/general/Toast";
import { isAxiosError } from "axios";
import { IpfsUpdatingMessage } from "./filesSlice";
import { activateIpfsApi } from "../../apis/ipfsApiKeysAPI";

const initialState: IpfsPublicStateType = {
  nodeStatus: null, //is IPFS enabled or not?
  activeStatusMessage: null,
  metrics: null,
  usage: null,
  metricsLoading: false,
  actionLoading: false,
};

export const getIpfsMetricsAsync = createAsyncThunk<
  { ipfsMetrics: IpfsMetrics | null; usage: number | null },
  { withoutLoading?: boolean; time: number },
  IThunkRejectValue
>(
  "ipfs/metrics",
  async (
    { withoutLoading, time },
    { rejectWithValue, fulfillWithValue, dispatch, requestId }
  ) => {
    try {
      dispatch(
        getIpfsMetricsAsync.pending(requestId, { withoutLoading, time })
      );

      const metrics_response = await getIpfsMetricsApi(time);
      const ipfsMetrics = metrics_response.data.Result;
      const NodeStatus = metrics_response.data.NodeStatus as number;

      dispatch(changeIpfsNodeStatus({ status: NodeStatus }));

      let usage = 0;

      if (NodeStatus === 2) {
        const usage_response = await getIpfsUsageApi();
        usage = usage_response.data.Result.Usage || 0;
      }

      return fulfillWithValue({ ipfsMetrics, usage });
    } catch (e) {
      if (isAxiosError(e)) {
        dispatch(
          changeIpfsNodeStatus({
            status: e.response?.data.NodeStatus || 4,
            message: IpfsUpdatingMessage,
          })
        );
        return fulfillWithValue({ usage: null, ipfsMetrics: null });
      } else {
        return rejectWithValue({
          message: getExtractErrors(e),
          code: getExtractErrorCode(e),
        });
      }
    }
  }
);

export const activateIpfsAsync = createAsyncThunk<
  number,
  { isActive: boolean },
  IThunkRejectValue
>("ipfs/activate", async ({ isActive }, { rejectWithValue }) => {
  try {
    const response = await activateIpfsApi(isActive);
    return response.data.NodeStatus;
  } catch (e) {
    return rejectWithValue({ message: getExtractErrors(e) });
  }
});

const ipfsPublicSlice = createSlice({
  name: "ipfs-public",
  initialState: initialState,
  reducers: {
    changeIpfsNodeStatus(
      state,
      action: PayloadAction<{ status: number; message?: string }>
    ) {
      state.nodeStatus =
        typeof action.payload.status === "number" ? action.payload.status : -1;
      state.activeStatusMessage = action.payload.message || null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getIpfsMetricsAsync.pending, (state, action) => {
        if (!action.meta.arg.withoutLoading) state.metricsLoading = true;
      })
      .addCase(getIpfsMetricsAsync.fulfilled, (state, action) => {
        state.metrics = action.payload.ipfsMetrics;
        state.usage = action.payload.usage;
        state.metricsLoading = false;
      })
      .addCase(getIpfsMetricsAsync.rejected, (state, { payload }) => {
        state.metricsLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(activateIpfsAsync.pending, (state) => {
        state.actionLoading = true;
      })
      .addCase(activateIpfsAsync.fulfilled, (state, { payload }) => {
        state.actionLoading = false;
        state.nodeStatus = payload;
      })
      .addCase(activateIpfsAsync.rejected, (state, { payload }) => {
        state.actionLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });
  },
});

export const selectIpfsNodeStatus = (state: RootState) =>
  state.ipfsPublic.nodeStatus;
export const selectIpfsStatusMessage = (state: RootState) =>
  state.ipfsPublic.activeStatusMessage;

//metrics
export const selectIpfsUsage = (state: RootState) => state.ipfsPublic.usage;
export const selectIpfsMetrics = (state: RootState) => state.ipfsPublic.metrics;
export const selectIpfsMetricsLoading = (state: RootState) =>
  state.ipfsPublic.metricsLoading;

export const selectIpfsActionLoading = (state: RootState) =>
  state.ipfsPublic.actionLoading;

export const { changeIpfsNodeStatus } = ipfsPublicSlice.actions;
export default ipfsPublicSlice.reducer;
