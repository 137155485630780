import { useNavigate } from "react-router-dom";
import { ReactComponent as CloseIcon } from "./../../../assets/icons/close.svg";
import { ReactComponent as CheckIcon } from "./../../../assets/icons/check.svg";
import { InstancesVolumesUrl } from "../../../utils/urls";
import { useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { InstanceVolumeCreateSchema } from "../../../utils/validations";
import { DBSCatalog } from "../../../types/database";
import {
  getInstancesProductAvailabilityAsync,
  getRegionsAsync,
  selectInstancesProductAvailibility,
  selectInstancesProductAvailibilityLoading,
  selectInstancesRegions,
  selectInstancesRegionsLoading,
} from "../../../store/instances/instancesSlice";
import {
  getDBSCatalogsAsync,
  selectDBSCatalogs,
  selectDBSCatalogsLoading,
} from "../../../store/database/servicesSlice";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { InstanceVolumeCreateApiType } from "../../../types/instance";
import {
  InstanceVolumeCapacityInput,
  InstanceVolumeNameInput,
  InstanceVolumeRegionInput,
  InstanceVolumeTypeInput,
} from "../../../components/instances/volumes/CreateComponents";
import {
  getVolumePlanTypeFromPlanCode,
  makeInstanceVolumeTypes,
} from "../../../components/instances/volumes/utile";
import { capitalizeFirstLetter, preventFormEnterAction } from "../../../utils";
import {
  createInstancesVolumesAsync,
  getInstancesVolumesAsync,
  selectInstancesVolumesActionLoading,
} from "../../../store/instances/instancesVolumesSlice";
import { getDBSPriceAmount } from "../../../components/databases/ServiceCreateComponents";
import { Button, cn, Flex, Loading, Typography } from "djuno-design";

const createSteps = [
  "regionSelection",
  "typeSelection",
  "capacitySelection",
  "nameSelection",
  "validation",
];

type CreateStep = (typeof createSteps)[number];

const InstanceVolumeCreatePage = () => {
  const navigate = useNavigate();

  const regions = useAppSelector(selectInstancesRegions);
  const regionsLoading = useAppSelector(selectInstancesRegionsLoading);

  const catalogs = useAppSelector(selectDBSCatalogs);
  const catalogsLoading = useAppSelector(selectDBSCatalogsLoading);

  const productAvailibility = useAppSelector(
    selectInstancesProductAvailibility
  );
  const productAvailibilityLoading = useAppSelector(
    selectInstancesProductAvailibilityLoading
  );

  const actionLoading = useAppSelector(selectInstancesVolumesActionLoading);

  // states
  const [expanded, setExpanded] = useState<CreateStep>(createSteps[0]);
  const [filteredCatalogs, setFilteredCatalogs] = useState<Array<DBSCatalog>>(
    []
  );

  const dispatch = useAppDispatch();

  const methods = useForm({
    mode: "all",
    resolver: yupResolver(InstanceVolumeCreateSchema),
    defaultValues: {
      selectedRegion: "",
      selectedType: "",
      capacity: 10,
      volumeName: "",
    },
  });

  const {
    setValue,
    handleSubmit,
    reset,
    control,
    formState: { errors },
    watch,
  } = methods;

  // watch form values

  const selectedRegionName = watch("selectedRegion");
  const selectedType = watch("selectedType");
  const capacity = watch("capacity");
  const volumeName = watch("volumeName");

  //get first data
  useEffect(() => {
    dispatch(getDBSCatalogsAsync());
    dispatch(getInstancesProductAvailabilityAsync({ addonFamily: "volume" }));
    dispatch(getRegionsAsync());
  }, [dispatch, reset]);

  useEffect(() => {
    if (catalogs) {
      setFilteredCatalogs(
        catalogs.filter((c) => c.planCode?.startsWith("volume."))
      );
    }
  }, [catalogs]);

  const typeOptions = useMemo(() => {
    if (productAvailibility) {
      const plans = productAvailibility.plans.filter((pa) =>
        pa.regions.map((r) => r.name).includes(selectedRegionName)
      );
      return makeInstanceVolumeTypes(plans);
    } else {
      return [];
    }
  }, [productAvailibility, selectedRegionName]);

  const selectedCatalog = useMemo(() => {
    return filteredCatalogs.find((c) => c.planCode === selectedType);
  }, [filteredCatalogs, selectedType]);

  const estimatedPrice = useMemo(() => {
    if (selectedCatalog) {
      const unitPrice = selectedCatalog.pricings[0].price;
      return unitPrice * 24 * 30 * capacity;
    }
    return 0;
  }, [capacity, selectedCatalog]);

  const handleEditRegionSelection = () => {
    setExpanded("regionSelection");

    setValue("selectedType", "");
    setValue("capacity", 10);
    setValue("volumeName", "");
  };

  const handleEditTypeSelection = () => {
    setExpanded("typeSelection");

    setValue("capacity", 10);
    setValue("volumeName", "");
  };

  const handleEditCapacitySelection = () => {
    setExpanded("capacitySelection");

    setValue("volumeName", "");
  };

  const handleEditNameSelection = () => {
    setExpanded("nameSelection");
  };

  const handleSubmitForm = (data: any) => {
    const apiData: InstanceVolumeCreateApiType = {
      name: data.volumeName,
      region: data.selectedRegion,
      size: data.capacity,
      type: getVolumePlanTypeFromPlanCode(data.selectedType),
      description: null,
      imageId: null,
      snapshotId: null,
    };

    if (!actionLoading) {
      dispatch(createInstancesVolumesAsync({ data: apiData })).then(
        (action) => {
          if (action.type === "instances/volumes/create/fulfilled") {
            dispatch(getInstancesVolumesAsync({}));
            navigate(InstancesVolumesUrl);
          }
        }
      );
    }
  };

  return (
    <>
      <div className="flex items-center justify-between h-16 px-6 sticky top-0 z-20 bg-white dark:bg-dark-1 border-b dark:border-dark-2">
        <div className="items-center justify-between flex flex-1 transition duration-150">
          <div className="font-medium mr-2 text-standard text-md dark:text-slate-100 flex items-center gap-1">
            Create a volume
          </div>
        </div>
        <div className="">
          <Button
            uiType="light"
            uiSize="small"
            onClick={() => navigate(InstancesVolumesUrl)}
            className="group"
          >
            <CloseIcon className="w-3 h-3 group-hover:rotate-90 group-hover:scale-110 transition-all duration-500" />
          </Button>
        </div>
      </div>

      {(productAvailibilityLoading || catalogsLoading || regionsLoading) && (
        <Flex justify="center" items="center" className="min-h-[300px] w-full">
          <Loading borderSize={2} />
        </Flex>
      )}
      {!productAvailibilityLoading && !catalogsLoading && !regionsLoading && (
        <form
          onSubmit={handleSubmit(handleSubmitForm)}
          onKeyDown={preventFormEnterAction}
        >
          <div className="mt-10 w-full px-6 flex flex-col lg:flex-row pb-24 gap-8 relative">
            <div className="w-full flex flex-col">
              {/* select region */}
              <Accordion
                expanded={expanded === "regionSelection"}
                sx={{ boxShadow: "none" }}
                className="bg-white dark:bg-dark-3"
              >
                <AccordionSummary
                  expandIcon={null}
                  aria-controls="panel1bh-content"
                  id="regionSelection"
                  sx={{ my: 0 }}
                >
                  <div className="flex w-full items-center justify-between">
                    {createSteps.indexOf(expanded) > 0 ? (
                      <>
                        <Typography.Text className="!text-base font-medium flex items-center gap-1">
                          <CheckIcon className="w-5 h-5 text-primary-500" />
                          Region selected: {selectedRegionName.toUpperCase()}
                        </Typography.Text>
                        <Typography.Link
                          className="!text-sm"
                          onClick={handleEditRegionSelection}
                        >
                          edit this step
                        </Typography.Link>
                      </>
                    ) : (
                      <Typography.Text
                        className={cn(
                          "text-base font-medium flex items-center gap-1",
                          {
                            "!text-primary-500": expanded === "regionSelection",
                          }
                        )}
                      >
                        1. Select a region
                      </Typography.Text>
                    )}
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <div className="mt-3">
                    <InstanceVolumeRegionInput
                      regions={regions}
                      control={control}
                      errorMessage={errors.selectedRegion?.message}
                    />
                  </div>

                  <Button
                    id="1th-next-button"
                    disabled={selectedRegionName === ""}
                    onClick={() => setExpanded("typeSelection")}
                  >
                    Next
                  </Button>
                </AccordionDetails>
              </Accordion>

              {/* select type */}
              <Accordion
                expanded={expanded === "typeSelection"}
                sx={{ boxShadow: "none" }}
                className="bg-white dark:bg-dark-3"
              >
                <AccordionSummary
                  expandIcon={null}
                  aria-controls="panel1bh-content"
                  id="typeSelection"
                  sx={{ my: 0 }}
                >
                  <div className="flex w-full items-center justify-between">
                    {createSteps.indexOf(expanded) > 1 ? (
                      <>
                        <Typography.Text className="!text-base font-medium flex items-center gap-1">
                          <CheckIcon className="w-5 h-5 text-primary-500" />
                          Type chosen:{" "}
                          {capitalizeFirstLetter(
                            getVolumePlanTypeFromPlanCode(selectedType)
                          )}
                        </Typography.Text>
                        <Typography.Link
                          className="!text-sm"
                          onClick={handleEditTypeSelection}
                        >
                          edit this step
                        </Typography.Link>
                      </>
                    ) : (
                      <Typography.Text
                        className={cn(
                          "!text-base font-medium flex items-center gap-1",
                          {
                            "!text-primary-500": expanded === "modelSelection",
                          }
                        )}
                      >
                        2. Select type
                      </Typography.Text>
                    )}
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <div className="mt-3">
                    <InstanceVolumeTypeInput
                      types={typeOptions}
                      control={control}
                      catalogs={filteredCatalogs}
                      errorMessage={errors.selectedType?.message}
                    />
                  </div>
                  <Button
                    id="2th-next-button"
                    disabled={selectedType === ""}
                    onClick={() => setExpanded("capacitySelection")}
                  >
                    Next
                  </Button>
                </AccordionDetails>
              </Accordion>

              {/* select capacity */}
              <Accordion
                expanded={expanded === "capacitySelection"}
                sx={{ boxShadow: "none" }}
                className="bg-white dark:bg-dark-3"
              >
                <AccordionSummary
                  expandIcon={null}
                  aria-controls="panel1bh-content"
                  id="capacitySelection"
                  sx={{ my: 0 }}
                >
                  <div className="w-full flex">
                    {createSteps.indexOf(expanded) > 2 ? (
                      <div className="flex flex-col gap-5 w-full">
                        <div className="flex w-full items-center justify-between">
                          <Typography.Text className="!text-base font-medium flex items-center gap-1">
                            <CheckIcon className="w-5 h-5 text-primary-500" />
                            Volume capacity: {capacity}
                          </Typography.Text>
                          <Typography.Link
                            className="!text-sm"
                            onClick={handleEditCapacitySelection}
                          >
                            edit this step
                          </Typography.Link>
                        </div>

                        {/* <div>
                            <div className="px-5 grid grid-cols-4">
                              <Text className="text-sm col-span-1">
                                Number of instances:
                              </Text>
                              <Text className="text-sm col-span-2">
                                {numberOfInstances}
                              </Text>
                            </div>
                            <div className="px-5 grid grid-cols-4">
                              <Text className="text-sm col-span-1">
                                Instance name:
                              </Text>
                              <Text className="text-sm col-span-2">
                                {instanceName}
                              </Text>
                            </div>
                            {autobackup !== null && (
                              <div className="px-5 grid grid-cols-4">
                                <Text className="text-sm col-span-1">
                                  Auto backup:
                                </Text>
                                <CheckIcon className="w-5 h-5 text-primary-500" />
                              </div>
                            )}
                          </div> */}
                      </div>
                    ) : (
                      <Typography.Text
                        className={cn(
                          "text-base font-medium flex items-center gap-1",
                          {
                            "!text-primary-500":
                              expanded === "instanceConfiguration",
                          }
                        )}
                      >
                        3. Volume capacity
                      </Typography.Text>
                    )}
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <div className="mt-3 mb-5">
                    <InstanceVolumeCapacityInput
                      selectedCatalog={selectedCatalog}
                      control={control}
                      errorMessage={errors.capacity?.message}
                      handleGoNexStep={() => {
                        setExpanded("nameSelection");
                      }}
                      estimatedPrice={estimatedPrice}
                    />
                  </div>
                </AccordionDetails>
              </Accordion>

              {/* select name */}
              <Accordion
                expanded={expanded === "nameSelection"}
                sx={{ boxShadow: "none" }}
                className="bg-white dark:bg-dark-3"
              >
                <AccordionSummary
                  expandIcon={null}
                  aria-controls="panel1bh-content"
                  id="nameSelection"
                  sx={{ my: 0 }}
                >
                  <div className="w-full flex">
                    {createSteps.indexOf(expanded) > 3 ? (
                      <div className="flex flex-col gap-5 w-full">
                        <div className="flex w-full items-center justify-between">
                          <Typography.Text className="!text-base font-medium flex items-center gap-1">
                            <CheckIcon className="w-5 h-5 text-primary-500" />
                            Volume name: {volumeName}
                          </Typography.Text>
                          <Typography.Link
                            className="!text-sm"
                            onClick={handleEditNameSelection}
                          >
                            edit this step
                          </Typography.Link>
                        </div>
                      </div>
                    ) : (
                      <Typography.Text
                        className={cn(
                          "!text-base font-medium flex items-center gap-1",
                          {
                            "!text-primary-500":
                              expanded === "instanceConfiguration",
                          }
                        )}
                      >
                        4. Volume name
                      </Typography.Text>
                    )}
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <div className="mt-3 mb-5">
                    <InstanceVolumeNameInput
                      control={control}
                      errorMessage={errors.volumeName?.message}
                      handleGoNexStep={() => {
                        setExpanded("validation");
                      }}
                    />
                  </div>
                </AccordionDetails>
              </Accordion>

              {/* validation */}
              <Accordion
                expanded={expanded === "validation"}
                sx={{ boxShadow: "none" }}
                className="bg-white dark:bg-dark-3"
              >
                <AccordionSummary
                  expandIcon={null}
                  aria-controls="panel1bh-content"
                  id="validation"
                  sx={{ my: 0 }}
                >
                  <div className="w-full flex">
                    <Typography.Text
                      className={cn(
                        "text-base font-medium flex items-center gap-1",
                        {
                          "!text-primary-500":
                            expanded === "instanceConfiguration",
                        }
                      )}
                    >
                      5. Validation
                    </Typography.Text>
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <div className="mt-3 mb-5">
                    <Typography.Text className="!text-sm">{`Estimated amount: $${getDBSPriceAmount(
                      estimatedPrice
                    )} /month`}</Typography.Text>
                  </div>

                  <div className="flex items-center gap-2">
                    <Button
                      uiType="primary"
                      loading={actionLoading}
                      type="submit"
                    >
                      Create the volume
                    </Button>
                  </div>
                </AccordionDetails>
              </Accordion>
            </div>
          </div>
        </form>
      )}
    </>
  );
};

export default InstanceVolumeCreatePage;
