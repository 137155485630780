import { DBSCategory, DBSEngine, DBSEngineType } from "../types/database";

export const getCategoryByEngine = (engine: DBSEngineType): DBSCategory => {
  switch (engine) {
    case "cassandra":
    case "mongodb":
    case "m3aggregator":
    case "m3db":
    case "mysql":
    case "postgresql":
    case "redis":
      return "operational";

    case "kafka":
    case "kafkaConnect":
    case "kafkaMirrorMaker":
      return "streaming";

    case "opensearch":
    case "grafana":
      return "analysis";

    default:
      return "operational";
  }
};

export const getRoutePrefixByCategory = (category: DBSCategory) => {
  switch (category) {
    case "operational":
      return "database";
    case "streaming":
      return "data-streaming";
    case "analysis":
      return "data-analysis";
  }
};

export const getRoutePrefixByEngine = (engine: DBSEngineType) => {
  const category = getCategoryByEngine(engine);
  return getRoutePrefixByCategory(category);
};
