import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import toast from "react-hot-toast";
import { Enum, IThunkRejectValue, RootState } from "../../types";
import { WebApp, WebAppsState, WebAppTemplate } from "../../types/web-app";
import {
  createWebAppMarketPlaceApi,
  deleteWebAppApi,
  getWebAppMarketPlaceApi,
  getWebAppsApi,
  resumeWebAppApi,
  suspendWebAppApi,
} from "../../apis/webAppsAPI";
import { getExtractErrors } from "../../apis";
import { CustomErrorToast } from "../../components/general/Toast";
import { IPlanGroup } from "../../types/billing";
import { getPlansApi } from "../../apis/billingAPI";

export const WebAppsRefreshStatus = [0, 1, 4];

export const WebAppsStatusEnums: Enum[] = [
  {
    Text: "NOTSTARTED",
    Value: 0,
  },
  {
    Text: "INPROGRESS",
    Value: 1,
  },
  {
    Text: "RUNNING",
    Value: 2,
  },
  {
    Text: "ERROR_CREATION",
    Value: 3,
  },
  {
    Text: "Waiting",
    Value: 4,
  },
];

export const WebAppsUpdatingMessage =
  "We are updating your service. Please wait...";

const initialState: WebAppsState = {
  webApps: [],
  loading: false,
  actionLoading: false,
  webApp: null,

  statusTypes: WebAppsStatusEnums,
  statusTypesLoading: false,

  plans: [],
  plansLoading: false,

  templates: [],
  templatesLoading: false,
  templatesCategories: {},
};

export const getWebAppsAsync = createAsyncThunk<
  { webApps: WebApp[] },
  { withoutLoading?: boolean },
  IThunkRejectValue
>(
  "web-apps",
  async (
    { withoutLoading },
    { rejectWithValue, fulfillWithValue, dispatch, requestId }
  ) => {
    try {
      dispatch(
        getWebAppsAsync.pending(requestId, {
          withoutLoading,
        })
      );

      const response = await getWebAppsApi();
      const webApps = response.data.Result;

      return fulfillWithValue({ webApps });
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);

export const deleteWebAppAsync = createAsyncThunk<
  boolean,
  { id: number },
  IThunkRejectValue
>("web-apps/delete", async ({ id }, { rejectWithValue }) => {
  try {
    await deleteWebAppApi(id);
    return true;
  } catch (e) {
    return rejectWithValue({ message: getExtractErrors(e) });
  }
});

export const suspendWebAppAsync = createAsyncThunk<
  boolean,
  { id: number },
  IThunkRejectValue
>("web-apps/suspend", async ({ id }, { rejectWithValue }) => {
  try {
    await suspendWebAppApi(id);
    return true;
  } catch (e) {
    return rejectWithValue({ message: getExtractErrors(e) });
  }
});

export const resumeWebAppAsync = createAsyncThunk<
  boolean,
  { id: number },
  IThunkRejectValue
>("web-apps/resume", async ({ id }, { rejectWithValue }) => {
  try {
    await resumeWebAppApi(id);
    return true;
  } catch (e) {
    return rejectWithValue({ message: getExtractErrors(e) });
  }
});

export const getWebAppsTemplatesAsync = createAsyncThunk<
  { templates: WebAppTemplate[]; categories: any },
  undefined,
  IThunkRejectValue
>("web-app/templates", async (_, { rejectWithValue, fulfillWithValue }) => {
  try {
    const response = await getWebAppMarketPlaceApi();
    const { Services, Categories } = response.data.Result;

    return fulfillWithValue({
      templates: Services,
      categories: Categories,
    });
  } catch (e) {
    return rejectWithValue({
      message: getExtractErrors(e),
    });
  }
});

export const craeteWebAppsFromTemplateAsync = createAsyncThunk<
  boolean,
  { data: any },
  IThunkRejectValue
>("web-app/templates/craete", async ({ data }, { rejectWithValue }) => {
  try {
    await createWebAppMarketPlaceApi(data);
    return true;
  } catch (e) {
    return rejectWithValue({
      message: getExtractErrors(e),
    });
  }
});

export const getWebAppsPlansAsync = createAsyncThunk<
  { plans: Array<IPlanGroup> },
  {
    withoutLoading?: boolean;
  },
  IThunkRejectValue
>(
  "web-app/plans",
  async (
    { withoutLoading = false },
    { rejectWithValue, fulfillWithValue, requestId, dispatch }
  ) => {
    try {
      dispatch(
        getWebAppsPlansAsync.pending(requestId, {
          withoutLoading,
        })
      );
      const response = await getPlansApi({
        serviceType: process.env.REACT_APP_WEB_APPS_SERVICE_TYPE,
      });
      const plans = response.data.Result;
      return fulfillWithValue({ plans });
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);

export const webAppsSlice = createSlice({
  name: "webApps",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getWebAppsAsync.pending, (state, action) => {
        if (!action.meta.arg.withoutLoading) state.loading = true;
      })
      .addCase(getWebAppsAsync.fulfilled, (state, action) => {
        state.webApps = action.payload.webApps;
        state.loading = false;
      })
      .addCase(getWebAppsAsync.rejected, (state, { payload }) => {
        state.loading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(deleteWebAppAsync.pending, (state) => {
        state.actionLoading = true;
      })
      .addCase(deleteWebAppAsync.fulfilled, (state) => {
        state.actionLoading = false;
      })
      .addCase(deleteWebAppAsync.rejected, (state, { payload }) => {
        state.actionLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(suspendWebAppAsync.pending, (state) => {
        state.actionLoading = true;
      })
      .addCase(suspendWebAppAsync.fulfilled, (state) => {
        state.actionLoading = false;
      })
      .addCase(suspendWebAppAsync.rejected, (state, { payload }) => {
        state.actionLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(resumeWebAppAsync.pending, (state) => {
        state.actionLoading = true;
      })
      .addCase(resumeWebAppAsync.fulfilled, (state) => {
        state.actionLoading = false;
      })
      .addCase(resumeWebAppAsync.rejected, (state, { payload }) => {
        state.actionLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(getWebAppsTemplatesAsync.pending, (state, action) => {
        state.templatesLoading = true;
      })
      .addCase(getWebAppsTemplatesAsync.fulfilled, (state, action) => {
        state.templates = action.payload.templates;
        state.templatesCategories = action.payload.categories;
        state.templatesLoading = false;
      })
      .addCase(getWebAppsTemplatesAsync.rejected, (state, { payload }) => {
        state.templatesLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });
    builder
      .addCase(craeteWebAppsFromTemplateAsync.pending, (state) => {
        state.actionLoading = true;
      })
      .addCase(craeteWebAppsFromTemplateAsync.fulfilled, (state) => {
        state.actionLoading = false;
      })
      .addCase(
        craeteWebAppsFromTemplateAsync.rejected,
        (state, { payload }) => {
          state.actionLoading = false;
          if (payload?.message)
            toast.error(() => CustomErrorToast(payload?.message));
        }
      );
    builder
      .addCase(getWebAppsPlansAsync.pending, (state, action) => {
        if (!action.meta.arg.withoutLoading) state.plansLoading = true;
      })
      .addCase(
        getWebAppsPlansAsync.fulfilled,
        (state, { payload: { plans } }) => {
          state.plansLoading = false;
          if (plans.length) state.plans = plans[0].PlanDetail;
        }
      )
      .addCase(getWebAppsPlansAsync.rejected, (state, { payload }) => {
        state.plansLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });
  },
});

export const selectWebApps = (state: RootState) => state.webApps.webApps;

export const selectWebAppsLoading = (state: RootState) => state.webApps.loading;

export const selectWebAppsActionLoading = (state: RootState) =>
  state.webApps.actionLoading;

export const selectSelectedWebApp = (state: RootState) => state.webApps.webApp;

export const selectWebAppsStatusTypes = (state: RootState) =>
  state.webApps.statusTypes;
export const selectWebAppsStatusTypesLoading = (state: RootState) =>
  state.webApps.statusTypesLoading;

export const selectWebAppsTemplates = (state: RootState) =>
  state.webApps.templates;
export const selectWebAppsTemplatesLoading = (state: RootState) =>
  state.webApps.templatesLoading;
export const selectWebAppsTemplatesCategories = (state: RootState) =>
  state.webApps.templatesCategories;

export const selectWebAppsPlans = (state: RootState) => state.webApps.plans;
export const selectWebAppsPlansLoading = (state: RootState) =>
  state.webApps.plansLoading;

// export const {} = webAppsSlice.actions;
export default webAppsSlice.reducer;
