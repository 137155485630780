import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { IThunkRejectValue, RootState } from "../../types";
import { isAxiosError } from "axios";
import toast from "react-hot-toast";
import { ToastClasses } from "../../components/modals/alerts";
import {
  createS3AccessKeyApi,
  deleteMultipleS3AccessKeyApi,
  deleteS3AccessKeyApi,
  getS3AccessKeyApi,
  getS3AccessKeysApi,
  updateS3AccessKeyApi,
} from "../../apis/s3/accessKeyAPI";
import {
  S3AccessKeyState,
  S3AccessKey,
  S3CreatedAccessKey,
} from "../../types/s3-access-key";
import { changeS3NodeStatus, S3UpdatingMessage } from "./s3PublicSlice";
import { getExtractErrors } from "../../apis";
import { CustomErrorToast } from "../../components/general/Toast";

const initialState: S3AccessKeyState = {
  accessKeys: [],
  loading: false,
  actionLoading: false,
  showEditor: false,
  showCreatedData: false,
  createdData: null,
  selectedAccessKeyName: null,
  selectedAccessKey: null,
  selectLoading: false,
};
export const polictText =
  '{\n    "Version": "2012-10-17",\n    "Statement": [\n        {\n            "Effect": "Allow",\n            "Action": [\n                "admin:*"\n            ]\n        },\n        {\n            "Effect": "Allow",\n            "Action": [\n                "s3:*"\n            ],\n            "Resource": [\n                "arn:aws:s3:::*"\n            ]\n        }\n    ]\n}';

export const getS3AccessKeysAsync = createAsyncThunk<
  { accessKeys: S3AccessKey[] },
  undefined,
  IThunkRejectValue
>(
  "s3-access-keys",
  async (_, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
      const response = await getS3AccessKeysApi();

      const { Result, NodeStatus } = response.data;
      const accessKeys = Result || [];

      dispatch(changeS3NodeStatus({ status: NodeStatus }));

      return fulfillWithValue({ accessKeys });
    } catch (e) {
      if (isAxiosError(e)) {
        dispatch(
          changeS3NodeStatus({
            status: e.response?.data.NodeStatus || 4,
            message: S3UpdatingMessage,
          })
        );
        return fulfillWithValue({ accessKeys: [] });
      } else {
        return rejectWithValue({ message: getExtractErrors(e) });
      }
    }
  }
);

export const getS3AccessKeyAsync = createAsyncThunk<
  { access: S3AccessKey },
  { accessKey: string },
  IThunkRejectValue
>(
  "s3-access-key",
  async ({ accessKey }, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
      const response = await getS3AccessKeyApi(accessKey);

      const { Result, NodeStatus } = response.data;
      const access = Result;

      dispatch(changeS3NodeStatus({ status: NodeStatus }));

      return fulfillWithValue({ access });
    } catch (e) {
      if (isAxiosError(e)) {
        dispatch(
          changeS3NodeStatus({
            status: e.response?.data.NodeStatus || 4,
            message: S3UpdatingMessage,
          })
        );
        return fulfillWithValue({ access: null });
      } else {
        return rejectWithValue({ message: getExtractErrors(e) });
      }
    }
  }
);

export const deleteS3AccessKeyAsync = createAsyncThunk<
  any,
  { accessKey: string },
  IThunkRejectValue
>("s3-access-keys/delete", async ({ accessKey }, { rejectWithValue }) => {
  try {
    const response = await deleteS3AccessKeyApi(accessKey);
    return response.data;
  } catch (e) {
    return rejectWithValue({ message: getExtractErrors(e) });
  }
});

export const deleteMultipleS3AccessKeyAsync = createAsyncThunk<
  any,
  { accessKeys: S3AccessKey[] },
  IThunkRejectValue
>(
  "s3-access-keys/delete-multiple",
  async ({ accessKeys }, { rejectWithValue }) => {
    try {
      const formData = accessKeys.map((ak) => ak.accessKey);
      const response = await deleteMultipleS3AccessKeyApi(formData);
      return response.data;
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);

export const createS3AccessKeyAsync = createAsyncThunk<
  any,
  any,
  IThunkRejectValue
>("s3-access-keys/create", async (data, { rejectWithValue }) => {
  try {
    const response = await createS3AccessKeyApi(data);
    return response.data;
  } catch (e) {
    return rejectWithValue({ message: getExtractErrors(e) });
  }
});

export const updateS3AccessKeyAsync = createAsyncThunk<
  any,
  { accessKey: string; data: any },
  IThunkRejectValue
>("s3-access-keys/update", async ({ accessKey, data }, { rejectWithValue }) => {
  try {
    const response = await updateS3AccessKeyApi(accessKey, data);
    return response.data;
  } catch (e) {
    return rejectWithValue({ message: getExtractErrors(e) });
  }
});

export const accessKeysSlice = createSlice({
  name: "accessKeys",
  initialState,
  reducers: {
    handleShowEditor: (state, action: { payload: { accessKey?: string } }) => {
      state.showEditor = true;
      if (typeof action.payload.accessKey !== "undefined") {
        state.selectedAccessKeyName = action.payload.accessKey;
      }
    },
    handleHideEditor: (state) => {
      state.showEditor = false;
      state.selectedAccessKeyName = null;
      state.selectedAccessKey = null;
    },
    handleShowCreatedAccessKey: (
      state,
      action: { payload: S3CreatedAccessKey }
    ) => {
      state.createdData = action.payload;
      state.showCreatedData = true;
    },
    handleClearCreatedAccessKey: (state) => {
      state.createdData = null;
      state.showCreatedData = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getS3AccessKeysAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(getS3AccessKeysAsync.fulfilled, (state, action) => {
        const { accessKeys } = action.payload;

        state.accessKeys = accessKeys;
        state.loading = false;
      })
      .addCase(getS3AccessKeysAsync.rejected, (state, { payload }) => {
        state.loading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(getS3AccessKeyAsync.pending, (state) => {
        state.selectLoading = true;
      })
      .addCase(getS3AccessKeyAsync.fulfilled, (state, action) => {
        state.selectedAccessKey = action.payload.access;
        state.selectLoading = false;
      })
      .addCase(getS3AccessKeyAsync.rejected, (state, { payload }) => {
        state.selectLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(createS3AccessKeyAsync.pending, (state) => {
        state.actionLoading = true;
      })
      .addCase(createS3AccessKeyAsync.fulfilled, (state) => {
        state.actionLoading = false;
      })
      .addCase(createS3AccessKeyAsync.rejected, (state, { payload }) => {
        state.actionLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(updateS3AccessKeyAsync.pending, (state) => {
        state.actionLoading = true;
      })
      .addCase(updateS3AccessKeyAsync.fulfilled, (state) => {
        state.actionLoading = false;
      })
      .addCase(updateS3AccessKeyAsync.rejected, (state, { payload }) => {
        state.actionLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(deleteS3AccessKeyAsync.pending, (state) => {
        state.actionLoading = true;
      })
      .addCase(deleteS3AccessKeyAsync.fulfilled, (state) => {
        state.actionLoading = false;
      })
      .addCase(deleteS3AccessKeyAsync.rejected, (state, { payload }) => {
        state.actionLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(deleteMultipleS3AccessKeyAsync.pending, (state) => {
        state.actionLoading = true;
      })
      .addCase(deleteMultipleS3AccessKeyAsync.fulfilled, (state) => {
        state.actionLoading = false;
      })
      .addCase(
        deleteMultipleS3AccessKeyAsync.rejected,
        (state, { payload }) => {
          state.actionLoading = false;
          if (payload?.message)
            toast.error(() => CustomErrorToast(payload?.message));
        }
      );
  },
});

export const selectS3AccessKeys = (state: RootState) =>
  state.s3AccessKeys.accessKeys;
export const selectLoading = (state: RootState) => state.s3AccessKeys.loading;
export const selectActionLoading = (state: RootState) =>
  state.s3AccessKeys.actionLoading;

export const selectShowEditor = (state: RootState) =>
  state.s3AccessKeys.showEditor;

export const selectSelectedS3AccessKeyName = (state: RootState) =>
  state.s3AccessKeys.selectedAccessKeyName;
export const selectSelectedS3AccessKey = (state: RootState) =>
  state.s3AccessKeys.selectedAccessKey;
export const selectS3AccessKeySelectionLoading = (state: RootState) =>
  state.s3AccessKeys.selectLoading;

export const selectShowCreatedData = (state: RootState) =>
  state.s3AccessKeys.showCreatedData;
export const selectCreatedData = (state: RootState) =>
  state.s3AccessKeys.createdData;

export const {
  handleShowEditor,
  handleHideEditor,
  handleShowCreatedAccessKey,
  handleClearCreatedAccessKey,
} = accessKeysSlice.actions;
export default accessKeysSlice.reducer;
