import { jwtEnvAxios } from ".";
import { Octokit } from "octokit";

const GET_REPOSITORIES_ACCOUNTS_API_URL = `/repositories`;
const SAVE_INSTALLATION_API_URL = (service: string) => `/${service}/install`;

export function getRepositoriesAccountsApi() {
  return jwtEnvAxios().get(GET_REPOSITORIES_ACCOUNTS_API_URL);
}

export function saveInstallationApi(service: string, installationId: string) {
  return jwtEnvAxios().post(SAVE_INSTALLATION_API_URL(service), {
    installationId,
  });
}

// export async function getGithubAccount(githubToken: string) {
//   const octokit = new Octokit({
//     auth: githubToken,
//   });
//   try {
//     // GET /users/{username}
//     return await octokit.request("GET /users/{username}", {
//       username: "AnsarDjuno",
//       headers: {
//         "X-GitHub-Api-Version": "2022-11-28",
//       },
//     });
//   } catch (e) {
//     console.log(e);
//   }
// }

export async function getGithubReposApi(githubToken: string) {
  const octokit = new Octokit({
    auth: githubToken,
  });

  return octokit.request("GET /installation/repositories", {
    headers: {
      "X-GitHub-Api-Version": "2022-11-28",
    },
  });
}

export async function getGithubRepoBranchesApi(
  githubToken: string,
  owner: string,
  repo: string
) {
  const octokit = new Octokit({
    auth: githubToken,
  });

  return octokit.request("GET /repos/{owner}/{repo}/branches", {
    owner,
    repo,
    headers: {
      "X-GitHub-Api-Version": "2022-11-28",
    },
  });
}
