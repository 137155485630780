import { useAppDispatch, useAppSelector } from "../../../../hooks";
import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { InstancesUrl, InstancesVolumesUrl } from "../../../../utils/urls";
import {
  getDBSCatalogsAsync,
  selectDBSCatalogs,
  selectDatabaseServicesActionLoading,
} from "../../../../store/database/servicesSlice";
import QuestionModal from "../../../modals/QuestionModal";
import { NotExist } from "../../../layouts/NotData";
import { Instance } from "../../../../types/instance";

import {
  getInstanceAsync,
  handleToggleInstanceApplicationLoginModal,
  handleToggleInstanceBootModal,
  handleToggleInstanceColdModal,
  handleToggleInstanceHotModal,
  handleToggleInstanceNameModal,
  handleToggleInstanceReactiveModal,
  handleToggleInstanceRebootModal,
  handleToggleInstanceReinstallModal,
  handleToggleInstanceRescueModal,
  handleToggleInstanceStopModal,
  handleToggleInstanceSuspendModal,
  handleToggleInstanceVolumeModal,
  selectInstance,
  selectInstanceLoading,
} from "../../../../store/instances/instanceSlice";
import {
  deleteInstanceAsync,
  getInstancesAsync,
} from "../../../../store/instances/instancesSlice";
import {
  getInstancesVolumesAsync,
  selectInstancesVolumes,
  selectInstancesVolumesLoading,
} from "../../../../store/instances/instancesVolumesSlice";

import InstanceNameModal from "./InstanceNameModal";
import InstancesStopModal from "./InstanceStopModal";
import InstanceExitRescueMode from "./InstanceExitRescueMode";
import InstancesBootModal from "./InstanceBootModal";
import InstanceSuspendModal from "./InstanceSuspendModal";
import InstanceReactiveModal from "./InstanceReactiveModal";
import InstanceColdRebootModal from "./InstanceColdRebootModal";
import InstanceRebootInRescueModeModal from "./InstanceRebootInRescueModeModal";
import InstanceReinstallModal from "./InstanceReinstallModal";
import InstancesHotRebootModal from "./InstancesHotRebootModal";
import InstanceLoginInformationModal from "./InstanceLoginInformationModal";

import { getDBSPriceAmount } from "../../../databases/ServiceCreateComponents";
import InstanceVolumeModal from "./InstanceVolumeModal";

import { ReactComponent as MoreIcon } from "./../../../../assets/icons/more.svg";
import { ReactComponent as RefreshIcon } from "./../../../../assets/icons/arrow-path.svg";
import { ReactComponent as ArchiveIcon } from "./../../../../assets/icons/archive-box.svg";
import { ReactComponent as EditIcon } from "./../../../../assets/icons/pencil-square.svg";
import { ReactComponent as BootIcon } from "./../../../../assets/icons/square-3stack.svg";
import { ReactComponent as StopIcon } from "./../../../../assets/icons/stop.svg";
import { ReactComponent as RebootIcon } from "./../../../../assets/icons/arrow-path-rounded-square.svg";
import { ReactComponent as PuseIcon } from "./../../../../assets/icons/puse-circle.svg";
import { ReactComponent as ExitIcon } from "./../../../../assets/icons/arrow-left-start-on-rectangle.svg";
import {
  Button,
  Card,
  Dropdown,
  Flex,
  Loading,
  Skeleton,
  Tag,
  Typography,
} from "djuno-design";

const InstanceOverviewTab = () => {
  // const { id } = useParams();

  const [deleteInstance, setDeleteInstance] = useState<Instance | null>(null);

  const instance = useAppSelector(selectInstance);
  const loading = useAppSelector(selectInstanceLoading);
  const actionLoading = useAppSelector(selectDatabaseServicesActionLoading);
  const catalogs = useAppSelector(selectDBSCatalogs);
  const volumes = useAppSelector(selectInstancesVolumes);
  const volumesLoading = useAppSelector(selectInstancesVolumesLoading);

  const addon =
    catalogs && catalogs.find((c) => c.planCode === instance?.planCode);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  // useEffect(() => {
  //   if (id) {
  //     dispatch(getInstanceAsync({ instanceId: id }));
  //   }
  // }, [dispatch, id]);

  useEffect(() => {
    if (!catalogs) {
      dispatch(getDBSCatalogsAsync());
    }
  }, [catalogs, dispatch]);

  useEffect(() => {
    dispatch(getInstancesVolumesAsync({}));
  }, [dispatch, instance]);

  const filterVolumes = useMemo(() => {
    if (!volumes || !instance) return [];
    return volumes.filter((volume) => volume.region === instance.region);
  }, [volumes, instance]);

  const attachedVolumes = useMemo(() => {
    if (!instance) return null;
    return filterVolumes.find((volume) =>
      volume.attachedTo.includes(instance.id)
    );
  }, [filterVolumes, instance]);

  const OverviewCardSetting = () => {
    if (!instance) return null;
    return (
      <div className="flex gap-2">
        <Button
          uiSize="medium"
          uiType="light"
          onClick={() =>
            dispatch(
              getInstanceAsync({
                instanceId: instance.id,
              })
            )
          }
          className="group"
          tooltip={{ content: "Refresh" }}
        >
          <RefreshIcon className="w-4 h-4 group-hover:rotate-90 group-hover:scale-110 transition-all duration-500" />
        </Button>

        <Dropdown
          anchor="bottom end"
          itemClassName="!whitespace-nowrap "
          itemsClassName="!w-56 !max-h-48 !overflow-y-auto"
          menu={[
            {
              key: "1",
              label: (
                <div className="flex items-center gap-1">
                  <EditIcon className="w-4" />
                  Rename instance
                </div>
              ),
              onClick: () => dispatch(handleToggleInstanceNameModal(true)),
            },

            {
              key: "boot",
              label: (
                <div className="flex items-center gap-1">
                  <BootIcon className="w-4" />
                  Boot
                </div>
              ),
              onClick: () => dispatch(handleToggleInstanceBootModal(true)),
              disabled:
                instance.status === "ACTIVE" ||
                instance.status === "SHELVED_OFFLOADED" ||
                instance.status === "UNSHELVING" ||
                instance.status === "SHELVING",
            },

            {
              key: "stop",
              label: (
                <div className="flex items-center gap-1">
                  {" "}
                  <StopIcon className="w-4" />
                  Stop
                </div>
              ),
              onClick: () => dispatch(handleToggleInstanceStopModal(true)),
              disabled:
                instance.status === "SHUTOFF" ||
                instance.status === "SHELVED_OFFLOADED" ||
                instance.status === "UNSHELVING" ||
                instance.status === "SHELVING",
            },

            ...(instance.status === "ACTIVE" || instance.status === "SHUTOFF"
              ? [
                  {
                    key: "reboot rescue",
                    label: (
                      <div className="flex items-center gap-1">
                        <RebootIcon className="w-4" />
                        Reboot in rescue mode
                      </div>
                    ),
                    onClick: () =>
                      dispatch(handleToggleInstanceRebootModal(true)),
                  },
                ]
              : []),
            ...(instance.status === "ACTIVE"
              ? [
                  {
                    key: "hot reboot",
                    label: (
                      <div className="flex items-center gap-1">
                        <RebootIcon className="w-4" />
                        Hot reboot (soft)
                      </div>
                    ),
                    onClick: () => dispatch(handleToggleInstanceHotModal(true)),
                  },

                  {
                    key: "cold reboot",
                    label: (
                      <div className="flex items-center gap-1">
                        <RebootIcon className="w-4" />
                        Cold reboot (hard)
                      </div>
                    ),
                    onClick: () =>
                      dispatch(handleToggleInstanceColdModal(true)),
                  },

                  {
                    key: "reinstall",
                    label: (
                      <div className="flex items-center gap-1">
                        <RefreshIcon className="w-4" />
                        Reinstall
                      </div>
                    ),
                    onClick: () =>
                      dispatch(handleToggleInstanceReinstallModal(true)),
                  },
                ]
              : []),
            ...(instance.status === "RESCUE"
              ? [
                  {
                    key: "exit rescue",
                    label: (
                      <div className="flex items-center gap-1">
                        <ExitIcon className="w-4" />
                        Exit rescue mode
                      </div>
                    ),
                    onClick: () =>
                      dispatch(handleToggleInstanceRescueModal(true)),
                  },
                ]
              : []),
            {
              key: "Suspend",
              label: (
                <div className="flex items-center gap-1">
                  {" "}
                  <PuseIcon className="w-4" />
                  Suspend
                </div>
              ),
              onClick: () => dispatch(handleToggleInstanceSuspendModal(true)),
              disabled:
                instance.status === "SHELVED_OFFLOADED" ||
                instance.status === "UNSHELVING" ||
                instance.status === "SHELVING",
            },

            {
              key: "Reactive",
              label: (
                <div className="flex items-center gap-1">
                  {" "}
                  <RefreshIcon className="w-4" />
                  Reactive
                </div>
              ),
              onClick: () => dispatch(handleToggleInstanceReactiveModal(true)),
              disabled:
                instance.status === "ACTIVE" ||
                instance.status === "UNSHELVING" ||
                instance.status === "BUILD" ||
                instance.status === "SHUTOFF" ||
                instance.status === "RESCUING" ||
                instance.status === "RESCUE" ||
                instance.status === "UNRESCUING" ||
                instance.status === "REBOOT" ||
                instance.status === "HARD_REBOOT" ||
                instance.status === "SHELVING" ||
                instance.status === "REBUILD",
            },
            { type: "divider" as const },
            {
              key: "end",
              label: (
                <div className="flex items-center gap-1">
                  {" "}
                  <ArchiveIcon className="w-4" />
                  Delete
                </div>
              ),
              danger: true,
              onClick: (_, close) => {
                close();
                setDeleteInstance(instance);
              },
              disabled: loading,
            },
          ]}
        >
          <Button>Actions</Button>
        </Dropdown>
      </div>
    );
  };

  return (
    <>
      {instance && !loading && (
        <>
          <div className="mt-5">
            <Card title="Information" setting={<OverviewCardSetting />}>
              <div className="mt-2 grid gap-7 gap-x-10 md:grid-cols-2">
                <Flex items="center" className="gap-1">
                  <Typography.Text strong className="!text-sm">
                    Status:
                  </Typography.Text>
                  <Tag
                    className="text-xs inline-block"
                    color={instance.status === "ACTIVE" ? "success" : "warning"}
                  >
                    {instance.status}
                  </Tag>
                </Flex>

                <Flex items="center" className="gap-1">
                  <Typography.Text strong className="!text-sm">
                    region:
                  </Typography.Text>
                  <Typography.Text className="!text-sm">
                    {instance?.region}
                  </Typography.Text>
                </Flex>

                <Flex items="center" className="gap-1">
                  <Typography.Text strong className="!text-sm">
                    Model:
                  </Typography.Text>
                  <Typography.Text className="!text-sm">
                    {instance?.flavor.name}
                  </Typography.Text>
                </Flex>

                <Flex items="center" className="gap-1">
                  <Typography.Text strong className="!text-sm">
                    RAM:
                  </Typography.Text>
                  <Typography.Text className="!text-sm">
                    {instance?.flavor.ram / 1000} GB
                  </Typography.Text>
                </Flex>

                <Flex items="center" className="gap-1">
                  <Typography.Text strong className="!text-sm">
                    Processor:
                  </Typography.Text>
                  <Typography.Text className="!text-sm">
                    {instance?.flavor.vcpus} vCores
                  </Typography.Text>
                </Flex>

                <Flex items="center" className="gap-1">
                  <Typography.Text strong className="!text-sm">
                    Storage:
                  </Typography.Text>
                  <Typography.Text className="!text-sm !inline-block">
                    {instance.flavor.disk} Gib
                  </Typography.Text>
                </Flex>

                <Flex items="center" className="gap-1">
                  <Typography.Text strong className="!text-sm">
                    Public bandwidth:
                  </Typography.Text>
                  <Typography.Text className="!text-sm !inline-block">
                    {instance.flavor.inboundBandwidth} Mbps
                  </Typography.Text>
                </Flex>

                <Flex items="center" className="gap-1">
                  <Typography.Text strong className="!text-sm">
                    Price:
                  </Typography.Text>
                  {addon && (
                    <Typography.Text className="!text-sm !inline-block">
                      ${addon && getDBSPriceAmount(addon.pricings[0].price)}{" "}
                      ex.VAT/{addon.pricings[0].intervalUnit}
                    </Typography.Text>
                  )}
                </Flex>

                <Flex items="center" justify="between" className="gap-1">
                  <Flex items="center" className="gap-1">
                    <Typography.Text strong className="!text-sm">
                      Volumes:
                    </Typography.Text>
                    {attachedVolumes?.status === "in-use" ? (
                      <Typography.Text className="!text-sm">
                        {attachedVolumes?.name} {attachedVolumes?.size} GiB
                      </Typography.Text>
                    ) : (
                      <>
                        {volumesLoading ? (
                          <Skeleton
                            shape="rectangle"
                            style={{ width: 100, height: 24 }}
                          />
                        ) : (
                          <Tag>Empty</Tag>
                        )}
                      </>
                    )}
                  </Flex>

                  <div className="flex border border-gray-300 rounded-md">
                    <Dropdown
                      itemsClassName="!p-0 !w-48"
                      anchor="bottom end"
                      menu={[
                        {
                          key: "1",
                          label: "Manage volumes",
                          onClick: () => navigate(InstancesVolumesUrl),
                          disabled: loading,
                        },
                        {
                          type: "divider",
                        },
                        {
                          key: "end",
                          label: "Attach a volume",
                          onClick: () =>
                            dispatch(handleToggleInstanceVolumeModal(true)),
                          disabled: loading,
                        },
                      ]}
                    >
                      <div className="p-1 rounded-md dark:border-dark-2 hover:dark:bg-dark-2 hover:bg-slate-200">
                        <MoreIcon className="w-4 h-4 dark:text-slate-100 text-slate-800" />
                      </div>
                    </Dropdown>
                  </div>
                </Flex>

                <Flex items="center" justify="between" className="gap-1">
                  <Flex items="center" className="gap-1">
                    <Typography.Text strong className="!text-sm">
                      Image:
                    </Typography.Text>

                    <Typography.Text className="!text-sm">
                      {instance && instance.image
                        ? instance.image.name
                        : "Undefined Image"}
                    </Typography.Text>
                  </Flex>

                  {instance.image && instance.image.tags.length > 0 && (
                    <div className="flex border border-gray-300 rounded-md">
                      <Dropdown
                        itemsClassName="!w-48"
                        anchor="bottom end"
                        menu={[
                          {
                            key: "1",
                            label: "Login information",
                            onClick: (_, close) => {
                              close();
                              dispatch(
                                handleToggleInstanceApplicationLoginModal(true)
                              );
                            },
                            disabled: loading,
                          },
                        ]}
                      >
                        <div className="p-1 rounded-md dark:border-dark-2 hover:dark:bg-dark-2 hover:bg-slate-200">
                          <MoreIcon className="w-4 h-4 dark:text-slate-100 text-slate-800" />
                        </div>
                      </Dropdown>
                    </div>
                  )}
                </Flex>
              </div>
            </Card>
          </div>

          <InstanceNameModal />
          <InstanceColdRebootModal />
          <InstancesHotRebootModal />
          <InstanceRebootInRescueModeModal />
          <InstanceExitRescueMode />
          <InstanceReinstallModal />
          <InstancesStopModal />
          <InstancesBootModal />
          <InstanceSuspendModal />
          <InstanceReactiveModal />
          <InstanceVolumeModal />
          <InstanceLoginInformationModal />
          <QuestionModal
            title="Delete an instance"
            description={`Are you sure that you want to delete the ${instance.name} instance?`}
            isOpen={deleteInstance !== null}
            onClose={() => setDeleteInstance(null)}
            onConfirm={() => {
              deleteInstance &&
                dispatch(
                  deleteInstanceAsync({
                    id: deleteInstance.id,
                  })
                ).then((action) => {
                  if (action.type === "instances/delete/fulfilled") {
                    dispatch(getInstancesAsync({ withoutLoading: false }));
                    setDeleteInstance(null);
                    navigate(InstancesUrl);
                  }
                });
            }}
            loading={actionLoading}
            confirmButtonType="danger"
          />
        </>
      )}

      {/* empty service */}
      {instance === null && !loading && (
        <Flex items="center" justify="center" className="h-full px-4">
          <NotExist
            url={() => navigate(InstancesUrl)}
            text="The cluster does not exist"
          />
        </Flex>
      )}

      {loading && (
        <Flex items="center" justify="center" className="!min-h-[300px]">
          <Loading borderSize={2} />
        </Flex>
      )}
    </>
  );
};

export default InstanceOverviewTab;
