import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IThunkRejectValue, RootState } from "../../types";
import { NewS3Metrics, S3PublicStateType } from "../../types/s3";
import { getExtractErrorCode, getExtractErrors } from "../../apis";
import {
  activateS3Api,
  getBucketsMaxShareExpAsyncApi,
  getNewS3MetricsApi,
} from "../../apis/s3API";
import toast from "react-hot-toast";
import { CustomErrorToast } from "../../components/general/Toast";
import { isAxiosError } from "axios";

export const S3UpdatingMessage = "We are updating your service. Please wait...";

const initialState: S3PublicStateType = {
  nodeStatus: null, //is IPFS enabled or not?
  activeStatusMessage: null,

  maxShareExp: null,
  maxShareExpLoading: false,

  newMetrics: null,
  newMetricsLoading: false,

  actionLoading: false,
};

export const getBucketsMaxShareExpAsync = createAsyncThunk<
  { exp: number },
  undefined,
  IThunkRejectValue
>("buckets/max-share-exp", async (_, { rejectWithValue, fulfillWithValue }) => {
  try {
    const response = await getBucketsMaxShareExpAsyncApi();
    const exp = response.data.Result;
    return fulfillWithValue({ exp });
  } catch (e) {
    return rejectWithValue({ message: getExtractErrors(e) });
  }
});

export const getNewS3MetricsAsync = createAsyncThunk<
  { newMetrics: NewS3Metrics },
  { withoutLoading?: boolean; time: number },
  IThunkRejectValue
>(
  "new/s3/metrics",
  async (
    { withoutLoading, time },
    { rejectWithValue, fulfillWithValue, dispatch, requestId }
  ) => {
    try {
      dispatch(
        getNewS3MetricsAsync.pending(requestId, { withoutLoading, time })
      );

      const metrics_response = await getNewS3MetricsApi(time);
      const s3Metrics = metrics_response.data.Result;

      return fulfillWithValue({ newMetrics: s3Metrics });
    } catch (e) {
      if (isAxiosError(e)) {
        dispatch(
          changeS3NodeStatus({
            status: e.response?.data.NodeStatus || 4,
            message: S3UpdatingMessage,
          })
        );
        return fulfillWithValue({ newMetrics: null, usage: null });
      } else {
        return rejectWithValue({
          message: getExtractErrors(e),
          code: getExtractErrorCode(e),
        });
      }
    }
  }
);

export const activateS3Async = createAsyncThunk<
  number,
  { isActive: boolean },
  IThunkRejectValue
>("s3/activate", async ({ isActive }, { rejectWithValue }) => {
  try {
    const response = await activateS3Api(isActive);
    return response.data?.NodeStatus || 0;
  } catch (e) {
    return rejectWithValue({ message: getExtractErrors(e) });
  }
});

const s3PublicSlice = createSlice({
  name: "s3-public",
  initialState: initialState,
  reducers: {
    changeS3NodeStatus(
      state,
      action: PayloadAction<{ status: number; message?: string }>
    ) {
      state.nodeStatus =
        typeof action.payload.status === "number" ? action.payload.status : -1;
      state.activeStatusMessage = action.payload.message || null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBucketsMaxShareExpAsync.pending, (state) => {
        state.maxShareExpLoading = true;
      })
      .addCase(getBucketsMaxShareExpAsync.fulfilled, (state, action) => {
        const { exp } = action.payload;

        state.maxShareExp = exp;
        state.maxShareExpLoading = false;
      })
      .addCase(getBucketsMaxShareExpAsync.rejected, (state, { payload }) => {
        state.maxShareExpLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });
    builder
      .addCase(getNewS3MetricsAsync.pending, (state, action) => {
        if (!action.meta.arg.withoutLoading) state.newMetricsLoading = true;
      })
      .addCase(getNewS3MetricsAsync.fulfilled, (state, action) => {
        state.newMetrics = action.payload.newMetrics;
        state.newMetricsLoading = false;
      })
      .addCase(getNewS3MetricsAsync.rejected, (state, { payload }) => {
        state.newMetricsLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(activateS3Async.pending, (state) => {
        state.actionLoading = true;
      })
      .addCase(activateS3Async.fulfilled, (state, { payload }) => {
        state.actionLoading = false;
        state.nodeStatus = payload;
      })
      .addCase(activateS3Async.rejected, (state, { payload }) => {
        state.actionLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });
  },
});

export const selectS3NodeStatus = (state: RootState) =>
  state.s3Public.nodeStatus;
export const selectS3StatusMessage = (state: RootState) =>
  state.s3Public.activeStatusMessage;

export const selectBucketsMaxShareExp = (state: RootState) =>
  state.s3Public.maxShareExp;
export const selectBucketsMaxShareExpLoading = (state: RootState) =>
  state.s3Public.maxShareExpLoading;

export const selectNewS3Metrics = (state: RootState) =>
  state.s3Public.newMetrics;
export const selectNewS3MetricsLoading = (state: RootState) =>
  state.s3Public.newMetricsLoading;

export const selectS3ActionLoading = (state: RootState) =>
  state.s3Public.actionLoading;

export const { changeS3NodeStatus } = s3PublicSlice.actions;
export default s3PublicSlice.reducer;
