import { useCallback, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "./../../../../hooks";

import {
  Alert,
  Button,
  Flex,
  Modal,
  Tabs,
  Tag,
  Tooltip,
  Typography,
} from "djuno-design";
import {
  getK8ServiceAsync,
  handleToggleK8EnableDisableModal,
  selectK8EnableDisableModal,
  selectK8Service,
  selectK8ServiceActionLoading,
  selectK8ServiceLoading,
  updateK8CustomizationAsync,
} from "./../../../../store/kubernetes/k8ServiceSlice";
import { ReactComponent as InfoIcon } from "./../../../../assets/icons/question-mark-circle.svg";
import { getK8ServicesAsync } from "../../../../store/kubernetes/k8ServicesSlice";

export const pluginImagesOptions = [
  {
    label: "Enable",
    value: "0",
  },
  {
    label: "Disable",
    value: "1",
  },
];

const K8EnableDisablePluginsModal = () => {
  const [pluginImageOption, setPluginImageOption] = useState<string>("0");

  const isOpen = useAppSelector(selectK8EnableDisableModal);

  const k8Service = useAppSelector(selectK8Service);
  const k8ServiceLoading = useAppSelector(selectK8ServiceLoading);
  const k8ServiceActionLoading = useAppSelector(selectK8ServiceActionLoading);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (k8Service?.customization?.apiServer?.admissionPlugins) {
      const { disabled } = k8Service.customization.apiServer.admissionPlugins;

      setPluginImageOption(disabled.includes("AlwaysPullImages") ? "1" : "0");
    }
  }, [k8Service]);

  const onSubmit = (data: any) => {
    if (k8Service && !k8ServiceLoading) {
      const existingEnabledPlugins =
        k8Service.customization.apiServer.admissionPlugins.enabled.filter(
          (plugin: string) =>
            plugin !== "NodeRestriction" && plugin !== "AlwaysPullImages"
        );

      dispatch(
        updateK8CustomizationAsync({
          kubeId: k8Service.id,
          data: {
            apiServer: {
              admissionPlugins: {
                enabled: [
                  "NodeRestriction",
                  ...(pluginImageOption === "0" ? ["AlwaysPullImages"] : []),
                  ...existingEnabledPlugins,
                ],
                disabled: [
                  pluginImageOption === "1" ? "AlwaysPullImages" : null,
                ].filter(Boolean),
              },
            },
          },
        })
      ).then((action) => {
        if (action.type === "k8-service/update/customization/fulfilled") {
          handleOnClose();
          dispatch(getK8ServicesAsync({ withoutLoading: false }));
          dispatch(getK8ServiceAsync({ id: k8Service.id }));
        }
      });
    }
  };
  const handleOnClose = useCallback(() => {
    dispatch(handleToggleK8EnableDisableModal(false));
  }, [dispatch]);

  useEffect(() => {
    return () => {
      handleOnClose();
    };
  }, [handleOnClose]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleOnClose}
      contentClassName="max-w-lg"
      title="Enable/disable plugins"
    >
      <div className="w-full">
        <Flex items="center" className="mt-6 gap-5" direction="col">
          <Alert
            description="For security concerns, the Node Restriction plugin must remain enabled."
            uiType="info"
            showIcon
          />
          <Alert
            description="Warning! Any changes to Admission Plugins require redeploying your cluster’s API Server."
            uiType="info"
            showIcon
          />
        </Flex>

        <div className="flex items-center justify-between mt-5 mb-2">
          <div className="flex items-center gap-2">
            <Typography.Text strong className="!text-sm whitespace-nowrap">
              Plugin Always Pull Images
            </Typography.Text>
            <Tooltip
              content="Each new pod has to download the required images every time."
              className="!text-sm"
            >
              <InfoIcon className="dd-w-4 dd-text-slate-500 dark:dd-text-slate-300 dark:hover:dd-text-slate-100" />
            </Tooltip>
          </div>

          <Tabs
            listClassName="!w-full"
            tabType="creamy"
            options={pluginImagesOptions.map((option) => ({
              label: option.label,
            }))}
            selectedIndex={pluginImagesOptions.findIndex(
              (o) => o.value === pluginImageOption
            )}
            onChange={({ index }) => {
              if (index !== undefined) {
                const selectedOption = pluginImagesOptions[index];
                if (selectedOption) {
                  setPluginImageOption(selectedOption.value);
                }
              }
            }}
          />
        </div>

        <div className="flex items-center justify-between mt-5">
          <div className="flex items-center gap-2">
            <Typography.Text strong className="!text-sm whitespace-nowrap">
              Plugin Node Restriction
            </Typography.Text>
            <Tooltip
              content="The NodeRestriction admission controller plugin restricts what Node and Pod objects a Kubelet can modify. When this admission controller is active, Kubelets are limited to modifying their own API Node object and only API Pod objects that are linked to their node."
              className="!text-sm"
            >
              <InfoIcon className="dd-w-4 dd-text-slate-500 dark:dd-text-slate-300 dark:hover:dd-text-slate-100" />
            </Tooltip>
          </div>

          <Tag color="success" className="!h-6">
            Enabled
          </Tag>
        </div>

        <Flex items="center" justify="end" className="gap-2 mt-5">
          <Button onClick={handleOnClose}>Cancel</Button>
          <Button
            uiType="primary"
            type="submit"
            onClick={onSubmit}
            loading={k8ServiceActionLoading}
          >
            Save
          </Button>
        </Flex>
      </div>
    </Modal>
  );
};

export default K8EnableDisablePluginsModal;
