import { jwtEnvAxios } from ".";
import { ISubscriptionApiData } from "../types/billing";

const DOWNLOAD_INVOICE_URL = (invoiceNumber: number) =>
  `/billings/invoices/${invoiceNumber}/download`;

export function getCloudCreditApi() {
  return jwtEnvAxios().get(`/billings/cloud-credit`);
}

export function makeBillingIntentApi() {
  return jwtEnvAxios().post(`/billings/intent`);
}

export function activeWithPromoApi(PromoCode: string) {
  return jwtEnvAxios().post(`/active-with-promo`, { PromoCode });
}

export function makeCreditOrderApi(amount: number) {
  return jwtEnvAxios().post(`/billings/cloud-credit`, {
    Amount: amount,
  });
}

export function topupWithPromoApi(code: string) {
  return jwtEnvAxios().post(`/billings/cloud-credit/promo`, {
    PromoCode: code,
  });
}

export function waitingStatusApi(token: string) {
  return jwtEnvAxios().put(`/waiting-status?token=${token}`);
}

// export function createPaymentMethodApi() {
//   return jwtEnvAxios().post(`/billings/payment-method`);
// }

//-----------------------------payment-methods-------------------------
export function paymentMethodsApi() {
  return jwtEnvAxios().get(`/billings/payment-method`);
}

export function setAsDefaultPaymentMethodApi(paymentMethodId: number) {
  return jwtEnvAxios().put(
    `/billings/payment-method/set-as-default/${paymentMethodId}`
  );
}

export function unfinalizedCountPaymentMethodsApi() {
  return jwtEnvAxios().get(`/billings/payment-method/unfinalized-count`);
}

export function deletePaymentMethodApi(paymentId: number) {
  return jwtEnvAxios().delete(`/billings/payment-method/${paymentId}`);
}

//----------------------------Subscriptions----------------------
export function getSubscriptionsApi(includePlans: boolean) {
  return jwtEnvAxios().get(
    `/billings/subscriptions?includePlans=${includePlans}`
  );
}

export function updateSubscriptionsApi(data: ISubscriptionApiData) {
  return jwtEnvAxios().put(`/billings/subscriptions`, data);
}

//----------------------------Plans------------------------------
export function getPlansApi({
  planGroupId,
  serviceType,
}: {
  planGroupId?: number;
  serviceType?: number | string;
}) {
  return jwtEnvAxios(undefined, true).get(
    `/plans?${planGroupId ? `planGroupId=${planGroupId}` : ""}&${
      serviceType ? `servicetypeid=${serviceType}` : ""
    }`
  );
}

export function getPlanGroupsApi() {
  return jwtEnvAxios(undefined, true).get(`/plans/plan-group`);
}

//----------------------------Billing-information----------------
export function getBillingInformationApi() {
  return jwtEnvAxios().get(`/billings/info`);
}

export function updateBillingInformationApi(data: any) {
  return jwtEnvAxios().put(`/billings/info`, data);
}

//----------------------------invoices---------------------------
//
export function invoicesApi({
  PageNumber,
  PageSize,
}: {
  PageNumber: number;
  PageSize?: number;
}) {
  return jwtEnvAxios().get(
    `/billings/invoices?pageNumber=${PageNumber}&pageSize=${PageSize}`
  );
}

//Pay now apis
export function payNowByCloudCreditApi(invoiceId: number) {
  return jwtEnvAxios().post(`/billings/invoice/${invoiceId}/cloud-credit`);
}

export function payNowByDefaultCardApi(invoiceId: number) {
  return jwtEnvAxios().post(`/billings/invoice/${invoiceId}/card`);
}

export function payNowByIntentApi(
  invoiceId: number,
  saveCard: boolean,
  defaultCard: boolean
) {
  return jwtEnvAxios().post(
    `/billings/invoice/${invoiceId}/intent?saveCard=${saveCard}&defaultCard=${defaultCard}`
  );
}

//download
export function downlodInvoiceApi(invoiceNumber: number) {
  return jwtEnvAxios().get(DOWNLOAD_INVOICE_URL(invoiceNumber), {
    responseType: "arraybuffer",
  });
}
