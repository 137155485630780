import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import toast from "react-hot-toast";

import { IThunkRejectValue, RootState } from "../../types";
import { getExtractErrors } from "../../apis";
import { CustomErrorToast } from "../../components/general/Toast";
import { NotificationsState, Notification } from "../../types/notifications";
import {
  getNotificationsApi,
  markAsReadNotificationApi,
} from "../../apis/notificationsAPI";
import { getLocalStorage, setLocalStorage } from "djuno-design";

export const dommyNotifs: Notification[] = [
  {
    Id: 1,
    Content:
      "your RPC endpoint has been successfully created your RPC endpoint has been successfully created your RPC endpoint has been successfully created your RPC endpoint has been successfully created.",
    NotificationTitle: "RPC creation RPC creation RPC creation",
    CreatedAt: "2024-12-15T10:34:11.7116359Z",
  },
];

const initialState: NotificationsState = {
  notifications: [],
  loading: false,
  audioAllowed: getLocalStorage("notif-sound", false),
};

export const getNotificationsAsync = createAsyncThunk<
  { notifications: Notification[] },
  { withoutLoading?: boolean },
  IThunkRejectValue
>(
  "notifications",
  async (
    { withoutLoading },
    { rejectWithValue, fulfillWithValue, dispatch, requestId }
  ) => {
    try {
      dispatch(
        getNotificationsAsync.pending(requestId, {
          withoutLoading,
        })
      );
      const response = await getNotificationsApi();
      const notifications = response.data.Result;

      return fulfillWithValue({ notifications });
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);

export const markAsReadNotificationAsync = createAsyncThunk<
  boolean,
  { notificationId: number } | undefined,
  IThunkRejectValue
>(
  "notifications/mark-as-read",
  async (data, { rejectWithValue, fulfillWithValue }) => {
    try {
      await markAsReadNotificationApi(data ? data.notificationId : undefined);
      return true;
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);

export const addNotification = (notification: Notification) => {
  return {
    type: "notifications/addNotification",
    payload: notification,
  };
};

export const notificationsSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    addNotification: (state, action: PayloadAction<Notification>) => {
      state.notifications.unshift(action.payload);
    },
    handleToggleNotifAudio: (state) => {
      setLocalStorage("notif-sound", !state.audioAllowed);
      state.audioAllowed = !state.audioAllowed;
    },
    handleReadAllNotif: (state) => {
      const newNotifs = state.notifications.map((n) => ({
        ...n,
        IsRead: true,
      }));
      state.notifications = newNotifs;
    },
    handleReadNotif: (state, action: PayloadAction<number>) => {
      const newNotifs = state.notifications.map((n) => {
        if (n.Id === action.payload) {
          return { ...n, IsRead: true };
        }
        return n;
      });
      state.notifications = newNotifs;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getNotificationsAsync.pending, (state, action) => {
        if (!action.meta.arg.withoutLoading) state.loading = true;
      })
      .addCase(getNotificationsAsync.fulfilled, (state, action) => {
        const { notifications } = action.payload;
        state.notifications = notifications;
        state.loading = false;
      })
      .addCase(getNotificationsAsync.rejected, (state, { payload }) => {
        state.loading = false;
        // state.notifications = dommyNotifs;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });
  },
});

export const selectNotifications = (state: RootState) =>
  state.notifications.notifications;
export const selectNotificationsLoading = (state: RootState) =>
  state.notifications.loading;

export const selectNotificationsAudioAllowed = (state: RootState) =>
  state.notifications.audioAllowed;

export const { handleToggleNotifAudio, handleReadAllNotif, handleReadNotif } =
  notificationsSlice.actions;
export default notificationsSlice.reducer;
