import { useState } from "react";
import { ReactComponent as MainBannerBg } from "./../../assets/images/header-bg.svg";
import { ContactUsUrl, HomeUrl, SignUpUrl } from "../../utils/urls";
import { Link } from "react-router-dom";
import { Button, cn, Flex, Typography, useDjunoDesign } from "djuno-design";
import Container, { ContainerFluid } from "../layouts/Container";
import { useAppSelector } from "../../hooks";
import { selectIsAuthenticate } from "../../store/auth/authSlice";
const IndexBanner = () => {
  const isAuth = useAppSelector(selectIsAuthenticate);
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);
  const onLoadedData = () => {
    setIsVideoLoaded(true);
  };

  return (
    // h-[350px] md:h-[400px]
    <div className="welcome-area overflow-hidden relative h-[400px] md:h-[470px] lg:h-[500px] xl:h-[700px] mt-[80px] lg:mt-0">
      <ContainerFluid className="relative inset-0 !h-[99%] lg:!h-full">
        <div
          className={cn(
            "md:h-[101%] absolute z-0 right-0 before:absolute before:w-full before:h-full before:opacity-50 before:overflow-hidden overflow-hidden",
            { "before:bg-image-cover-gradient": isVideoLoaded }
          )}
        >
          <video
            className={cn("md:h-full w-[unset] lg:w-full opacity-0", {
              "opacity-100": isVideoLoaded,
            })}
            id="video"
            autoPlay
            playsInline
            loop
            muted
            onLoadedData={onLoadedData}
          >
            <source src="https://webcdn.djuno.io/cdn/loopvideo.mov" />
          </video>
        </div>
        <MainBannerBg className="h-[101%] absolute z-10 right-0 hidden md:block text-white dark:text-dark-1 translate-x-1" />
      </ContainerFluid>
      <Container className="absolute inset-0 w-full z-50 pt-[50px] md:pt-[100px] lg:pt-[150px] xl:pt-[210px]">
        <div className="header-button ">
          <div className="grid grid-cols-12">
            <div className="col-span-12 md:col-span-6 lg:col-span-5 xl:col-span-6 ">
              <Flex direction="col" className="gap-5">
                <Typography.Text className="font-semibold !text-xl md:!text-2xl  lg:!text-3xl drop-shadow-lg  lg:!drop-shadow-none !text-slate-50 md:!text-slate-800 dark:!text-slate-50">
                  Low-code Web3.0 development platform
                </Typography.Text>
                <Typography.Text
                  uiType="secondary"
                  className="!text-md md:!text-xl !text-slate-50 md:!text-slate-800 dark:!text-slate-50"
                >
                  Djuno web3.0 development platform offers a complete stack for
                  enterprise blockchain provided as a low cost low code platform
                </Typography.Text>
              </Flex>
              <div className="mt-16 md:mt-5">
                <div className="grid grid-cols-12 gap-4 max-w-[400px]">
                  <Link to={ContactUsUrl} className="col-span-6">
                    <Button uiType="light" className="!w-full !justify-center">
                      <Typography.Text
                        className="!font-semibold"
                        uiType="transparent"
                      >
                        Contact sales
                      </Typography.Text>
                    </Button>
                  </Link>
                  <Link
                    to={isAuth ? HomeUrl : SignUpUrl}
                    className="col-span-6"
                  >
                    <Button
                      uiType="primary"
                      className="!w-full !justify-center"
                    >
                      <Typography.Text
                        className="!font-semibold"
                        uiType="transparent"
                      >
                        Get started
                      </Typography.Text>
                    </Button>
                  </Link>

                  {/* <ProductHuntButton className="col-span-6" /> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export const ProductHuntButton: React.FC<{ className?: string }> = ({
  className,
}) => {
  const { theme } = useDjunoDesign();

  return (
    <div className={className}>
      {theme.mode === "dark" ? (
        <a
          href="https://www.producthunt.com/products/djuno/reviews?utm_source=badge-product_review&utm_medium=badge&utm_souce=badge-djuno"
          target="_blank"
          rel="noreferrer"
        >
          <img
            src="https://api.producthunt.com/widgets/embed-image/v1/product_review.svg?product_id=992227&theme=dark"
            alt="Djuno - All&#0045;in&#0045;one&#0032;Web3&#0032;cloud&#0032;and&#0032;low&#0032;code&#0032;developer&#0032;platform | Product Hunt"
            className="w-full"
          />
        </a>
      ) : (
        <a
          href="https://www.producthunt.com/posts/djuno?embed=true&utm_source=badge-featured&utm_medium=badge&utm_souce=badge-djuno"
          target="_blank"
          rel="noreferrer"
        >
          <img
            src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=886423&theme=neutral&t=1739963766486"
            alt="Djuno - All&#0045;in&#0045;one&#0032;Web3&#0032;cloud&#0032;and&#0032;low&#0032;code&#0032;developer&#0032;platform | Product Hunt"
            className="w-full"
          />
        </a>
      )}
    </div>
  );
};
export default IndexBanner;
