import { useNavigate, useParams } from "react-router-dom";
import { ReactComponent as BellAlertIcon } from "./../../../assets/icons/bell.svg";
import { S3TierAddUrl } from "../../../utils/urls";
import { TierTypes, tierTypesNames } from "../../../types/s3-tiers";
import MinIoForm from "./forms/MinIoForm";
import GoogleCloudForm from "./forms/GoogleCloudForm";
import AmazonS3Form from "./forms/AmazonS3Form";
import AzureForm from "./forms/AzureForm";
import { S3NotActiveTab } from "../../layouts/NotData";
import { useAppSelector } from "../../../hooks";
import { selectS3NodeStatus } from "../../../store/s3/s3PublicSlice";
import { Button, Typography } from "djuno-design";

const S3TierEditorPage = () => {
  const s3Status = useAppSelector(selectS3NodeStatus);
  const navigate = useNavigate();
  const { type } = useParams<{ type: TierTypes }>();

  if (s3Status !== 2) {
    return <S3NotActiveTab />;
  }
  return (
    <div className="pb-20 w-full">
      <div className="flex flex-col mx-auto gap-7 p-6 mt-5 rounded-lg border bg-white dark:bg-dark-3 dark:border-dark-2 max-w-3xl">
        {type === "minio" && <MinIoForm />}
        {type === "gcs" && <GoogleCloudForm />}
        {type === "s3" && <AmazonS3Form />}
        {type === "azure" && <AzureForm />}

        {(typeof type === "undefined" || !tierTypesNames.includes(type)) && (
          <div className="flex justify-start z-10 relative p-8 gap-8">
            <div className="flex items-center justify-center dark:bg-dark-2 w-[80px] h-[80px] border dark:border-dark-2 rounded-xl">
              <BellAlertIcon className="w-1/2 text-slate-600 dark:text-slate-200" />
            </div>
            <div className="flex flex-col gap-2 flex-1">
              <Typography.Title level={4}>Invalid tier type</Typography.Title>
              <Typography.Text
                uiType="secondary"
                className="!font-normal !text-sm"
              >
                Invalid type chosen. Please select a valid event type from the
                available options.
              </Typography.Text>
              <Button
                onClick={() => navigate(S3TierAddUrl)}
                uiType="primary"
                className="mt-5"
              >
                See valid options
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default S3TierEditorPage;
