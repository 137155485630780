import { useAppDispatch, useAppSelector } from "../../../hooks";
import { useCallback, useEffect, useMemo, useRef } from "react";
import {
  handleSetWebAppCredentialId,
  selectWebAppImageCredentialId,
  selectWebAppSelectedDeployType,
  getWebAppImagesAsync,
  getWebAppTagsAsync,
  handleSetWebAppTagValue,
  selectWebAppImageValue,
  selectWebAppSearchImages,
  selectWebAppTagValue,
  selectWebAppSearchTags,
  selectWebAppImagesLoading,
  selectWebAppTagsLoading,
  handleSetWebAppImageValue,
  handleSetWebAppImagesResult,
  handleSetWebAppTagsResult,
  selectWebAppImageQuery,
  selectWebAppTagQuery,
  handleSetWebAppTagQuery,
  handleSetWebAppImageQuery,
  handleSetWebAppGitRepo,
  selectWebAppGitRepo,
} from "../../../store/web-app/webAppCreateSlice";
import { SettingsRegisteriesUrl } from "../../../utils/urls";
import {
  getCredentialsAsync,
  selectCredentials,
  selectCredentialsLoading,
} from "../../../store/settings/registeries/registeriesSlice";
import { getRegisteryData } from "../../settings/registeries/RegisteriesTab";
import {
  Button,
  Card,
  cn,
  Combobox,
  EmptyState,
  Flex,
  Loading,
  Select,
  SimpleTable,
  Tooltip,
  Typography,
} from "djuno-design";
import CustomLink from "../../general/CustomLink";
import {
  getGithubRepositoriesAsync,
  getRepositoriesAccountsAsync,
  selectGithubReposData,
  selectGithubReposLoading,
  selectRepoAccounts,
  selectRepoAccountsLoading,
} from "../../../store/settings/registeries/repositoriesSlice";
import { ReactComponent as GithubIcon } from "./../../../assets/icons/socials/github.svg";
import { ReactComponent as LinkIcon } from "./../../../assets/icons/arrow-top-right-on-square.svg";
import { ReactComponent as CheckIcon } from "./../../../assets/icons/check.svg";
import { Link } from "react-router-dom";
import { formatTimestamp } from "../../../utils/date";

const DeployingSourceStep = () => {
  const selectedDeployType = useAppSelector(selectWebAppSelectedDeployType);
  return (
    <div className="w-full min-h-[calc(100%-6rem)]">
      {selectedDeployType === "image" && <DeployingImageCard />}
      {selectedDeployType === "git" && <DeployingGitCard />}
    </div>
  );
};

// deploy from image
const DeployingImageCard = () => {
  return (
    <Flex direction="col">
      <Flex className="my-5">
        <Card title="Deploy an image">
          <DeployingImageFrom />
        </Card>
        <DeployingImageMessage className="!hidden lg:!block ml-10 max-w-full lg:max-w-[150px]  xl:max-w-[300px]" />
      </Flex>
      <DeployingImageMessage className="lg:!hidden" />
    </Flex>
  );
};

export const DeployingImageFrom = () => {
  const credentials = useAppSelector(selectCredentials);
  const credentialsLoading = useAppSelector(selectCredentialsLoading);

  // const [imageQuery, setImageQuery] = useState<string>(webApp?.ImageName || "");
  // const [tagQuery, setTagQuery] = useState<string>(webApp?.ImageTag || "");

  const selectedCredentialId = useAppSelector(selectWebAppImageCredentialId);

  const imageValue = useAppSelector(selectWebAppImageValue);
  const imageQuery = useAppSelector(selectWebAppImageQuery);
  const imagesLoading = useAppSelector(selectWebAppImagesLoading);
  const imagesSearchResult = useAppSelector(selectWebAppSearchImages);

  const tagValue = useAppSelector(selectWebAppTagValue);
  const tagQuery = useAppSelector(selectWebAppTagQuery);
  const tagsLoading = useAppSelector(selectWebAppTagsLoading);
  const tagSearchResult = useAppSelector(selectWebAppSearchTags);

  const timeout = useRef<NodeJS.Timeout | null>(null);

  const dispatch = useAppDispatch();

  const imagesOptions = useMemo(
    () =>
      imagesSearchResult.map((i) => ({
        label: i,
        value: i,
      })),
    [imagesSearchResult]
  );

  const tagOptions = useMemo(
    () =>
      tagSearchResult.map((s) => ({
        label: s,
        value: s,
      })),
    [tagSearchResult]
  );

  useEffect(() => {
    if (credentials.length === 0) dispatch(getCredentialsAsync());
  }, [credentials.length, dispatch]);

  const handleChangeCredential = useCallback(
    (option: string | undefined) => {
      //images
      dispatch(handleSetWebAppImageQuery(""));
      dispatch(handleSetWebAppImageValue(""));
      dispatch(handleSetWebAppImagesResult([]));

      //tags
      dispatch(handleSetWebAppTagQuery(""));
      dispatch(handleSetWebAppTagValue(""));
      dispatch(handleSetWebAppTagsResult([]));

      dispatch(handleSetWebAppCredentialId(option || null));
    },
    [dispatch]
  );

  const handleChangeImageQuery = (value: string | undefined) => {
    dispatch(handleSetWebAppImageQuery(value || ""));
    timeout.current && clearTimeout(timeout.current);
    if (value && value !== "") {
      timeout.current = setTimeout(
        () => dispatch(getWebAppImagesAsync({ query: value })),
        1000
      );
    }
  };

  const handleChangeImageOption = (selected: string | undefined) => {
    // console.log("handleChangeImageOption", selected);
    dispatch(handleSetWebAppTagQuery(""));
    dispatch(handleSetWebAppTagValue(""));
    dispatch(handleSetWebAppTagsResult([]));

    dispatch(handleSetWebAppImageQuery(selected || ""));
    dispatch(handleSetWebAppImageValue(selected || ""));
    if (selected === imageValue) {
      dispatch(getWebAppTagsAsync());
    }
  };

  const handleChangeTagQuery = (value: string | undefined) => {
    dispatch(handleSetWebAppTagValue(""));
    dispatch(handleSetWebAppTagQuery(value || ""));
    // timeout.current && clearTimeout(timeout.current);
    // if (value && value !== "") {
    //   timeout.current = setTimeout(
    //     () => dispatch(getWebAppTagsAsync({ tagQuery: value })),
    //     2000
    //   );
    // }
  };

  const handleChangeTagOption = (selected: string | undefined) => {
    if (selected) {
      dispatch(handleSetWebAppTagQuery(selected));
    }
    dispatch(handleSetWebAppTagValue(selected || ""));
  };

  useEffect(() => {
    if (selectedCredentialId)
      dispatch(getWebAppImagesAsync({ query: imageValue }));
  }, [dispatch, imageValue, selectedCredentialId]);

  // handle image value changes
  useEffect(() => {
    if (imageValue !== "") {
      dispatch(getWebAppTagsAsync());
    } else {
      dispatch(handleSetWebAppImagesResult([]));
    }
  }, [dispatch, imageValue]);

  return (
    <div className="flex flex-col mt-10 space-y-12 md:space-y-16">
      <div className="grid  grid-cols-3 md:grid-cols-6">
        <Flex direction="col" className="mr-4 mb-2 col-span-3">
          <Typography.Text
            size="sm"
            className="font-medium !text-xs md:!text-sm"
          >
            Credential (Optional)
          </Typography.Text>
          <Typography.Text
            size="sm"
            uiType="secondary"
            className="!text-xs md:!text-sm"
          >
            Use a credential to access private images. Manage credentials in{" "}
            <CustomLink to={SettingsRegisteriesUrl}>Settings</CustomLink>.
          </Typography.Text>
        </Flex>
        <div className="w-full col-span-3">
          <Select<any>
            emptyString="No credential"
            clearable
            options={[
              ...credentials.map((credential) => {
                const { Icon } = getRegisteryData(credential.RegistryType);
                return {
                  label: (
                    <div className="flex items-center gap-1">
                      {Icon}
                      {credential.Name}
                    </div>
                  ),
                  value: credential.Id.toString(),
                  extraData: credential,
                };
              }),
            ]}
            value={selectedCredentialId || undefined}
            onChange={handleChangeCredential}
            loading={credentialsLoading}
            // disabled={imagesLoading || tagsLoading}
          />
        </div>
      </div>
      <div className="grid  grid-cols-3 md:grid-cols-6">
        <Flex direction="col" className="mb-2 mr-4 col-span-3">
          <Typography.Text className="!text-xs md:!text-sm font-medium">
            Image URL
          </Typography.Text>
          <Typography.Text uiType="secondary" className="!text-xs md:!text-sm ">
            The image URL for your external image.
          </Typography.Text>
        </Flex>
        <div className="w-full  col-span-3">
          <Combobox
            options={imagesOptions}
            value={imageValue}
            onChange={handleChangeImageOption}
            query={imageQuery}
            onChangeQuery={handleChangeImageQuery}
            placeholder="docker.io/library/nginx"
            loading={imagesLoading}
            optionsClassName="!max-h-[210px]"
            clearable
            clearQueryOnClose={false}
          />
        </div>
      </div>
      <div className="grid  grid-cols-3 md:grid-cols-6">
        {" "}
        <Flex direction="col" className="mb-2 mr-4 col-span-3">
          <Typography.Text className="!text-xs md:!text-sm font-medium">
            Image Tag
          </Typography.Text>
        </Flex>
        <div className="w-full  col-span-3">
          <Combobox
            options={tagOptions}
            value={tagValue}
            onChange={handleChangeTagOption}
            query={tagQuery}
            onChangeQuery={handleChangeTagQuery}
            loading={tagsLoading}
            disabled={imageValue === ""}
            optionsClassName="!max-h-[210px]"
            clearable={() => {
              handleChangeTagQuery("");
            }}
            clearQueryOnClose={false}
          />
        </div>
      </div>
    </div>
  );
};

const DeployingImageMessage: React.FC<{ className: string }> = ({
  className,
}) => {
  return (
    <div className={cn("py-8 text-sm", className)}>
      <Flex direction="col">
        <Typography.Text size="sm" className="text-base font-medium">
          Registry Credentials
        </Typography.Text>
        <Typography.Text
          size="sm"
          uiType="secondary"
          className="mt-2 leading-5"
        >
          We currently support private images from GitHub, GitLab, and Docker
          Hub registries. We don’t restrict public image registry types.
        </Typography.Text>
      </Flex>
    </div>
  );
};

// deploy from git
const DeployingGitCard = () => {
  return (
    <Flex direction="col">
      <Flex className="my-5">
        <Card title="Git Provider">
          <DeployingGitFrom />
        </Card>
      </Flex>
    </Flex>
  );
};

export const DeployingGitFrom = () => {
  //accounts
  const repoAccounts = useAppSelector(selectRepoAccounts);
  const repoAccountsLoading = useAppSelector(selectRepoAccountsLoading);

  //github data
  const githubReposData = useAppSelector(selectGithubReposData);
  const githubReposLoading = useAppSelector(selectGithubReposLoading);

  //
  const selectedRepo = useAppSelector(selectWebAppGitRepo);

  const dispatch = useAppDispatch();

  const getRepositoriesAccounts = useCallback(() => {
    dispatch(getRepositoriesAccountsAsync());
  }, [dispatch]);

  useEffect(() => {
    getRepositoriesAccounts();
  }, [getRepositoriesAccounts]);

  useEffect(() => {
    if (!repoAccountsLoading && repoAccounts.GithubAccessToken) {
      dispatch(getGithubRepositoriesAsync(repoAccounts.GithubAccessToken));
    }
  }, [dispatch, repoAccounts.GithubAccessToken, repoAccountsLoading]);

  return (
    <div className="flex flex-col min-h-[150px]">
      {(repoAccountsLoading || githubReposLoading) && (
        <Flex items="center" justify="center" className="min-h-full my-auto">
          <Loading borderSize={2} />
        </Flex>
      )}

      {!repoAccountsLoading && !githubReposLoading && (
        <>
          {(!githubReposData ||
            githubReposData.total_count === 0 ||
            repoAccounts?.GithubAccessToken === null) && (
            <Flex
              items="center"
              justify="center"
              className="min-h-full my-auto"
            >
              <EmptyState
                text={
                  <Flex direction="col" items="center">
                    {repoAccounts?.GithubAccessToken === null && (
                      <Typography.Text>
                        GitHub access is missing. Please{" "}
                        <CustomLink to={SettingsRegisteriesUrl}>
                          connect your account
                        </CustomLink>
                        .
                      </Typography.Text>
                    )}
                    {repoAccounts?.GithubAccessToken && !githubReposData && (
                      <>
                        <Typography.Text>
                          Unable to load repositories. Please try again later.
                        </Typography.Text>
                        <Button onClick={getRepositoriesAccounts}>
                          Reload
                        </Button>
                      </>
                    )}
                    {repoAccounts?.GithubAccessToken &&
                      githubReposData?.total_count === 0 && (
                        <Typography.Text>
                          No repositories available
                        </Typography.Text>
                      )}
                  </Flex>
                }
              />
            </Flex>
          )}
        </>
      )}

      {!repoAccountsLoading && !githubReposLoading && githubReposData && (
        <SimpleTable containerClassName="min-h-full">
          <SimpleTable.Body>
            {githubReposData.repositories.map((repo, i) => (
              <SimpleTable.Row
                onClick={() => {
                  dispatch(handleSetWebAppGitRepo(repo));
                }}
              >
                <SimpleTable.TD className="!border-b-0">
                  <Flex items="center" justify="between" className="gap-2">
                    <Flex items="center" className="flex-1 gap-2">
                      <GithubIcon className="w-4 flex-shrink-0" />
                      <Flex items="center" className="gap-1">
                        <Typography.Text size="sm" className="font-medium">
                          {repo.owner.login}
                        </Typography.Text>
                        <Typography.Text size="xs" uiType="secondary">
                          {" "}
                          /{" "}
                        </Typography.Text>
                        <Typography.Text size="sm">{repo.name}</Typography.Text>
                      </Flex>
                      <Tooltip
                        content="View repo"
                        className="!text-xs"
                        place="top-start"
                      >
                        <Link to={repo.html_url} target="_blank">
                          <LinkIcon className="w-3 flex-shrink-0" />
                        </Link>
                      </Tooltip>
                      <Typography.Text
                        size="xs"
                        uiType="secondary"
                        className="ml-2"
                      >
                        {
                          formatTimestamp(repo.pushed_at, "MM/DD/YYYY HH:mm")
                            .datetime
                        }
                      </Typography.Text>
                    </Flex>
                    {selectedRepo && selectedRepo.id === repo.id && (
                      <CheckIcon className="w-4 flex-shrink-0" />
                    )}
                  </Flex>
                </SimpleTable.TD>
              </SimpleTable.Row>
            ))}
          </SimpleTable.Body>
        </SimpleTable>
      )}
    </div>
  );
};
export default DeployingSourceStep;
