import { useAppDispatch, useAppSelector } from "../../../hooks";
import { Button, Checkbox, Modal, SimpleTable, Typography } from "djuno-design";
import { useCallback, useEffect, useState } from "react";
import {
  getDBSUsersAsync,
  handleToggleUserAclDeleteModal,
  selectDASUserAclDeleteModal,
  selectDatabaseService,
  selectDatabaseServiceLoading,
  selectDatabaseServiceUser,
  selectDatabaseServiceUserLoading,
  updateDBSUserAsync,
} from "../../../store/database/serviceSlice";
import { IDBSUserAcl } from "../../../types/database";

export const UserAclDeleteModal: React.FC = () => {
  const isOpen = useAppSelector(selectDASUserAclDeleteModal);

  const service = useAppSelector(selectDatabaseService);
  const serviceLoading = useAppSelector(selectDatabaseServiceLoading);

  const user = useAppSelector(selectDatabaseServiceUser);
  const userLoading = useAppSelector(selectDatabaseServiceUserLoading);

  const userActionLoading = useAppSelector(selectDatabaseServiceUserLoading);

  const dispatch = useAppDispatch();

  const [checkedAcls, setCheckedAcls] = useState<Array<string>>([]);
  const handleChangeChecked = (acl: IDBSUserAcl) => {
    setCheckedAcls((prev) => {
      if (prev.some((a) => a === acl.permission + "/" + acl.pattern)) {
        return prev.filter((a) => a !== acl.permission + "/" + acl.pattern);
      }
      return [...prev, acl.permission + "/" + acl.pattern];
    });
  };

  const handleSubmitForm = (data: any) => {
    if (!serviceLoading && !userActionLoading) {
      if (user && service) {
        let newAcls = user.acls?.filter(
          (acl) =>
            !checkedAcls.some((a) => a === acl.permission + "/" + acl.pattern)
        );
        dispatch(
          updateDBSUserAsync({
            id: service.id,
            engine: service.engine,
            userId: user.id,
            data: { acls: newAcls || [] },
          })
        ).then((action) => {
          if (action.type === "service/user/update/fulfilled") {
            dispatch(
              getDBSUsersAsync({ id: service.id, engine: service.engine })
            );
            handleOnClose();
          }
        });
      }
    }
  };

  const handleOnClose = useCallback(() => {
    setCheckedAcls([]);
    dispatch(handleToggleUserAclDeleteModal({ status: false }));
  }, [dispatch]);

  useEffect(() => {
    return () => {
      handleOnClose();
    };
  }, [handleOnClose]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleOnClose}
      contentClassName="!max-w-xl overflow-hidden"
      title={"Delete ACL"}
    >
      <div className="flex flex-col gap-3">
        <SimpleTable
          loading={userLoading || userActionLoading}
          containerClassName="min-h-[240px]"
        >
          <SimpleTable.Head>
            <SimpleTable.Row>
              <SimpleTable.TH lable="" />
              <SimpleTable.TH lable="Permission" />
              <SimpleTable.TH lable="Index model" />
            </SimpleTable.Row>
          </SimpleTable.Head>
          <SimpleTable.Body>
            {user?.acls?.map((acl, i) => (
              <SimpleTable.Row key={i}>
                <SimpleTable.TD>
                  <div className="flex items-center space-x-1 ml-1">
                    <div onClick={(event) => event.stopPropagation()}>
                      <Checkbox
                        value={checkedAcls.some(
                          (a) => a === acl.permission + "/" + acl.pattern
                        )}
                        onChange={() => handleChangeChecked(acl)}
                      />
                    </div>
                  </div>
                </SimpleTable.TD>
                <SimpleTable.TD>
                  <Typography.Text className="!text-xs md:!text-sm block">
                    {acl.permission}
                  </Typography.Text>
                </SimpleTable.TD>
                <SimpleTable.TD>
                  <Typography.Text className="!text-xs md:!text-sm block">
                    {acl.pattern}
                  </Typography.Text>
                </SimpleTable.TD>
              </SimpleTable.Row>
            ))}
          </SimpleTable.Body>
        </SimpleTable>

        <div className="flex justify-end gap-2">
          <Button uiType="simple" onClick={handleOnClose}>
            Cancel
          </Button>
          <Button
            uiType="primary"
            type="submit"
            loading={userLoading || userActionLoading}
            disabled={checkedAcls.length === 0}
            onClick={handleSubmitForm}
          >
            Delete selected ACLs
          </Button>
        </div>
      </div>
    </Modal>
  );
};
