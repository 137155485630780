import axios from "axios";
import { jwtEnvAxios } from ".";

export function getK8ServicesApi() {
  return jwtEnvAxios("k8").get(`/kube`);
}

export function updateK8ServiceApi(kubeId: string, data: any) {
  return jwtEnvAxios("k8").put(`/kube/${kubeId}`, data);
}

export function createK8ServiceApi(data: any) {
  return jwtEnvAxios("k8").post(`/kube`, data);
}

export function deleteK8ServiceApi(kubeId: string) {
  return jwtEnvAxios("k8").delete(`/kube/${kubeId}`);
}

export function getK8ServiceApi(kubeId: string) {
  return jwtEnvAxios("k8").get(`/kube/${kubeId}`);
}

export function getK8LogsUrlApi(kubeId: string) {
  return jwtEnvAxios("k8").post(`/kube/${kubeId}/log/url`, { kind: "audit" });
}

export function getK8LogsApi(url: string) {
  return axios.get(url);
}

export function getK8NodePoolsApi(kubeId: string) {
  return jwtEnvAxios("k8").get(`/kube/${kubeId}/nodepool`);
}

export function getK8IpRestrictionsApi(kubeId: string) {
  return jwtEnvAxios("k8").get(`/kube/${kubeId}/ipRestrictions`);
}

export function createK8IpRestrictionsApi(kubeId: string, data: Array<string>) {
  return jwtEnvAxios("k8").put(`/kube/${kubeId}/ipRestrictions`, { ips: data });
}

export function K8ResetClusterApi(kubeId: string, data: any) {
  return jwtEnvAxios("k8").post(`/kube/${kubeId}/reset`, data);
}
export function K8ResetKubeconfigApi(kubeId: string) {
  return jwtEnvAxios("k8").post(`/kube/${kubeId}/kubeconfig/reset`);
}
export function K8UpdatePolicyApi(kubeId: string, data: any) {
  return jwtEnvAxios("k8").put(`/kube/${kubeId}/updatePolicy`, data);
}
export function K8GetConfigureOIDCApi(kubeId: string) {
  return jwtEnvAxios("k8").get(`/kube/${kubeId}/openIdConnect`);
}
export function K8ConfigureOIDCApi(kubeId: string, data: any) {
  return jwtEnvAxios("k8").post(`/kube/${kubeId}/openIdConnect`, data);
}
export function K8DeleteConfigureOIDCApi(kubeId: string) {
  return jwtEnvAxios("k8").delete(`/kube/${kubeId}/openIdConnect`);
}
export function K8UpdateConfigureOIDCApi(kubeId: string, data: any) {
  return jwtEnvAxios("k8").put(`/kube/${kubeId}/openIdConnect`, data);
}
export function K8UpdateCustomizationApi(kubeId: string, data: any) {
  return jwtEnvAxios("k8").put(`/kube/${kubeId}/customization`, data);
}
export function K8GetMetricEtcdUsageListApi(kubeId: string) {
  return jwtEnvAxios("k8").get(`/kube/${kubeId}/metrics/etcdUsage`);
}
