import { useForm } from "react-hook-form";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Input, Modal, Typography } from "djuno-design";
import { useCallback, useEffect } from "react";
import {
  createDASPatternsAsync,
  getDASPatternsAsync,
  handleToggleDASPatternModal,
  selectDASPatternsActionLoading,
  selectDASShowEditor,
  selectDatabaseService,
} from "../../../store/database/serviceSlice";
import { DataAnalysisPatternCreateSchema } from "../../../utils/validations";

export const PatternCreateModal: React.FC = () => {
  const isOpen = useAppSelector(selectDASShowEditor);
  const service = useAppSelector(selectDatabaseService);
  const patternsActionLoading = useAppSelector(selectDASPatternsActionLoading);

  const dispatch = useAppDispatch();

  const methods = useForm({
    resolver: yupResolver(DataAnalysisPatternCreateSchema),
  });
  const {
    handleSubmit,
    register,
    formState: { errors, isValid },
    reset,
    setValue,
  } = methods;

  const handleSubmitForm = (data: any) => {
    if (!patternsActionLoading) {
      service &&
        dispatch(
          createDASPatternsAsync({
            engine: service.engine,
            clusterId: service.id,
            data: {
              pattern: data.pattern,
              maxIndexCount: +data.maxIndexCount,
            },
          })
        ).then((action) => {
          if (action.type === "service/patterns/create/fulfilled") {
            dispatch(
              getDASPatternsAsync({
                engine: service?.engine,
                clusterId: service?.id,
              })
            );
            handleOnClose();
          }
        });
    }
  };

  const handleOnClose = useCallback(() => {
    reset();
    dispatch(handleToggleDASPatternModal(false));
  }, [dispatch, reset]);

  useEffect(() => {
    return () => {
      handleOnClose();
    };
  }, [handleOnClose]);

  useEffect(() => {
    if (isOpen) {
      setValue("pattern", "");
      setValue("maxIndexCount", "");
    }
  }, [isOpen, setValue]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleOnClose}
      contentClassName="!max-w-xl overflow-hidden"
      title={"Add an template"}
    >
      <form className="mt-5" onSubmit={handleSubmit(handleSubmitForm)}>
        <div className="flex flex-col gap-3">
          <Typography.Text size="sm">
            Here you can use an encompassing correspondence model, i.e. logs_foo
            for the oldest indexes you want to delete as soon as the number of
            indexes exceeds this threshold. Please note that setting this model
            to 0 means that it will not be evaluated.
          </Typography.Text>
          <Typography.Text size="sm">
            This system can be very useful when, for example, OpenSearch is used
            to store logs and an index is created once a day. With this usage,
            you can then define how many days of logs you want to keep.
          </Typography.Text>

          <Typography.Text size="sm">Examples:</Typography.Text>
          <ul className="list-disc marker:text-primary-400 pl-8 space-y-1 list-image-[#eee]">
            <li>
              <Typography.Text size="sm">
                <Typography.Text size="sm" className="font-semibold">
                  "logs"
                </Typography.Text>{" "}
                will correspond to the{" "}
                <Typography.Text size="sm" className="font-semibold">
                  "logs"
                </Typography.Text>{" "}
                index, but not{" "}
                <Typography.Text size="sm" className="font-semibold">
                  "logsfoo"
                </Typography.Text>{" "}
                or
                <Typography.Text size="sm" className="font-semibold">
                  "foologs"
                </Typography.Text>
              </Typography.Text>
            </li>
            <li>
              <Typography.Text size="sm">
                <Typography.Text size="sm" className="font-semibold">
                  "logs*"
                </Typography.Text>{" "}
                will correspond to the{" "}
                <Typography.Text size="sm" className="font-semibold">
                  "logsfoo"
                </Typography.Text>{" "}
                and{" "}
                <Typography.Text size="sm" className="font-semibold">
                  "logs_foo_bar"
                </Typography.Text>{" "}
                indexes, but not{" "}
                <Typography.Text size="sm" className="font-semibold">
                  "foologs"
                </Typography.Text>
              </Typography.Text>
            </li>
            <li>
              <Typography.Text size="sm">
                <Typography.Text size="sm" className="font-semibold">
                  "*_logs_*"
                </Typography.Text>{" "}
                will correspond to the{" "}
                <Typography.Text size="sm" className="font-semibold">
                  "foo_logs_bar"
                </Typography.Text>{" "}
                index, but not
                <Typography.Text size="sm" className="font-semibold">
                  "foologsbar"
                </Typography.Text>
              </Typography.Text>
            </li>
            <li>
              <Typography.Text size="sm">
                <Typography.Text size="sm" className="font-semibold">
                  "logs.?"
                </Typography.Text>{" "}
                will correspond to the{" "}
                <Typography.Text size="sm" className="font-semibold">
                  "logs.1"
                </Typography.Text>{" "}
                index, but not
                <Typography.Text size="sm" className="font-semibold">
                  "logs.11"
                </Typography.Text>
              </Typography.Text>
            </li>
          </ul>
          <Input
            label="Model"
            {...register("pattern")}
            error={errors.pattern?.message}
            placeholder="Logs_*"
          />
          <Input
            label="Muximum number of indexes"
            {...register("maxIndexCount")}
            error={errors.maxIndexCount?.message}
            type="number"
          />

          <div className="flex justify-end gap-2">
            <Button uiType="simple" onClick={handleOnClose}>
              Cancel
            </Button>
            <Button
              uiType="primary"
              type="submit"
              loading={patternsActionLoading}
              disabled={!isValid}
            >
              Create
            </Button>
          </div>
        </div>
      </form>
    </Modal>
  );
};
