import { jwtEnvAxios } from ".";
import { CreateIpfsApiKeyData } from "../types/ipfs-api-key";

const GET_IPFS_API_KEYS_URL = `/ipfs/api-settings`;
const DELETE_IPFS_API_KEYS_URL = (id: number) => `/ipfs/api-settings/${id}`;
const CREATE_IPFS_API_KEYS_URL = `/ipfs/api-settings`;
const UPDATE_IPFS_API_KEYS_URL = (id: number) => `/ipfs/api-settings/${id}`;

export function getIpfsApiKeysApi() {
  return jwtEnvAxios().get(GET_IPFS_API_KEYS_URL);
}

export function deleteIpfsApiKeyApi(id: number) {
  return jwtEnvAxios().delete(DELETE_IPFS_API_KEYS_URL(id));
}

export async function createIpfsApiKeyApi(formData: CreateIpfsApiKeyData) {
  return jwtEnvAxios().post(CREATE_IPFS_API_KEYS_URL, formData);
}

export function updateIpfsApiKeyApi(
  id: number,
  formData: CreateIpfsApiKeyData
) {
  return jwtEnvAxios().put(UPDATE_IPFS_API_KEYS_URL(id), formData);
}

export function activateIpfsApi(isActive: boolean) {
  return jwtEnvAxios().put("/ipfs/drive/activate", { IsActive: isActive });
}
