import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { isAxiosError } from "axios";
import { IThunkRejectValue, RootState } from "../../types";
import type { PayloadAction } from "@reduxjs/toolkit";
import toast from "react-hot-toast";
import {
  createIpfsGatewayApi,
  deleteIpfsGatewayApi,
  getIpfsGatewaysApi,
  updateIpfsGatewayApi,
} from "../../apis/ipfsGatewaysAPI";
import {
  CreateIpfsGatewayData,
  IpfsGateway,
  IpfsGatewaysStateType,
} from "../../types/ipfs-gateway";
import { changeIpfsNodeStatus } from "./ipfsPublicSlice";
import { getExtractErrors } from "../../apis";
import { CustomErrorToast } from "../../components/general/Toast";
import { IpfsUpdatingMessage } from "./filesSlice";

const initialState: IpfsGatewaysStateType = {
  loading: false,
  gateways: [],
  selectedGateway: null,
  showEditorModal: false,
  actionLoading: false,
};

export const getIpfsGatewaysAysnc = createAsyncThunk<
  { gateways: IpfsGateway[] },
  undefined,
  IThunkRejectValue
>(
  "ipfs/gateways",
  async (_, { fulfillWithValue, rejectWithValue, dispatch }) => {
    try {
      const response = await getIpfsGatewaysApi();

      const { NodeStatus, Result } = response.data;
      const gateways = Result || [];

      dispatch(changeIpfsNodeStatus({ status: NodeStatus }));

      return fulfillWithValue({ gateways });
    } catch (e) {
      if (isAxiosError(e)) {
        dispatch(
          changeIpfsNodeStatus({
            status: e.response?.data.NodeStatus || 4,
            message: IpfsUpdatingMessage,
          })
        );
        return fulfillWithValue({ gateways: [] });
      } else {
        return rejectWithValue({ message: getExtractErrors(e) });
      }
    }
  }
);

export const deleteIpfsGatewayAsync = createAsyncThunk<
  any,
  { id: number },
  IThunkRejectValue
>("ipfs/gateways/delete", async ({ id }, { rejectWithValue }) => {
  try {
    const response = await deleteIpfsGatewayApi(id);
    return response.data;
  } catch (e) {
    return rejectWithValue({ message: getExtractErrors(e) });
  }
});

export const createIpfsGatewayAsync = createAsyncThunk<
  any,
  CreateIpfsGatewayData,
  IThunkRejectValue
>("ipfs/gateways/create", async (formData, { rejectWithValue }) => {
  try {
    const response = await createIpfsGatewayApi([formData]);
    return response.data;
  } catch (e) {
    return rejectWithValue({ message: getExtractErrors(e) });
  }
});

export const updateIpfsGatewayAsync = createAsyncThunk<
  any,
  { id: number; formData: CreateIpfsGatewayData },
  IThunkRejectValue
>("ipfs/gateways/update", async ({ id, formData }, { rejectWithValue }) => {
  try {
    const response = await updateIpfsGatewayApi(id, formData);
    return response.data;
  } catch (e) {
    return rejectWithValue({ message: getExtractErrors(e) });
  }
});

const ipfsGatewaysSlice = createSlice({
  name: "ipfsGateways",
  initialState: initialState,
  reducers: {
    clearGateways(state) {
      state.gateways = initialState.gateways;
      state.loading = initialState.loading;
    },
    handleShowEditorModal: (
      state,
      action: PayloadAction<{ gateway?: IpfsGateway }>
    ) => {
      state.showEditorModal = true;
      state.selectedGateway = action.payload.gateway || null;
    },
    handleHideEditorModal: (state) => {
      state.showEditorModal = false;
      state.selectedGateway = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getIpfsGatewaysAysnc.fulfilled, (state, action) => {
        state.gateways = action.payload.gateways;
        state.loading = false;
      })
      .addCase(getIpfsGatewaysAysnc.pending, (state) => {
        state.loading = true;
      })
      .addCase(getIpfsGatewaysAysnc.rejected, (state, { payload }) => {
        state.loading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(deleteIpfsGatewayAsync.fulfilled, (state, action) => {
        state.actionLoading = false;
      })
      .addCase(deleteIpfsGatewayAsync.pending, (state, action) => {
        state.actionLoading = true;
      })
      .addCase(deleteIpfsGatewayAsync.rejected, (state, { payload }) => {
        state.actionLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(createIpfsGatewayAsync.pending, (state) => {
        state.actionLoading = true;
      })
      .addCase(createIpfsGatewayAsync.fulfilled, (state) => {
        state.actionLoading = false;
      })
      .addCase(createIpfsGatewayAsync.rejected, (state, { payload }) => {
        state.actionLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(updateIpfsGatewayAsync.pending, (state) => {
        state.actionLoading = true;
      })
      .addCase(updateIpfsGatewayAsync.fulfilled, (state) => {
        state.actionLoading = false;
      })
      .addCase(updateIpfsGatewayAsync.rejected, (state, { payload }) => {
        state.actionLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });
  },
});

export const selectLoading = (state: RootState) => state.ipfsGateways.loading;
export const selectIPFSGatewayActionLoading = (state: RootState) =>
  state.ipfsGateways.actionLoading;

export const selectIpfsGateways = (state: RootState) =>
  state.ipfsGateways.gateways;

export const selectSelectedIpfsGateway = (state: RootState) =>
  state.ipfsGateways.selectedGateway;

export const selectShowEditorModal = (state: RootState) =>
  state.ipfsGateways.showEditorModal;

export const { clearGateways, handleHideEditorModal, handleShowEditorModal } =
  ipfsGatewaysSlice.actions;

export default ipfsGatewaysSlice.reducer;
