import { useAppDispatch, useAppSelector } from "../../../hooks";
import { WorkflowNotActiveTab } from "../../layouts/NotData";
import {
  activateWorkflowAsync,
  changeWorkflowNodeStatus,
  getWorkflowsAsync,
  selectWorkflowNodeStatus,
} from "../../../store/workflows/workflowsSlice";
import { PlansTab } from "../../plans/UniversalPlansBox";
import DeactivatePlan from "../../plans/DeactivatePlan";
import { useNavigate } from "react-router-dom";
import { WorkflowsUrl } from "../../../utils/urls";

const WorkflowPlansTab = () => {
  const workflowServiceType =
    Number(process.env.REACT_APP_WORKFLOW_SERVICE_TYPE) || 3;
  const workflowStatus = useAppSelector(selectWorkflowNodeStatus);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  if (workflowStatus !== 2) {
    return <WorkflowNotActiveTab />;
  }
  return (
    <>
      <PlansTab serviceType={workflowServiceType} />
      <DeactivatePlan
        deactivateFunction={() => {
          dispatch(activateWorkflowAsync({ isActive: false })).then(
            (action) => {
              if (action.type === "workflow/activate/fulfilled") {
                navigate(WorkflowsUrl);
              }
            }
          );
          // dispatch(changeWorkflowNodeStatus({ status: 5 }));
        }}
      />
    </>
  );
};

export default WorkflowPlansTab;
